import { Component, OnInit, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { position } from '../../model/data.modal';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { DcrService } from '../../services/dcr.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: any

  get name() { return this.user.username }
  get isLogin() { return this.user != undefined && this.user != null }
  constructor(
    private _auth: AuthService,
    private _dcr:DcrService,
    private elementRef: ElementRef,
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    this._auth.getCurrentSession().subscribe((session) => {
      this.user = session?.user;
    })
  }

  logout() {
    this._auth.logout();
  }

  logIn() {
    this._auth.logIn();
  }

  ngAfterViewInit() {
    this.setCartPosition();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event): void {
    this.setCartPosition();
  }

  setCartPosition(): void {
    let cartDiv = this.elementRef.nativeElement.querySelector('#userInfo');
    if (cartDiv == null) cartDiv = this.elementRef.nativeElement.querySelector('#loginLink')
    let x = cartDiv.offsetLeft - Math.floor(750 * (window.outerWidth / 1920));
    let y = cartDiv.offsetTop;
    this._dataService.changePosition(new position(x, y));
    console.log(x, y);
  }
}
