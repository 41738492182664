<div class="contain-inter">
    <app-favorite-page #favPage [section]="'database'" (saveSuccessBol) = "getSaveFlag($event)" (deleteSuccessBol)="deleteSucess($event)"></app-favorite-page>
    <app-common-modal #commoModal></app-common-modal>
    <div class="selproduct-area" [style.width]="selproductWid">
        <span class="collspan" (click)="widchange()">
            <em nz-icon [nzType]="left?'double-right':'double-left'" nzTheme="outline"></em>
        </span>
        <div class="selproduct-area-label" [hidden]="!left">Interoperability Comparison</div>
        <div class="selproduct-cond" [hidden]="left">
            <h2>Compare</h2>
            <h3>Select Solutions</h3>
            <ng-container *ngFor="let s of SelListModal,let i=index">
                <span> Solution {{i+1}}<em *ngIf="i!=0" style="font-size: 16px;float:right" nz-icon
                        (click)="delColumn(i,'column')" class="trash" nzType="delete" nzTheme="outline"></em>
                </span>
                <div class="SelCls">
                    <span>{{PH.SOLUTION}}</span>
                    <nz-select nzShowSearch [(ngModel)]="s.selectedPro" (ngModelChange)="onChange('column',i)"
                        nzPlaceHolder="Select a Solution">
                        <ng-container *ngFor="let p of s.ProductData">
                            <nz-option *ngIf="!LoadSel" [nzValue]="p" [nzLabel]="p.name"></nz-option>
                        </ng-container>
                        <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                            <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                        </nz-option>
                    </nz-select>
                    <div *ngIf="s.rebrandNameList.length">
                        <a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
                            "Also known as"
                            <em nz-icon nzType="question-circle" nzTheme="outline"></em>
                        </a>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item><strong>This solution is also known as:</strong></li>
                                <li nz-menu-item *ngFor="let name of s.rebrandNameList,let i = index">{{name}}</li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <span>{{PH.VERSION}}</span>
                    <nz-select *ngIf="!collectionBoolean" [disabled]="s.versionHide || !s.selectedPro" nzAllowClear
                        [nzMode]="versionSelMod" nzPlaceHolder="{{s.versionHide?'':'All versions'}}"
                        [(ngModel)]="s.selectedReleases" (ngModelChange)="ReleaseChange($event,i)">
                        <nz-option *ngFor="let option of s.VersionData" [nzLabel]="option.version"
                            [nzValue]="option">
                        </nz-option>
                    </nz-select>
                    <nz-select *ngIf="collectionBoolean" [disabled]="s.versionHide" nzAllowClear
                        [nzMode]="versionSelMod" nzPlaceHolder="Select a version" [(ngModel)]="s.selectedReleases"
                        (ngModelChange)="ReleaseChange($event,i)">
                        <nz-option *ngFor="let option of s.VersionData" [nzLabel]="option.version"
                            [nzValue]="option">
                        </nz-option>
                    </nz-select>
                </div>
            </ng-container>
            <button class="addSolution ant-btn-block" id="step1" nzType="primary" nz-button
                [disabled]="collectionBoolean||anotherFirBut" nzBlock (click)="addColumn('column')" style="width: 51%;">
                <em nz-icon nzType="plus-circle" nzTheme="outline"></em>ADD SOLUTION</button>
            <h2>With</h2>
            <h3>{{PH.THIRDPARTY}}</h3>
            <ng-container *ngFor="let s of SelRowListModal,let i=index">
                        <span> Solution {{i+1}}<em *ngIf="i!=0" style="font-size: 16px;float:right" nz-icon
                            (click)="delColumn(i,'row')" class="trash" nzType="delete" nzTheme="outline"></em>
                    </span>
                <div class="SelCls">
                    <span>{{PH.CATEGORY}}</span>
                    <nz-select [disabled]="collectionBoolean" nzShowSearch [(ngModel)]="s.selectCatagory"
                        (ngModelChange)="onChange('row',i)" [nzPlaceHolder]="PH.CATEGORY">
                        <ng-container *ngFor="let p of s.CategoryData">
                            <nz-option *ngIf="!LoadSel" [nzValue]="p" [nzLabel]="p.name"></nz-option>
                        </ng-container>
                        <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                            <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                        </nz-option>
                    </nz-select>
                    <ng-container *ngIf="s.ProductData">
                        <span>{{PH.SOLUTION}}</span>
                        <nz-select [disabled]="rowDisabled||collectionBoolean||!s.selectCatagory.id" nzAllowClear nzMode="multiple"
                            nzPlaceHolder="{{s.versionHide?'':PH.ALLSOLUTION}}" [(ngModel)]="s.selectProduct"
                            (ngModelChange)="ReleaseRowChange($event,i)">
                            <nz-option *ngFor="let option of s.ProductData"
                                [nzLabel]="option.name + ' ' +option.version" [nzValue]="option">
                            </nz-option>
                        </nz-select>
                    </ng-container>
                </div>
            </ng-container>
            <button class="addSolution ant-btn-block" id="step1" nzType="primary" nz-button
                [disabled]="collectionBoolean||anotherSecondBut" nzBlock (click)="addColumn('row')" style="width: 51%;">
                <em nz-icon nzType="plus-circle" nzTheme="outline"></em>ADD SOLUTION</button>
            <div class="fix-but-reset">
                <div class="fix-but">
                    <button class="checkInter" nzType="primary" (click)="onClick('checkInterop')" nz-button
                        [disabled]="LoadSel||disabledCheckInterop" nzBlock>
                        CHECK INTEROPERABILITY</button>
                </div>
                <span (click)="reset(!disabledCheckInterop||!tableEmpty)"
                    [ngStyle]="{'cursor':!disabledCheckInterop||!tableEmpty?'pointer':'not-allowed'}"
                    style=" color: #1d428a;font-weight: 600;font-size: 14px;">CLEAR</span>
            </div>
        </div>
    </div>
    <div class="tableShow-area" [style.width]="tableShowWid" [style.overflow]="tableShowOverflow">
            <!-- <nz-alert nzBanner *ngIf="hideTip" nzMessage='Unselect respective check boxes under "Hide Legacy Releases" section to check more interoperability.' nzCloseable style="position:sticky;left:0;">
        </nz-alert> -->
        <div class="rightBut" style="position:sticky;left:0;display: flex;justify-content: space-between;margin-top: 8px;">
            <div style="display: flex;justify-content: space-between;flex-direction: column;margin-bottom: -3px;">
                <span style="font-size:25px;color:#000000">Interoperability Result</span>
                <div style="font-weight: bold;">
                    <button class="btn" *ngIf="!saveFlag" style="padding: 0;border: none;color: #8C8C8C;"
                        (click)="onClick('add',$event)" [disabled]="canAddFavoBol">
                        <clr-icon id="cartCollect" shape="star" class="is-solid"
                            style="zoom: 2;position: fixed;display: none;"></clr-icon>
                        <clr-icon shape="star"></clr-icon>
                        Add to My Favorite List
                    </button>
                    <button class="btn" *ngIf="saveFlag" style="padding: 0;border: none;color: #8C8C8C;"
                        (click)="onClick('delete',$event)" [disabled]="canAddFavoBol">
                        <clr-icon shape="star" class="is-solid"></clr-icon>
                        Remove from My Favorite List
                    </button>

                    <label style="margin-left:8px;"
                                [nzDisabled]="collectionBoolean" (ngModelChange)="checkChange()" nz-checkbox
                                [(ngModel)]="checkOptionsTwo[0].checked">
                                Hide Legacy Releases
                                <em style="cursor: pointer;" nz-icon
                                    title="Unselect 'Hide Legacy Releases' to check the interoperability of end-of-support releases."
                                    nzType="exclamation-circle" nzTheme="outline" nz-tooltip></em>
                </label>
                <label style="margin-left:8px;"
                    (ngModelChange)="checkChange()" nz-checkbox
                    [(ngModel)]="checkOptionZero.checked">{{checkOptionZero.label}}</label>
                </div>
            </div>

            <div class="rightBut_child" style="display: flex;flex-direction: column;justify-content: space-between;text-align: right; padding-bottom: 10px;color: #0079B8;font-size: 12px;font-weight: 600;">

                <nz-button-group nzSize="small">
                    <a nz-button nzType="link" href="{{vcgAddress}}" target="_blank">Compatibility Guide <i nz-icon nzType="arrow-right"
                            nzTheme="outline"></i></a>
                </nz-button-group>
                <div>
                    <nz-button-group nzSize="small">
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu1"style="width: 100%;">
                        DOWNLOAD
                        <i nz-icon nzType="down"></i>
                    </button>
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu2" nzPlacement="bottomRight" style="padding: 0;">
                        <i nz-icon nzType="ellipsis"></i>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':onClick('csv')" nz-tooltip [nzTooltipTitle]="tooltipOne[1]">CSV
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':onClick('copy')" nz-tooltip [nzTooltipTitle]="tooltipOne[2]">COPY
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <button *ngIf="CollapseBoolean" nzSize="small" nz-button
                        (click)="onClick('collapse')" style="margin-left: 35px;pointer-events: auto;"
                        [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}">
                        Collapse All
                    </button>
                <button *ngIf="!CollapseBoolean" nzSize="small" nz-button
                    (click)="onClick('collapse')" style="margin-left: 35px;pointer-events: auto;"
                    [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}">
                    Expand All
                </button>
                </div>

            </div>

        </div>
        <div class="tableShow-table">
            <nz-table #groupingTable style="width:auto" id="groupingTable" [nzData]="panels" nzBordered nzSize="small"
                nzHideOnSinglePage="true" [nzFrontPagination]="false" [nzNoResult]="emptyTemplate">
                <thead>
                    <tr>
                        <th rowspan="2" nzWidth="1%" nzLeft class="lenged" style="background: white;">
                            <div style="width: 330px;display: flex;">
                                <ul>
                                    <li title="{{tooltipTwo[0]}}" class="comptibility">Compatible</li>
                                    <li title="{{tooltipTwo[2]}}" class="Incompatible">Incompatible </li>
                                    <li title="{{tooltipTwo[3]}}" class="notSupport">Not Supported </li>
                                </ul>
                                <ul>
                                    <li title="{{tooltipTwo[5]}}" class="EOTS">Past End of Technical Guidance</li>
                                    <li title="{{tooltipTwo[4]}}" class="ENGS">Past End of General Support</li>
                                </ul>
                            </div>
                        </th>
                        <ng-container nzLeft *ngFor='let hd of VerHD | cumgroupBy: "childProductName"'>
                            <th nzLeft style="text-align: left;top: 0px;z-index: 20;background: white;"
                                [colspan]="hd.value.length" class="firstTh firstThText">{{hd.key}}</th>
                        </ng-container>
                    </tr>
                    <tr>
                        <th nzLeft nz-tooltip [nzTooltipTitle]="(v.version==='Nonversioned' || v.version==='0.0.0')?'':titleTemplate" class="secondThText"
                            style="text-align: center;top: 40px;z-index: 20;font-size: 12px;background: white;"
                            *ngFor='let v of VerHD'>
                            <span> {{(v.version==='Nonversioned' || v.version==='0.0.0')?"":v.version}}</span>
                            <span [ngClass]="{'techGuided' : !v.techGuided }"></span>
                            <span [ngClass]="{'genGuided': !v.genGuided }"></span>
                            <ng-template #titleTemplate>
                                <span>{{v.version}}</span><br>
                                <span>GA : {{formatTime(v.gaDate)}}</span><br>
                                <span *ngIf="v.rnLink"><a href="{{v.rnLink}}" target="_blank">Release
                                        Notes</a><br></span>
                                <span *ngIf="v.dLink"><a href="{{v.dLink}}" target="_blank">Download
                                        Page</a><br></span>
                            </ng-template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let Category of panels,let i = index">
                        <tr *ngIf="Category.versions && Category.versions.length != 0">
                            <td class="productTR" [nzShowExpand]="Category.nzShowExpand"
                                [(nzExpand)]="Category.nzExpand" [attr.colspan]="VerHD.length+1">
                                {{Category.name}}
                            </td>
                        </tr>
                        <ng-container *ngFor="let vendor of Category.versions">
                            <ng-container *ngFor="let product of vendor.products,let j = index">
                                <tr *ngIf="Category.nzExpand">
                                    <td class="tdProduct" style="color: rgba(0,0,0,.85);" nzLeft >
                                        {{vendor.name}} - {{product.name}} {{product.version}}</td>
                                    <ng-container *ngFor="let release of getHD(product.prodReleaseMap)">
                                        <td *ngIf="release.footnotes==''" (mouseover)="tdMouseOver(i,j)"
                                            (mouseout)="tdMouseOut()"
                                            [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                            [ngClass]="getTdStyle(release)">
                                        </td>
                                        <td *ngIf="release.footnotes!=''" nz-tooltip
                                            (mouseover)="tdMouseOver(i,j)" (mouseout)="tdMouseOut()"
                                            [nzTooltipTitle]="tdInfo"
                                            [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                            [ngClass]="getTdStyle(release)">
                                        </td>
                                        <ng-template #tdInfo>
                                            <span [innerHTML]="release.footnotes"></span><br>
                                        </ng-template>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
        <div id="databasePrint" [style.display]="copyType">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th class="secondThText" *ngFor='let version of VerHD'> {{version.productName}}
                            {{version.version}} </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let Category of panels,let i = index">
                        <ng-container *ngFor="let vendor of Category.versions">
                            <ng-container *ngFor="let product of vendor.products">
                                <tr *ngIf="Category.nzExpand">
                                    <td>{{vendor.name}} -{{product.name}} {{product.version}}</td>
                                    <ng-container *ngFor="let release of getHD(product.prodReleaseMap)">
                                        <td>{{getTdStyle(release,'print')}}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
                <tr></tr>
            </table>
            <span>* This is a legacy release. For more information, please refer to https://lifecycle.vmware.com.</span>
        </div>
    </div>





    <ngx-loading [show]="isVisible" [config]="{ backdropBorderRadius: '3px'}" [template]="customLoadingTemplate">
    </ngx-loading>
    <ng-template #emptyTemplate>
        <tr>
            <nz-empty *ngIf="NoResultId=='No Data'" nzNotFoundImage="simple"></nz-empty>
            <nz-empty *ngIf="NoResultId=='No Result'"
                nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                [nzNotFoundContent]="contentTpl">
                <ng-template #contentTpl>
                    <span> {{NoResultId}} </span>
                </ng-template>
            </nz-empty>
        </tr>
    </ng-template>
