import { Injectable } from '@angular/core';
import { position } from '../model/data.modal';
import { Subject } from 'rxjs';
import { RESOURCE_TYPE } from '../model/common.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  productSelData: any[] = [];//save interoperability select data
  solutionSelData: any[] = [];//save database select data
  upgradeSelData: any[] = [];
  multiCloudSelData: any[] = [];
  productFavData: any[] = [];//save interoperability favor data
  multiCloudFavData: any[] = [];//save multiCloud favor data
  solutionFavData: any[] = [];//save database favor data
  upgradeFavData: any[] = [];
  collectionData: any[] = [];//save collection data
  versionData: any[] = [];
  //cart service
  private cartPosition = new Subject<position>();
  public firstPostion;
  private cartCount = new Subject<number>();
  public total: number = 0;

  cartPosition$ = this.cartPosition.asObservable();
  cartCount$ = this.cartCount.asObservable();

  changePosition(position) {
    this.cartPosition.next(position);
    this.firstPostion = position;
  }

  changeCartCount(num: number) {
    this.cartCount.next(num)
  }

  constructor() { }

  clearDataOfType(type) {
    switch (type) {
      case RESOURCE_TYPE.INVENTORY:
        this.clearProductFavData();
        return
      case RESOURCE_TYPE.MULTICLOUD:
        this.clearMultiCloudFavData();
        return
      case RESOURCE_TYPE.THIRDPARTY:
        this.clearSolutionFavData();
        return
      case RESOURCE_TYPE.UPGRADE:
        this.clearUpgradeFavData();
        return
    }
  }

  getProductSelData() {
    return this.productSelData
  }

  setProductSelData(val) {
    this.productSelData = val;
  }

  getSolutionSelData() {
    return this.solutionSelData;
  }

  setSolutionSelData(val) {
    this.solutionSelData = val;
  }

  getUpgradeData() {
    return this.upgradeSelData;
  }

  setUpgradeData(val) {
    this.upgradeSelData = val;
  }

  getMultiCloudData() {
    return this.multiCloudSelData;
  }

  setMultiCloudData(val) {
    this.multiCloudSelData = val;
  }
  getProductFavData() {
    return this.productFavData
  }

  setProductFavData(val) {
    this.productFavData = val;
  }

  getMultiCloudFavData() {
    return this.multiCloudFavData
  }

  setMultiCloudFavData(val) {
    this.multiCloudFavData = val
  }

  clearMultiCloudFavData() {
    this.multiCloudFavData = []
  }

  clearProductFavData() {
    this.productFavData = [];
  }

  getSolutionFavData() {
    return this.solutionFavData;
  }

  setSolutionFavData(val) {
    this.solutionFavData = val;
  }

  clearSolutionFavData() {
    this.solutionFavData = [];
  }

  getUpgradeFavData() {
    return this.upgradeFavData;
  }

  setUpgradeFavData(val) {
    this.upgradeFavData = val;
  }

  clearUpgradeFavData() {
    this.upgradeFavData = [];
  }

  getCollectionData() {
    return this.collectionData;
  }

  setCollectionData(val) {
    this.collectionData = val;
  }

  getVersionData() {
    return this.versionData
  }

  setVersionData(val) {
    this.versionData = val;
  }

}
