import { Component, OnInit, TemplateRef, ViewChild, HostListener, ElementRef, Renderer2 } from '@angular/core';
//mock
import { productMock } from "../../mock/product-mock";
import { DcrService } from "../../services/dcr.service";
import { CommonService } from "../../services/common.service";
import * as moment from "moment";
import { NgxLoadingComponent } from "ngx-loading";
import { Router, ActivatedRoute } from '@angular/router';
import { truncate } from 'fs';
import { Location } from '@angular/common';
import { FavoritePageComponent } from '../favorite-page/favorite-page.component';
import { EVENT_TYPE } from '../../model/common.model';
import { DataService } from '../../services/data.service';
import * as $ from "jquery";
import { forkJoin } from 'rxjs';
import { position } from '../../model/data.modal';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { groupBy } from 'lodash';

@Component({
  selector: 'app-multi-cloud',
  templateUrl: './multi-cloud.component.html',
  styleUrls: ['./multi-cloud.component.scss']
})
export class MultiCloudComponent implements OnInit {

  @ViewChild('favPage', { static: false }) favPage: FavoritePageComponent;
  @ViewChild('commoModal', { static: false }) commoModal: CommonModalComponent;
  saveFlag: boolean;
  favoriteList: any[] = [];
  canAddFavoBol: boolean = true;
  deleteId: any;
  collect: any[];
  disabledCheckInterop: boolean = true;
  tableShowOverflow: string;
  selproductWid: string; tableShowWid: string;
  left: boolean = false;
  CollapseBoolean: boolean = true;
  urlChangeBoolean: boolean = true;
  //table columns products
  productListData: any;
  columnData: any[] = [{ id: "", releases: [] }];
  ShowColumnData: any[];
  ShowColumn: string[] = [];

  rowLen: number = 0;
  readonly statusCls: string[] = ["", "tdCompatible", "tdIncompatible", "tdCompatibleNT", "tdNotSupport"];
  readonly statusInfoCls: string[] = ["", "tdCompatibleinfo", "tdIncompatibleinfo", "tdCompatibleNTinfo", "tdNotSupportinfo"];

  tableTdWords = [, 'Compatible', 'Incompatible', 'Compatible', 'Not Supported'];
  checkOptionsOne = [
    { label: 'Compatible', value: 'Apple', checked: false },
    { label: 'Compatible: Not Tested', value: 'Orange', checked: false },
    { label: 'Incompatible', value: 'Pear', checked: false },
    { label: 'Not Supported', value: 'Orange', checked: true }
  ];
  checkOptionsTwo = [
    { label: 'Past End of General Support', value: 'Apple', checked: true },
    { label: 'Past End of Technical Guidance', value: 'Pear', checked: true },
  ];

  tooltipOne: string[];
  tooltipTwo: string[];

  cartPosition: position = null;
  flingsLink = "https://vsd.esp.vmware.com";
  vsdAddress = "https://vsd.esp.vmware.com"
  vsdLink:string
  vcgAddress = "https://www.vmware.com/resources/compatibility/search.php"

  @ViewChild('ngxLoading', { static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false }) customLoadingTemplate: TemplateRef<any>;
  constructor(
    private dcrService: DcrService,
    private common: CommonService,
    private elementRef: ElementRef,
    private router: Router,
    private routerInfo: ActivatedRoute,
    private location: Location,
    private _data: DataService,
    private renderer: Renderer2
  ) {
    location.onUrlChange(url => this.ngAfterContentInit());
  }
  ngOnInit(): void {
    //left nav collapse
    this.selproductWid = "400px";
    this.tableShowWid = "";
    this.vsdLink = this.flingsLink
    //table
    // this.checkSession();
    this.getUpSel();
    this.getFavoriteList();
    if (this.SelRowListModal[0].selectedPro == 0) {
      this.disabledCheckInterop = false;
    }
    this.tooltipOne = this.common.tooltipForCCP;
    this.tooltipTwo = this.common.tooltipForLenged;
    if (!this.cartPosition) {
      this.cartPosition = this._data.firstPostion;
    }
  }

  params: any;
  ngAfterContentInit(): void {
    if (window.location.pathname.indexOf('MultiCloud') == -1) {
      return
    }
    setTimeout(() => {
      this.routerInfo.queryParams.subscribe((params) => {
        if (JSON.stringify(params) != '{}' && !params.hasOwnProperty("productId")) {
          let paramFilters = {
            isHideGenSupported: "isHideLegacyReleases" in params ? JSON.parse(params['isHideLegacyReleases']) : true,
            isHideTechSupported: "isHideLegacyReleases" in params ? JSON.parse(params['isHideLegacyReleases']) : true,
            col: params['col'],
            row: params['row'] 
          }
          params = Object.assign(this.filter, paramFilters);
          this.urlGetMatrix(params);
          let pid = this.getPid(params)
          this.vsdLink = pid.length>0?this.vsdAddress+`/interops/1?pids=`+ pid.toString():this.flingsLink
          this.params = params;
        } else {
          this.hideTip = false;
          this.disabledCheckInterop = true;
          this.checkOptionsOne[0].checked = false;
          this.checkOptionsOne[1].checked = false;
          this.checkOptionsOne[2].checked = false;
          this.checkOptionsOne[3].checked = true;
          this.checkOptionsTwo[0].checked = true;
          this.checkOptionsTwo[1].checked = true;
          this.clearTableData();
          this.SelListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
          this.SelRowListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
          if (this.selValNoAll) this.SelRowListModal[0].ProductData = [productMock.AllSolution, ...this.selValNoAll2];
          if (this.selValNoAll) this.SelListModal[0].ProductData = this.selValNoAll;
        }
      }, error => {
        console.log('error');
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      });
    }, 1000);
  }
  ngAfterViewInit(): void {
    if (window.location.hash.indexOf('Interoperability') == -1) {
      return
    }
  }

  urlGetMatrix(params) {
    let p = Object.assign({ rows: [], columns: [] }, params);
    let columns = params['col']?.split("&"), columnsVer = [], columnObj = {};
    for (var i = 0; i < columns?.length; i++) {
      columnsVer = columns[i]?.split(',');
      columnObj = {
        product: columnsVer[0],
        releases: columnsVer.slice(1).toString() == '' ? [] : columnsVer.slice(1)
      }
      p.rows.push(columnObj);
    }
    let rows = params['row']?.split("&"), rowsVer = [], rowsObj = {};
    for (var i = 0; i < rows?.length; i++) {
      rowsVer = rows[i]?.split(',');
      rowsObj = {
        product: rowsVer[0],
        releases: rowsVer.slice(1).toString() == '' ? [] : rowsVer.slice(1)
      }
      p.columns.push(rowsObj);
    }
    this.urlChangeBoolean && this.getMatrix(p)
  }

  getUrl(params) {
    if (params) {

      this.anotherFirBut = false;
      this.disabledCheckInterop = false;
      this.setRowUrl(params['col']);
      this.setColUrl(params['row']);
      this.setChecklist(params);
      this.canAddFavoBol = false;
      this.containFavo();
    }
  }

  setRowUrl(c) {
    this.SelListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
    let pdc = this.selValNoAll;
    let cList = c.split("&"), version, rebrand = [];
    for (let i = 0; i < cList.length; i++) {
      let vList = cList[i].split(",").map(Number);
      let verHide = false;
      if (!vList[i]) {
        // this.SelListModal[i].ProductData = [...this.selValNoAll, ...this.collections].sort((a, b) => { return this.common.sortFn(a, b) });
      }
      // version = this.common.getVersion(vList[0], this.selValNoAll);
      version = this.common.getVersion(vList[0], this.selValNoAll);
      version = this.common.reDuplicates(version);
      rebrand = this.common.getNameList(vList[0], this.selValNoAll)
      if (version[0].version == "Nonversioned" || version[0].version == "0.0.0") {
        verHide = true;
      }
      if (i == 0) {
        Object.assign(this.SelListModal[0], { "ProductData": pdc, "VersionData": version, "selectedPro": vList[0], "selectedReleases": [], versionHide: verHide, rebrandNameList: rebrand });
        if (vList.length - 1 != version.length) {
          this.SelListModal[i].selectedReleases = vList.slice(1) == 0 ? [] : vList.slice(1);
        }
      } else {
        if (vList.length - 1 != version.length) {
          this.SelListModal.push({ "ProductData": pdc, "VersionData": version, "selectedPro": vList[0], "selectedReleases": verHide ? [] : (vList.slice(1) == 0 ? [] : vList.slice(1)), versionHide: verHide, rebrandNameList: rebrand });
        } else {
          this.SelListModal.push({ "ProductData": pdc, "VersionData": version, "selectedPro": vList[0], "selectedReleases": [], versionHide: verHide, rebrandNameList: rebrand });
        }
      }

    }
  }

  setColUrl(r) {
    this.SelRowListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
    let pdr = [productMock.AllSolution, ...this.selValNoAll2];
    let pdc = this.selValNoAll;
    let cList = r.split("&"), version, rebrand = [];
    for (let i = 0; i < cList.length; i++) {
      let vList = cList[i].split(",").map(Number);
      let verHide = false;
      if (vList[0] != 0) {
        // version = this.common.getVersion(vList[0], this.selValNoAll);
        version = this.common.getVersion(vList[0], this.selValNoAll2);
        version = this.common.reDuplicates(version);
        rebrand = this.common.getNameList(vList[0], this.selValNoAll2)
        this.rowDisabled = false;
        if (version[0].version == "Nonversioned" || version[0].version == "0.0.0") {
          verHide = true;
        }
      } else {
        this.SelRowListModal = [{ "ProductData": pdr, "VersionData": version, "selectedPro": vList[0], "selectedReleases": [], versionHide: verHide, rebrandNameList: rebrand }];
      }
      if (!vList[i]) {
        // this.SelListModal[i].ProductData = [...this.selValNoAll, ...this.collections].sort((a, b) => { return this.common.sortFn(a, b) });
      }
      if (i == 0) {
        Object.assign(this.SelRowListModal[0], { "ProductData": pdr, "VersionData": version, "selectedPro": vList[0], "selectedReleases": [], versionHide: verHide, rebrandNameList: rebrand });
        if (version && vList.length - 1 != version.length) {
          this.SelRowListModal[i].selectedReleases = vList.slice(1) == 0 ? [] : vList.slice(1);
        }
      } else {
        if (vList.length - 1 != version.length) {
          this.SelRowListModal.push({ "ProductData": pdr, "VersionData": version, "selectedPro": vList[0], "selectedReleases": verHide ? "" : vList.slice(1) == 0 ? [] : vList.slice(1), versionHide: verHide, rebrandNameList: rebrand });
        } else {
          this.SelRowListModal.push({ "ProductData": pdr, "VersionData": version, "selectedPro": vList[0], "selectedReleases": [], versionHide: verHide, rebrandNameList: rebrand });
        }
      }
    }
    this.hideTip = this.common.hideTooltip(this.SelRowListModal) || this.common.hideTooltip(this.SelListModal);
  }
  //collect
  collections;
  getCollection() {
    this.dcrService.getCollections().subscribe(data => {
      this._data.setCollectionData(data);
      this.collections = data;
      console.log(this.collections)
      this.getUpSel();
    }, error => {
      this.getUpSel();
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    })

  }

  //inter Select data
  LoadSel: boolean = true;
  selValNoAll;
  selValNoAll2;

  getUpSel() {
    if (this.common.getItem("tabNu") == "1") {
      forkJoin({
        data: this.dcrService.getProduct("MultiCloud"),
        data2: this.dcrService.getProduct("product")
      }).subscribe(({ data, data2 }) => {
        this.LoadSel = false;
        this.selValNoAll = JSON.parse(JSON.stringify(data));
        this.selValNoAll2 = JSON.parse(JSON.stringify(data2));
        this._data.setMultiCloudData(this.selValNoAll);
        this.selValNoAll = this.selValNoAll;
        this.selValNoAll2 = this.selValNoAll2;
        this.SelListModal[0].ProductData = this.selValNoAll;

        this.SelRowListModal[0].ProductData = [productMock.AllSolution, ...this.selValNoAll2];

        setTimeout(() => {
          this.getUrl(this.params);
        }, 3000);
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })
    }
  }

  setChecklist(filter) {
    Object.assign(this.filter, filter);
    this.checkOptionsOne[0].checked = filter.isHideCompatible;
    this.checkOptionsOne[1].checked = filter.isHideNTCompatible;
    this.checkOptionsOne[2].checked = filter.isHideIncompatible;
    this.checkOptionsOne[3].checked = filter.isHideNotSupported;
    this.checkOptionsTwo[0].checked = filter.isHideGenSupported;
    this.checkOptionsTwo[1].checked = filter.isHideTechSupported;
  }

  topColumn: any[];
  tableEmpty: boolean = true;
  setTableData(data, type) {
    this.tableEmpty = false;
    // this.columnData = this.convertThList(Object.values(data)[0][0].rowProdReleaseMap).sort((a, b) => { return this.common.sortId(a, b) });
    this.columnData = this.convertThList(Object.values(data)[0][0].rowProdReleaseMap[0])
    // this.columnData.sort((a, b) => (a.childId > b.childId) ? 1 : ((b.childId > a.childId) ? -1 : 0))
    this.columnData = this.common.removeRepeat(this.columnData);
    this.topColumn = JSON.parse(JSON.stringify(this.columnData));
    if (type == "show") this.ShowColumnData = JSON.parse(JSON.stringify(this.columnData))
    if (this.columnData.length == 1) {
      this.rowLen = this.columnData[0].releases.length
    } else {
      this.columnData.forEach(c => this.rowLen += c.releases.length)
    }
    this.rowLen += 1;
    this.productListData = this.convertTableList(data);
  }

  convertThList(relseseMap) {
    let releaseList = groupBy(relseseMap,'childProductName');
    let thList = [];
    Object.keys(releaseList).forEach((key,index) => {
      thList.push({
            id: index,
            // childId: relseseMap[r][0].childId,
            name: key,
            length: releaseList[key].length,
            releases: releaseList[key],
            checked: true,
            disabled: false
          })
    });
    // let relseseKeyList = Obje
    // let relseseKeyList = Object.keys(relseseMap)
    // if (this.ShowColumn.length) relseseKeyList = this.ShowColumn;
    // let thList = [], obj = {};
    // relseseKeyList.forEach((r) => {
    //   thList.push({
    //     id: r,
    //     // childId: relseseMap[r][0].childId,
    //     name: relseseMap[r][0].productName,
    //     length: relseseMap[r].length,
    //     releases: relseseMap[r].sort((a, b) => { return this.common.sortVersion(a, b) }),
    //     checked: true,
    //     disabled: false
    //   })
    // })
    console.log(thList)
    if (thList.length == 1) {
      thList[0].disabled = true;
    }
    return thList

  }

  convertTableList(TableData) {
    let tableKeyList = Object.keys(TableData)
    // .sort(function (s1, s2) {
    //   var l = s1.toLowerCase(), m = s2.toLowerCase();
    //   return l === m ? 0 : l > m ? 1 : -1;
    // });
    let dataList = [], obj = {};
    tableKeyList.forEach(r => {
      dataList.push({
        id: r,
        name: TableData[r][0].childProductName ? TableData[r][0].childProductName : TableData[r][0].productName,
        length: this.rowLen,
        expand: true,
        releases: TableData[r]
      })
    })
    dataList.forEach(r => {
      r.releases.forEach(v => {
        v.rowProdReleaseMap = v.rowProdReleaseMap ? this.convertThList(v.rowProdReleaseMap[0]) : []
      })
    })
    return dataList

  }

  widchange() {

    this.left = !this.left;
    this.selproductWid = this.left ? "25px" : "400px";
    this.tableShowWid = "";

  }

  //change class
  displayStatus(status: number, note: string, rowChildRelease): string {

    let cls = (!rowChildRelease.techGuided || !rowChildRelease.genGuided) ? ' trSupp' : " ";
    return note == '' || note == null ? `${this.statusCls[status]} + ${cls}` : `${this.statusInfoCls[status]} + ${cls}`;

  }

  display() {

  }
  isVisible: boolean = false;
  NoResultId = "No Data"
  getMatrix(obj) {

    setTimeout(() => {
      if (window.location.hash.indexOf("Interoperability") > -1) {
        this.common.setItem("hashTab1", window.location.hash);
      }
    }, 20)
    this.isVisible = true;
    this.dcrService.getInteroperabilityMatrix(obj).subscribe(data => {

      if (JSON.stringify(data) == "{}") {
        this.clearTableData();
      } else {
        this.ShowColumn = [];
        this.dcrService.InterMatrix = JSON.parse(JSON.stringify(data))
        this.setTableData(JSON.parse(JSON.stringify(data)), "show")
        this.tableShowOverflow = "auto";
        this.CollapseBoolean = true;
      }
      this.isVisible = false;
      // this.common.createMessage('warning');
    }, error => {
      this.clearTableData();
      this.isVisible = false;
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      } else {
        this.NoResultId = 'No Result';
      }
    })

  }
  collectionBoolean = false;
  anotherFirBut = true;
  versionSelMod = "multiple";
  getCollectionMatrix(collection) {
    let params: any = {
      "columns": [],
      "rows": [],
      "isCollection": true,
      "isHideGenSupported": false,
      "isHideTechSupported": false,
      "isHideCompatible": false,
      "isHideIncompatible": false,
      "isHideNTCompatible": false,
      "isHideNotSupported": true,
    }
    let keys = [], obj = { product: "", releases: [] }, componentsList = [];
    collection.components.forEach(r => {
      keys.push(Object.keys(r)[0])
    })
    keys = [...new Set(keys)];
    for (let k of keys) {
      obj = { product: "", releases: [] }
      obj.product = k;
      collection.components.forEach(r => {
        if (k == Object.keys(r)[0]) {
          obj.releases.push(Object.values(r)[0])
        }
      })
      componentsList.push(obj);
    }
    // let componentsList = collection.components;
    params.columns = componentsList;
    params.rows = componentsList;
    this.getMatrix(params);
  }

  //select mock data
  SelListModal: any[] = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];

  addColumn() {
    // let pd = [...this.selValNoAll, ...this.collections].sort((a, b) => { return this.common.sortFn(a, b) });
    this.SelListModal.push({ "ProductData": this.selValNoAll, "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] })
    this.anotherFirBut = true;
  }

  delColumn(index) {

    this.SelListModal.splice(index, 1);
    // this.formParams(this.SelRowListModal, this.SelListModal);
    this.anotherFirBut = false;
  }

  clearTableData() {
    this.tableEmpty = true;
    this.productListData = [];
    this.columnData = [{ id: "", releases: [] }];
    this.tableShowOverflow = "hidden";
    this.common.removeItem("hashTab1");
  }

  reset(t) {
    if (t) {
      this.hideTip = false;
      this.disabledCheckInterop = true;
      this.checkOptionsOne[0].checked = false;
      this.checkOptionsOne[1].checked = false;
      this.checkOptionsOne[2].checked = false;
      this.checkOptionsOne[3].checked = true;
      this.checkOptionsTwo[0].checked = true;
      this.checkOptionsTwo[1].checked = true;
      this.clearTableData();
      this.SelListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
      this.SelRowListModal = [{ "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] }];
      this.SelRowListModal[0].ProductData = [productMock.AllSolution, ...this.selValNoAll2];
      this.SelListModal[0].ProductData = this.selValNoAll;
      let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
      this.router.navigateByUrl(url);
      this.saveFlag = false;
      this.canAddFavoBol = true;
      this.vsdLink = this.flingsLink
    }
  }
  checkCollect() {
    this.collectionBoolean = true;
    // this.versionSelMod = "default";
    this.clearTableData();

    this.checkOptionsOne[2].checked = false;
    this.checkOptionsTwo[0].checked = false;
    this.checkOptionsTwo[1].checked = false;
  }

  ProductChange(value, index): void {
    this.anotherFirBut = false;
    this.disabledCheckInterop = false;
    this.SelListModal[index].rebrandNameList = [];
    if (value == null) {
      this.SelListModal[index].VersionData = [];
      return
    }
    this.collectionBoolean = false;
    this.versionSelMod = "multiple";
    this.SelListModal[index].selectedReleases = [];

    if (value) {

      let version = this.common.getVersion(value, this.selValNoAll);
      version = this.common.reDuplicates(version);
      this.SelListModal[index].VersionData = version;
      if (this.SelListModal[index].VersionData[0].version == "Nonversioned" || this.SelListModal[index].VersionData[0].version == "0.0.0") {
        this.SelListModal[index].versionHide = true;
      } else {
        this.SelListModal[index].versionHide = false;
      }
      if (this.SelListModal.length == 1 && this.SelRowListModal.length == 1) {
        this.SelRowListModal[0].selectedPro = 0;
        this.SelRowListModal[0].selectedReleases = [];
        this.SelRowListModal[index].rebrandNameList = [];
        this.SelRowListModal[index].VersionData = [];
        this.rowDisabled = true;
      }
      this.SelListModal[index].selectedReleases = [];
      this.SelListModal[index].rebrandNameList = this.common.getNameList(value, this.selValNoAll);
      // this.formParams(this.SelRowListModal, this.SelListModal)
    }
    if (this.SelListModal.length == 1 && this.SelListModal[0].selectedReleases.length == 0 && this.SelListModal[0].selectedPro == null) {
      this.clearTableData();
    }

  }

  ReleaseChange(value, index): void {
    // if (this.collectionBoolean) {
    //   this.disabledCheckInterop = false;
    //   this.collect = this.SelListModal[index].VersionData.filter(r => r.id == value);
    //   // let collect = this.SelListModal[index].VersionData.filter(r => r.id == value);
    //   // this.getCollectionMatrix(collect[0])
    // } else {
    //   // this.formParams(this.SelRowListModal, this.SelListModal)
    // }

  }


  SelRowListModal: any[] = [{ "ProductData": [], "VersionData": [], "selectedPro": productMock.AllSolution, "selectedReleases": [], versionHide: false, rebrandNameList: [] }];

  addRow() {

    this.SelRowListModal.push({ "ProductData": this.selValNoAll2, "VersionData": [], "selectedPro": "", "selectedReleases": [], versionHide: false, rebrandNameList: [] })
    this.rowDisabled = true;
  }

  delRow(index) {

    this.SelRowListModal.splice(index, 1);
    this.rowDisabled = false;
    // this.formParams(this.SelRowListModal, this.SelListModal);
  }

  rowDisabled: boolean = true;
  ProductRowChange(value, index): void {

    this.SelRowListModal[index].rebrandNameList = [];
    if (value == 0) {
      value = productMock.AllSolution;
    } else {
      if (value == null) {
        this.SelRowListModal[index].VersionData = [];
        return
      }
      value = this.selValNoAll2.filter(r => r.id == value)[0]
    }

    if (value.id == 0) {
      this.SelRowListModal = [{ "ProductData": [productMock.AllSolution, ...this.selValNoAll2], "VersionData": [], "selectedPro": 0, "selectedReleases": [], rebrandNameList: [] }];
      this.rowDisabled = true;
    } else {
      let version = this.common.getVersion(value.id, this.selValNoAll2);
      version = this.common.reDuplicates(version)
      if (version[0].version == "Nonversioned" || version[0].version == "0.0.0") {
        this.SelRowListModal[index].versionHide = true;
      } else {
        this.SelRowListModal[index].versionHide = false;
      }
      this.SelRowListModal[index].VersionData = version;
      this.SelRowListModal[index].rebrandNameList = this.common.getNameList(value.id, this.selValNoAll2);
      this.SelRowListModal[index].selectedReleases = [];
      this.rowDisabled = false;
    }

    // this.formParams(this.SelRowListModal, this.SelListModal)
    if (this.SelRowListModal.length == 1 && this.SelRowListModal[0].selectedReleases.length == 0 && this.SelRowListModal[0].selectedPro == null) {
      this.clearTableData();
    }

  }

  ReleaseRowChange(value, index): void {
    // this.formParams(this.SelRowListModal, this.SelListModal)
    this.SelRowListModal[index].selectedReleases = value;
  }

  CheckInter(): void {
    // if (this.collectionBoolean) {
    //   this.getCollectionMatrix(this.collect[0])
    // } else {
    this.formParams(this.SelRowListModal, this.SelListModal)
    // }
    this.saveFlag = false;
    this.canAddFavoBol = false;
    this.containFavo();
    this.elementRef.nativeElement.querySelector('.tableShow-area').scrollTop = 0
  }

  /****unselect hide tooltip*********/
  hideTip: boolean = false;
  formParams(rowData, columnData) {

    this.hideTip = this.common.hideTooltip(rowData) || this.common.hideTooltip(columnData);
    let params: any = {
      "columns": [],
      "rows": [],
      "isCollection": false
    }
    Object.assign(params, this.filter);
    if (rowData[0].selectedPro != 0) {

      rowData.forEach(r => {
        let allVersion = [];
        let j = r.selectedReleases.length == 0 || r.selectedReleases[0] == 0
        params.columns.push({
          product: r.selectedPro,
          releases: j ? allVersion : r.selectedReleases
        })
      })
    } else {

      params.columns.push({
        product: 0,
        releases: []
      })
    }
    columnData.forEach(r => {
      let allVersion = [];
      params.rows.push({
        product: r.selectedPro,
        releases: r.selectedReleases.length ? r.selectedReleases : allVersion
      })
    })
    this.isVisible = false;
    let colVal = this.formatUrlParam(params.rows);
    let rowVal = this.formatUrlParam(params.columns);
    this.urlChangeBoolean = false;
    if (JSON.parse(this.filter.isHideGenSupported.toString()) && JSON.parse(this.filter.isHideTechSupported.toString())) {
      this.router.navigate(
        [],
        {
          relativeTo: this.routerInfo,
          queryParams: { col: colVal, row: rowVal },
          queryParamsHandling: '',
        });
    } else {
      let filterParams = {
        isHideLegacyReleases: this.filter.isHideGenSupported
      }
      this.router.navigate(
        [],
        {
          relativeTo: this.routerInfo,
          queryParams: Object.assign(filterParams, { col: colVal, row: rowVal }),//Object.assign(this.filter, { col: colVal, row: rowVal })
          queryParamsHandling: 'merge',
        });
    }
    let pid = this.getPid({ col: colVal, row: rowVal })
    this.vsdLink = this.vsdAddress+`/interops/1?pids=`+ pid.toString()
    this.getMatrix(params);

  }

  formatUrlParam(d: any) {
    let n: string[] = [];
    d.forEach((r) => { n.push(`${r.product},${r.releases.join(",")}`) });
    return n.join("&")
  }

  collapseTable() {
    if (!this.tableEmpty) {
      this.CollapseBoolean = !this.CollapseBoolean;
      this.productListData.forEach(r => r.expand = this.CollapseBoolean);
    }
  }

  //columnCheck
  allChecked = false;
  indeterminate = true;

  updateAllChecked() {

    this.ShowColumnData = this.ShowColumnData.map(item => {
      return {
        ...item,
        disabled: false,
        checked: true
      };
    })
    this.ShowColumn = [];
    this.ShowColumnData.forEach(r => {
      this.ShowColumn.push(r.id)
    })
    this.setTableData(JSON.parse(JSON.stringify(this.dcrService.InterMatrix)), "");

  }

  unCheck(value) {

    if (value.length == 1) {
      this.ShowColumnData.forEach(r => {
        if (value[0].id == r.id) {
          r.disabled = true;
          r.checked = true;
        } else {
          r.disabled = false;
          r.checked = false
        }
      })
    } else {
      this.ShowColumnData.forEach(r => {
        r.disabled = false
      })
    }

    this.ShowColumn = [];
    value.forEach(r => {
      this.ShowColumn.push(r.id)
    })
    this.setTableData(JSON.parse(JSON.stringify(this.dcrService.InterMatrix)), "");

  }

  //checkbox list
  filter = {
    "isHideGenSupported": true,
    "isHideTechSupported": true,
    "isHideCompatible": false,
    "isHideIncompatible": false,
    "isHideNTCompatible": false,
    "isHideNotSupported": true,
    "isCollection": false,
  }

  log(v) {
    console.log(v, this.checkOptionsOne)
    setTimeout(() => {
      this.checkOptionsOne[1].checked = this.checkOptionsOne[0].checked ? true : false
      this.filter = {
        "isHideGenSupported": this.checkOptionsTwo[0].checked,
        "isHideTechSupported": this.checkOptionsTwo[0].checked,
        "isHideCompatible": this.checkOptionsOne[0].checked,
        "isHideNTCompatible": this.checkOptionsOne[0].checked,
        "isHideIncompatible": this.checkOptionsOne[2].checked,
        "isHideNotSupported": true,
        "isCollection": false,
      }
      this.formParams(this.SelRowListModal, this.SelListModal)
    }, 10)

  }
  //copy
  copyType: string = "none";
  copyClick() {

    this.copyType = "block";
    this.common.createModalMessage("Info", 'Copy to Clipboard').success()
    setTimeout(() => {
      this.common.copy("print")
      this.copyType = "none"
    }, 1)

  }

  //csv
  CSVlink() {

    this.common.csv("print", "Interoperability")

  }

  //print
  printLink() {

    let css = `<style type="text/css">${this.common.cssStr}}</style> `
    this.common.print("print", css)

  }

  //formatTime
  formatTime(min) {
    return moment(min).format('yyyy-MM-DD')
  }

  //highLight
  tdSelected: any;
  tdMouseOver(i, j) {
    if (i == 0) {
      this.tdSelected = j;
    } else {
      this.tdSelected = JSON.stringify(i) + JSON.stringify(j);
    }
  }

  tdMouseOut() {
    this.tdSelected = "";
  }

  @HostListener('window:scroll', ['$event'])
  scrollTop: number = 0;
  onScroll(s) {
    // let st = s.target;
    // let f: any = document.getElementsByClassName('ph-footer');
    // let l: any = document.getElementsByClassName('selproduct-cond');
    // if (st.scrollHeight == st.clientHeight + st.scrollTop) {
    //   console.log(st.scrollTop);
    //   // console.log(st.clientHeight);
    //   // console.log(st.scrollHeight);
    //   this.scrollTop==0?this.scrollTop = st.scrollTop:"";
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 230px)";
    //   l[0].style.height = "calc(100vh - 273px)";
    //   f[0].style.display = "block";
    // } else if(this.scrollTop > st.scrollTop){
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 142px)";
    //   l[0].style.height = "calc(100vh - 185px)";
    //   f[0].style.display = "none";
    // }
  }
  onClick(type: string, event?) {
    switch (type) {
      case EVENT_TYPE.ADD:
        this.favPage.compareList = this.SelListModal;
        this.favPage.withList = this.SelRowListModal;
        this.favPage.openAddModal();
        break;
      case EVENT_TYPE.DELETE:
        this.favPage.openDeleteModal(this.deleteId);
        break;
    }
  }
  getSaveFlag(e) {
    // this.saveFlag = e;
    this.common.addToCart(this);
    this.getFavoriteList();
    setTimeout(() => {
      this.containFavo();
    }, 2000)
  }
  deleteSucess(e) {
    this.saveFlag = !e;
    this._data.setMultiCloudFavData([]);
    this.getFavoriteList();
  }
  getFavoriteList() {
    let favoData: any;
    this.favoriteList = [];
    this.dcrService.getFavoriteList("MultiCloud").subscribe(data => {
      this._data.setMultiCloudFavData(data);
      favoData = data;
      favoData.forEach(r => {
        this.favoriteList.push({ "id": r.id, "Compare": JSON.parse(r.aReleases), "With": JSON.parse(r.bReleases) })
      });
    })
  }
  containFavo() {
    let col = [];
    let row = [];
    this.SelListModal.forEach(c => {
      let colAllVersion = this.common.getVersion(c.selectedPro, c.ProductData);
      colAllVersion = this.common.reDuplicates(colAllVersion);
      if (c.selectedReleases.length == 0 || c.selectedReleases.length == colAllVersion.length) col.push({ "product": [c.selectedPro], "release": [] })
      else col.push({ "product": [c.selectedPro], "release": c.selectedReleases })
    })
    this.SelRowListModal.forEach(r => {
      let rowAllVersion = this.common.getVersion(r.selectedPro, r.ProductData);
      rowAllVersion = this.common.reDuplicates(rowAllVersion);
      if (r.selectedPro == 0) {
        row.push({ "product": [], "release": [] })
      }
      else if (r.selectedRelease.length===0 || r.selectedReleases.length == rowAllVersion.length) {
        row.push({ "product": [r.selectedPro], "release": [] })
      } else {
        row.push({ "product": [r.selectedPro], "release": r.selectedReleases })
      }
    })
    if (this.favoriteList.length == 0) this.getFavoriteList();
    let p = this.common.containFavoriteList(col, row, this.favoriteList);
    if (p.thesame) {
      this.saveFlag = true;
      this.deleteId = p.deleteId
    } else { this.saveFlag = false }
  }

  getPid(params){
    let pid = [];
    let cList = params.col?.split("&");
    let rList = params.row?.split("&");
    cList.forEach(c => {
      let p = c.split(',')
      pid.push(p[0])
    });
    rList.forEach(r => {
      let p = r.split(',')
      if(Number(p[0]) !==0 ) pid.push(p[0])
    });
    return pid

  }
  openModal(){
    this.commoModal.openModal('link',this.vsdLink)
  }
}

