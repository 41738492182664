import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';

import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

declare var Fingerprint2: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  fontAwesomeIcon = {
    faTwitter,
    faFacebookF,
    faLinkedinIn,
    faYoutube,
  };
  hash;
  nowtime:string;

  constructor(private feedbackService: FeedbackService) {}

  ngOnInit() {
    this.nowtime = (new Date()).getFullYear().toString()
    this.feedbackService.showFeedback();
    // new Fingerprint2().get((result, components) => {
    //   this.hash = result;
    //   if(this.hash){
    //     this.feedbackService.identifyUser(this.hash);
    //   }      
    //   // console.log(result); //a hash, representing your device fingerprint
    //   // console.log(components); // an array of FP components
    // });
    // this.feedbackService.identifyUser('guest');

  }
}
