<table class="table">
    <thead>
        <tr>
            <td colspan="2">{{tableHead}}</td>
        </tr>
        <tr>
            <td>{{type!="database"?"Solution name":"Category name"}}</td>
            <td>{{type!="database"?"Version":"Solution"}}</td>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let p of dataList">
            <tr *ngFor="let v of p.releases,let i = index" slot="row">
                <td *ngIf="i == 0"  [rowSpan]="p.releases.length" style="vertical-align: middle;">{{p.productName}}</td>
                <td>{{v.version}}</td>
            </tr>
           
        </ng-container>
    </tbody>
</table>