<div class="myFavoriteView">
    <app-favorite-page #favPage [section]="'product'"></app-favorite-page>
    <div class="clr-row favBreadcrumb">
        <nz-breadcrumb nzSeparator=">">
            <nz-breadcrumb-item>
                <a [routerLink]="['/']">Home Page</a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>My Favorite</nz-breadcrumb-item>
        </nz-breadcrumb>
    </div>
    <div class="favContent">
        <div class="favTab">
            <clr-tabs clrLayout="vertical">
                <clr-tab>
                    <button clrTabLink (click)="getFavoriteData('product')">Interoperability</button>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink (click)="getFavoriteData('MultiCloud')">Multi-Cloud Interoperability</button>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink (click)="getFavoriteData('database')">3rd Party Solution Interoperability</button>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink (click)="getFavoriteData('upgrade')">Upgrade Path</button>
                </clr-tab>
            </clr-tabs>
        </div>
        <div class="favPanel">
            <span class="spinner" [hidden]="!loading||emptyList">
                Loading...
            </span>
            <nz-empty [hidden]="!emptyList"></nz-empty>
            <clr-accordion [clrAccordionMultiPanel]='true' [hidden]="loading">
                <clr-accordion-panel *ngFor='let panel of projectList;let i = index'>
                    <clr-accordion-title>
                        <div class="title">
                            <span class="projectName" title="{{panel.name}}">{{panel.name}}</span>
                            <span class="notes" title="Notes: {{panel.notes}}">
                                <em>Notes: {{panel.notes}}</em>
                            </span>
                            <span class="time"> {{panel.lastUpdateDate|date:'MM/dd/yyyy'}}</span>
                        </div>
                        <div class="operate">
                            <button class="btn btn-primary btn-sm" (click)="onClick('check',panel)"  style="margin-left: -5px;">
                                View Matrix
                            </button>
                            <a (click)="onClick('delete',panel)">
                                <clr-icon shape="trash"></clr-icon>
                            </a>
                            <!-- <a (click)="onClick('share',panel)">
                                <clr-icon shape="share"></clr-icon>
                            </a> -->
                        </div>
                    </clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                        <ng-container *ngFor="let item of panel.modelList">
                            <div class="content-title" (click)="item.expand = !item.expand">
                                <clr-icon shape="angle"
                                    [ngStyle]="{'transform':item.expand?'rotate(90deg)':'rotate(180deg)'}"></clr-icon>
                                <span> {{item.name}} </span>
                            </div>
                            <div class="content-area" [hidden]='item.expand'>
                                <div class="content-area-item" *ngFor="let proItem of item.productList">
                                    <div> <h3>Solution name:</h3><span> {{proItem.product}}</span> </div>
                                    <div> <h3>Versions:</h3><span> {{proItem.release}}</span> </div>
                                </div>
                            </div>
                        </ng-container>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </clr-accordion>

        </div>
    </div>
</div>
<div class="delete">
<clr-modal [(clrModalOpen)]="showDeleteModal" [clrModalSize]="'sm'">
    <div class="modal-body">
        <p style="height: 25px;font-size: 15px;color: black;">Do you want to delete this project?</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="showDeleteModal=!showDeleteModal">Cancel</button>
        <button class="btn btn-primary" (click)="deleteFavorite()">Delete</button>
    </div>
</clr-modal>
</div>