import { Component, OnInit, ViewChild, TemplateRef, HostListener, ElementRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

//service
import { DcrService } from "../../services/dcr.service";
import { CommonService } from "../../services/common.service";
import * as moment from "moment";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from "ngx-loading";
import { Location } from '@angular/common';

@Component({
  selector: 'app-database-tab',
  templateUrl: './database-tab.component.html',
  styleUrls: ['./database-tab.component.scss']
})
export class databaseTabComponent implements OnInit {
  disabledCheckInterop: boolean = true;
  tableShowOverflow: string;
  selproductWid: string; tableShowWid: string;
  left: boolean = false;

  //table columns products
  productListData: any;
  // columnData: { name?: "", product?: { releases: [] } } = { name: "", "product": { "releases": [] } }
  columnData = [];
  ShowColumnData: any[];
  productName: any[];
  ShowColumn: string[] = [];

  rowLen: number;
  readonly statusCls: string[] = ["", "tdCompatible", "tdIncompatible", "tdCompatibleNT", "tdNotSupport"];
  readonly statusInfoCls: string[] = ["", "tdCompatibleinfo", "tdIncompatibleinfo", "tdCompatibleNTinfo", "tdNotSupportinfo"];

  tableTdWords = [, 'Compatible', 'Incompatible', 'Compatible', 'Not Supported'];
  checkOptionsOne = [
    { label: 'Not Supported', value: 'Apple', checked: true },
  ];
  checkOptionsTwo = [
    { label: 'Past End of General Support', value: 'Apple', checked: true },
    { label: 'Past End of Technical Guidance', value: 'Pear', checked: true },
  ];
  //checkbox list
  filter = {
    "isHideEmpty": true,
    "isHideGenSupported": true,
    "isHideTechSupported": true,
  }
  log(v) {
    setTimeout(() => {

      this.filter = {
        "isHideGenSupported": this.checkOptionsTwo[0].checked,
        "isHideTechSupported": this.checkOptionsTwo[1].checked,
        "isHideEmpty": this.checkOptionsOne[0].checked,
      }
      this.formParams(this.SelListModal, this.SelDatabaseListModal)
    }, 10)
  }

  @ViewChild('ngxLoading', { static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false }) customLoadingTemplate: TemplateRef<any>;
  constructor(
    private dcrService: DcrService,
    private common: CommonService,
    private message: NzMessageService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,    
    private location: Location
  ) {    
    location.onUrlChange(url => this.ngAfterContentInit());
   }

  tooltipOne: string[];
  tooltipTwo: string[];
  paramsObj: any = {
    "productId": "",
    "releases": [],
    "extProdIds": []
  }
  ngOnInit(): void {
    // this.selproductWid = "16%";
    this.selproductWid = "400px";
    // this.tableShowWid = "84%";
    this.tableShowWid = "";
    //databaseVersion
    this.getDatabaseSel();

    this.tooltipOne = this.common.tooltipForCCP;
    this.tooltipTwo = this.common.tooltipForLenged;
  }

  ngAfterContentInit() {
    if (window.location.hash.indexOf('Database') == -1) {
      return
    }
    this.route.queryParams.subscribe(params => {
      if (params['productId'] && window.location.hash.indexOf('Database?') > -1) {
        this.paramsObj = JSON.parse(JSON.stringify(params));
        if (!params['extProdIds']) {
          this.paramsObj.extProdIds = [];
        }
        if (Array.isArray(this.paramsObj.releases)) {
          this.paramsObj.releases = this.paramsObj.releases.map(Number);
        } else if (this.paramsObj.releases) {
          this.paramsObj.releases = [parseInt(this.paramsObj.releases)];
        } else {
          this.paramsObj.releases = [];
        }
        if (!Array.isArray(this.paramsObj.extProdIds)) {
          this.paramsObj.extProdIds = [this.paramsObj.extProdIds];
        }
        this.disabledCheckInterop = false;
        this.SelListModal.selectedPro = parseInt(this.paramsObj.productId);
        this.setChecklist(this.paramsObj);
        this.dcrService.getProduct("database").subscribe(data => {
          this.LoadSel = false;
          this.selValNoAll = JSON.parse(JSON.stringify(data));

          this.SelListModal.ProductData = data;
          this.SelListModal.ProductData.sort((a, b) => { return this.common.sortFn(a, b) });
          let version = this.common.getVersion(this.paramsObj.productId, this.selValNoAll);
          this.SelListModal.VersionData = version.sort((a, b) => { return this.common.sortVersion(a, b) });
          this.SelListModal.rebrandNameList = this.common.getNameList(this.paramsObj.productId, this.selValNoAll);
          this.SelListModal.selectedReleases = this.paramsObj.releases;

          this.getMatrix(this.paramsObj);
        })

        this.dcrService.getDatabase().subscribe((data: []) => {
          this.SelDatabaseListModal.databaseData = this.common.databaseSwitch(data);
          this.selectType = "multiple";
          this.SelDatabaseListModal.selectedDatabases = this.paramsObj.extProdIds;  
        })


      } else {
        this.hideTip = false;
        this.disabledCheckInterop = false;
        this.checkOptionsOne[0].checked = true;
        this.checkOptionsTwo[1].checked = true;
        this.checkOptionsTwo[0].checked = true;
        this.clearTableData();
        // if (this.common.getItem('SelDatabaseListModal')) {
        //   this.disabledCheckInterop = false;
        //   this.SelListModal = JSON.parse(this.common.getItem("SelDatabaseListModal"))
        //   this.SelDatabaseListModal = JSON.parse(this.common.getItem("SelDatabase2ListModal"))
        //   this.setChecklist(JSON.parse(this.common.getItem("filterDB")));
        //   this.formParams(this.SelListModal, this.SelDatabaseListModal)
        // }

      }
    }, error => {
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    });
  }

  setChecklist(filter) {
    Object.assign(this.filter, filter);
    this.checkOptionsOne[0].checked = filter.isHideEmpty;
    this.checkOptionsTwo[1].checked = filter.isHideTechSupported;
    this.checkOptionsTwo[0].checked = filter.isHideGenSupported;
  }

  tableEmpty: boolean = true;
  setTableData(data, type: string = "") {
    this.tableEmpty = false;
    // this.columnData = this.convertThList(data[0].prodReleaseMap).sort((a, b) => { return this.common.sortId(a, b) });
    this.columnData = this.convertThList(data[0].prodReleaseMap);
    this.columnData = this.common.removeRepeat(this.columnData);
    if (type == "") this.ShowColumnData = JSON.parse(JSON.stringify(this.columnData))
    if (this.columnData.length == 1) {
      this.rowLen = this.columnData[0].releases.length
    } else {
      this.columnData.forEach(c => this.rowLen += c.releases.length)
    }
    this.rowLen += 1;
    data.forEach(r => {
      r.prodReleaseMap = r.prodReleaseMap ? this.convertThList(r.prodReleaseMap) : []
    })
    this.productListData = data;
    if (this.SelDatabaseListModal.selectedDatabases.length == 0) {
      this.selectType = "default";
      this.SelDatabaseListModal.selectedDatabases = "0";
    }
    this.hideTip = this.common.hideTooltipDB(this.SelListModal, this.SelDatabaseListModal);
  }

  convertThList(relseseMap) {

    // let relseseKeyList = Object.keys(relseseMap).sort((a, b) => Number(b) - Number(a));
    let relseseKeyList = Object.keys(relseseMap);
    if (this.ShowColumn.length) relseseKeyList = this.ShowColumn;
    let thList = [], obj = {};
    relseseKeyList.forEach((r) => {
      thList.push({
        id: r,
        name: relseseMap[r][0].productName,
        length: relseseMap[r].length,
        releases: relseseMap[r].sort((a, b) => { return this.common.sortVersion(a, b) }),
        checked: true,
        disabled: false
      })
    })
    if (thList.length == 1) {
      thList[0].disabled = true;
    }
    return thList

  }
  //change class
  displayStatus(status: number, note: string, rowChildRelease): string {
    let cls = (!rowChildRelease.techGuided || !rowChildRelease.genGuided) ? ' trSupp' : " ";
    return note == null || note == "" ? `${this.statusCls[status]} + ${cls}` : `${this.statusInfoCls[status]} + ${cls}`;

  }

  isVisible: boolean = false;
  NoResultId = "No Data"
  getMatrix(obj) {
    setTimeout(() => {
      if (window.location.hash.indexOf("Database") > -1) {
        this.common.setItem("hashTab2", window.location.hash)
      }
    }, 20)
    this.isVisible = true;
    this.dcrService.getDatabaseMatrix(obj).subscribe(data => {
      this.isVisible = false;
      this.ShowColumn = [];
      this.dcrService.DatabaseList = JSON.parse(JSON.stringify(data))
      if (this.dcrService.DatabaseList.length) {
        this.setTableData(data)
        this.tableShowOverflow = "auto";
      } else {
        this.productListData = [];
        this.columnData = [];
        if (this.SelDatabaseListModal.selectedDatabases.length == 0) {
          this.selectType = "default";
          this.SelDatabaseListModal.selectedDatabases = "0";
        }
      }
      // this.common.createMessage('warning');
    }, error => {
      // this.clearTableData();
      this.productListData = [];
      this.columnData = [];
      this.isVisible = false;
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }else{
        this.NoResultId = 'No Result';
      }
    })

  }

  unCheck(value) {
    if (value.length == 1) {
      this.ShowColumnData.forEach(r => {
        if (value[0].id == r.id) {
          r.disabled = true;
          r.checked = true;
        } else {
          r.disabled = false;
          r.checked = false
        }
      })
    } else {
      this.ShowColumnData.forEach(r => {
        r.disabled = false
      })
    }

    this.ShowColumn = [];
    value.forEach(r => {
      this.ShowColumn.push(r.id)
    })
    this.setTableData(JSON.parse(JSON.stringify(this.dcrService.DatabaseList)), "uncheck");
  }
  clearTableData() {
    this.tableEmpty = true;
    // this.productName = [];
    this.productListData = [];
    // this.columnData = { name: "", "product": { "releases": [] } };
    this.columnData = [];
    this.tableShowOverflow = "hidden";
    this.SelListModal.selectedPro = "";
    this.SelListModal.selectedReleases = [];
    this.SelListModal.VersionData = [];
    this.SelListModal.rebrandNameList = [];
    this.SelDatabaseListModal.selectedDatabases = [];
    this.disabledCheckInterop = true;
    this.common.removeItem("hashTab2");
  }

  reset(t) {
    if (t) {
      this.hideTip = false;
      this.disabledCheckInterop = false;
      this.checkOptionsOne[0].checked = true;
      this.checkOptionsTwo[1].checked = true;
      this.checkOptionsTwo[0].checked = true;
      this.clearTableData();
      // let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
      this.router.navigateByUrl(`/Database`);
    }
  }

  selValNoAll;
  LoadSel: boolean = true;
  getDatabaseSel() {
    if(this.common.getItem("tabNu") == "1"){
      this.dcrService.getProduct("database").subscribe(data => {
        this.LoadSel = false;
        this.selValNoAll = JSON.parse(JSON.stringify(data));

        this.SelListModal.ProductData = data;
        this.SelListModal.ProductData.sort((a, b) => { return this.common.sortFn(a, b) });
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })

      this.dcrService.getDatabase().subscribe((data: []) => {
        this.SelDatabaseListModal.databaseData = this.common.databaseSwitch(data);
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })
    }
  }

  widchange() {
    this.left = !this.left;
    this.selproductWid = this.left ? "25px" : "400px";
    this.tableShowWid = "";
  }

  //select mock data
  SelListModal: any = { "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], rebrandNameList: [] };

  ProductChange(value): void {
    this.disabledCheckInterop = false;

    let version = this.common.getVersion(value, this.selValNoAll);
    this.SelListModal.VersionData = version.sort((a, b) => { return this.common.sortVersion(a, b) });
    this.SelListModal.rebrandNameList = this.common.getNameList(value, this.selValNoAll);
    this.SelListModal.selectedReleases = [];

    this.selectType = "default";
    this.SelDatabaseListModal.selectedDatabases = "0";
  }


  ReleaseChange(value): void {
    // this.formParams(this.SelListModal, this.SelDatabaseListModal)
  }

  CheckInter(): void {
    this.formParams(this.SelListModal, this.SelDatabaseListModal);
    this.elementRef.nativeElement.querySelector('.tableShow-area').scrollTop = 0;
  }

  SelDatabaseListModal: any = { "databaseData": [], "selectedDatabases": [] };
  selectType: string = 'default';
  databaseChange(value) {
    // this.formParams(this.SelListModal, this.SelDatabaseListModal)
    if (value.indexOf("0") > -1 || value == "0") {
      this.selectType = "default";
      this.SelDatabaseListModal.selectedDatabases = "0";
    } else {
      this.selectType = "multiple";
    }
  }

  /****unselect hide tooltip*********/
  hideTip: boolean = false;
  formParams(rowData, columnData) {

    this.hideTip = this.common.hideTooltipDB(rowData, columnData);

    setTimeout(() => {

      // this.common.setItem("SelDatabaseListModal", JSON.stringify(this.SelListModal));
      // this.common.setItem("SelDatabase2ListModal", JSON.stringify(this.SelDatabaseListModal));
      // this.common.setItem("filterDB", JSON.stringify(this.filter));

    }, 20)

    let params: any = {
      "productId": "",
      "releases": [],
      "extProdIds": []
    }
    let releases: any = [];
    let extProdIds: any = [];
    Object.assign(params, this.filter);
    params.productId = rowData.selectedPro;
    let allVersion = [];
    if (rowData.selectedReleases.length > 0) {
      rowData.selectedReleases.forEach(r => {
        // params.releases.push(r);
        releases.push(r);
      })
    } else {
      // rowData.VersionData.forEach(r => {
      //   // params.releases.push(r.id)
      //   releases.push(r.id);
      // })
    }
    if (this.selectType == "default") {
      extProdIds = [];
    } else {
      if (typeof columnData.selectedDatabases != 'string') {
        columnData.selectedDatabases.forEach(r => {
          // params.extProdIds.push(r);
          extProdIds.push(r);
        })
      } else {
        extProdIds.push(columnData.selectedDatabases)
      }

    }
    this.isVisible = false

    let productId = params.productId;
    params.extProdIds = extProdIds;

    if (rowData.selectedReleases.length > 0) {
      params.releases = releases;
    }
    let isHideEmpty = this.filter.isHideEmpty;
    let isHideGenSupported = this.filter.isHideGenSupported;
    let isHideTechSupported = this.filter.isHideTechSupported;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { productId: productId, releases: releases, extProdIds: extProdIds, isHideEmpty: isHideEmpty, isHideGenSupported: isHideGenSupported, isHideTechSupported: isHideTechSupported },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.getMatrix(params);
  }

  //copy
  copyType: string = "none";
  copyClick() {
    this.copyType = "block";
    this.common.createModalMessage("Info", 'Copy to Clipboard').success();
    setTimeout(() => {
      this.common.copy("databasePrint")
      this.copyType = "none"
    }, 1)
  }

  //csv
  CSVlink() {
    this.common.csv("databasePrint", "Database")
  }

  //print
  printLink() {
    let css = `<style type="text/css">${this.common.cssStr}}</style> `
    this.common.print("databasePrint", css)
  }

  //formatTime
  formatTime(min) {
    return moment(min).format('yyyy-MM-DD')
  }

  @HostListener('window:scroll', ['$event'])
  scrollTop: number = 0;
  onScroll(s) {
    // let st = s.target;
    // let f: any = document.getElementsByClassName('ph-footer');
    // if (st.scrollHeight == st.clientHeight + st.scrollTop) {
    //   console.log(st.scrollTop);
    //   // console.log(st.clientHeight);
    //   // console.log(st.scrollHeight);
    //   this.scrollTop == 0 ? this.scrollTop = st.scrollTop : "";
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 230px)";
    //   f[0].style.display = "block";
    // } else if (this.scrollTop > st.scrollTop) {
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 142px)";
    //   f[0].style.display = "none";
    // }
  }

  //highLight
  tdSelected: any;
  tdMouseOver(i, j) {
    if (i == 0) {
      this.tdSelected = j;
    } else {
      this.tdSelected = JSON.stringify(i) + JSON.stringify(j);
    }
  }

  tdMouseOut() {
    this.tdSelected = "";
  }
}
