import { Component, OnInit, ViewChild, TemplateRef, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

//service
import { DcrService } from "../../services/dcr.service";
import { CommonService } from "../../services/common.service";
import * as moment from "moment";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from "ngx-loading";
import { Location } from '@angular/common';
import { ProductModel, CategoryModel, EVENT_TYPE, PLACEHOLD, CheckOne, CheckTwo, VersionMatrix, VersionMatrixHeader, statusCls, statusInfoCls } from '../../model/common.model';
import { ProductInter, CategoryInter } from '../../model/data.modal';
import { throwError } from 'rxjs';
import { productMock } from "../../mock/product-mock";
import { FavoritePageComponent } from '../favorite-page/favorite-page.component';
import { DataService } from '../../services/data.service';
import { position } from '../../model/data.modal';
import { CommonModalComponent } from '../common-modal/common-modal.component';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})

export class SolutionComponent implements OnInit {

  cartPosition: position = null;

  @ViewChild('ngxLoading', { static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false }) customLoadingTemplate: TemplateRef<any>;
  @ViewChild('favPage', { static: false }) favPage: FavoritePageComponent;
  @ViewChild('commoModal', { static: false }) commoModal: CommonModalComponent;
  saveFlag: boolean;
  canAddFavoBol: boolean = true;
  favoriteList: any[] = [];
  deleteId: any;

  vsdAddress = "https://vsd.esp.vmware.com"
  vcgAddress = "https://www.vmware.com/resources/compatibility/search.php"
  constructor(
    private dcrService: DcrService,
    private common: CommonService,
    private message: NzMessageService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _data: DataService,
    private renderer: Renderer2
  ) {
    // location.onUrlChange(url => this.ngAfterContentInit());
  }

  PH = PLACEHOLD;
  VerHD = [];
  readonly statusCls: string[] = statusCls;
  readonly statusInfoCls: string[] = statusInfoCls;
  tableTdWords = [, 'Compatible', 'Incompatible', 'Compatible', 'Not Supported'];

  selproductWid: string = "400px";
  tableShowWid: string = "";
  tableShowOverflow: string = 'hidden';
  versionSelMod: string = "multiple"; //select model type
  copyType: string = "none";
  NoResultId: string = "No Data";

  left: boolean = false;//width control
  collectionBoolean: boolean = false;//collection control
  anotherFirBut: boolean = true;//add delete disabled control
  anotherSecondBut: boolean = true;//add delete disabled control
  rowDisabled: boolean = true;//row disabled control
  LoadSel: boolean = true;//select loading control
  disabledCheckInterop: boolean = true; //checkInterop button control
  hideTip: boolean = false;//tip disabled control
  tableEmpty: boolean = true;//table Empty control
  CollapseBoolean: boolean = true;//table collapse control
  isVisible: boolean = false;//table empty control 

  tooltipOne: string[] = this.common.tooltipForCCP;
  tooltipTwo: string[] = this.common.tooltipForLenged;
  checkOptionsTwo =  [
    { label: 'Past End of General Support', value: 'Apple', checked: true }, 
    { label: 'Past End of Technical Guidance', value: 'Pear', checked: true }
  ];
  checkOptionZero={label:'Hide Patch Releases',value:'pathc',checked:true};

  SelListModal: ProductInter[] = JSON.parse(JSON.stringify([ProductModel]));//column select data
  SelRowListModal: CategoryInter[] = JSON.parse(JSON.stringify([CategoryModel]));//row select data
  productListData: any;
  panels: any[] = [];
  param: any;

  ngOnInit() {
    this.getDBProduct();

    this.panels = [];
    this.getFavoriteList();
    if (!this.cartPosition) {
      this.cartPosition = this._data.firstPostion;
    }
  }

  ngAfterContentInit() {
    if (window.location.pathname.indexOf('Solution') == -1) {
      return
    }
    this.route.queryParams.subscribe(params => {
      if (params['key']) {
        this.disabledCheckInterop = false;
        let param = JSON.parse(params['key'])
        let paramFilters = {
          isHideGenSupported: "isHideLegacyReleases" in param ? JSON.parse(param['isHideLegacyReleases']) : true,
          isHideTechSupported: "isHideLegacyReleases" in param ? JSON.parse(param['isHideLegacyReleases']) : true,
          isHidePatch: "isHidePatch" in param ? JSON.parse(param['isHidePatch']) : true,
          columns: param['columns'],
          rows: param['rows'],
          isHideEmpty:true,
        }
        this.param = Object.assign(this.filter,paramFilters)
        this.getVersionMatrix(this.param);
      }
    }, error => {
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    });
  }

  widchange() {
    this.left = !this.left;
    this.selproductWid = this.left ? "25px" : "400px";
    this.tableShowWid = "";
  }

  addColumn(type) {
    switch (type) {
      case EVENT_TYPE.COLUMN:
        this.SelListModal.push(JSON.parse(JSON.stringify(ProductModel)));
        this.anotherFirBut = true;
        break;
      case EVENT_TYPE.ROW:
        this.SelRowListModal.push(JSON.parse(JSON.stringify(CategoryModel)));
        this.anotherSecondBut = true;
        break;
    }
  }

  delColumn(index, type) {
    switch (type) {
      case EVENT_TYPE.COLUMN:
        this.SelListModal.splice(index, 1);
        break;
      case EVENT_TYPE.ROW:
        this.SelRowListModal.splice(index, 1);
        break;
    }
  }

  onChange(type, index) {
    switch (type) {
      case EVENT_TYPE.COLUMN:
        this.anotherFirBut = false;
        this.disabledCheckInterop = false;
        let version = this.common.getVersion(this.SelListModal[index].selectedPro.id, this.SelListModal[index].ProductData);
        version = this.common.reDuplicates(version)
        this.SelListModal[index].VersionData = version;
        this.SelListModal[index].versionHide = this.SelListModal[index].VersionData[0].version == "Nonversioned" ? true : false;
        this.SelListModal[index].selectedReleases = [];
        this.SelListModal[index].rebrandNameList = this.common.getNameList(this.SelListModal[index].selectedPro.id, this.SelListModal[index].ProductData);
        if (this.SelListModal.length == 1 && this.SelRowListModal.length == 1) {
          this.SelRowListModal[0].selectCatagory = productMock.AllCategories;
          this.SelRowListModal[0].selectProduct = [];
          this.anotherSecondBut = true;
        }
        break;
      case EVENT_TYPE.ROW:
        if (this.SelRowListModal[index].selectCatagory == productMock.AllCategories) {
          this.anotherSecondBut = true;
          this.rowDisabled = true;
          for (var i = this.SelRowListModal.length; i > 1; i--) {
            this.SelRowListModal.pop();
          }
        }
        if (this.SelRowListModal[index].selectCatagory == productMock.AllCategories && index == 0) {
          this.anotherSecondBut = true;
          this.rowDisabled = true;
        } else {
          this.anotherSecondBut = false;
          this.rowDisabled = false;
        }
        if (this.SelListModal[0].selectedPro) {
          this.disabledCheckInterop = false;
        }
        this.SelRowListModal[index].ProductData = this.SelRowListModal[index].selectCatagory?.products;
        this.SelRowListModal[index].selectProduct = [];
        break;
    }
  }

  onClick(type) {
    switch (type) {
      case EVENT_TYPE.PRINT:
        let css = `<style type="text/css">${this.common.cssStr}}</style> `
        this.common.print("databasePrint", css);
        break;
      case EVENT_TYPE.COPY:
        this.copyType = "block";
        this.common.createModalMessage("Info", 'Copy to Clipboard').success();
        setTimeout(() => {
          this.common.copy("databasePrint")
          this.copyType = "none"
        }, 1)
        break;
      case EVENT_TYPE.CSV:
        this.common.csv("databasePrint", "Database")
        break;
      case EVENT_TYPE.COLLAPSE:
        if (!this.tableEmpty) {
          this.CollapseBoolean = !this.CollapseBoolean;
          this.panels.forEach(r => r.nzExpand = this.CollapseBoolean);
        }
        break;
      case EVENT_TYPE.CHECKINTEROP:
        this.canAddFavoBol = false;
        this.saveFlag = false
        this.containFavo();
        this.formVersionParams();
        this.checkIsHidePatch()
        break;
      case EVENT_TYPE.ADD:
        this.favPage.compareList = this.SelListModal;
        this.favPage.withList = this.SelRowListModal;
        this.favPage.openAddModal();
        break;
      case EVENT_TYPE.DELETE:
        this.favPage.openDeleteModal(this.deleteId);
        break;
    }
  }

  formVersionParams() {
    this.isVisible = true;
    this.hideTip = true;
    let isHideGenSupported = this.checkOptionsTwo[0].checked;
    let isHideTechSupported = this.checkOptionsTwo[0].checked;
    let isHidePatch = this.checkOptionZero.checked;
    let row = [], columns = [];
    let rObj = { productId: "", releases: [] }, cObj = { categoryId: "", extProdIds: [] };
    this.SelListModal.forEach(r => {
      rObj = { productId: "", releases: [] };
      rObj.productId = r.selectedPro.id;
      r.selectedReleases == [] ? rObj.releases = [] : rObj.releases = r.selectedReleases.map(r => { return r.id })
      row.push(rObj);
    })
    this.SelRowListModal.forEach(r => {
      cObj = { categoryId: "", extProdIds: [] };
      cObj.categoryId = r.selectCatagory.id;
      r.selectProduct == [] ? cObj.extProdIds = [] : cObj.extProdIds = r.selectProduct.map(r => { return r.id })
      columns.push(cObj);
    })
    let p = { rows: row, columns: columns, isHideGenSupported: isHideGenSupported, isHideTechSupported: isHideTechSupported, isHideEmpty: true ,isHidePatch:isHidePatch};
    this.getVersionMatrix(p);
    let rparam = (isHideGenSupported && isHideTechSupported && isHidePatch) ? 
      { rows: row, columns: columns } :
        { rows: row, columns: columns, isHideLegacyReleases: isHideGenSupported,isHidePatch:isHidePatch};
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { key: JSON.stringify(rparam) },
        queryParamsHandling: 'merge',
      });
  }


  setCondition(data) {
    let version = [];
    ProductModel.ProductData = this.common.reDuplicates(ProductModel.ProductData)
    if (!data) return
    this.anotherFirBut = false;
    this.checkOptionsTwo[0].checked = data?.isHideGenSupported;
    this.checkOptionsTwo[1].checked = data?.isHideTechSupported;
    data.rows.forEach((r, i) => {
      if (i != 0) {
        this.setCol(r, i)
      } else {
        let selectedPro = ProductModel.ProductData.filter((p) => { return p.id == r.productId })[0];
        this.SelListModal[i].selectedPro = selectedPro;
        version = this.common.getVersion(selectedPro.id, ProductModel.ProductData);
        version = this.common.reDuplicates(version);
        this.SelListModal[i].VersionData = version;
        this.SelListModal[i].versionHide = this.SelListModal[i].VersionData[0].version == "Nonversioned" ? true : false;
        // this.SelListModal[i].VersionData = selectedPro.releases;
        this.SelListModal[i].rebrandNameList = this.common.getNameList(r.productId, ProductModel.ProductData);
        version.length == r.releases.length ?
          this.SelListModal[i].selectedReleases = [] :
          this.SelListModal[i].selectedReleases = version.filter((s) => { return r.releases.indexOf(s.id) > -1 });
      }
    })
    data.columns.forEach((r, i) => {
      if (i != 0) {
        this.setRow(r, i)
      } else {
        // let selectCatagory = CategoryModel.CategoryData.filter((p) => { return p.id == r.categoryId })[0];
        let selectCatagory = this.SelRowListModal[i].CategoryData.filter((p) => { return p.id == r.categoryId })[0];
        this.SelRowListModal[i].selectCatagory = selectCatagory;
        this.SelRowListModal[i].ProductData = selectCatagory?.products;
        selectCatagory.products.length == r.extProdIds.length ?
          this.SelRowListModal[i].selectProduct = [] :
          this.SelRowListModal[i].selectProduct = selectCatagory.products.filter((s) => { return r.extProdIds.indexOf(s.id) > -1 });
        if (selectCatagory.id != 0) {
          this.rowDisabled = false;
        }
      }
    })
    if (this.SelListModal[0].selectedPro != '') {
      this.canAddFavoBol = false;
      this.containFavo();
    }
    this.checkIsHidePatch();
  }

  setCol(r, i) {
    ProductModel.ProductData = this.common.reDuplicates(ProductModel.ProductData)
    let selectedPro = ProductModel.ProductData.filter((p) => { return p.id == r.productId })[0];
    let version = this.common.getVersion(selectedPro.id, ProductModel.ProductData);
    version = this.common.reDuplicates(version);
    let VersionData = version;
    let selectedReleases = [];
    let rebrand = [];
    VersionData.length == r.releases.length ?
      selectedReleases = [] :
      selectedReleases = version.filter((s) => { return r.releases.indexOf(s.id) > -1 });
    rebrand = this.common.getNameList(r.productId, ProductModel.ProductData);
    this.SelListModal.push({ "ProductData": ProductModel.ProductData, VersionData: VersionData, "selectedPro": selectedPro, "selectedReleases": selectedReleases, versionHide: false, rebrandNameList: rebrand });
  }

  setRow(r, i) {
    let selectCatagory = CategoryModel.CategoryData.filter((p) => { return p.id == r.categoryId })[0];
    let ProductData = selectCatagory?.products;
    let selectProduct;
    selectCatagory.products.length == r.extProdIds.length ?
      selectProduct = [] :
      selectProduct = selectCatagory?.products.filter((s) => { return r.extProdIds.indexOf(s.id) > -1 });
    this.SelRowListModal.push({ "CategoryData": CategoryModel.CategoryData, "ProductData": ProductData, "selectCatagory": selectCatagory, "selectProduct": selectProduct, versionHide: false });

  }

  getTdStyle(r, type) {
    if (type == 'print') return this.tableTdWords[r.status]
    let cls = (!r.techGuided || !r.genGuided) ? ' trSupp' : " ";
    return r.footnotes == '' || r.footnotes == null ? `${this.statusCls[r.status]} + ${cls}` : `${this.statusInfoCls[r.status]} + ${cls}`;
  }

  getDBProduct() {
    this.dcrService.getProduct("database").subscribe(data => {
      let pd = JSON.parse(JSON.stringify(data))
      this._data.setSolutionSelData(pd);
      this.LoadSel = false;
      ProductModel.ProductData = pd;
      this.SelListModal[0].ProductData = ProductModel.ProductData;
      this.getCategory();
    }, error => {
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    })
  }

  getCategory() {
    this.dcrService.getCategory().subscribe(data => {
      this._data.setVersionData(data);
      CategoryModel.CategoryData = data as any;
      this.SelRowListModal[0].CategoryData = JSON.parse(JSON.stringify(data));
      this.SelRowListModal[0].CategoryData.unshift(productMock.AllCategories);
      setTimeout(() => {
        this.setCondition(this.param);
      }, 3000);
    }, error => {
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    })
  }

  getVersionMatrix(p) {
    this.VerHD = [];
    this.dcrService.getVersionMatrix<any[]>(p).subscribe(data => {
      this.isVisible = false;
      let verObj = this.getProdReleaseMap(data);
      if (verObj.res)
        this.VerHD = this.getHD(verObj.map)
      this.panels = data.map(r => {
        return { nzShowExpand: true, nzExpand: true, ...r }
      })
      this.tableShowOverflow = "auto";
      this.tableEmpty = false;
    }, error => {
      this.isVisible = false;
      this.VerHD = [];
      this.panels = [];
      this.productListData = [];
      // this.clearTableData();
      this.hideTip = false;
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      } else {
        this.NoResultId = 'No Result';
      }
    })
  }

  getProdReleaseMap(data): { res: boolean, map: [] } {
    let result = null;
    try {
      data.forEach((r, i) => {
        if (r.versions.length > 0) {
          result = { res: true, map: r.versions[0].products[0]?.prodReleaseMap }
          throw Error();
        }
      })
    } catch (e) {

    }
    return result ? result : { res: false, map: [] }
  }

  getHD(Map) {
    let list = new Array()
    for (let x in Map) {
      list = list.concat(Map[x])
    }
    return list
  }

  checkChange() {
    setTimeout(() => {
      this.formVersionParams();
    }, 200)
  }


  //table columns products
  columnData = [];
  ShowColumnData: any[];
  productName: any[];
  ShowColumn: string[] = [];

  rowLen: number;

  //checkbox list
  filter = {
    "isHideEmpty": true,
    "isHideGenSupported": true,
    "isHideTechSupported": true,
    "isHidePatch": true,
  }
  log(v) {
    setTimeout(() => {
      this.formParams(this.SelListModal, this.SelDatabaseListModal)
    }, 10)
  }

  paramsObj: any = {
    "productId": "",
    "releases": [],
    "extProdIds": []
  }

  setChecklist(filter) {
    Object.assign(this.filter, filter);
    // this.checkOptionsOne[0].checked = filter.isHideEmpty;
    this.checkOptionsTwo[1].checked = filter.isHideTechSupported;
    this.checkOptionsTwo[0].checked = filter.isHideGenSupported;
  }

  setTableData(data, type: string = "") {
    this.tableEmpty = false;
    // this.columnData = this.convertThList(data[0].prodReleaseMap).sort((a, b) => { return this.common.sortId(a, b) });
    this.columnData = this.convertThList(data[0].prodReleaseMap);
    this.columnData = this.common.removeRepeat(this.columnData);
    if (type == "") this.ShowColumnData = JSON.parse(JSON.stringify(this.columnData))
    if (this.columnData.length == 1) {
      this.rowLen = this.columnData[0].releases.length
    } else {
      this.columnData.forEach(c => this.rowLen += c.releases.length)
    }
    this.rowLen += 1;
    data.forEach(r => {
      r.prodReleaseMap = r.prodReleaseMap ? this.convertThList(r.prodReleaseMap) : []
    })
    this.productListData = data;
    if (this.SelDatabaseListModal.selectedDatabases.length == 0) {
      this.selectType = "default";
      this.SelDatabaseListModal.selectedDatabases = "0";
    }
    this.hideTip = this.common.hideTooltipDB(this.SelListModal, this.SelDatabaseListModal);
  }

  convertThList(relseseMap) {

    // let relseseKeyList = Object.keys(relseseMap).sort((a, b) => Number(b) - Number(a));
    let relseseKeyList = Object.keys(relseseMap);
    if (this.ShowColumn.length) relseseKeyList = this.ShowColumn;
    let thList = [], obj = {};
    relseseKeyList.forEach((r) => {
      thList.push({
        id: r,
        name: relseseMap[r][0].productName,
        length: relseseMap[r].length,
        releases: relseseMap[r],
        checked: true,
        disabled: false
      })
    })
    if (thList.length == 1) {
      thList[0].disabled = true;
    }
    return thList

  }
  //change class
  displayStatus(status: number, note: string, rowChildRelease): string {
    let cls = (!rowChildRelease.techGuided || !rowChildRelease.genGuided) ? ' trSupp' : " ";
    return note == null || note == "" ? `${this.statusCls[status]} + ${cls}` : `${this.statusInfoCls[status]} + ${cls}`;

  }

  getMatrix(obj) {
    setTimeout(() => {
      if (window.location.hash.indexOf("Database") > -1) {
        this.common.setItem("hashTab2", window.location.hash)
      }
    }, 20)
    this.isVisible = true;
    this.dcrService.getDatabaseMatrix(obj).subscribe(data => {
      this.isVisible = false;
      this.ShowColumn = [];
      this.dcrService.DatabaseList = JSON.parse(JSON.stringify(data))
      if (this.dcrService.DatabaseList.length) {
        this.setTableData(data)
        this.tableShowOverflow = "auto";
      } else {
        this.productListData = [];
        this.columnData = [];
        if (this.SelDatabaseListModal.selectedDatabases.length == 0) {
          this.selectType = "default";
          this.SelDatabaseListModal.selectedDatabases = "0";
        }
      }
      // this.common.createMessage('warning');
    }, error => {
      // this.clearTableData();
      this.productListData = [];
      this.columnData = [];
      this.isVisible = false;
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      } else {
        // this.NoResultId = 'No Result';
      }
    })

  }

  unCheck(value) {
    if (value.length == 1) {
      this.ShowColumnData.forEach(r => {
        if (value[0].id == r.id) {
          r.disabled = true;
          r.checked = true;
        } else {
          r.disabled = false;
          r.checked = false
        }
      })
    } else {
      this.ShowColumnData.forEach(r => {
        r.disabled = false
      })
    }

    this.ShowColumn = [];
    value.forEach(r => {
      this.ShowColumn.push(r.id)
    })
    this.setTableData(JSON.parse(JSON.stringify(this.dcrService.DatabaseList)), "uncheck");
  }
  clearTableData() {
    this.tableEmpty = true;
    this.VerHD = [];
    this.panels = [];
    this.SelListModal = [];
    this.SelRowListModal = [];
    this.SelListModal = JSON.parse(JSON.stringify([ProductModel]));
    this.SelRowListModal = JSON.parse(JSON.stringify([CategoryModel]));
    if (this.SelRowListModal[0].CategoryData.filter((r) => { return r.id == 0 }).length == 0) {
      this.SelRowListModal[0].CategoryData.unshift(productMock.AllCategories);
    }
    this.productListData = [];
    this.columnData = [];
    this.tableShowOverflow = "hidden";
    this.NoResultId = 'No Data';
    this.disabledCheckInterop = true;
    this.common.removeItem("hashTab2");
  }

  reset(t) {
    if (t) {
      this.hideTip = false;
      this.checkOptionsTwo[1].checked = true;
      this.checkOptionsTwo[0].checked = true;
      this.clearTableData();
      this.saveFlag = false;
      this.canAddFavoBol = true;
      this.router.navigateByUrl(`/Solution`);
    }
  }

  selValNoAll;
  // LoadSel: boolean = true;
  getDatabaseSel() {
    if (this.common.getItem("tabNu") == "2") {
      this.dcrService.getProduct("database").subscribe(data => {
        this.LoadSel = false;
        this.selValNoAll = JSON.parse(JSON.stringify(data));

        // this.SelListModal.ProductData = data;
        // this.SelListModal.ProductData.sort((a, b) => { return this.common.sortFn(a, b) });
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })

      this.dcrService.getDatabase().subscribe((data: []) => {
        this.SelDatabaseListModal.databaseData = this.common.databaseSwitch(data);
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })
    }
  }

  //select mock data
  // SelListModal: any = { "ProductData": [], "VersionData": [], "selectedPro": "", "selectedReleases": [], rebrandNameList: [] };

  ProductChange(value): void {
    this.disabledCheckInterop = false;

    let version = this.common.getVersion(value, this.selValNoAll);
    // this.SelListModal.VersionData = version.sort((a, b) => { return this.common.sortVersion(a, b) });
    // this.SelListModal.rebrandNameList = this.common.getNameList(value, this.selValNoAll);
    // this.SelListModal.selectedReleases = [];

    this.selectType = "default";
    this.SelDatabaseListModal.selectedDatabases = "0";
  }


  ReleaseChange(value, i): void {
    // this.formParams(this.SelListModal, this.SelDatabaseListModal)
    let id = this.SelListModal[i].selectedPro.id;
    this.SelListModal[i].selectedReleases = value;

  }

  checkIsHidePatch(){
    let colHidePatch = false;
    for (const col of this.SelListModal) {
      if(col.selectedReleases.length>0 && !colHidePatch){
        for (let i = 0; i < col.selectedReleases.length; i++) {
          if(col.selectedReleases[i].releaseType==="Patch"){
            colHidePatch = true;
            break;
          }
        }
      }else if(colHidePatch){
        break;
      }
    }
    if(colHidePatch){
      this.filter.isHidePatch = false;
      this.checkOptionZero.checked = false;
    }else{
      this.filter.isHidePatch = true;
      this.checkOptionZero.checked = true;
    }
  }
  ReleaseRowChange(value, i) {
    this.SelRowListModal[i].selectProduct = value;
  }

  CheckInter(): void {
    this.formParams(this.SelListModal, this.SelDatabaseListModal);
    // this.elementRef.nativeElement.querySelector('.tableShow-area').scrollTop = 0;
  }

  SelDatabaseListModal: any = { "databaseData": [], "selectedDatabases": [] };
  selectType: string = 'default';
  databaseChange(value) {
    // this.formParams(this.SelListModal, this.SelDatabaseListModal)
    if (value.indexOf("0") > -1 || value == "0") {
      this.selectType = "default";
      this.SelDatabaseListModal.selectedDatabases = "0";
    } else {
      this.selectType = "multiple";
    }
  }

  /****unselect hide tooltip*********/
  formParams(rowData, columnData) {
    this.hideTip = this.common.hideTooltipDB(rowData, columnData);
    let params: any = {
      "productId": "",
      "releases": [],
      "extProdIds": []
    }
    let releases: any = [];
    let extProdIds: any = [];
    Object.assign(params, this.filter);
    params.productId = rowData.selectedPro;
    let allVersion = [];
    if (rowData.selectedReleases.length > 0) {
      rowData.selectedReleases.forEach(r => {
        // params.releases.push(r);
        releases.push(r);
      })
    } else {
      // rowData.VersionData.forEach(r => {
      //   // params.releases.push(r.id)
      //   releases.push(r.id);
      // })
    }
    if (this.selectType == "default") {
      extProdIds = [];
    } else {
      if (typeof columnData.selectedDatabases != 'string') {
        columnData.selectedDatabases.forEach(r => {
          // params.extProdIds.push(r);
          extProdIds.push(r);
        })
      } else {
        extProdIds.push(columnData.selectedDatabases)
      }

    }
    this.isVisible = false

    let productId = params.productId;
    params.extProdIds = extProdIds;

    if (rowData.selectedReleases.length > 0) {
      params.releases = releases;
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { productId: productId, releases: releases, extProdIds: extProdIds },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.getMatrix(params);
  }

  //formatTime
  formatTime(min) {
    return moment(min).format('yyyy-MM-DD')
  }

  //highLight
  tdSelected: any;
  tdMouseOver(i, j) {
    if (i == 0) {
      this.tdSelected = j;
    } else {
      this.tdSelected = JSON.stringify(i) + JSON.stringify(j);
    }
  }

  tdMouseOut() {
    this.tdSelected = "";
  }
  getSaveFlag(e) {
    this.common.addToCart(this)
    this.getFavoriteList();
    setTimeout(() => {
      this.containFavo();
    }, 2000)
  }
  deleteSucess(e) {
    this.saveFlag = !e;
    this._data.setSolutionFavData([]);
    this.getFavoriteList();
  }
  getFavoriteList() {
    let favoData: any;
    this.favoriteList = [];
    this.dcrService.getFavoriteList("database").subscribe(data => {
      this._data.setSolutionFavData(data);
      favoData = data;
      favoData.forEach(r => {
        this.favoriteList.push({ "id": r.id, "Compare": JSON.parse(r.aReleases), "With": JSON.parse(r.bReleases) })
      });
    })
  }
  containFavo() {
    let col = [];
    let row = [];
    this.SelListModal.forEach(c => {
      let colAllVersion = this.common.getVersion(c.selectedPro.id, c.ProductData);
      colAllVersion = this.common.reDuplicates(colAllVersion);
      if (c.selectedReleases.length == 0 || c.selectedReleases.length == colAllVersion.length) col.push({ "product": [c.selectedPro.id], "release": [] })
      else {
        let version = [];
        c.selectedReleases.forEach(v => version.push(v.id));
        col.push({ "product": [c.selectedPro.id], "release": version })
      }
    })
    this.SelRowListModal.forEach(r => {
      let RowAllVersion = this._data.getVersionData().filter((re) => { return re.id == r.selectCatagory.id })[0];
      if (r.selectCatagory == productMock.AllCategories) {
        row.push({ "product": [], "release": [] })
      }
      else if (r.selectProduct == [] || r.selectProduct.length == RowAllVersion.products.length) {
        row.push({ "product": [r.selectCatagory.id], "release": [] })
      } else {
        let ver = [];
        r.selectProduct.forEach(v => ver.push(v.id));
        row.push({ "product": [r.selectCatagory.id], "release": ver })
      }
    })
    if (this.favoriteList.length == 0) this.getFavoriteList();
    let p = this.common.containFavoriteList(col, row, this.favoriteList);
    if (p.thesame) {
      this.saveFlag = true;
      this.deleteId = p.deleteId
    }
  }
  openModal(){
    this.commoModal.openModal('link',this.vsdAddress)
  }
}

