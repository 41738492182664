<div class="contain-inter">
    <app-favorite-page #favPage [section]="'MultiCloud'" (saveSuccessBol)="getSaveFlag($event)"
        (deleteSuccessBol)="deleteSucess($event)"></app-favorite-page>
    <app-common-modal #commoModal></app-common-modal>
    <div class="selproduct-area" [style.width]="selproductWid">
        <div>
            <span (click)="widchange()" class="collspan" [hidden]="left"><em nz-icon nzType="double-left"
                    nzTheme="outline"></em></span>
            <span class="collspan" [hidden]="!left"><em nz-icon nzType="double-right" nzTheme="outline"
                    (click)="widchange()"></em></span>
            <div class="selproduct-area-label" [hidden]="!left">
                Interoperability Comparison
            </div>
        </div>
        <div class="selproduct-cond" [hidden]="left">
            <!-- <h3>Interoperability Comparison</h3>
            <p>You can check the Interoperability by comparing one solution with multiple platforms and solutions</p> -->
            <h2>Compare</h2>
            <h3 style="color: rgba(86, 86, 86, 1);border-bottom: 1px solid rgba(0, 0, 0, 0.2);margin-bottom: 0.5rem;">
                Select Solutions</h3>
            <!-- <h3>Select a Solution</h3> -->
            <!-- <p>To show all versions leave versions blank.</p> -->
            <ng-container *ngFor="let s of SelListModal,let i=index">
                <span> Solution {{i+1}}<em *ngIf="i!=0" style="font-size: 16px;float:right" nz-icon
                        (click)="delColumn(i,'column')" class="trash" nzType="delete" nzTheme="outline"></em>
                </span>
                <div
                    style="position: relative;margin-bottom: 5px;background-color:rgba(29, 66, 138, 0.05);padding-bottom: 5px;">
                    <!-- <span>
                        Solution {{i+1}}<em *ngIf="i!=0" style="font-size: 16px;" nz-icon (click)="delColumn(i)"
                            class="trash" nzType="delete" nzTheme="outline"></em>
                    </span> -->
                    <div style="padding: 10px;">
                        <span>Select a Solution</span>
                        <nz-select nzShowSearch [(ngModel)]="s.selectedPro" (ngModelChange)="ProductChange($event,i)"
                            nzPlaceHolder="Select a Solution">
                            <ng-container *ngFor="let p of s.ProductData">
                                <nz-option *ngIf="!LoadSel" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
                            </ng-container>
                            <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                                <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                            </nz-option>
                        </nz-select>
                        <div *ngIf="s.rebrandNameList.length">
                            <!-- <span>View </span> -->
                            <a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
                                "Also known as"
                                <em nz-icon nzType="question-circle" nzTheme="outline"></em>
                            </a>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item><strong>This solution is also known as:</strong></li>
                                    <li nz-menu-item *ngFor="let name of s.rebrandNameList,let i = index">{{name}}</li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                        <span>Select a Version</span>
                        <nz-select *ngIf="!collectionBoolean" [disabled]="s.versionHide||!s.selectedPro" nzAllowClear
                            [nzMode]="versionSelMod" nzPlaceHolder="{{s.versionHide?'':'All versions'}}"
                            [(ngModel)]="s.selectedReleases" (ngModelChange)="ReleaseChange($event,i)">
                            <nz-option *ngFor="let option of s.VersionData" [nzLabel]="option.version"
                                [nzValue]="option.id">
                            </nz-option>
                        </nz-select>
                        <!-- <nz-select *ngIf="collectionBoolean" [disabled]="s.versionHide" nzAllowClear
                            [nzMode]="versionSelMod" nzPlaceHolder="Select a version" [(ngModel)]="s.selectedReleases"
                            (ngModelChange)="ReleaseChange($event,i)">
                            <nz-option *ngFor="let option of s.VersionData" [nzLabel]="option.version"
                                [nzValue]="option.id">
                            </nz-option>
                        </nz-select> -->
                    </div>
                </div>
            </ng-container>
            <button class="addSolution ant-btn-block" id="step1" nzType="primary" nz-button [disabled]="anotherFirBut"
                nzBlock (click)="addColumn()" style="width: 51%;"> <em nz-icon nzType="plus-circle"
                    nzTheme="outline"></em>ADD
                SOLUTION</button>
            <h2>With</h2>
            <h3 style="color: rgba(86, 86, 86, 1); border-bottom: 1px solid rgba(0, 0, 0, 0.2);margin-bottom: 0.5rem;">
                Add Platform/Solution</h3>
            <!-- <p>Add platforms/solutions to see if they are compatible with the selected solution.</p> -->
            <ng-container *ngFor="let s of SelRowListModal,let i=index">
                <span>
                    Platform/Solution {{i+1}} <em *ngIf="i!=0" style="font-size: 16px;float:right" nz-icon
                        (click)="delRow(i)" class="trash" nzType="delete" nzTheme="outline"></em>
                </span>
                <div
                    style="position: relative;;margin-bottom: 5px;background-color:rgba(29, 66, 138, 0.05);padding-bottom: 10px;">
                    <div style="padding: 10px;">
                        <span>Select a Solution</span>
                        <nz-select nzShowSearch [(ngModel)]="s.selectedPro" (ngModelChange)="ProductRowChange($event,i)"
                            nzPlaceHolder="Select a Solution">
                            <!-- <nz-option *ngFor="let p of s.ProductData" [nzValue]="p.id" [nzLabel]="p.name"></nz-option> -->
                            <ng-container *ngFor="let p of s.ProductData">
                                <nz-option *ngIf="!LoadSel" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
                            </ng-container>
                            <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                                <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                            </nz-option>
                        </nz-select>
                        <div *ngIf="s.rebrandNameList.length">
                            <!-- <span>View </span> -->
                            <a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
                                "Also known as"
                                <em nz-icon nzType="question-circle" nzTheme="outline"></em>
                            </a>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item><strong>This solution is also known as:</strong></li>
                                    <li nz-menu-item *ngFor="let name of s.rebrandNameList,let i = index">{{name}}</li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                        <span>Select a Version</span>
                        <nz-select [disabled]="rowDisabled||!s.selectedPro||s.versionHide" nzAllowClear
                            nzMode="multiple" nzPlaceHolder="{{s.versionHide?'':'All versions'}}"
                            [(ngModel)]="s.selectedReleases" (ngModelChange)="ReleaseRowChange($event,i)">
                            <nz-option *ngFor="let option of s.VersionData" [nzLabel]="option.version"
                                [nzValue]="option.id">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
            </ng-container>
            <button class="addSolution" nzType="primary" (click)="addRow()" [disabled]="rowDisabled" nz-button nzBlock
                style="width: 51%;">
                <em nz-icon nzType="plus-circle" nzTheme="outline"></em>ADD
                SOLUTION</button>
            <div class="fix-but-reset">
                <div class="fix-but">
                    <button class="checkInter" nzType="primary" (click)="CheckInter()" nz-button
                        [disabled]="LoadSel||disabledCheckInterop" nzBlock>
                        CHECK INTEROPERABILITY</button>
                </div>
                <span (click)="reset(!disabledCheckInterop||!tableEmpty)"
                    [ngStyle]="{'cursor':!disabledCheckInterop||!tableEmpty?'pointer':'not-allowed'}"
                    style=" color: #1d428a;font-weight: 600;font-size: 14px;">CLEAR</span>
            </div>
        </div>
    </div>
    <div class="tableShow-area" [style.width]="tableShowWid" [style.overflow]="tableShowOverflow"
        (scroll)="onScroll($event)">
        <!-- <div *ngIf="hideTip" style="position:sticky;left:0;">
            <nz-alert nzBanner
                nzMessage='Unselect respective check boxes under "Hide Legacy Releases" section to check more interoperability.'
                nzCloseable>
            </nz-alert>
        </div> -->
        <div class="rightBut" style="position:sticky;left:0;display: flex;justify-content: space-between;margin-top: 8px;">
            <div style="display: flex;justify-content: space-between;flex-direction: column;margin-bottom: -3px;">
                <span style="font-size:25px;color:#000000">Interoperability Result</span>
                <div style="font-weight: bold;">
                    <button class="btn" *ngIf="!saveFlag" style="padding: 0;border: none;color: #8C8C8C;"
                        (click)="onClick('add',$event)" [disabled]="canAddFavoBol">
                        <clr-icon id="cartCollect" shape="star" class="is-solid"
                            style="zoom: 2;position: fixed;display: none;"></clr-icon>
                        <clr-icon shape="star"></clr-icon>
                        Add to My Favorite List
                    </button>
                    <button class="btn" *ngIf="saveFlag" style="padding: 0;border: none;color: #8C8C8C;"
                        (click)="onClick('delete',$event)" [disabled]="canAddFavoBol">
                        <clr-icon shape="star" class="is-solid"></clr-icon>
                        Remove from My Favorite List
                    </button>

                    <label style="margin-left:8px;font-weight: bold;"
                        (ngModelChange)="log(checkOptionsOne)" nz-checkbox
                        [(ngModel)]="checkOptionsTwo[0].checked">Hide Legacy Releases
                        <em style="cursor: pointer;" nz-icon
                            title="Unselect 'Hide Legacy Releases' to check the interoperability of end-of-support releases."
                            nzType="exclamation-circle" nzTheme="outline" nz-tooltip></em>
                    </label>
                </div>

            </div>

            <div class="rightBut_child" style="display: flex;flex-direction: column;justify-content: space-between;text-align: right; padding-bottom: 10px;color: #0079B8;font-size: 12px;font-weight: 600;">

                <nz-button-group nzSize="small">
                    <a nz-button nzType="link" href="{{vcgAddress}}" target="_blank">Compatibility Guide <i nz-icon nzType="arrow-right"
                            nzTheme="outline"></i></a>
                </nz-button-group>
                <div>
                    <nz-button-group nzSize="small">
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu1" style="width: 100%;">
                        DOWNLOAD
                        <i nz-icon nzType="down"></i>
                    </button>
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu2" nzPlacement="bottomRight" style="padding: 0;">
                        <i nz-icon nzType="ellipsis"></i>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':CSVlink()" nz-tooltip [nzTooltipTitle]="tooltipOne[1]">CSV
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':copyClick()" nz-tooltip [nzTooltipTitle]="tooltipOne[2]">COPY
                        </li>
                        <!-- <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':printLink()" nz-tooltip [nzTooltipTitle]="tooltipOne[0]">PRINT
                        </li> -->
                    </ul>
                </nz-dropdown-menu>

                <button *ngIf="CollapseBoolean" nzSize="small" nz-button
                        (click)="collapseTable()" style="margin-left: 35px;pointer-events: auto;"
                        [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}">
                        Collapse All
                    </button>
                <button *ngIf="!CollapseBoolean" nzSize="small" nz-button
                    (click)="collapseTable()" style="margin-left: 35px;pointer-events: auto;"
                    [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}">
                    Expand All
                </button>
                </div>

            </div>

        </div>
        <div class="tableShow-table">
            <nz-table #groupingTable style="width:auto" id="groupingTable" [nzData]="productListData" nzBordered
                nzSize="small" nzHideOnSinglePage="true" [nzFrontPagination]="false" [nzNoResult]="emptyTemplate">
                <ng-template #emptyTemplate>
                    <tr>
                        <nz-empty *ngIf="NoResultId=='No Data'" nzNotFoundImage="simple"></nz-empty>
                        <nz-empty *ngIf="NoResultId=='No Result'"
                            nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            [nzNotFoundContent]="contentTpl">
                            <ng-template #contentTpl>
                                <span> {{NoResultId}} </span>
                            </ng-template>
                        </nz-empty>
                    </tr>
                </ng-template>
                <thead>
                    <tr>
                        <th rowspan="2" nzWidth="1%" nzLeft class="lenged" style="background: white;">
                            <div style="width: 330px;display: flex;">
                                <ul>
                                    <li title="{{tooltipTwo[0]}}" class="comptibility">Compatible</li>
                                    <li title="{{tooltipTwo[2]}}" class="Incompatible">Incompatible
                                    </li>
                                    <li title="{{tooltipTwo[3]}}" class="notSupport">Not Supported
                                    </li>
                                </ul>
                                <ul>
                                    <!-- <li title="{{tooltipTwo[1]}}" class="comptibility_NT">Compatible:
                                            Not
                                            Tested
                                        </li> -->
                                    <li title="{{tooltipTwo[5]}}" class="EOTS">Past End of Technical
                                        Guidance</li>
                                    <li title="{{tooltipTwo[4]}}" class="ENGS">Past End of General
                                        Support</li>
                                </ul>
                            </div>
                        </th>
                        <ng-container *ngFor="let c of columnData">
                            <th nzLeft class="firstTh" style="text-align: left;top: 0px;z-index: 20;background: white;"
                                [colspan]="c.length">
                                {{c.name}}
                            </th>
                        </ng-container>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let c of columnData">
                            <ng-container *ngFor="let v of c.releases">
                                <!-- <th nzLeft nz-tooltip [nzTooltipTitle]="titleTemplate" class=""
                                        [ngStyle]="{'background':v.version=='Nonversioned'?'#d7d7d7':''}"
                                        style="text-align: center;top: 40px;z-index: 20;font-size: 12px;"> -->
                                <th nzLeft nz-tooltip [nzTooltipTitle]="(v.version==='Nonversioned'|| v.version === '0.0.0')?'':titleTemplate"
                                    class=""
                                    style="text-align: center;top: 40px;z-index: 20;font-size: 12px;background: white;">
                                    <span> {{(v.version==='Nonversioned'|| v.version === '0.0.0')?"":v.version}}</span>
                                    <span [ngClass]="{'techGuided' : !v.techGuided }"></span>
                                    <span [ngClass]="{'genGuided': !v.genGuided }"></span>
                                    <ng-template #titleTemplate>
                                        <span>{{v.version}}</span><br>
                                        <span>GA : {{formatTime(v.gaDate)}}</span><br>
                                        <span *ngIf="v.rnLink"><a href="{{v.rnLink}}" target="_blank">Release
                                                Notes</a><br></span>
                                        <span *ngIf="v.dLink"><a href="{{v.dLink}}" target="_blank">Download
                                                Page</a><br></span>
                                    </ng-template>
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let product of groupingTable.data">
                        <tr>
                            <td class="tdProduct productTR" [nzShowExpand]="!!product.releases"
                                [(nzExpand)]="product.expand" [attr.colspan]="rowLen">
                                <div class="prod-left-fix">
                                    {{product.name}}
                                </div>
                            </td>
                        </tr>
                        <ng-container *ngFor="let release of product.releases">
                            <tr *ngIf="product.expand"
                                [ngClass]="{'trSupp' : !release.techGuided || !release.genGuided }">
                                <td class="tdProduct" style="color: rgba(0,0,0,.85);" nzLeft nz-tooltip
                                    [nzTooltipTitle]="(release.version==='Nonversioned'|| release.version==='0.0.0')?'':titleTemplate"
                                    [ngClass]="{'trSupp' : !release.techGuided || !release.genGuided }"
                                    *ngIf="!release.techGuided || !release.genGuided">
                                    <div style="width: 330px;">
                                        <em>
                                            {{(release.version==='Nonversioned'|| release.version==='0.0.0')?release.productName:release.version}}
                                            {{release.productName==product.name?"":" - " + release.productName}}
                                            </em>
                                            <span [ngClass]="{'techGuided' : !release.techGuided }"></span>
                                            <span [ngClass]="{'genGuided': !release.genGuided }"></span>
                                            <ng-template #titleTemplate>
                                                <span>{{release.version}}</span><br>
                                                <span>GA : {{formatTime(release.gaDate)}}</span><br>
                                                <span *ngIf="release.rnLink"><a href="{{release.rnLink}}"
                                                        target="_blank">Release Notes</a><br></span>
                                                <span *ngIf="release.dLink"><a href="{{release.dLink}}"
                                                        target="_blank">Download
                                                        Page</a><br></span>
                                            </ng-template>
                                    </div>
                                </td>
                                <td class="tdProduct" style="color: rgba(0,0,0,.85);" nzLeft nz-tooltip
                                    [nzTooltipTitle]="(release.version==='Nonversioned'|| release.version==='0.0.0')?'':titleTemplate"
                                    [ngClass]="{'trSupp' : !release.techGuided || !release.genGuided }"
                                    *ngIf="release.techGuided && release.genGuided">
                                    <div style="width: 330px;">
                                        <span>{{(release.version==='Nonversioned'|| release.version==='0.0.0')?release.productName:release.version}}
                                            {{release.productName==product.name?"":" - " + release.productName}}</span>
                                        <ng-template #titleTemplate>
                                            <span>{{release.version}}</span><br>
                                            <span>GA : {{formatTime(release.gaDate)}}</span><br>
                                            <span *ngIf="release.rnLink"><a href="{{release.rnLink}}"
                                                    target="_blank">Release Notes</a><br></span>
                                            <span *ngIf="release.dLink"><a href="{{release.dLink}}"
                                                    target="_blank">Download
                                                    Page</a><br></span>
                                        </ng-template>
                                    </div>
                                </td>
                                <ng-container *ngFor="let rowRelease of release.rowProdReleaseMap,let i = index">
                                    <ng-container *ngFor="let rowChildRelease of rowRelease.releases,let j = index">
                                        <td *ngIf="rowChildRelease.footnotes==''" (mouseover)="tdMouseOver(i,j)"
                                            (mouseout)="tdMouseOut()"
                                            [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                            [ngClass]="displayStatus(rowChildRelease.status,rowChildRelease.footnotes,rowChildRelease)">
                                        </td>
                                        <td *ngIf="rowChildRelease.footnotes!=''" nz-tooltip
                                            (mouseover)="tdMouseOver(i,j)" (mouseout)="tdMouseOut()"
                                            [nzTooltipTitle]="tdInfo"
                                            [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                            [ngClass]="displayStatus(rowChildRelease.status,rowChildRelease.footnotes,rowChildRelease)">
                                        </td>
                                        <ng-template #tdInfo>
                                            <span [innerHTML]="rowChildRelease.footnotes"></span><br>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
            <div id="print" [style.display]="copyType">
                <table>
                    <tr>
                        <th>
                        </th>
                        <ng-container *ngFor="let c of columnData">
                            <ng-container *ngFor="let v of c.releases">
                                <th style="text-align: center;">{{c.name}} {{(v.version==='Nonversioned'||v.version==='0.0.0')?'':v.version}}
                                    <span *ngIf="!v.techGuided||!v.genGuided">*</span>
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <ng-container *ngFor="let product of groupingTable.data">
                        <ng-container *ngFor="let release of product.releases">
                            <tr *ngIf="product.expand">
                                <td nzLeft>{{product.name}} {{(release.version === 'Nonversioned' || release.version === '0.0.0') ? '' : release.version}}
                                    <span *ngIf="!release.techGuided||!release.genGuided">*</span>
                                </td>
                                <ng-container *ngFor="let rowRelease of release.rowProdReleaseMap">
                                    <ng-container *ngFor="let rowChildRelease of rowRelease.releases">
                                        <td>
                                            <span>{{tableTdWords[rowChildRelease.status]}}</span>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td></td>
                    </tr>
                </table>
                <span>* This is a legacy release. For more information, please refer to
                    https://lifecycle.vmware.com.</span>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="isVisible" [config]="{ backdropBorderRadius: '3px'}" [template]="customLoadingTemplate">
</ngx-loading>
