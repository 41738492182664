import { Component, ElementRef } from '@angular/core';

import { CommonService } from "../app/services/common.service";
import { FeedbackService } from '../app/services/feedback.service';
import * as introJs from 'intro.js/intro.js';
import { InfoBanner } from './model/common.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  introJS = introJs();
  title = 'external-app';
  closeGuideHeader: boolean = false;
  nu: boolean = false;
  IB = InfoBanner[1];
  constructor(
    private elementRef: ElementRef,
    private common: CommonService,
    private feedbackService: FeedbackService
  ) {

  }

  ngOnInit() {
    this.introSetoptions();
    // this.feedbackService.showFeedback();
    // this.feedbackService.identifyUser('guest');

    // Number(this.common.getItem("tabNu"))==0?this.nu=false:this.nu=true;
    switch (window.location.hash) {
      case '#/Interoperability': {
        this.common.setItem("tabNu", 0);
        break;
      }
      case '#/Database': {
        this.common.setItem("tabNu", 1);
        break;
      }
      case '#/Upgrade': {
        this.common.setItem("tabNu", 2);
        break;
      }
      default: {
        this.common.setItem("tabNu", 0);
      }
    }

    if (window.location.hash.indexOf("Interoperability") > -1) {
      this.nu = false;
    } else {
      this.nu = true;
    }
  }

  onGuide() {
    this.introJS.start();
  }

  close() {
    this.closeGuideHeader = true;
    this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 166px)";
    this.elementRef.nativeElement.querySelector('.selproduct-cond').style.height = "calc(100vh - 300px)";
  }

  introSetoptions() {
    setTimeout(() => {
      this.introJS.setOptions({
        steps: [
          {
            element: this.elementRef.nativeElement.querySelector('#step1'),
            intro: "Check more than one products interoperability on x-axis.",
            position: 'bottom'
          },
          {
            element: this.elementRef.nativeElement.querySelector('#step2'),
            intro: " Click to customize columns in the matrix.",
            position: 'bottom'
          },
          {
            element: this.elementRef.nativeElement.querySelector('#step3'),
            // intro: "Check to hide columns/rows which has only one Interoperability (Compatible, Compatible: Not Tested, Incompatible, Not supported) status.",
            intro: "Click to hide columns/rows which has only one Interoperability (Compatible, Compatible: Not Tested, Incompatible, Not supported) status.",
            position: 'bottom'
          },
          {
            element: this.elementRef.nativeElement.querySelector('#step4'),
            // intro: "Check to hide releases that has past End of Technical Guidance date and End of General Support date.",
            intro: "Click to hide releases that has past End of Technical Guidance date and End of General Support date.",
            position: 'bottom'
          },
          {
            element: this.elementRef.nativeElement.querySelector('#step5'),
            intro: "Click to collapse or expand the matrix.",
            position: 'bottom'
          },
        ]
      });
    }, 100)
  }
}
