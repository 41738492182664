import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { finalize, filter, switchMap, catchError } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentsService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  REDIRECT_URI = '/login/authenticate';
  private userSessionKey = 'external-user-sessionkey';
  private jwtService: JwtHelperService;
  private currentSession: BehaviorSubject<any>;
  private authUrl: string;
  private apiUrl: string;
  private logoutUrl: string;
  private orgId:string;
  private codeChallenge:string;
  header = { "headers": { "X-Auth-Key": "N31mVcQkL?Q]GSe[Tve0Wl8b[i2_vU:ClohDvU7Ex;GCu4=hxa=q>3B<aMEZRwmT" }, "set-cookie": "samesite=none" };

  get httpOption() { return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; }

  constructor(private _loc: Location, private _http: HttpClient, private _env: EnvironmentsService) {
    this.authUrl = this._env.config.authUrl;
    this.apiUrl = this._env.config.simServiceUrl;
    this.codeChallenge = this._env.config.codeChallenge;
    this.orgId = this._env.config.orgId;
    this.logoutUrl = this._env.config.logoutUrl;
    this.jwtService = new JwtHelperService();
    let session = null;
    try {
      session = JSON.parse(localStorage.getItem(this.userSessionKey));
    } catch (e) { }
    this.currentSession = new BehaviorSubject(session);
  }

  saveCurrentSession(session){
    session.user = this.jwtService.decodeToken(session.access_token);
    localStorage.setItem(this.userSessionKey,JSON.stringify(session));
    this.currentSession.next(session);
  }

  getCurrentSession(): BehaviorSubject<any>{
    return this.currentSession
  }

  getRefreshToken() {
    const session = this.currentSession.getValue();
    return session ? session.refresh_token : null;
  }

  getAccessToken() {
    const session = this.currentSession.getValue();
    return session ? session.access_token : null;
  }

  clearCurrentSession(){
    localStorage.removeItem(this.userSessionKey);
    this.currentSession.next(null);
  }
  
  isTokenExpired(token: string) {
    return this.jwtService.isTokenExpired(token);
  }
  
  logIn(){
    let cur = window.location.pathname + window.location.search;
    this.redirectToLogin(cur);
  }

  logout(){
    let cur = window.location.href
    this.clearCurrentSession();
    this.redirectToLogin(cur,'logout');
  }

  redirectToLogin(url:string, type?:string){
    const clientId = this._env.config.authClientId;
    const redirectUri = window.location.origin + this._loc.prepareExternalUrl(this.REDIRECT_URI);
   const state = { client_id: clientId, url: url };
    const encoded = btoa(JSON.stringify(state));
    const orgLink = '/csp/gateway/am/api/orgs/' + this.orgId;
    const login = `${this.authUrl}/discovery?idp_id=vmware.com&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${encoded}&orgLink=${orgLink}&code_challenge=${this.codeChallenge}&code_challenge_method=S256`;
    
    const logoutUrl = `${this.authUrl}/discovery?logout&orgId=${this.orgId}`;
    type != "logout" ? window.location.href = `${login}` : window.location.href = logoutUrl;
  }

  //Authentication APIs
  authenticase(code: string): Observable<any> {
    const clientId = this._env.config.authClientId;
    const redirectUri = window.location.origin + this._loc.prepareExternalUrl(this.REDIRECT_URI);
    let data: any = { 
      grant_type: 'authorization_code', 
      code: code, 
      refresh_token:null, 
      client_id:  clientId,
      redirect_uri: redirectUri,
  };
    // if (!this._env.config.production) data.bypass_uri_check = true;
    return this._http.post(this.apiUrl+'/csp/token', data, this.header);
  }

  refreshToken(token: string) {
    const clientId = this._env.config.authClientId;
    const redirectUri = window.location.origin + this._loc.prepareExternalUrl(this.REDIRECT_URI);
    let data = { 
      grant_type: 'refresh_token',
      code:null, 
      refresh_token: token,
      client_id:  clientId,
      redirect_uri: redirectUri,
    };
    return this._http.post(this.apiUrl+'/csp/token', data, this.header);
  }
}
