import { Component, OnInit, ViewChild, TemplateRef, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

//service
import { DcrService } from "../../services/dcr.service";
import { CommonService } from "../../services/common.service";

import * as moment from "moment";
import { Router, ActivatedRoute } from '@angular/router';
import { NgxLoadingComponent } from "ngx-loading"
import { Location } from '@angular/common';
import { FavoritePageComponent } from '../favorite-page/favorite-page.component';
import { EVENT_TYPE } from '../../model/common.model';
import { DataService } from '../../services/data.service';
import { position } from '../../model/data.modal';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {
  ShowColumnData: any[];
  ShowColumn: string[] = [];
  tableShowOverflow: string;
  selproductWid: string; tableShowWid: string;
  left: boolean = false;

  tableTdWords = [, 'Compatible', 'Incompatible', 'Compatible', 'Not Supported'];
  readonly statusCls: string[] = ["", "tdCompatible", "tdIncompatible", "tdCompatibleNT", "tdNotSupport"];
  readonly statusInfoCls: string[] = ["", "tdCompatibleinfo", "tdIncompatibleinfo", "tdCompatibleNTinfo", "tdNotSupportinfo"];

  cartPosition: position = null;

  @ViewChild('ngxLoading', { static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false }) customLoadingTemplate: TemplateRef<any>;
  @ViewChild('favPage', { static: false }) favPage: FavoritePageComponent;
  @ViewChild('commoModal', { static: false }) commoModal: CommonModalComponent;
  saveFlag: boolean;
  favoriteList: any[] = [];
  canAddFavoBol: boolean = true;
  deleteId: any;
  param: any;

  user: any

  checkOptionZero={label:'Hide Patch Releases',value:'pathc',checked:true};
  checkOptionOne={label:'Hide Legacy Releases',value:'legacy',checked:true};
  get name() { return this.user.username }
  get isLogin() { return this.user != undefined && this.user != null }

  vsdAddress = "https://vsd.esp.vmware.com"
  vcgAddress = "https://www.vmware.com/resources/compatibility/search.php"
  constructor(
    private dcrService: DcrService,
    private common: CommonService,
    private message: NzMessageService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _data: DataService,
    private renderer: Renderer2,
    private _auth: AuthService,
  ) {
    // location.onUrlChange(url => this.ngAfterContentInit());
  }
  tooltipOne: string[];
  tooltipTwo: string[];
  ngOnInit(): void {
    this._auth.getCurrentSession().subscribe((session) => {
      this.user = session?.user;
    })
    this.selproductWid = "400px";
    this.tableShowWid = ""
    //table
    this.getUpSel();

    this.tooltipOne = this.common.tooltipForCCP;
    this.tooltipTwo = this.common.tooltipForLenged;
    this.getFavoriteList();
    if (!this.cartPosition) {
      this.cartPosition = this._data.firstPostion;
    }

    this.route.queryParams.subscribe(params => {
      if (params['productId']) {
        let productId = JSON.parse(params['productId'])
          let isHidePatch = params['isHidePatch'] ? JSON.parse(params['isHidePatch']) : true
          let isHideLegacyReleases = params['isHideLegacyReleases'] ? JSON.parse(params['isHideLegacyReleases']) : true
          this.SelListModal.selectedPro = productId;
          this.param = productId;
          this.checkOptionZero.checked = isHidePatch;
          this.checkOptionOne.checked = isHideLegacyReleases;
          this.getMatrix(productId);
      } else {
        this.clearTableData();
        this.SelListModal.selectedPro = "";
      }
    }, error => {
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      }
    });
  }

  //pack up 
  widchange() {

    this.left = !this.left;
    this.selproductWid = this.left ? "25px" : "400px";
    this.tableShowWid = "";

  }

  //upgrade Select data
  LoadSel: boolean = true;
  getUpSel() {
    if (this.common.getItem("tabNu") == "3") {
      this.dcrService.getProduct("upgrade").subscribe(data => {
        this._data.setUpgradeData(data);
        this.LoadSel = false;
        this.SelListModal.ProductData = JSON.parse(JSON.stringify(data));
        this.dcrService.UpgradeProList = JSON.parse(JSON.stringify(data))
        setTimeout(() => {
          this.setCondition(this.param);
        }, 3000);
      }, error => {
        if (error.error.text != "No Results") {
          this.common.errStatus(error.status, error.error);
        }
      })
    }
  }

  setCondition(params) {
    if (params) {
      this.SelListModal.rebrandNameList = this.common.getNameList(params, this.SelListModal.ProductData);
      this.canAddFavoBol = false;
      this.containFavo();
    }
  }

  // get table data 
  isVisible: boolean = false;
  NoResultId = "No Data"
  getMatrix(productId) {

    setTimeout(() => {
      if (window.location.hash.indexOf("Upgrade") > -1) {
        this.common.setItem("hashTab3", window.location.hash)
      }
      // this.common.setItem("productId", JSON.stringify(productId));
      // this.common.setItem("UpSelListModal", JSON.stringify(this.SelListModal));
    }, 20)

    this.isVisible = true;
    this.dcrService.getUpgrades(productId,this.checkOptionZero.checked,this.checkOptionOne.checked).subscribe(data => {
      this.isVisible = false;
      if(data['upgradeProducts'].length > 0){
        this.setTableData(data)
      }else{
        this.clearTableData();
      }
      // this.ShowColumn = [];
      // this.dcrService.UpgradeMatrixList = JSON.parse(JSON.stringify(data))
      this.tableShowOverflow = "auto";
      // this.common.createMessage('warning');
    }, error => {
      this.clearTableData();
      this.isVisible = false;
      if (error.error.text != "No Results") {
        this.common.errStatus(error.status, error.error);
      } else {
        this.NoResultId = 'No Result';
      }
    })

  }

  unCheck(value) {
    if (value.length == 1) {
      this.ShowColumnData.forEach(r => {
        if (value[0].id == r.id) {
          r.disabled = true;
          r.checked = true;
        } else {
          r.disabled = false;
          r.checked = false
        }
      })
    } else {
      this.ShowColumnData.forEach(r => {
        r.disabled = false
      })
    }
    this.ShowColumn = [];
    value.forEach(r => {
      this.ShowColumn.push(r.id)
    })
    this.setTableData(JSON.parse(JSON.stringify(this.dcrService.UpgradeMatrixList)), "uncheck");
  }

  clearTableData() {
    this.tableEmpty = true;
    this.productListData = [];
    this.columnData = [];//{ name: "", "upgradeProducts": { "releases": [] } };
    this.headerName = [];
    this.tableShowOverflow = "hidden";
    this.common.removeItem("hashTab3");
  }

  productListData: any[];
  productName: string;
  // columnData: { name?: "", upgradeProducts?: { releases: [] } } = { name: "", "upgradeProducts": { "releases": [] } };
  columnData: any[];
  column: any[];
  headerName: any[];
  headerNameArr: any[];
  rowLen: number;
  tableEmpty: boolean = true;
  setTableData(data, type: string = "") {
    this.tableEmpty = false;
    this.productName = data.name;

    const startsWith = wordToCompare => (value, index, array) => {
      return wordToCompare.indexOf(value.productId) > -1;
    }
    if (this.ShowColumn.length > 0) {
      data.upgradeProducts.forEach(r => {
        r.releases = r.releases.filter(startsWith(this.ShowColumn));
      })
    }


    this.columnData = data.upgradeProducts[0].releases;
    let thList = [], obj = { "id": "" };


    this.headerNameArr = data.upgradeProducts[0].releases.reduce(function (r, a) {
      r[a.childProductName] = r[a.childProductName] || [];

      let b: any = {};
      b.id = a.productId;
      b.name = a.childProductName,
        b.length = 1,
        b.releases = [];
      b.releases.push(a);
      b.checked = true;
      b.disabled = false;
      if (obj.id != b.id) {
        obj = b;
        thList.push(b);
      } else {
        thList[thList.length - 1].releases.push(a);
        thList[thList.length - 1].length = thList[thList.length - 1].releases.length;
      }
      r[a.childProductName].push(a);
      return r;
    }, Object.create(null));
    this.headerName = Object.keys(this.headerNameArr);
    if (type == "") this.ShowColumnData = JSON.parse(JSON.stringify(thList))

    this.rowLen = data.upgradeProducts[0].releases.length
    this.productListData = data.upgradeProducts;
  }

  checkChange(){
    setTimeout(()=>{
      if(this.SelListModal.selectedPro){
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: this.checkOptionZero.checked && this.checkOptionOne.checked 
              ? { productId: this.SelListModal.selectedPro } 
              : { productId: this.SelListModal.selectedPro, isHidePatch:this.checkOptionZero.checked, isHideLegacyReleases: this.checkOptionOne.checked },
        });
      }
    },100)
  }


  //select mock data
  SelListModal: any = { "ProductData": [], "selectedPro": "", "rebrandNameList": [] };

  ProductChange(value) {
    this.checkOptionZero.checked = true;

    this.SelListModal.rebrandNameList = [];
    if (value != null) {
      this.isVisible = false;
      this.SelListModal.rebrandNameList = this.common.getNameList(value, this.SelListModal.ProductData);
      let queryPayload = this.checkOptionZero.checked ? {productId: value} : { productId: value, isHidePatch:this.checkOptionZero.checked }
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: queryPayload,
        });
      // this.getMatrix(value);
      this.canAddFavoBol = false;
      this.saveFlag = false;
      this.containFavo();
      this.elementRef.nativeElement.querySelector('.tableShow-area').scrollTop = 0
    }

  }

  //change class
  displayStatus(status: number, note: string, rowChildRelease): string {
    let cls = (!rowChildRelease.techGuided || !rowChildRelease.genGuided) ? ' trSupp' : " ";
    return note == null || note == "" ? `${this.statusCls[status]} + ${cls}` : `${this.statusInfoCls[status]} + ${cls}`;
    // return note == null || note == "" ? this.statusCls[status] : this.statusInfoCls[status]

  }

  //copy
  copyType: string = "none";
  copyMessage;
  copyClick() {
    this.copyType = "block";
    this.common.createModalMessage("Info", 'Copy to Clipboard').success();
    setTimeout(() => {
      console.log(this.copyMessage);
      this.message.remove(this.copyMessage);
      this.common.copy("UpgradePrint")
      this.copyType = "none"
    }, 1)
  }

  //csv
  CSVlink() {
    this.common.csv("UpgradePrint", "Upgrade")
  }

  //print
  printLink() {
    let css = `<style type="text/css">${this.common.cssStr}}</style> `
    this.common.print("UpgradePrint", css)
  }

  formatTime(min) {
    return moment(min).format('yyyy-MM-DD')
  }

  @HostListener('window:scroll', ['$event'])
  scrollTop: number = 0;
  onScroll(s) {
    // let st = s.target;
    // let f: any = document.getElementsByClassName('ph-footer');
    // if (st.scrollHeight == st.clientHeight + st.scrollTop && st.scrollHeight != st.clientHeight) {
    //   console.log(st.scrollTop);
    //   // console.log(st.clientHeight);
    //   // console.log(st.scrollHeight);
    //   this.scrollTop == 0 ? this.scrollTop = st.scrollTop : "";
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 230px)";
    //   f[0].style.display = "block";
    // } else if (this.scrollTop > st.scrollTop) {
    //   this.elementRef.nativeElement.querySelector('.contain-inter').style.height = "calc(100vh - 142px)";
    //   f[0].style.display = "none";
    // }
  }

  //highLight
  tdSelected: any;
  tdMouseOver(i) {
    this.tdSelected = i;
  }

  tdMouseOut() {
    this.tdSelected = "131355";
  }

  onClick(type: string) {
    switch (type) {
      case EVENT_TYPE.ADD:
        this.favPage.compareList = this.SelListModal;
        this.favPage.openAddModal();
        break;
      case EVENT_TYPE.DELETE:
        this.favPage.openDeleteModal(this.deleteId);
        break;
    }
  }
  getSaveFlag(e) {
    this.common.addToCart(this);
    this.getFavoriteList();
    setTimeout(() => {
      this.containFavo();
    }, 2000)
  }
  deleteSucess(e) {
    this.saveFlag = !e;
    this._data.setUpgradeFavData([]);
    this.getFavoriteList();
  }
  getFavoriteList() {
    let favoData: any;
    this.favoriteList = [];
    this.dcrService.getFavoriteList("upgrade").subscribe(data => {
      this._data.setUpgradeFavData(data);
      favoData = data;
      favoData.forEach(r => {
        this.favoriteList.push({ "id": r.id, "Compare": JSON.parse(r.aReleases), "With": JSON.parse(r.bReleases) })
      });
    })
  }
  containFavo() {
    let combol: boolean = false;
    if (this.favoriteList.length == 0) this.getFavoriteList();
    this.favoriteList.forEach(f => {
      if (!combol) {
        if (f.Compare[0].product[0] == this.SelListModal.selectedPro) {
          combol = true;
          this.deleteId = f.id;
        }
      }
    })
    if (combol) this.saveFlag = true;
  }
  openModal(){
    this.commoModal.openModal('link',this.vsdAddress)
  }
  downloadVCF(){
    this.commoModal.openModal('download')
  }
}
