import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgPipesModule } from 'ngx-pipes';
import { ROUTING } from "./app.routing";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { ClarityModule } from "@clr/angular";

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import en from '@angular/common/locales/en';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/footer/footer.component';
import { TabPageComponent } from './components/tab-page/tab-page.component';
import { TabnavComponent } from './components/tabnav/tabnav.component';
import { InteroperableComponent } from './components/interoperable/interoperable.component';
import { databaseTabComponent } from './components/database-tab/database-tab.component';
import { UpgradeComponent } from './components/upgrade/upgrade.component';
import { cumgroupBy } from './pipe/groupByPipe';

import { NgxLoadingModule } from 'ngx-loading';

import { DcrService } from "./services/dcr.service";
import { AuthInterceptor } from './services/auth.interceptor';
import { SolutionComponent } from './components/solution/solution.component';
import { LoginComponent } from './components/login/login.component';
import { FavoritePageComponent } from './components/favorite-page/favorite-page.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { MyFavoriteComponent } from './components/my-favorite/my-favorite.component';
import { MultiCloudComponent } from './components/multi-cloud/multi-cloud.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TabPageComponent,
    TabnavComponent,
    InteroperableComponent,
    databaseTabComponent,
    UpgradeComponent,
    SolutionComponent,
    cumgroupBy,
    FavoritePageComponent,
    DataTableComponent,
    LoginComponent,
    MyFavoriteComponent,
    MultiCloudComponent,
    CommonModalComponent
  ],
  imports: [
    BrowserModule,
    ROUTING,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DemoNgZorroAntdModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ClarityModule,
    NgPipesModule,
    NgxLoadingModule.forRoot({})
  ],
  providers   : [ 
    { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },
    DcrService,
    FontAwesomeModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
