<div class="contain-inter">
    <app-favorite-page #favPage [section]="'upgrade'" (saveSuccessBol) = "getSaveFlag($event)" (deleteSuccessBol)="deleteSucess($event)"></app-favorite-page>
    <app-common-modal #commoModal></app-common-modal>
    <div class="selproduct-area" [style.width]="selproductWid">
        <div>
            <span (click)="widchange()" class="collspan" [hidden]="left"><em nz-icon nzType="double-left"
                    nzTheme="outline"></em></span>
            <span class="collspan" [hidden]="!left"><em nz-icon nzType="double-right" nzTheme="outline"
                    (click)="widchange()"></em></span>
            <div class="selproduct-area-label" [hidden]="!left">
                Interoperability Comparison
            </div>
        </div>
        <div class="selproduct-cond" [hidden]="left">
            <h3>Select a Solution</h3>
            <div style="background-color:rgba(29, 66, 138, 0.05);padding: 0px 10px 10px 10px;">
            <nz-select nzShowSearch [(ngModel)]="SelListModal.selectedPro" (ngModelChange)="ProductChange($event)"
                nzPlaceHolder="Select a Solution">
                <ng-container *ngFor="let p of SelListModal.ProductData">
                    <nz-option *ngIf="!LoadSel" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
                </ng-container>
                <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                    <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                </nz-option>
            </nz-select>
            <div *ngIf="SelListModal.rebrandNameList.length">
                <a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
                    "Also known as"
                    <em nz-icon nzType="question-circle" nzTheme="outline"></em>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item><strong>This solution is also known as:</strong></li>
                        <li nz-menu-item *ngFor="let name of SelListModal.rebrandNameList,let i = index">{{name}}</li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            </div>
        </div>
    </div>
    <div class="tableShow-area" [style.width]="tableShowWid" [style.overflow]="tableShowOverflow"
        (scroll)="onScroll($event)">
        <!-- <div class="">
            <nz-alert nzBanner nzMessage="Unselect 'Hide Legacy Releases' to check more interoperability." nzCloseable></nz-alert>
        </div> -->
        <div class="rightBut" style="position:sticky;left:0;display: flex;justify-content: space-between;margin-top: 8px;">
            <div class="check-2" id="step4">
                <span style="font-size:25px;color:#000000">Interoperability Result</span>
                <div style="display: flex;justify-content: start;max-height: 48px;">
                    <div style="font-weight: bold;">
                        <button class="btn" *ngIf="!saveFlag" style="padding: 0;border: none;color: #8C8C8C;margin-top: 10px;"
                            (click)="onClick('add')" [disabled]="canAddFavoBol">
                            <clr-icon id="cartCollect" shape="star" class="is-solid"
                                style="zoom: 2;position: fixed;display: none;"></clr-icon>
                            <clr-icon shape="star"></clr-icon>
                            Add to My Favorite List
                        </button>
                        <button class="btn" *ngIf="saveFlag" style="padding: 0;border: none;color: #8C8C8C;margin-top: 10px;"
                            (click)="onClick('delete')" [disabled]="canAddFavoBol">
                            <clr-icon shape="star" class="is-solid"></clr-icon>
                            Remove from My Favorite List
                        </button>
                    </div>
                    <div class="checkArea">
                        <label style="margin-left:0px;margin-right:8px;font-weight: bold;"
                            (ngModelChange)="checkChange()" nz-checkbox
                            [(ngModel)]="checkOptionOne.checked">Hide Legacy Releases <em style="cursor: pointer;" nz-icon
                            title="Unselect 'Hide Legacy Releases' to check the interoperability of end-of-support releases."
                            nzType="exclamation-circle" nzTheme="outline" nz-tooltip></em>
                        </label>
                        <label style="margin-left:0px;margin-right:8px"
                            (ngModelChange)="checkChange()" nz-checkbox
                            [(ngModel)]="checkOptionZero.checked">{{checkOptionZero.label}}</label>
                    </div>
                </div>
            </div>

            <div class="rightBut_child" style="display: flex;flex-direction: column;justify-content: space-between;text-align: right; padding-bottom: 10px;color: #0079B8;font-size: 12px;font-weight: 600;">

                <nz-button-group nzSize="small">
                    <a nz-button nzType="link" href="{{vcgAddress}}" target="_blank">Compatibility Guide <i nz-icon nzType="arrow-right"
                            nzTheme="outline"></i></a>
                </nz-button-group>
                <nz-button-group nzSize="small">
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu1" >
                        DOWNLOAD
                        <i nz-icon nzType="down"></i>
                    </button>
                    <button nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu2" nzPlacement="bottomRight" style="padding: 0;">
                        <i nz-icon nzType="ellipsis"></i>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':CSVlink()" nz-tooltip [nzTooltipTitle]="tooltipOne[1]">CSV
                        </li>
                        <li nz-menu-item [nzDisabled]='!isLogin' (click)="!isLogin?'':downloadVCF()" nz-tooltip [nzTooltipTitle]="!isLogin?tooltipOne[3]:''">Download VCF Bundle</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':copyClick()" nz-tooltip [nzTooltipTitle]="tooltipOne[2]">COPY
                        </li>
                        <!-- <li nz-menu-item [nzDisabled]='tableEmpty' (click)="tableEmpty?'':printLink()" nz-tooltip [nzTooltipTitle]="tooltipOne[0]">PRINT
                        </li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>

        </div>
        <div class="tableShow-table">
            <div class="butArea" >
                <!-- <div [style.height]="'30px'" class="tableTop">
                    <div class="thLeft" style="width:100%">
                        <a class="columns" style="pointer-events: auto;"
                            [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}" nz-dropdown [nzDisabled]="tableEmpty"
                            nzTrigger="click" [nzDropdownMenu]="menu">
                            <span>
                                Hide Columns
                            </span>
                        </a>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <div class="dropDownCls">
                                <div class="Down-header">Show Columns</div>
                                <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="unCheck($event)">
                                    <div nz-row>
                                        <div *ngFor="let i of ShowColumnData" nz-col nzSpan="24"><label nz-checkbox
                                                [nzValue]="i" [nzDisabled]="i.disabled"
                                                [ngModel]="i.checked">{{i.name}}</label></div>
                                    </div>
                                </nz-checkbox-wrapper>
                            </div>
                        </nz-dropdown-menu>
                    </div>
                </div> -->
                <nz-table #groupingTable id="UpgradeTable" [nzData]="productListData" nzBordered nzSize="small"
                    nzHideOnSinglePage="true" [nzFrontPagination]="false" [nzNoResult]="emptyTemplate">
                    <ng-template #emptyTemplate>
                        <tr>
                            <nz-empty *ngIf="NoResultId=='No Data'" nzNotFoundImage="simple"></nz-empty>
                            <nz-empty *ngIf="NoResultId=='No Result'" nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                [nzNotFoundContent]="contentTpl">
                                <ng-template #contentTpl>
                                    <span> {{NoResultId}} </span>
                                </ng-template>
                            </nz-empty>
                        </tr>
                    </ng-template>
                    <thead>
                        <tr>
                            <th rowspan="2" nzWidth="1%" nzLeft class="lenged" style="background: white;">
                                <div style="width: 330px;display: flex;">
                                    <ul>
                                        <li title="{{tooltipTwo[0]}}" class="comptibility">Compatible</li>
                                        <li title="{{tooltipTwo[2]}}" class="Incompatible">Incompatible
                                        </li>
                                        <li title="{{tooltipTwo[3]}}" class="notSupport">Not Supported
                                        </li>
                                    </ul>
                                    <ul>
                                        <!-- <li title="{{tooltipTwo[1]}}" class="comptibility_NT">Compatible:
                                            Not
                                            Tested
                                        </li> -->
                                        <li title="{{tooltipTwo[5]}}" class="EOTS">Past End of Technical
                                            Guidance</li>
                                        <li title="{{tooltipTwo[4]}}" class="ENGS">Past End of General
                                            Support</li>
                                    </ul>
                                </div>
                            </th>
                            <ng-container *ngFor="let productName of headerName">
                                <th nzLeft class="firstTh" style="text-align: left;top: 0px;z-index: 20;background: white;"
                                    [colspan]="headerNameArr[productName].length">
                                    {{productName}}
                                </th>
                            </ng-container>
                        </tr>
                        <tr>
                            <ng-container *ngFor="let release of columnData">
                                <th nz-tooltip [nzTooltipTitle]="titleTemplate" class="" nzLeft
                                    style="text-align: center;top: 40px;z-index: 20;font-size: 12px;background: white;">
                                    {{release.version}}
                                    <span [ngClass]="{'techGuided' : !release.techGuided }"></span>
                                    <span [ngClass]="{'genGuided': !release.genGuided }"></span>

                                </th>
                                <ng-template #titleTemplate>
                                    <span>{{release.version}}</span><br>
                                    <span>GA : {{formatTime(release.gaDate)}}</span><br>
                                    <span *ngIf="release.rnLink"><a href="{{release.rnLink}}" target="_blank">Release
                                            Notes</a><br></span>
                                    <span *ngIf="release.dLink"><a href="{{release.dLink}}" target="_blank">Download
                                            Page</a><br></span>
                                </ng-template>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let p of groupingTable.data,let i=index"
                        [ngClass]="displayStatus(p.status,p.footnotes,p)">
                            <td nzLeft nz-tooltip [nzTooltipTitle]="titleTemplate" style="color: rgba(0,0,0,.85);">
                                {{p.childProductName+" "+p.version }}
                                <span [ngClass]="{'techGuided' : !p.techGuided }"></span>
                                <span [ngClass]="{'genGuided': !p.genGuided }"></span>
                            </td>
                            <ng-template #titleTemplate>
                                <span>{{p.version}}</span><br>
                                <span>GA : {{formatTime(p.gaDate)}}</span><br>
                                <span *ngIf="p.rnLink"><a href="{{p.rnLink}}" target="_blank">Release
                                        Notes</a><br></span>
                                <span *ngIf="p.dLink"><a href="{{p.dLink}}" target="_blank">Download
                                        Page</a><br></span>
                            </ng-template>
                            <ng-container *ngFor="let release of p.releases,let i = index">
                                <td *ngIf="release.footnotes==undefined" [style.background]="'#d7d7d7'"></td>
                                <td *ngIf="release.footnotes!=undefined&&release.footnotes==''"
                                    (mouseover)="tdMouseOver(i)" (mouseout)="tdMouseOut()"
                                    [ngStyle]="{'background-color':tdSelected==i?'#fafafa':''}"
                                    [ngClass]="displayStatus(release.status,release.footnotes,release)">
                                </td>
                                <td *ngIf="release.footnotes!=undefined&&release.footnotes!=''" nz-tooltip
                                    [nzTooltipTitle]="tdInfo" (mouseover)="tdMouseOver(i)" (mouseout)="tdMouseOut()"
                                    [ngStyle]="{'background-color':tdSelected==i?'#fafafa':''}"
                                    [ngClass]="displayStatus(release.status,release.footnotes,release)">
                                </td>
                                <ng-template #tdInfo>
                                    <span [innerHTML]="release.footnotes"></span><br>
                                </ng-template>
                            </ng-container>
                        </tr>

                    </tbody>
                </nz-table>
                <div id="UpgradePrint" [style.display]="copyType">
                    <table>
                        <tr>
                            <th> </th>
                            <ng-container *ngFor="let productName of headerName">
                                <th *ngFor="let release of headerNameArr[productName]">
                                    {{release.productName}} {{release.version}} <span
                                    *ngIf="!release.techGuided||!release.genGuided">*</span>
                                </th>
                            </ng-container>
                        </tr>
                        <tbody>
                            <tr *ngFor="let p of groupingTable.data">
                                <td>{{p.versionName}}
                                    <span *ngIf="!p.techGuided||!p.genGuided">*</span>
                                </td>
                                <ng-container *ngFor="let release of p.releases,let i = index">
                                    <td *ngIf="release.footnotes==undefined" [style.background]="'#d7d7d7'"></td>
                                    <td *ngIf="release.footnotes!=undefined">
                                        <span>{{tableTdWords[release.status]}}</span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                        <tr></tr>
                    </table>
                    <span>* This is a legacy release. For more information, please refer to https://lifecycle.vmware.com.</span>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="isVisible" [config]="{ backdropBorderRadius: '3px'}" [template]="customLoadingTemplate">
</ngx-loading>
