<!-- <div class="alert alert-app-level" [ngClass]="IB.type" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-text" style="font-size: 14px;    text-align: center;">
                <clr-icon *ngIf="IB.class=='exclamation-circle'" style="width:1.6rem;height:1.6rem" class="alert-icon" shape="exclamation-circle"></clr-icon>
                <clr-icon *ngIf="IB.class=='exclamation-triangle'" style="width:1.6rem;height:1.6rem" class="alert-icon" shape="exclamation-triangle"></clr-icon>
                <clr-icon *ngIf="IB.class=='info-circle'" style="width:1.6rem;height:1.6rem" class="alert-icon" shape="info-circle"></clr-icon>
                {{IB.text}}
            </div>
        </div>
    </div>
</div> -->
        <!-- <button type="button" class="close" aria-label="Close">
            <clr-icon aria-hidden="true" shape="close"></clr-icon>
        </button> -->

<!-- <div class="guide-header" [ngClass]="{'hide': closeGuideHeader}">
    <div class="topHeader">
        <i>Welcome to an improved Product Interoperability Matrix experience.</i>
        <button class="btn btn-primary" id="walkMeId" [disabled]="nu" (click)='onGuide()'>WALK ME</button>
        <span class="feedback right">
            <a class="feedback-trigger" href="javascript:void(0);">
                <button class="btn btn-primary">FEEDBACK</button>
            </a>
        </span>
    </div>
    <button aria-label="Close" class="close" type="button" (click)='close()'>
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
</div> -->

<app-header></app-header>

<router-outlet></router-outlet>

<app-footer></app-footer>