import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DcrService } from "../../services/dcr.service";
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { productMock } from "../../mock/product-mock";
import { EVENT_TYPE, RESOURCE_TYPE } from '../../model/common.model';
import { CommonService } from "../../services/common.service";

@Component({
  selector: 'app-favorite-page',
  templateUrl: './favorite-page.component.html',
  styleUrls: ['./favorite-page.component.scss']
})
export class FavoritePageComponent implements OnInit {

  @Input() section: string;       
  showAddModal: boolean = false;
  showDeleteModal: boolean = false;
  alrLogin:boolean = false;
  compareList: any;
  withList: any[];
  params: any = { "name": "", "columns": [], "rows": [], "notes": "", "section": "" };
  tableCompare: any[];
  tableWith: any[];
  deleteId:any
  saveDisabled:boolean=true;
  @Output() saveSuccessBol = new EventEmitter<boolean>();
  @Output() deleteSuccessBol = new EventEmitter<boolean>();
  saveSuccess: boolean;
  constructor(
    private dcrService : DcrService,
    private _data: DataService,
    private _auth: AuthService,
    private common: CommonService
    ) { }

  ngOnInit(): void {
    if(this._data.getVersionData().length == 0) this.getCategory();
    if(this._data.getSolutionSelData().length == 0) this.getSolutions();
  }
  changeName(){
    if (this.params.name != "") {
      this.saveDisabled=false;
    }else{this.saveDisabled=true;}
  }
  logIn() {
    this._auth.logIn();
  }
  openAddModal() {
    if(JSON.parse(localStorage.getItem("external-user-sessionkey"))?.access_token){
      this.showAddModal = true;
      setTimeout(() => {
        if (this.section == RESOURCE_TYPE.UPGRADE) {
          this.tableCompare = this.getName([this.compareList]);
        } else {
          this.tableCompare = this.getName(this.compareList);
          this.tableWith = this.getName(this.withList);
        }
      }, 1000)
    }else{
      this.alrLogin = true;
    }
   
  }
  openDeleteModal(delId) {
    this.showDeleteModal = true;
    this.deleteId = delId;
  }
  saveFavorite() {
    let col, row;
    switch (this.section) {
      case RESOURCE_TYPE.INVENTORY:
      case RESOURCE_TYPE.MULTICLOUD:
      case RESOURCE_TYPE.THIRDPARTY:
        col = this.setColum(this.tableCompare);
        row = this.setRow(this.tableWith);
        this.params.columns = col;
        this.params.rows = row;
        this.params.section = this.section;
        this.dcrService.saveFavoriteList(this.params).subscribe(()=>{
          this._data.clearDataOfType(this.section);
          this.saveSuccess = true
          this.saveSuccessBol.emit(this.saveSuccess)
          this.showAddModal = false;
        })
        break;
      case RESOURCE_TYPE.UPGRADE:
        col = this.setColum(this.tableCompare);
        this.params.columns = col;
        this.params.section = this.section;
          this.dcrService.saveFavoriteList(this.params).subscribe(()=>{
            this._data.clearDataOfType(this.section);
            this.saveSuccess = true
            this.saveSuccessBol.emit(this.saveSuccess)
            this.showAddModal = false;
           
          })
        break;
    }

  }

  deleteFavorite() {
    this.dcrService.deleteFavoriteList(this.deleteId).subscribe(data => {
      this._data.clearDataOfType(this.section);
      this.showDeleteModal = false;
      this.deleteSuccessBol.emit(true);
    })
  }
  setColum(tableCompare) {
    let columData = []
    let version = [];
    tableCompare.forEach((r) => {
      version=[];
      if (r.releases[0].version == 'All Versions' || r.releases[0].version == "") {
        columData.push({ "product": r.productId, "releases": [] });
      } else {
        r.releases.forEach(re => {
          version.push(re.id);
        });
        columData.push({ "product": r.productId, "releases": version })
      }
    })
    return columData;
  }
  setRow(tableWith) {
    let rowData = []
    let version = [];
    tableWith.forEach((r) => {
      version=[];
      if (r.productId == 0 && r.productName == "All Categories" ) {
        rowData.push({ "product": [], "releases": [] });
      } else {
        if (r.releases[0].version == 'All Versions' || r.releases[0].version == 'All Solutions' || r.releases[0].version == "") {
          rowData.push({ "product": r.productId, "releases": [] });
        } else {
          r.releases.forEach(re => {
            version.push(re.id);
          });
          rowData.push({ "product": r.productId, "releases": version })
        }
      }

    })
    return rowData;
  }

  private getName(data: any[]) {
    let tableData: any[] = [{ "productId": "", "productName": "", "releases": [] }];
    let name;
    let vername;
    let versionname;
    let release: any[];
    let selectProduct;
    switch (this.section) {
      case RESOURCE_TYPE.INVENTORY:
      case RESOURCE_TYPE.MULTICLOUD:
        data.forEach((r, index) => {
          if (r.selectedPro == 0) {
            Object.assign(tableData[0], { "productId": productMock.AllSolution.id, "productName": productMock.AllSolution.name, "releases": [{ "id": "3703", "version": "All Versions" }] });
          }
          selectProduct = r.ProductData.filter(p => p.id == r.selectedPro)[0];
          name = selectProduct.name;
          release = [{ "id": "", "version": "" }];
          let allversion = this.common.getVersion(r.selectedPro, r.ProductData).sort((a, b) => { return this.common.sortVersion(a, b) });
          allversion = this.common.reDuplicates(allversion);
          if (r.selectedReleases.length == 0 || r.selectedReleases.length == allversion.length) {
            vername =  allversion.length == 1 ? allversion[0].version:'';
            (vername.indexOf("Nonversioned")!=-1 || vername == "0.0.0") ? Object.assign(release[0], { "id": "", "version": "" }) : Object.assign(release[0], { "id": "", "version": "All Versions" });
          }else{
          r.selectedReleases.forEach((v, i) => {
            let selectRelease = r.VersionData.filter(ver => ver.id == v)[0];
            versionname = selectRelease.version;
            if (i == 0) {
              Object.assign(release[0], { "id": v, "version": versionname });
            } else {
              release.push({ "id": v, "version": versionname });
            }
          });
        }
          if (index == 0) {
            Object.assign(tableData[0], { "productId": r.selectedPro, "productName": name, "releases": release });
          } else {
            tableData.push({ "productId": r.selectedPro, "productName": name, "releases": release });
          }
        })
        break;
      case RESOURCE_TYPE.THIRDPARTY:
        if(data == this.withList){
          data.forEach((r, index) => {
            if (r.selectCatagory == productMock.AllCategories) {
              release = [{ "id": "", "version": "All Solutions" }]
              name = "All Categories";
              Object.assign(tableData[0], { "productId": productMock.AllCategories.id, "productName": name, "releases": release });
            } else {
              name = r.selectCatagory.name;
              release = [{ "id": "", "version": "" }];
              let RowAllVersion = this._data.getVersionData().filter((re) => {return re.id == r.selectCatagory.id})[0];
              if (r.selectProduct.length == 0 || r.selectProduct.length == RowAllVersion.products.length) {
                Object.assign(release[0], { "id": "", "version": "All Solutions" });
              }else{
                r.selectProduct.forEach((s, index1) => {
                  if (index1 == 0) {
                    Object.assign(release[0], { "id": s.id, "version": s.name +" "+ s.version });
                  } else {
                    release.push({ "id": s.id, "version": s.name +" "+ s.version });
                  }
                })
              }
              if (index == 0) {
                Object.assign(tableData[0], { "productId": r.selectCatagory.id, "productName": name, "releases": release });
              } else {
                tableData.push({ "productId": r.selectCatagory.id, "productName": name, "releases": release });
              }
            }
           
          })
        }else{
          data.forEach((r,index) => {
            name = r.selectedPro.name;
            release = [{ "id": "", "version": "" }]
            let colAllVersion = this.common.getVersion(r.selectedPro.id, r.ProductData).sort((a, b) => { return this.common.sortVersion(a, b) });
            colAllVersion = this.common.reDuplicates(colAllVersion);
            if (r.selectedReleases.length == 0 || r.selectedReleases.length == colAllVersion.length) {
              vername =  colAllVersion.length == 1 ? colAllVersion[0].version:'';
              (vername == "Nonversioned") ? Object.assign(release[0], { "id": "", "version": "" }) : Object.assign(release[0], { "id": "", "version": "All Versions" });
            }else{
              r.selectedReleases.forEach((v, i) => {
                if (i == 0) {
                  Object.assign(release[0], { "id": v.id, "version": v.version });
                } else {
                  release.push({ "id": v.id, "version": v.version });
                }
  
              });
            } 
            if (index == 0) {
              Object.assign(tableData[0], { "productId": r.selectedPro.id, "productName": name, "releases": release });
            } else {
              tableData.push({ "productId": r.selectedPro.id, "productName": name, "releases": release });
            }
          })
        }
        
        break;
      case RESOURCE_TYPE.UPGRADE:
        data.forEach((r, index) => {
          selectProduct = r.ProductData.filter(p => p.id == r.selectedPro)[0];
          name = selectProduct.name;
          release = [{ "id": "", "version": "All Versions" }];
          Object.assign(tableData[0], { "productId": r.selectedPro, "productName": name, "releases": release });
        })
        break;
    }
    return tableData;
  }

  getCategory(){
    this.dcrService.getCategory().subscribe(data => {
      this._data.setVersionData(data)
    })
  }
  getSolutions(){
    this.dcrService.getProduct("database").subscribe(data => {
      this._data.setSolutionSelData(data);
    })
  }
}
