import { throwError as observableThrowError, from as observableFrom, of as observableOf, combineLatest as observableCombineLatest, forkJoin as observableForkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap, delay, retry } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EnvironmentsService } from "./environment.service";
import { RESOURCE_TYPE } from '../model/common.model';
import { DataService } from "./data.service";
import { AuthService } from './auth.service';
import 'rxjs/Rx'

// util

import * as _ from 'lodash';

@Injectable()
export class DcrService {

  baseUrl: string;
  favoriteHeader: any;
  favBaseUrl: string;
  tokenUrl:string;

  public InterProList: any;
  public InterMatrix: any;

  public DatabaseProList: any;
  public DatabaseList: any;

  public UpgradeProList: any;
  public UpgradeMatrixList: any;
  constructor(

    private http: HttpClient,
    private _data: DataService,
    private environmentsService: EnvironmentsService,
    private _auth: AuthService,
    ) {
    this.baseUrl = window.location.hostname.indexOf('localhost') > -1 ? '/apidata' : `${this.environmentsService.config.simServiceUrl}`;
    // this.baseUrl = `${this.environmentsService.config.simServiceUrl}`;
    this.favBaseUrl = window.location.hostname.indexOf('localhost') > -1 ? '/favor' : `${this.environmentsService.config.simServiceUrl}`;
  }

  // header = { "headers": { "X-Auth-Key": "N31mVcQkL?Q]GSe[Tve0Wl8b[i2_vU:ClohDvU7Ex;GCu4=hxa=q>3B<aMEZRwmT" } };

  header = { "headers": { "X-Auth-Key": "N31mVcQkL?Q]GSe[Tve0Wl8b[i2_vU:ClohDvU7Ex;GCu4=hxa=q>3B<aMEZRwmT" }, "set-cookie": "samesite=none" };
  public getProduct(type) {
    let selData = [];
    switch (type) {
      case RESOURCE_TYPE.INVENTORY:
        selData = this._data.getProductSelData();
        break;
      case RESOURCE_TYPE.THIRDPARTY:
        selData = this._data.getSolutionSelData();
        break;
      case RESOURCE_TYPE.UPGRADE:
        selData = this._data.getUpgradeData();
        break;
      case RESOURCE_TYPE.MULTICLOUD:
        selData = this._data.getMultiCloudData();
        break;
      default:
        break;
    }
    if (selData.length) return of(selData)
    return this.http.get(this.baseUrl + `/products?interoptype=${type}`, this.header);
  }

  public getDatabase() {
    return this.http.get(this.baseUrl + `/databases`, this.header);
  }

  public getInteroperabilityMatrix(params) {
    return this.http.post(this.baseUrl + `/products/interoperabilityMatrix`, params, this.header);
  }

  public getDatabaseMatrix(params) {
    return this.http.post(this.baseUrl + `/databases/databaseMatrix`, params, this.header);
  }

  public getUpgrades(productId,isHidePatch,isHideLegacyReleases) {
    return this.http.post(this.baseUrl + `/upgrades`, 
    { 
      "product_id": productId,
      "isHidePatch":isHidePatch,
      "isHideGenSupported":isHideLegacyReleases,
      "isHideTechSupported":isHideLegacyReleases 
    }, 
    this.header)
  }

  public getCollections() {
    let data = this._data.getCollectionData();
    if (data.length) return of(data)
    return this.http.get(this.baseUrl + '/collections', this.header)
  }

  public getCategory() {
    let data = this._data.getVersionData();
    if (data.length) return of(data)
    return this.http.get(this.baseUrl + '/version', this.header)

  }

  public getVersionMatrix<T>(param): Observable<T> {
    return this.http.post<T>(this.baseUrl + '/databases/versionMatrix', param, this.header);
  }

  public getFavoriteList(type): any {
    let selData = [];
    switch (type) {
      case RESOURCE_TYPE.INVENTORY:
        selData = this._data.getProductFavData();
        break;
      case RESOURCE_TYPE.MULTICLOUD:
        selData = this._data.getMultiCloudFavData();
        break;
      case RESOURCE_TYPE.THIRDPARTY:
        selData = this._data.getSolutionFavData();
        break;
      case RESOURCE_TYPE.UPGRADE:
        selData = this._data.getUpgradeFavData();
        break;
      default:
        break;
    }
    if (selData.length) return of(selData)
    return this.http.get(this.favBaseUrl + `/matrixsaved?type=${type}`);
  }

  public saveFavoriteList(param) {
    return this.http.post(this.favBaseUrl + '/matrixsaved', param);
  }

  public deleteFavoriteList(id) {
    return this.http.post(this.favBaseUrl + `/deletematrixsaved?id=${id}`, { "id": id }, this.header);
  }
  
  public getbundle(){
    let header = {"headers": { "X-Auth-Key": "N31mVcQkL?Q]GSe[Tve0Wl8b[i2_vU:ClohDvU7Ex;GCu4=hxa=q>3B<aMEZRwmT",'Authorization': this._auth.getAccessToken()}};
    return this.http.get(this.baseUrl+`/bundle`,header)
  }
}