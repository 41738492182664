<nz-tabset [nzSelectedIndex]="Selected" (nzSelectedIndexChange)="change($event)">
  <nz-tab [nzTitle]="navList[0].name">
    <app-interoperable *ngIf="Selected===0"></app-interoperable>
  </nz-tab>
  <nz-tab [nzTitle]="navList[1].name">
    <!-- <app-database-tab></app-database-tab> -->
    <app-multi-cloud *ngIf="Selected===1"></app-multi-cloud>
  </nz-tab>
  <nz-tab [nzTitle]="navList[2].name">
    <app-solution *ngIf="Selected===2"></app-solution>
  </nz-tab>
  <nz-tab [nzTitle]="navList[3].name">
    <app-upgrade *ngIf="Selected===3"></app-upgrade>
  </nz-tab>
</nz-tabset>