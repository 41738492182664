<div #favPage>
    <clr-modal *ngIf="showAddModal" [(clrModalOpen)]="showAddModal" [clrModalSize]="'lg'">
        <div class="modal-title"></div>
        <div class="modal-body">
            <p class="top_tip">&nbsp;&nbsp;&nbsp;Please give a name for this
                project.</p>
            <div class="top">
                <form clrForm>
                    <clr-control-container>
                        <label><span>*</span>Project Name</label>
                        <input clrControl placeholder="Basic text" name="name" maxlength="200" [(ngModel)]="params.name" (ngModelChange)="changeName()" required />
                        <div *ngIf="saveDisabled" class="re_tip" >This is a required field.</div>
                    </clr-control-container>
                    <clr-control-container>
                        <label>Notes</label> 
                        <textarea  name="notes" maxlength="200" [(ngModel)]="params.notes"></textarea>
                    </clr-control-container>
                </form>
            </div>
            <div class="down_table">
                <p>Your project includes below solutions:</p>
                <app-data-table #datatable [dataList]="tableCompare" [tableHead]="'Compare'"></app-data-table>
                <app-data-table *ngIf="section!='upgrade'" #datatable [dataList]="tableWith" [tableHead]="'With'" [type]="section"></app-data-table>
            </div>
        </div>
        <div class="modal-footer">
                <button class="btn btn-primary" (click)="showAddModal=!showAddModal">Cancel</button>
                <button class="btn btn-primary" style="background: #1d428a;color: white;" (click)="saveFavorite()" [disabled]="saveDisabled">Save</button>
        </div>

    </clr-modal>
   
    <div class="delete">
        <clr-modal *ngIf="showDeleteModal" [(clrModalOpen)]="showDeleteModal" [clrModalSize]="'sm'" >
            <div class="modal-body">
                <p  class="del_tip">Do you want to delete this project?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="showDeleteModal=!showDeleteModal">Cancel</button>
                <button class="btn btn-primary" (click)="deleteFavorite()">Delete</button>
            </div>
        </clr-modal>
    </div>
    <clr-modal *ngIf="alrLogin" [(clrModalOpen)]="alrLogin" [clrModalSize]="'sm'" >
        <div class="modal-body">
            <p class="del_tip">Please login in first.</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="logIn()">Login</button>
        </div>
    </clr-modal>
</div>