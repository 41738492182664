export class productMock {
    static AllSolution = {
        "id": 0,
        "name": "All Solutions",
        "childId": null,
        "parentId": 133,
        "releases": [
            {
                "id": 3703,
                "version": "9.9.0",
                "guided": true,
                "gaDate": 1568649600000,
                "rnLink": null,
                "dLink": null,
                "components": [],
                "collectionComponents": [],
                "dummy": false,
                "techGuided": true,
                "genGuided": true,
                "rowReleases": null,
                "matrix": null
            }
        ]
    }
    static AllCategories = { 
        "id": 0, 
        "products": [], 
        "name": "All Categories", 
        "versions": []
    }
    static AllDatabases = {
        "name": "All databases",
        "release": [
            {
                "id": 3703,
                "value":"All databases",
            }
        ]
    }
    static productData =

        [

            {
                "id": 512,
                "name": "VMware Dynamic Environment Manager",
                "childId": null,
                "parentId": 133,
                "releases": [
                    {
                        "id": 3703,
                        "version": "9.9.0",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3769,
                        "version": "9.10.0",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3924,
                        "version": "9.11.0",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 1,
                "name": "VMware vSphere Hypervisor (ESXi)",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 14,
                        "version": null,
                        "guided": true,
                        "gaDate": 1259769600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": true,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 15,
                        "version": null,
                        "guided": false,
                        "gaDate": 1242835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 16,
                        "version": null,
                        "guided": true,
                        "gaDate": 1258560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 17,
                        "version": null,
                        "guided": true,
                        "gaDate": 1276099200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 18,
                        "version": null,
                        "guided": false,
                        "gaDate": 1346256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 96,
                        "version": null,
                        "guided": true,
                        "gaDate": 1304524800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 141,
                        "version": null,
                        "guided": false,
                        "gaDate": 1314201600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 181,
                        "version": null,
                        "guided": true,
                        "gaDate": 1267977600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 243,
                        "version": null,
                        "guided": true,
                        "gaDate": 1321459200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 251,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 253,
                        "version": null,
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 391,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 430,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 441,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 500,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 507,
                        "version": null,
                        "guided": false,
                        "gaDate": 1379520000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 559,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 577,
                        "version": null,
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 620,
                        "version": null,
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 694,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 795,
                        "version": "ESXi 5.5U3",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 796,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 994,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1032,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1033,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2131,
                        "version": "vSphere 6.5.0",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2135,
                        "version": "vSphere 6.0 U3",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2331,
                        "version": "vSphere 6.5 U1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2731,
                        "version": "vSphere 6.5 U2",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/vsphere-vcenter-server-65u2-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2735,
                        "version": "vSphere 6.7.0",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2861,
                        "version": "vSphere 6.7 U1",
                        "guided": true,
                        "gaDate": 1539619200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2980,
                        "version": null,
                        "guided": false,
                        "gaDate": 1346256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3221,
                        "version": "vSphere 6.7 U2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.7/rn/vsphere-esxi-67u2-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3363,
                        "version": "vSphere 6.5 U3",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3456,
                        "version": "vSphere 6.7 U3",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.7/rn/vsphere-esxi-67u3-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3495,
                        "version": "vSphere 7.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 513,
                "name": "VMware Workspace ONE® Access",
                "childId": null,
                "parentId": 140,
                "releases": [
                    {
                        "id": 3708,
                        "version": "20.1.0",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": "https://docs-staging.vmware.com/en/VMware-Workspace-ONE-Access/20.01/rn/VMware-Workspace-ONE-Access-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workspace_one_access_vidm/20_01",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 2,
                "name": "VMware vCenter Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 35,
                        "version": "VC 4.0",
                        "guided": true,
                        "gaDate": 1242835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 36,
                        "version": "vCenter 4.0 U1",
                        "guided": true,
                        "gaDate": 1258560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 37,
                        "version": "VC 4.0 U2",
                        "guided": true,
                        "gaDate": 1276099200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 38,
                        "version": "VC 4.1 U3",
                        "guided": true,
                        "gaDate": 1346256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 140,
                        "version": "VC 5.0",
                        "guided": true,
                        "gaDate": 1314115200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 172,
                        "version": "VC 4.1 Update 1",
                        "guided": true,
                        "gaDate": 1297267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 173,
                        "version": "VC 4.0 U3",
                        "guided": true,
                        "gaDate": 1304524800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 194,
                        "version": "VC 4.1 Update 2",
                        "guided": true,
                        "gaDate": 1319644800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 242,
                        "version": "VC 4.0 Update 4",
                        "guided": true,
                        "gaDate": 1321459200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 252,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 254,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 392,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 431,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 442,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 444,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 448,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 462,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 508,
                        "version": null,
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 578,
                        "version": null,
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 611,
                        "version": null,
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 695,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 792,
                        "version": "VC 5.5U3",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 998,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1034,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1035,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2132,
                        "version": "vSphere 6.5.0",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2136,
                        "version": "vSphere 6.0 U3",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2332,
                        "version": "vSphere 6.5 U1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2732,
                        "version": "vSphere 6.5 U2",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/vsphere-vcenter-server-65u2-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2736,
                        "version": "vSphere 6.7.0",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2862,
                        "version": "vSphere 6.7 U1",
                        "guided": true,
                        "gaDate": 1539619200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2981,
                        "version": null,
                        "guided": false,
                        "gaDate": 1346256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3222,
                        "version": "vSphere 6.7 U2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.7/rn/vsphere-esxi-67u2-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3364,
                        "version": "vSphere 6.5 U3",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3457,
                        "version": "vSphere 6.7 U3",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.7/rn/vsphere-esxi-67u3-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3496,
                        "version": "vSphere 7.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 514,
                "name": "VMware Workspace ONE® Access Connector",
                "childId": null,
                "parentId": 301,
                "releases": [
                    {
                        "id": 3707,
                        "version": "20.1.0",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 4,
                "name": "VMware vCenter Lab Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 78,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1247414400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 79,
                        "version": "4.0.1",
                        "guided": true,
                        "gaDate": 1260374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 91,
                        "version": "4.0.2",
                        "guided": true,
                        "gaDate": 1279123200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 183,
                        "version": "4.0.3",
                        "guided": true,
                        "gaDate": 1300377600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 195,
                        "version": "4.0.4",
                        "guided": true,
                        "gaDate": 1312992000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 5,
                "name": "VMware Lifecycle Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 44,
                        "version": "1.1",
                        "guided": false,
                        "gaDate": 1264694400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 90,
                        "version": "1.0.2",
                        "guided": false,
                        "gaDate": 1252512000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 143,
                        "version": "1.2",
                        "guided": false,
                        "gaDate": 1281542400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 517,
                "name": "VMware Smart Assurance ",
                "childId": 640,
                "parentId": null,
                "releases": [
                    {
                        "id": 3761,
                        "version": "10.0",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 6,
                "name": "VMware vCenter Server Heartbeat",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 98,
                        "version": "6.3",
                        "guided": true,
                        "gaDate": 1278950400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 108,
                        "version": "5.5 U2",
                        "guided": false,
                        "gaDate": 1258560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 170,
                        "version": "6.3 U1",
                        "guided": true,
                        "gaDate": 1297267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 171,
                        "version": "6.4",
                        "guided": true,
                        "gaDate": 1314115200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 289,
                        "version": "6.4 U1",
                        "guided": true,
                        "gaDate": 1346256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 368,
                        "version": "6.5",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "https://vmware.com/support/heartbeat/doc/vcenter-server-heartbeat-65-release-notes.html",
                        "dLink": "https://vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_server_heartbeat/6_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 468,
                        "version": "6.5 U1",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "https://www.vmware.com/support/heartbeat/doc/vcenter-server-heartbeat-65-u1-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=infrastructure_operations_management/vmware_vcenter_server_heartbeat/6_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 517,
                        "version": "6.6.0",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 903,
                        "version": "6.6 U1",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1918,
                        "version": "6.6 U2",
                        "guided": false,
                        "gaDate": 1470240000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 8,
                "name": "vCenter Backup",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 49,
                        "version": "1.5 U1",
                        "guided": true,
                        "gaDate": 1242835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 50,
                        "version": "1.5 U2",
                        "guided": true,
                        "gaDate": 1268236800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 9,
                "name": "VMware vCenter Converter Plug-In",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 57,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 58,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 59,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 60,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 177,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 521,
                "name": "vRealize Operations Compliance Pack for DISA",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3794,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1570032000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3796,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": "https://marketplace.vmware.com/resources/a4e356cd32a7475f8c7a8fce6c532c3f/doc/4a95652e493847ac9428dcf191a5693c/vRealize%20Operations%20Compliance%20Pack%20for%20DISA%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-compliance-pack-for-disa",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 10,
                "name": "VMware ThinApp",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 114,
                        "version": "4.6",
                        "guided": false,
                        "gaDate": 1282060800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 575,
                        "version": "5.0.0",
                        "guided": true,
                        "gaDate": 1383148800000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp50.html",
                        "dLink": "https://www.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 597,
                        "version": "4.7.3",
                        "guided": true,
                        "gaDate": 1352736000000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp473.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_downloads/vmware_thinapp/4_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 700,
                        "version": "5.0.1",
                        "guided": true,
                        "gaDate": 1373472000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 701,
                        "version": "4.6.2",
                        "guided": false,
                        "gaDate": 1314201600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 739,
                        "version": "5.1.0",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp50.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 967,
                        "version": "5.2.0",
                        "guided": true,
                        "gaDate": 1447862400000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp52.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1031,
                        "version": "5.2.1",
                        "guided": true,
                        "gaDate": 1458144000000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp521.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1394,
                        "version": "5.0.1",
                        "guided": true,
                        "gaDate": 1399910400000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp501.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1630,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": 1432137600000,
                        "rnLink": "https://www.vmware.com/support/thinapp4/doc/releasenotes_thinapp511.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2185,
                        "version": "5.2.2",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2628,
                        "version": "5.2.3",
                        "guided": true,
                        "gaDate": 1516636800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/ThinApp/523/releasenotes_thinapp523.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_thinapp/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2956,
                        "version": "5.2.4",
                        "guided": true,
                        "gaDate": 1537200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3057,
                        "version": "5.2.5",
                        "guided": true,
                        "gaDate": 1550678400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3723,
                        "version": "5.2.6",
                        "guided": true,
                        "gaDate": 1568217600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4075,
                        "version": "5.2.7",
                        "guided": true,
                        "gaDate": 1585584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 11,
                "name": "VMware Capacity Planner",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 82,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1255881600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 83,
                        "version": "1.0.1",
                        "guided": false,
                        "gaDate": 1260806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 115,
                        "version": "1.0.4",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 116,
                        "version": "1.5",
                        "guided": false,
                        "gaDate": 1291132800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 122,
                        "version": "1.0.2",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 123,
                        "version": "1.0.3",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 196,
                        "version": "1.5.2",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 12,
                "name": "VMware vSphere Update Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 70,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 179,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1314201600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 272,
                        "version": "5.0 U1",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 276,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1347206400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 332,
                        "version": "5.0.0 U2",
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 383,
                        "version": "5.1 U1",
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 456,
                        "version": "5.0.0 U3",
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 457,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 504,
                        "version": "5.1 U2",
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 513,
                        "version": "6.0.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 580,
                        "version": "5.5 U1",
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 622,
                        "version": "5.5 U2",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "http://wwwcontentdev.vmware.com:10001/support/vsphere5/doc/vsphere-update-manager-55u2-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 724,
                        "version": "5.1 U3",
                        "guided": false,
                        "gaDate": 1417622400000,
                        "rnLink": "http://wwwcontentdev.vmware.com:10066/support/vsphere5/doc/vsphere-update-manager-51u3-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 803,
                        "version": "5.5 U3",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 885,
                        "version": "6.0 U1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsphere/60/vsphere-update-manager-60u1-release-notes.html?__utma=207178772.1103967364.1441031843.1441907520.1441907732.4&__utmb=207178772.1.10.1441907732&__utmc=207178772&__utmx=-&__utmz=207178772.1441907732.4.2.utmcsr=google|utmccn=%28organic%29|utmcmd=organic|utmctr=%28not%20provided%29&__utmv=-&__utmk=74160145",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=datacenter_cloud_infrastructure/vmware_vsphere/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 995,
                        "version": "6.0 U2",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2340,
                        "version": "6.0 U3",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 13,
                "name": "VMware vCenter Orchestrator",
                "childId": 117,
                "parentId": null,
                "releases": [
                    {
                        "id": 71,
                        "version": "4.0.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 74,
                        "version": "4.1.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 152,
                        "version": "4.2.0",
                        "guided": true,
                        "gaDate": 1318953600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 313,
                        "version": "4.1.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 314,
                        "version": "4.1.2",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 315,
                        "version": "4.2.1",
                        "guided": true,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 325,
                        "version": "5.1.0",
                        "guided": true,
                        "gaDate": 1347206400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 375,
                        "version": "4.1.3",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 406,
                        "version": "4.2.2",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 424,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 482,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/orchestrator/doc/vcenter-orchestrator-55-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 523,
                        "version": "4.2.3",
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 591,
                        "version": "5.1.2",
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 645,
                        "version": "5.5.1",
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 689,
                        "version": "5.5.2",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/orchestrator/doc/vcenter-orchestrator-552-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VCL-VCOVA_552",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 690,
                        "version": "5.5.3",
                        "guided": true,
                        "gaDate": 1442332800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 742,
                        "version": "5.1.3",
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/orchestrator/doc/vcenter-orchestrator-513-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VSP51-VCL-VCOVA-513&productId=285&rPId=7217",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 525,
                "name": "VMware Workspace ONE Assist for macOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4621,
                        "version": "20.3.1",
                        "guided": true,
                        "gaDate": 1589299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3810,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1573574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3982,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585497600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 529,
                "name": "VMware vRealize Operations Cloud",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3849,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Operations-Cloud/services/rn/vRealize-Operations-Manager-Cloud.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 531,
                "name": "VMware AppDefense ",
                "childId": null,
                "parentId": 376,
                "releases": [
                    {
                        "id": 4156,
                        "version": "2.3.2",
                        "guided": true,
                        "gaDate": 1592409600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3023,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1540915200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3301,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1550505600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3302,
                        "version": "2.2",
                        "guided": true,
                        "gaDate": 1558368000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3423,
                        "version": "2.1.1",
                        "guided": true,
                        "gaDate": 1552320000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3580,
                        "version": "2.3",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3621,
                        "version": "2.2.1",
                        "guided": true,
                        "gaDate": 1563811200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4035,
                        "version": "2.3.1",
                        "guided": true,
                        "gaDate": 1581350400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 532,
                "name": "VMware Secure Email Gateway",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3902,
                        "version": "2.14",
                        "guided": true,
                        "gaDate": 1574784000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4082,
                        "version": "2.15.1",
                        "guided": true,
                        "gaDate": 1587398400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 534,
                "name": "vRealize Log Insight Cloud",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3927,
                        "version": "1.10",
                        "guided": true,
                        "gaDate": 1580745600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 23,
                "name": "VMware Horizon (with View)",
                "childId": 260,
                "parentId": null,
                "releases": [
                    {
                        "id": 112,
                        "version": "4.5",
                        "guided": false,
                        "gaDate": 1283961600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 113,
                        "version": "4.0.2",
                        "guided": false,
                        "gaDate": 1284480000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 150,
                        "version": "5.0",
                        "guided": false,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 185,
                        "version": "4.6",
                        "guided": false,
                        "gaDate": 1298476800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 297,
                        "version": "5.0.1",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 298,
                        "version": "4.6.1",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 328,
                        "version": "5.2.0",
                        "guided": false,
                        "gaDate": 1362326400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 329,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1337097600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 377,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1345046400000,
                        "rnLink": "https://www.vmware.com/support/view51/doc/view-511-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_view/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 417,
                        "version": "4.6.2",
                        "guided": false,
                        "gaDate": 1355155200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_downloads/vmware_view/4_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 418,
                        "version": "5.1.2",
                        "guided": false,
                        "gaDate": 1355328000000,
                        "rnLink": "https://www.vmware.com/support/view51/doc/view-512-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 454,
                        "version": "6.0.0",
                        "guided": true,
                        "gaDate": 1403107200000,
                        "rnLink": "https://www.vmware.com/support/horizon-view/doc/horizon-view-60-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_with_view/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 472,
                        "version": "4.6.3",
                        "guided": false,
                        "gaDate": 1362585600000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_downloads/vmware_view/4_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 473,
                        "version": "5.1.3",
                        "guided": false,
                        "gaDate": 1363190400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_view/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 474,
                        "version": "5.3",
                        "guided": false,
                        "gaDate": 1384963200000,
                        "rnLink": "https://www.vmware.com/support/view53/doc/horizon-view-53-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 635,
                        "version": "5.3.1",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/view53/doc/horizon-view-531-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 710,
                        "version": "5.3.2",
                        "guided": false,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/view53/doc/horizon-view-532-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_with_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 726,
                        "version": "5.3.3",
                        "guided": false,
                        "gaDate": 1416844800000,
                        "rnLink": "https://www.vmware.com/support/horizon-view/doc/horizon-view-533-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_horizon_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 729,
                        "version": "6.1.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/horizon-view/doc/horizon-61-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=405&downloadGroup=HORIZON-DAAS-610-BIN",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 730,
                        "version": "6.0.1",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/horizon-view/doc/horizon-view-601-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIEW-601-GA&productId=420&rPId=6503",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 823,
                        "version": "6.0.2",
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 866,
                        "version": "5.3.4",
                        "guided": true,
                        "gaDate": 1424880000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 868,
                        "version": "6.2.0",
                        "guided": true,
                        "gaDate": 1441209600000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-6-view/horizon-62-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VIEW-620-STD_ADV&productId=529&rPId=8683",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 872,
                        "version": "6.1.1",
                        "guided": true,
                        "gaDate": 1433347200000,
                        "rnLink": "https://www.vmware.com/support/horizon-view/doc/horizon-611-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VIEW-611-STD_ADV&productId=501&rPId=8160",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 902,
                        "version": "5.3.5",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-view/horizon-view-535-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VIEW-530-PREMIER&productId=323&rPId=5438",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 928,
                        "version": "6.2.1",
                        "guided": true,
                        "gaDate": 1449504000000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-6-view/horizon-621-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_horizon_6/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 942,
                        "version": "5.3.6",
                        "guided": true,
                        "gaDate": 1456761600000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-view/horizon-view-536-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_with_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 974,
                        "version": "6.2.2",
                        "guided": true,
                        "gaDate": 1454515200000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-6-view/horizon-622-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_horizon_6/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1040,
                        "version": "6.2.3",
                        "guided": true,
                        "gaDate": 1469030400000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-6-view/horizon-623-view-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIEW-623-OSS&productId=529",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2034,
                        "version": "5.3.7",
                        "guided": true,
                        "gaDate": 1475683200000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-view/horizon-view-537-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_with_view/5_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2158,
                        "version": "6.2.4",
                        "guided": true,
                        "gaDate": 1492444800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-6-view/624/horizon-view-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2487,
                        "version": "6.2.5",
                        "guided": true,
                        "gaDate": 1508169600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2711,
                        "version": "6.2.6",
                        "guided": true,
                        "gaDate": 1522252800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-6/6.2.6/rn/horizon-626-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2955,
                        "version": "6.2.7",
                        "guided": true,
                        "gaDate": 1533571200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-6/6.2.7/rn/horizon-627-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3427,
                        "version": "6.2.8",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-6/index.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3492,
                        "version": "6.2.9",
                        "guided": true,
                        "gaDate": 1560182400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 24,
                "name": "VMware vSphere Client",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 117,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 118,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 119,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 120,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 121,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 151,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 188,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 197,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 223,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 244,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 303,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 366,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 379,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 393,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 450,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 505,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 510,
                        "version": null,
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 606,
                        "version": null,
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 636,
                        "version": null,
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 651,
                        "version": null,
                        "guided": true,
                        "gaDate": 1408896000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 793,
                        "version": null,
                        "guided": true,
                        "gaDate": 1433952000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 798,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 839,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 913,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 993,
                        "version": null,
                        "guided": true,
                        "gaDate": 1456761600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2065,
                        "version": "vSphere 6.0 U3",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 25,
                "name": "VMware vCenter Configuration Manager",
                "childId": 121,
                "parentId": null,
                "releases": [
                    {
                        "id": 125,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1283961600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 226,
                        "version": "5.4",
                        "guided": false,
                        "gaDate": 1292169600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 227,
                        "version": "5.4.1",
                        "guided": false,
                        "gaDate": 1320076800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 233,
                        "version": "5.5",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 277,
                        "version": "5.6.0",
                        "guided": false,
                        "gaDate": 1354118400000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vcenter-configuration-manager-56-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 322,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1346256000000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vcenter-configuration-manager-551-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCM_551&productId=169&rPId=2984",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 498,
                        "version": "5.7",
                        "guided": false,
                        "gaDate": 1374681600000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vcenter-configuration-manager-57-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCM_57&productId=169&rPId=3931",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 593,
                        "version": "5.7.1",
                        "guided": false,
                        "gaDate": 1386604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 632,
                        "version": "5.7.2",
                        "guided": false,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vcenter-configuration-manager-572-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_downloads/vmware_vcenter_configuration_manager/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 28,
                "name": "VMware vCenter Converter Standalone",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 136,
                        "version": "4.0",
                        "guided": false,
                        "gaDate": 1234368000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 137,
                        "version": "4.0.1",
                        "guided": false,
                        "gaDate": 1242835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 138,
                        "version": "4.3",
                        "guided": false,
                        "gaDate": 1283184000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 180,
                        "version": "5.0",
                        "guided": false,
                        "gaDate": 1314806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 414,
                        "version": "5.1",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "http://wwwcontentdev.vmware.com:5070/support/converter/doc/conv_sa_51_rel_notes.html",
                        "dLink": "http://downloads.vmware.com/d/info/infrastructure_operations_management/vmware_vcenter_converter_standalone/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 520,
                        "version": "5.5",
                        "guided": false,
                        "gaDate": 1382371200000,
                        "rnLink": "http://www.vmware.com/support/converter/doc/conv_sa_55_rel_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_converter_standalone/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 631,
                        "version": "5.5.1",
                        "guided": true,
                        "gaDate": 1397664000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 768,
                        "version": "5.5.3",
                        "guided": true,
                        "gaDate": 1412784000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 769,
                        "version": "5.5.2",
                        "guided": true,
                        "gaDate": 1403539200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 770,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": 1404144000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 771,
                        "version": "5.1.2",
                        "guided": true,
                        "gaDate": 1412784000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 865,
                        "version": "6.0",
                        "guided": false,
                        "gaDate": 1431532800000,
                        "rnLink": "http://www.vmware.com/support/converter/doc/conv_sa_60_rel_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_converter_standalone/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 976,
                        "version": "6.1",
                        "guided": false,
                        "gaDate": 1453132800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/converter/61/conv_sa_61_rel_notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/evalcenter?p=converter",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 999,
                        "version": "6.1.1",
                        "guided": false,
                        "gaDate": 1455552000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2600,
                        "version": "6.2.0",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 29,
                "name": "VMware vCloud Director",
                "childId": 224,
                "parentId": null,
                "releases": [
                    {
                        "id": 139,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1283097600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 154,
                        "version": "1.5",
                        "guided": false,
                        "gaDate": 1314806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 175,
                        "version": "1.0.1",
                        "guided": false,
                        "gaDate": 1297267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 259,
                        "version": "1.5.1",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 311,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_51.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VCL-VCD510-EN&productId=289&rPId=3008",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 390,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1351094400000,
                        "rnLink": "http://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_511.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VCL-VCD511-EN&productId=289&rPId=3060",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 412,
                        "version": "1.5.2",
                        "guided": false,
                        "gaDate": 1355068800000,
                        "rnLink": "http://wwwcontentdev.vmware.com:9740/support/vcd/doc/rel_notes_vcloud_director_152.html",
                        "dLink": "http://downloads.vmware.com/d/info/datacenter_downloads/vmware_vcloud_director/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 413,
                        "version": "5.1.2",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_512.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_director/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 429,
                        "version": "5.5.0",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_55.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VCL-VCD550&productId=356&rPId=4244",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 487,
                        "version": "5.1.3",
                        "guided": false,
                        "gaDate": 1389801600000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_513.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_director/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 576,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_551.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_director/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 649,
                        "version": "5.5.2",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_552.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCL-VCD552&productId=408&rPId=5635",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 812,
                        "version": "5.5.3",
                        "guided": false,
                        "gaDate": 1427040000000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_553.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_director/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 877,
                        "version": "5.5.4",
                        "guided": false,
                        "gaDate": 1436371200000,
                        "rnLink": "http://concrete2.vmware.com/support/vcd/doc/rel_notes_vcloud_director_554.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VCD_554",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 944,
                        "version": "5.5.5",
                        "guided": false,
                        "gaDate": 1445443200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcd/555/rel_notes_vcloud_director_555.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_director/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1052,
                        "version": "5.5.6",
                        "guided": false,
                        "gaDate": 1460563200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcd/556/rel_notes_vcloud_director_556.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VCD_556&productId=356&rPId=10875",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 30,
                "name": "VMware vCloud Request Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 142,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1291132800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 31,
                "name": "VMware vCenter Chargeback Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 144,
                        "version": "1.5",
                        "guided": false,
                        "gaDate": 1283097600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 145,
                        "version": "1.0.1",
                        "guided": false,
                        "gaDate": 1256572800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 146,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1247414400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 176,
                        "version": "1.6",
                        "guided": false,
                        "gaDate": 1297267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 245,
                        "version": "1.6.2",
                        "guided": false,
                        "gaDate": 1314806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 247,
                        "version": "1.6.1",
                        "guided": false,
                        "gaDate": 1309968000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 265,
                        "version": "2.0",
                        "guided": false,
                        "gaDate": 1322496000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 305,
                        "version": "2.0.1",
                        "guided": false,
                        "gaDate": 1331136000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 321,
                        "version": "2.5",
                        "guided": true,
                        "gaDate": 1347206400000,
                        "rnLink": "https://phnx-portal-stage.vmware.com/support/vcbm/doc/vcbm_2_5_0_release_notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=CBM-250&productId=298&rPId=3020",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 438,
                        "version": "2.5.1",
                        "guided": true,
                        "gaDate": 1370880000000,
                        "rnLink": "https://www.vmware.com/support/vcbm/doc/vcbm_2_5_1_release_notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=CBM-250&productId=298&rPId=3020",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 522,
                        "version": "2.6.0",
                        "guided": true,
                        "gaDate": 1386604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 721,
                        "version": "2.6.0 U1",
                        "guided": true,
                        "gaDate": 1404144000000,
                        "rnLink": "https://www.vmware.com/support/vcbm/doc/vcbm_2_6_0_1_release_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/it_business_management/vmware_vcenter_chargeback/2_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 772,
                        "version": "2.7",
                        "guided": true,
                        "gaDate": 1412611200000,
                        "rnLink": "https://www.vmware.com/support/vcbm/doc/vcbm_2_7_release_notes.html",
                        "dLink": "http://www.vmware.com/products/vcenter-chargeback/vcenter-chargeback-manager-service-providers.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 978,
                        "version": "2.7.1",
                        "guided": true,
                        "gaDate": 1461772800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/ChargebackMgmt/271/vcbm_2_7_1_release_notes.htmlhttp://pubs.vmware.com/Release_Notes/en/ChargebackMgmt/271/vcbm_2_7_1_release_notes.html",
                        "dLink": "http://www.vmware.com/products/vcenter-chargeback/vcenter-chargeback-manager-service-providers.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1779,
                        "version": "2.7.2",
                        "guided": true,
                        "gaDate": 1476892800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/ChargebackMgmt/272/vcbm_2_7_2_release_notes.html",
                        "dLink": "http://www.vmware.com/products/vcenter-chargeback/vcenter-chargeback-manager-service-providers.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2216,
                        "version": "2.7.3",
                        "guided": true,
                        "gaDate": 1489420800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 32,
                "name": "VMware vCenter AppSpeed",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 147,
                        "version": "1.5",
                        "guided": false,
                        "gaDate": 1284480000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 148,
                        "version": "1.2",
                        "guided": false,
                        "gaDate": 1260806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 149,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1247414400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 33,
                "name": "VMware vSphere Web Client",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 153,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 304,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 359,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 400,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 428,
                        "version": null,
                        "guided": true,
                        "gaDate": 1425225600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 440,
                        "version": null,
                        "guided": true,
                        "gaDate": 1409241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 451,
                        "version": null,
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 452,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 511,
                        "version": null,
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 561,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 617,
                        "version": null,
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 618,
                        "version": null,
                        "guided": true,
                        "gaDate": 1408464000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 794,
                        "version": null,
                        "guided": true,
                        "gaDate": 1433952000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 914,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1026,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 35,
                "name": "VMware vCenter Operations Manager",
                "childId": 116,
                "parentId": null,
                "releases": [
                    {
                        "id": 158,
                        "version": "5.0.0",
                        "guided": false,
                        "gaDate": 1327334400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 296,
                        "version": "5.6",
                        "guided": false,
                        "gaDate": 1354118400000,
                        "rnLink": "http://www.vmware.com/support/vcops/doc/vcops-56-vapp-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 301,
                        "version": "5.0.1",
                        "guided": false,
                        "gaDate": 1335369600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 323,
                        "version": "5.0.2",
                        "guided": false,
                        "gaDate": 1341417600000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-502-release-notes.html",
                        "dLink": "https://vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 341,
                        "version": "5.0.3",
                        "guided": false,
                        "gaDate": 1346256000000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-enterprise503-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 389,
                        "version": "5.7",
                        "guided": false,
                        "gaDate": 1365004800000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-57-vapp-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 447,
                        "version": "5.7.1",
                        "guided": false,
                        "gaDate": 1370880000000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-enterprise503-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 449,
                        "version": "5.7.2",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/pubs/vcops-pubs.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 497,
                        "version": "5.8.0",
                        "guided": true,
                        "gaDate": 1386604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 604,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": "http://wwwcontentdev.vmware.com:9090/support/vcops/doc/vcops-581-vapp-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations_management_suite/5_8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 664,
                        "version": "5.8.2",
                        "guided": true,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-582-vapp-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=infrastructure_operations_management/vmware_vcenter_operations_management_suite/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 705,
                        "version": "5.7.3",
                        "guided": false,
                        "gaDate": 1404921600000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-573-vapp-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations_management_suite/5_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 728,
                        "version": "5.8.3",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-583-installable-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations_management_suite/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 781,
                        "version": "5.8.4",
                        "guided": true,
                        "gaDate": 1414425600000,
                        "rnLink": "https://www.vmware.com/support/vcops/doc/vcops-584-vapp-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 785,
                        "version": "5.7.4",
                        "guided": false,
                        "gaDate": 1415808000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 806,
                        "version": "5.8.5",
                        "guided": true,
                        "gaDate": 1394553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1361,
                        "version": "5.0.0",
                        "guided": false,
                        "gaDate": 1327334400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 549,
                "name": "VMware Enterprise PKS",
                "childId": 644,
                "parentId": 356,
                "releases": [
                    {
                        "id": 4155,
                        "version": "1.6.2",
                        "guided": true,
                        "gaDate": 1588089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4643,
                        "version": "1.5.3",
                        "guided": true,
                        "gaDate": 1589817600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2699,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1518019200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2817,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1530115200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2965,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1537977600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3042,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1547568000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3207,
                        "version": "1.2.1",
                        "guided": true,
                        "gaDate": 1541088000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3208,
                        "version": "1.2.2",
                        "guided": true,
                        "gaDate": 1541952000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3209,
                        "version": "1.2.3",
                        "guided": true,
                        "gaDate": 1543420800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3256,
                        "version": "1.2.4",
                        "guided": true,
                        "gaDate": 1544371200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Pivotal-Container-Service/1.2/rn/VMware-Pivotal-Container-Service-12-Release-Notes.html",
                        "dLink": "https://network.pivotal.io/products/pivotal-container-service/#/releases/252333",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3322,
                        "version": "1.3.2",
                        "guided": true,
                        "gaDate": 1549987200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3344,
                        "version": "1.4.0",
                        "guided": true,
                        "gaDate": 1555948800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3415,
                        "version": "1.3.3",
                        "guided": true,
                        "gaDate": 1550764800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3416,
                        "version": "1.3.4",
                        "guided": true,
                        "gaDate": 1553529600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3442,
                        "version": "1.3.5",
                        "guided": true,
                        "gaDate": 1553702400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3485,
                        "version": "1.3.6",
                        "guided": true,
                        "gaDate": 1554652800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3486,
                        "version": "1.3.7",
                        "guided": true,
                        "gaDate": 1560960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3524,
                        "version": "1.4.1",
                        "guided": true,
                        "gaDate": 1560960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3543,
                        "version": "1.5.0",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3742,
                        "version": "1.6.0",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Enterprise-PKS/1.6/rn/VMware-Enterprise-PKS-16-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_enterprise_pks/1_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3864,
                        "version": "1.5.1",
                        "guided": true,
                        "gaDate": 1570636800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3884,
                        "version": "1.3.8",
                        "guided": true,
                        "gaDate": 1566144000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3885,
                        "version": "1.4.3",
                        "guided": true,
                        "gaDate": 1570723200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3886,
                        "version": "1.4.2",
                        "guided": true,
                        "gaDate": 1566144000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3954,
                        "version": "1.7.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3986,
                        "version": "1.6.1",
                        "guided": true,
                        "gaDate": 1578844800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 37,
                "name": "VMware Data Recovery",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 165,
                        "version": "2.0",
                        "guided": false,
                        "gaDate": 1314115200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 166,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1242835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 167,
                        "version": "1.1",
                        "guided": false,
                        "gaDate": 1258560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 168,
                        "version": "1.2",
                        "guided": false,
                        "gaDate": 1276099200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 186,
                        "version": "1.2.1",
                        "guided": false,
                        "gaDate": 1300377600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 628,
                        "version": "2.0.3",
                        "guided": false,
                        "gaDate": 1391616000000,
                        "rnLink": "http://www.vmware.com/support/vdr/doc/vdr_203_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 551,
                "name": "VMware Tanzu Kubernetes Grid",
                "childId": null,
                "parentId": 530,
                "releases": [
                    {
                        "id": 4110,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1586361600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tanzu-Kubernetes-Grid/1.0/rn/VMware-Tanzu-Kubernetes-Grid-10-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_tanzu_kubernetes_grid/1_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4252,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1589990400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tanzu-Kubernetes-Grid/1.1/rn/VMware-Tanzu-Kubernetes-Grid-11-Release-Notes.html",
                        "dLink": "vmware.com/go/get-tkg",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4728,
                        "version": "1.1.2",
                        "guided": true,
                        "gaDate": 1593014400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tanzu-Kubernetes-Grid/1.1.2/rn/VMware-Tanzu-Kubernetes-Grid-112-Release-Notes.html",
                        "dLink": "vmware.com/go/get-tkg",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 39,
                "name": "VMware Tools from VMware ESX/ESXi",
                "childId": 139,
                "parentId": null,
                "releases": [
                    {
                        "id": 199,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 200,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 201,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 202,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 203,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 204,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 205,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 206,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 207,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 208,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 307,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 330,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 374,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 384,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 394,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 432,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 443,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 509,
                        "version": null,
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 512,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 579,
                        "version": null,
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 605,
                        "version": null,
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 747,
                        "version": null,
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 797,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 891,
                        "version": null,
                        "guided": true,
                        "gaDate": 1438617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 915,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 553,
                "name": "VMware Workspace ONE Assist Installer",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4170,
                        "version": "20.3.1",
                        "guided": true,
                        "gaDate": 1586102400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4038,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585497600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 41,
                "name": "VMware vCenter Update Manager Client Plug-In",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 210,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 211,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 212,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 213,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 214,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 215,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 217,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 218,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 219,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 220,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 273,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 275,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 353,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 367,
                        "version": null,
                        "guided": true,
                        "gaDate": 1355932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 382,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 459,
                        "version": null,
                        "guided": true,
                        "gaDate": 1381939200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 460,
                        "version": null,
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 503,
                        "version": null,
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 514,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 581,
                        "version": null,
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 623,
                        "version": null,
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 722,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 804,
                        "version": null,
                        "guided": true,
                        "gaDate": 1439395200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 886,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1029,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 42,
                "name": "VMware vCenter Converter Client Plug-In",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 228,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 229,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 230,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 231,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 232,
                        "version": null,
                        "guided": true,
                        "gaDate": 1325347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 555,
                "name": "VMware NSX Intelligence",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4037,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4039,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1586188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4051,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 45,
                "name": "Pivotal tc Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 239,
                        "version": "2.6",
                        "guided": false,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 256,
                        "version": "2.6.1",
                        "guided": false,
                        "gaDate": 1316620800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 533,
                        "version": "2.8.2",
                        "guided": false,
                        "gaDate": 1359561600000,
                        "rnLink": "https://www.vmware.com/support/vfabric-tcserver/doc/vfabric-tcserver-rn-2.8.0.html#whatsnew282",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_tc_server/2_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 541,
                        "version": "2.6.3",
                        "guided": false,
                        "gaDate": 1324310400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 46,
                "name": "VMware vFabric Hyperic",
                "childId": 95,
                "parentId": null,
                "releases": [
                    {
                        "id": 299,
                        "version": "4.6.5",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 348,
                        "version": "4.6.6",
                        "guided": false,
                        "gaDate": 1339516800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 358,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1353254400000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/vfabric-hyperic-rn-5.0.0.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=application_management/vmware_vfabric_hyperic/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 425,
                        "version": "5.7",
                        "guided": false,
                        "gaDate": 1370880000000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes_hyperic57.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_hyperic/5_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 524,
                        "version": "5.7.1",
                        "guided": false,
                        "gaDate": 1378137600000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes_hyperic57.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_hyperic/5_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 525,
                        "version": "5.0.2",
                        "guided": true,
                        "gaDate": 1378137600000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes-hyperic502.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=application_management/vmware_vfabric_hyperic/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 761,
                        "version": "5.7.2",
                        "guided": false,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091206",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_hyperic/5_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 762,
                        "version": "5.0.3",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091207",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_hyperic/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 47,
                "name": "VMware vFabric Web Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 240,
                        "version": "5.0",
                        "guided": false,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 255,
                        "version": "5.0.1",
                        "guided": false,
                        "gaDate": 1316620800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 548,
                        "version": "5.1",
                        "guided": false,
                        "gaDate": 1337616000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 48,
                "name": "VMware vFabric RabbitMQ",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 237,
                        "version": "2.4.1",
                        "guided": true,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 553,
                        "version": "2.8.1",
                        "guided": true,
                        "gaDate": 1335974400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 560,
                "name": "VMware vRealize Operations Management Pack for Google Cloud Platform (GCP)",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4070,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1587398400000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.0/rn/GCP-10-MP-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-google-cloud-platform",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 49,
                "name": "Pivotal GemFire",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 235,
                        "version": "6.6",
                        "guided": true,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 555,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1351094400000,
                        "rnLink": "https://www.vmware.com/support/vfabric-gemfire/doc/vfabric-gemfire-rn-7.0.0.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/application_platform/vmware_vfabric_gemfire/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 557,
                        "version": "6.6.3",
                        "guided": true,
                        "gaDate": 1339516800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 562,
                "name": "VMware vRealize Operations Management Pack for VMware vRealize Network Insight",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4072,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.0/rn/vRealize-Network-Insight-10-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-vrealize-network-insight#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 51,
                "name": "VMware vSphere Storage Appliance",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 246,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1314115200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 294,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "https://www.vmware.com/support/vsa/doc/vsphere-storage-appliance-51-release-notes.html",
                        "dLink": "https://www.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 385,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1351094400000,
                        "rnLink": "https://www.vmware.com/support/vsa/doc/vsphere-storage-appliance-51-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere_storage_appliance/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 469,
                        "version": "5.1.3",
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 516,
                        "version": "5.5.0",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 644,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 775,
                        "version": "5.5.2",
                        "guided": false,
                        "gaDate": 1412092800000,
                        "rnLink": "https://ikb.vmware.com/kb/2091000",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VSP55-VSA-552&productId=354&rPId=6585",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1048,
                        "version": "5.5.6",
                        "guided": true,
                        "gaDate": 1459699200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 52,
                "name": "VMware vFabric AppInsight",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 306,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 347,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1338307200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 357,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1353254400000,
                        "rnLink": "https://www.vmware.com/support/appinsight/doc/releasenotes_appinsight5.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=application_management/vmware_vfabric_application_performance_manager/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 53,
                "name": "VMware vCenter Infrastructure Navigator",
                "childId": 124,
                "parentId": null,
                "releases": [
                    {
                        "id": 249,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1327334400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 324,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1335369600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 342,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1338739200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 343,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1347206400000,
                        "rnLink": "https://www.vmware.com/support/adm/doc/vcenter-infrastructure-navigator-12-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCL51-VIN-120&productId=284&rPId=2764",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 344,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1354118400000,
                        "rnLink": "http://www.vmware.com/support/adm/doc/vcenter-infrastructure-navigator-20-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_operations/5_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 477,
                        "version": "5.7",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": "https://wiki.eng.vmware.com/VIN/VIN_3.0",
                        "dLink": "https://my.vmware.com/web/vmware/downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 570,
                        "version": "5.8",
                        "guided": true,
                        "gaDate": 1386604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 657,
                        "version": "5.8.2",
                        "guided": true,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/adm/doc/release-notes-vcenter-infrastructure-navigator-582.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_infrastructure_navigator/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 764,
                        "version": "5.8.3",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091095",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIN_583&productId=377&rPId=6723",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 765,
                        "version": "2.0.1",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091108",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIN_201&productId=314&rPId=6722",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 766,
                        "version": "5.7.1",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091093",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIN_571&productId=358&rPId=6841",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 54,
                "name": "VMware vFabric Data Director",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 402,
                        "version": "2.5",
                        "guided": true,
                        "gaDate": 1354723200000,
                        "rnLink": "https://www.vmware.com/support/datadirector/doc/vfabric-data-director-25-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_data_director/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 455,
                        "version": "2.7.0",
                        "guided": true,
                        "gaDate": 1368633600000,
                        "rnLink": "https://www.vmware.com/support/datadirector/doc/vfabric-data-director-27-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_data_director/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 55,
                "name": "VMware Service Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 407,
                        "version": "9.1.4",
                        "guided": true,
                        "gaDate": 1354723200000,
                        "rnLink": "http://www.vmware.com/products/datacenter-virtualization/service-manager/features.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_downloads/vmware_service_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 445,
                        "version": "9.1.5",
                        "guided": true,
                        "gaDate": 1363190400000,
                        "rnLink": "http://www.vmware.com/products/datacenter-virtualization/service-manager/features.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_downloads/vmware_service_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 519,
                        "version": "9.1.6",
                        "guided": true,
                        "gaDate": 1371052800000,
                        "rnLink": "http://www.vmware.com/products/datacenter-virtualization/service-manager/features.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_downloads/vmware_service_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 634,
                        "version": "9.1.8",
                        "guided": true,
                        "gaDate": 1393430400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 663,
                        "version": "9.1.9",
                        "guided": true,
                        "gaDate": 1402502400000,
                        "rnLink": "http://www.vmware.com/products/datacenter-virtualization/service-manager/features.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_downloads/vmware_service_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 56,
                "name": "VMware vCloud Connector",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 268,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 269,
                        "version": "1.5.0",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 271,
                        "version": "2.0.0",
                        "guided": false,
                        "gaDate": 1355932800000,
                        "rnLink": "https://www.vmware.com/support/hybridcloud/doc/hybridcloud_15_rel_notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 439,
                        "version": "2.5",
                        "guided": false,
                        "gaDate": 1370880000000,
                        "rnLink": "https://www.vmware.com/support/hybridcloud/doc/hybridcloud_25_rel_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=284&downloadGroup=VCL-VCC25",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 562,
                        "version": "2.6",
                        "guided": false,
                        "gaDate": 1392048000000,
                        "rnLink": "https://www.vmware.com/support/hybridcloud/doc/hybridcloud_26_rel_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCC26-GA&productId=229",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 757,
                        "version": "2.6.1",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 788,
                        "version": "2.7",
                        "guided": false,
                        "gaDate": 1415808000000,
                        "rnLink": "https://www.vmware.com/support/hybridcloud/doc/hybridcloud_27_rel_notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCC27-GA&productId=229",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 570,
                "name": "VMware Cloud Director Availability",
                "childId": null,
                "parentId": 476,
                "releases": [
                    {
                        "id": 4096,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1591027200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 58,
                "name": "VMware vCenter Operations for Horizon",
                "childId": 123,
                "parentId": null,
                "releases": [
                    {
                        "id": 300,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1337097600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 415,
                        "version": "1.5.0",
                        "guided": true,
                        "gaDate": 1365004800000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vcops-view-15-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_vcenter_operations_manager_for_view/1_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 565,
                        "version": "1.5.1",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vcops-view-151-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 697,
                        "version": "1.6",
                        "guided": true,
                        "gaDate": 1403107200000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vcops-horizon-16-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=439&downloadGroup=VCOPS-HORIZON-160",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 748,
                        "version": "1.7",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vcops-horizon-17-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCOPS-HORIZON-170&productId=454&rPId=6532",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 59,
                "name": "VMware Postgres",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 310,
                        "version": "9.1.3",
                        "guided": true,
                        "gaDate": 1337616000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 404,
                        "version": "9.1.6",
                        "guided": true,
                        "gaDate": 1350316800000,
                        "rnLink": "https://www.vmware.com/support/vfabric-postgres/doc/vfabric-postgres-916-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=application_platform/vmware_vfabric_postgres/9_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 422,
                        "version": "9.2.2",
                        "guided": false,
                        "gaDate": 1360166400000,
                        "rnLink": "https://www.vmware.com/support/vfabric-postgres/doc/vfabric-postgres-92-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_postgres/9_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 461,
                        "version": "9.2.4",
                        "guided": false,
                        "gaDate": 1365004800000,
                        "rnLink": "https://www.vmware.com/support/vfabric-postgres/doc/vfabric-postgres-92-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_postgres/9_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 475,
                        "version": "9.1.9",
                        "guided": true,
                        "gaDate": 1365004800000,
                        "rnLink": "https://www.vmware.com/support/vfabric-postgres/doc/vfabric-postgres-916-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_postgres/9_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 679,
                        "version": "9.2.6",
                        "guided": false,
                        "gaDate": 1390406400000,
                        "rnLink": "https://www.vmware.com/support/vfabric-postgres/doc/vfabric-postgres-92-release-notes.html#whatsnew926",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_platform/vmware_vfabric_postgres/9_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1660,
                        "version": "9.3.12",
                        "guided": false,
                        "gaDate": 1460563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1666,
                        "version": "9.4.5",
                        "guided": true,
                        "gaDate": 1447776000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2089,
                        "version": "9.3.14",
                        "guided": false,
                        "gaDate": 1474560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2324,
                        "version": "9.4.11",
                        "guided": true,
                        "gaDate": 1488211200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2360,
                        "version": "9.5.6",
                        "guided": true,
                        "gaDate": 1493222400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2432,
                        "version": "9.5.7",
                        "guided": true,
                        "gaDate": 1495555200000,
                        "rnLink": "https://www.postgresql.org/docs/9.5/static/release-9-5-7.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2473,
                        "version": "9.6.3",
                        "guided": true,
                        "gaDate": 1498665600000,
                        "rnLink": "https://www.postgresql.org/docs/9.6/static/release-9-6-3.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2533,
                        "version": "9.6.4",
                        "guided": true,
                        "gaDate": 1503331200000,
                        "rnLink": "https://www.postgresql.org/docs/9.6/static/release-9-6-4.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2534,
                        "version": "9.5.8",
                        "guided": true,
                        "gaDate": 1503331200000,
                        "rnLink": "https://www.postgresql.org/docs/9.5/static/release-9-5-8.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 571,
                "name": "VMware Telco Cloud Automation",
                "childId": null,
                "parentId": 556,
                "releases": [
                    {
                        "id": 4050,
                        "version": "3.5.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 61,
                "name": "VMware vCenter Application Discovery Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 340,
                        "version": "7.0",
                        "guided": false,
                        "gaDate": 1347465600000,
                        "rnLink": "https://www.vmware.com/support/adm/doc/vcenter-application-discovery-manager-700-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vcenter_application_discovery_manager/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 62,
                "name": "VMware ITBM Suite AdvEnt Edition",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 352,
                        "version": "ITBM Suite 7.1.2",
                        "guided": true,
                        "gaDate": 1341072000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 355,
                        "version": "ITBM Suite 7.5",
                        "guided": true,
                        "gaDate": 1353254400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 576,
                "name": "VMware SD-WAN by VeloCloud",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4123,
                        "version": "3.4.0",
                        "guided": true,
                        "gaDate": 1582560000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4126,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1572969600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4136,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 577,
                "name": "VMware Workspace ONE Email Notification Service 2 (ENS2)",
                "childId": null,
                "parentId": 348,
                "releases": [
                    {
                        "id": 4147,
                        "version": "1.7",
                        "guided": true,
                        "gaDate": 1585670400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4202,
                        "version": "1.7.1",
                        "guided": true,
                        "gaDate": 1586966400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 65,
                "name": "VMware vCloud Application Director",
                "childId": 115,
                "parentId": 181,
                "releases": [
                    {
                        "id": 529,
                        "version": "6.0",
                        "guided": false,
                        "gaDate": 1386604800000,
                        "rnLink": "https://www.vmware.com/support/appdirector/doc/releasenotes_appdirector60.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 629,
                        "version": "6.0.1",
                        "guided": true,
                        "gaDate": 1392220800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 699,
                        "version": "6.1.0",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 578,
                "name": "VMware Integrated OpenStack",
                "childId": null,
                "parentId": 119,
                "releases": [
                    {
                        "id": 4117,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1591200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 810,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "http://concrete2.vmware.com/support/integrated-openstack/doc/vmware-integrated-openstack-10-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_cloud_infrastructure/vmware_integrated_openstack/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 916,
                        "version": "2.0",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/integrated-openstack/20/vmware-integrated-openstack-20-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_cloud_infrastructure/vmware_integrated_openstack/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 972,
                        "version": "2.0.1",
                        "guided": false,
                        "gaDate": 1449676800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/integrated-openstack/20/vmware-integrated-openstack-201-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=datacenter_cloud_infrastructure/vmware_integrated_openstack/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1059,
                        "version": "2.0.3",
                        "guided": true,
                        "gaDate": 1459699200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1081,
                        "version": "2.5",
                        "guided": false,
                        "gaDate": 1464883200000,
                        "rnLink": "http://www.vmware.com/products/whats-new-openstack.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_integrated_openstack/2_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1680,
                        "version": "3.0.0",
                        "guided": false,
                        "gaDate": 1473868800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/integrated-openstack/30/vmware-integrated-openstack-3-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=608&downloadGroup=VIO_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2102,
                        "version": "2.5.1",
                        "guided": false,
                        "gaDate": 1475424000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2148,
                        "version": "3.1.0",
                        "guided": false,
                        "gaDate": 1487606400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/integrated-openstack/31/vmware-integrated-openstack-31-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_integrated_openstack/3_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2313,
                        "version": "2.5.2",
                        "guided": false,
                        "gaDate": 1488211200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2317,
                        "version": "4.0",
                        "guided": false,
                        "gaDate": 1505750400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Integrated-OpenStack/4.0/rn/vmware-integrated-openstack-40.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_integrated_openstack/4_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2524,
                        "version": "4.1",
                        "guided": false,
                        "gaDate": 1516204800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2716,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1530547200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2925,
                        "version": "4.1.1",
                        "guided": false,
                        "gaDate": 1531756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2959,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2975,
                        "version": "4.1.2",
                        "guided": false,
                        "gaDate": 1537200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3102,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1567440000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 66,
                "name": "VMware vCloud Networking and Security",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 365,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_51.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/security_products/vmware_vcloud_networking_and_security/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 387,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1351094400000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_511.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VSP51-VCL-VCNS511&productId=289&rPId=3060",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 395,
                        "version": "5.1.2",
                        "guided": false,
                        "gaDate": 1355932800000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_512.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/security_products/vmware_vcloud_networking_and_security/5_12",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 480,
                        "version": "5.5",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/pubs/vshield_pubs.html",
                        "dLink": "https://my.vmware.com/web/vmware/downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 588,
                        "version": "5.1.3",
                        "guided": false,
                        "gaDate": 1392048000000,
                        "rnLink": "http://wwwcontentdev.vmware.com:9998/support/vshield/doc/releasenotes_vshield_513.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VSP51-VCL-VCNS511&productId=289&rPId=3060",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 612,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1396454400000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_511.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VSP51-VCL-VCNS511&productId=289&rPId=3060",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 655,
                        "version": "5.5.2",
                        "guided": false,
                        "gaDate": 1397577600000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_552.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/security_products/vmware_vcloud_networking_and_security/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 667,
                        "version": "5.1.4",
                        "guided": false,
                        "gaDate": 1397577600000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_514.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/security_products/vmware_vcloud_networking_and_security/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 693,
                        "version": "5.5.3",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_553.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_suite/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 776,
                        "version": "5.5.4",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_554.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/security_products/vmware_vcloud_networking_and_security/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1188,
                        "version": "1.0 U2",
                        "guided": true,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1189,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1241625600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1190,
                        "version": "5.0.2",
                        "guided": true,
                        "gaDate": 1343664000000,
                        "rnLink": "https://www.vmware.com/support/vshield/doc/releasenotes_vshield_502.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/security_products/vmware_vshield_app/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1191,
                        "version": "5.0.1",
                        "guided": true,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1192,
                        "version": "5.0.0",
                        "guided": true,
                        "gaDate": 1314806400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1193,
                        "version": "4.1.0 U2",
                        "guided": true,
                        "gaDate": 1326038400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1194,
                        "version": "1.0 U1",
                        "guided": true,
                        "gaDate": 1287590400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 68,
                "name": "VMware vSphere Data Protection",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 370,
                        "version": "5.1",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_510_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP51&productId=285&rPId=2766",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 435,
                        "version": "5.1.10",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_5110_releasenotes.html",
                        "dLink": "http://www.vmware.com/download/download.do?downloadGroup=VDP51_10",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 436,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 476,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_551_releasenotes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VDP55_1&productId=353&rPId=4287#eulaDeclinedSection",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 484,
                        "version": "5.1.11",
                        "guided": false,
                        "gaDate": 1370448000000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_5111_releasenotes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 586,
                        "version": "5.5.5",
                        "guided": false,
                        "gaDate": 1384358400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 587,
                        "version": "5.5.6",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_556_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP55_6&productId=353&rPId=5256",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 640,
                        "version": "5.8",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_580_releasenotes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VDP58_0&productId=408&rPId=5635",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 687,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "http://www.vmware.com/support/vdr/doc/vdp_600_releasenotes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VDP60&productId=491&rPId=7502",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 751,
                        "version": "5.5.7",
                        "guided": false,
                        "gaDate": 1412784000000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_557_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=351&rPId=6814&downloadGroup=VDP55_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 752,
                        "version": "5.5.8",
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 826,
                        "version": "5.5.9",
                        "guided": true,
                        "gaDate": 1421683200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 836,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1422460800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 883,
                        "version": "6.0.1",
                        "guided": true,
                        "gaDate": 1431964800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 910,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/61/vdp_610_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP61&productId=491&rPId=8762",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 947,
                        "version": "5.8.3",
                        "guided": false,
                        "gaDate": 1445270400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/58/data-protection-583-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=353&rPId=8950&downloadGroup=VDP58_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 948,
                        "version": "6.0.2",
                        "guided": true,
                        "gaDate": 1435766400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 951,
                        "version": "6.0.3",
                        "guided": true,
                        "gaDate": 1447084800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/60/data-protection-603-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=491&rPId=9475&downloadGroup=VDP60_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 952,
                        "version": "6.1.1",
                        "guided": true,
                        "gaDate": 1447084800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/61/data-protection-611-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VDP611&productId=491&rPId=9475",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 953,
                        "version": "5.5.11",
                        "guided": true,
                        "gaDate": 1447689600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 981,
                        "version": "6.1.2",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/61/data-protection-612-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&downloadGroup=VDP612",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1056,
                        "version": "5.8.4",
                        "guided": false,
                        "gaDate": 1460390400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/58/data-protection-584-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=353&rPId=8950&downloadGroup=VDP58_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1063,
                        "version": "6.0.4",
                        "guided": true,
                        "gaDate": 1462204800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/60/data-protection-604-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=491&rPId=10652&downloadGroup=VDP60_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1806,
                        "version": "6.1.3",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/61/data-protection-613-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VDP613&productId=572&rPId=13202",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2275,
                        "version": "6.1.4",
                        "guided": true,
                        "gaDate": 1489593600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2431,
                        "version": "6.0.5",
                        "guided": true,
                        "gaDate": 1496678400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vdp/60/data-protection-605-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&rPId=16463&downloadGroup=VDP60_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2527,
                        "version": "6.1.5",
                        "guided": true,
                        "gaDate": 1503504000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-615-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP615&productId=614&rPId=17783",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2617,
                        "version": "6.0.6",
                        "guided": true,
                        "gaDate": 1511193600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.0/rn/data-protection-606-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/details?productId=490&rPId=19899&downloadGroup=VDP60_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2632,
                        "version": "6.1.6",
                        "guided": true,
                        "gaDate": 1514822400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-616-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&downloadGroup=VDP616",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2658,
                        "version": "6.0.7",
                        "guided": true,
                        "gaDate": 1514822400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/en/web/vmware/details?productId=491&rPId=20475&downloadGroup=VDP60_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2683,
                        "version": "6.1.7",
                        "guided": true,
                        "gaDate": 1519056000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-617-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP617&productId=614&rPId=21564",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2788,
                        "version": "6.1.8",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": "http://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-618-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP618&productId=491&rPId=22739",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2923,
                        "version": "6.0.8",
                        "guided": true,
                        "gaDate": 1531152000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.0/rn/data-protection-608-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&rPId=24397&downloadGroup=VDP60_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2998,
                        "version": "6.1.9",
                        "guided": true,
                        "gaDate": 1537977600000,
                        "rnLink": "http://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-619-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP619&productId=614&rPId=27255",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3075,
                        "version": "6.0.9",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.0/rn/data-protection-609-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&rPId=20478&downloadGroup=VDP60_9",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3081,
                        "version": "6.1.10",
                        "guided": true,
                        "gaDate": 1542643200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-6110-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/details?downloadGroup=VDP6110&productId=614&rPId=28811",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3406,
                        "version": "6.1.11",
                        "guided": true,
                        "gaDate": 1553529600000,
                        "rnLink": "http://docs.vmware.com/en/VMware-vSphere/6.5/rn/data-protection-6111-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VDP6111&productId=614&rPId=31473",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3407,
                        "version": "6.0.10",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vSphere/6.0/rn/data-protection-6010-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=491&rPId=32386&downloadGroup=VDP60_10",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 72,
                "name": "VMware vCenter Multi-Hypervisor Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 386,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1353254400000,
                        "rnLink": "https://www.vmware.com/support/mhm/doc/vcenter-multi-hypervisor-manager-10-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCL-VSP510-VC-510A-EN&productId=285&rPId=2765",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 410,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": "https://www.vmware.com/support/mhm/doc/vcenter-multi-hypervisor-manager-11-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCL-VSP510-VC-51U1&productId=285&rPId=3665",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 725,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VC55U2&productId=353&rPId=5720",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 802,
                        "version": "1.1.2",
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": "https://phnx-portal-stage.vmware.com/support/mhm/doc/vcenter-multi-hypervisor-manager-112-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCL-VSP510-VC-51U3&productId=285&rPId=7216",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 74,
                "name": "VMware vCloud Automation Center",
                "childId": 114,
                "parentId": null,
                "releases": [
                    {
                        "id": 397,
                        "version": "5.1",
                        "guided": false,
                        "gaDate": 1355328000000,
                        "rnLink": "http://www.vmware.com/products/datacenter-virtualization/vcloud-automation-center/overview.html",
                        "dLink": "http://www.vmware.com/go/download-vcac",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 478,
                        "version": "5.2",
                        "guided": false,
                        "gaDate": 1369843200000,
                        "rnLink": "https://www.vmware.com/pdf/vcac-52-release-notes.pdf",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/5_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 573,
                        "version": "6.0",
                        "guided": false,
                        "gaDate": 1386604800000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-60-release-notes.html",
                        "dLink": "https://my.vmware.com/go/download-vcac",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 616,
                        "version": "5.2.1",
                        "guided": false,
                        "gaDate": 1394640000000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-521-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/5_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 630,
                        "version": "6.0.1",
                        "guided": false,
                        "gaDate": 1392220800000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-601-release-notes.html",
                        "dLink": "https://my.vmware.com/go/download-vcac",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 654,
                        "version": "5.2.2",
                        "guided": false,
                        "gaDate": 1402502400000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-522-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/5_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 691,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-61-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 733,
                        "version": "5.2.3",
                        "guided": false,
                        "gaDate": 1414425600000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-523-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/5_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 782,
                        "version": "6.1.1",
                        "guided": false,
                        "gaDate": 1414598400000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vcloud-automation-center-611-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vcloud_automation_center/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 587,
                "name": "VMware Skyline Collector",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4189,
                        "version": "2.3.0",
                        "guided": true,
                        "gaDate": 1574697600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4190,
                        "version": "2.4.0",
                        "guided": true,
                        "gaDate": 1589817600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 78,
                "name": "VMware vCenter Update Manager Web Client ",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 399,
                        "version": null,
                        "guided": true,
                        "gaDate": 1366819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 458,
                        "version": null,
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 502,
                        "version": null,
                        "guided": true,
                        "gaDate": 1389801600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 515,
                        "version": null,
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 582,
                        "version": null,
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 624,
                        "version": null,
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 723,
                        "version": null,
                        "guided": true,
                        "gaDate": 1417622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 805,
                        "version": null,
                        "guided": true,
                        "gaDate": 1439395200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 887,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1030,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 79,
                "name": "VMware Horizon Workspace",
                "childId": 105,
                "parentId": null,
                "releases": [
                    {
                        "id": 416,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1362326400000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/hw_release_notes_10.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_workspace/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 518,
                        "version": "1.5.0",
                        "guided": false,
                        "gaDate": 1375113600000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/hw_release_notes_15.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_workspace/1_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 642,
                        "version": "1.8.0",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/hw_release_notes_18.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_workspace/1_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 665,
                        "version": "1.8.1",
                        "guided": false,
                        "gaDate": 1397750400000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/hw_release_notes_181.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_workspace/1_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 720,
                        "version": "1.8.2",
                        "guided": false,
                        "gaDate": 1404144000000,
                        "rnLink": "https://www.vmware.com/support/pubs/horizon-workspace-pubs.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=HZNWS182&productId=399&rPId=6083",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 81,
                "name": "VMware vSphere Data Protection Advanced",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 471,
                        "version": "5.1.20",
                        "guided": false,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 485,
                        "version": "5.1.21",
                        "guided": false,
                        "gaDate": 1370448000000,
                        "rnLink": "https://www.vmware.com/support/vdr/doc/vdp_5121_releasenotes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 583,
                        "version": "5.5.5",
                        "guided": true,
                        "gaDate": 1384358400000,
                        "rnLink": "http://www.vmware.com/support/vdr/doc/vdp_555_releasenotes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 584,
                        "version": "5.5.6",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 585,
                        "version": "5.8.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 753,
                        "version": "5.5.8",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 754,
                        "version": "5.5.7",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 84,
                "name": "VMware vSphere Big Data Extensions",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 483,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/bigdataextensions/doc/vsphere-big-data-extensions-10-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=BDE-100-GA&productId=353&rPId=4286",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 592,
                        "version": "1.1.0",
                        "guided": false,
                        "gaDate": 1389801600000,
                        "rnLink": "https://www.vmware.com/support/bigdataextensions/doc/vsphere-big-data-extensions-11-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=BDE_110_GA&productId=351&rPId=4975",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 633,
                        "version": "2.0.0",
                        "guided": false,
                        "gaDate": 1402502400000,
                        "rnLink": "https://www.vmware.com/support/bigdataextensions/doc/vsphere-big-data-extensions-20-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=BDE_200_GA&productId=353&rPId=5940",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 707,
                        "version": "2.1.0",
                        "guided": false,
                        "gaDate": 1413216000000,
                        "rnLink": "https://www.vmware.com/support/bigdataextensions/doc/vsphere-big-data-extensions-21-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=BDE_210_GA&productId=353&rPId=6656",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 849,
                        "version": "2.2.0",
                        "guided": false,
                        "gaDate": 1433347200000,
                        "rnLink": "https://www.vmware.com/support/bigdataextensions/doc/vsphere-big-data-extensions-21-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=BDE_210_GA&productId=353&rPId=6656",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 930,
                        "version": "2.3.0",
                        "guided": false,
                        "gaDate": 1449504000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/bde/23/vsphere-big-data-extensions-23-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=BDE_230&productId=491&rPId=9646",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1045,
                        "version": "2.3.1",
                        "guided": true,
                        "gaDate": 1459267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2312,
                        "version": "2.3.2",
                        "guided": false,
                        "gaDate": 1487779200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/bde/23/vsphere-big-data-extensions-23-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 86,
                "name": "VMware Mirage",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 493,
                        "version": "4.3.0",
                        "guided": false,
                        "gaDate": 1384790400000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage43.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_mirage/4_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 646,
                        "version": "4.4.1",
                        "guided": false,
                        "gaDate": 1395158400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_mirage/4_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 647,
                        "version": "5.0.0",
                        "guided": false,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage50.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 648,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1410796800000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage51.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 708,
                        "version": "4.4.3",
                        "guided": true,
                        "gaDate": 1402502400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 767,
                        "version": "5.2.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage52.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 833,
                        "version": "5.3.0",
                        "guided": false,
                        "gaDate": 1425312000000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage53.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 867,
                        "version": "5.4.0",
                        "guided": false,
                        "gaDate": 1434988800000,
                        "rnLink": "https://www.vmware.com/support/mirage/doc/releasenotes-mirage54.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 901,
                        "version": "5.5.0",
                        "guided": false,
                        "gaDate": 1443628800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/mirage/55/releasenotes-mirage55.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1006,
                        "version": "5.7.0",
                        "guided": false,
                        "gaDate": 1456761600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/mirage/57/releasenotes-mirage57.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1055,
                        "version": "5.8.0",
                        "guided": false,
                        "gaDate": 1465833600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/mirage/58/releasenotes-mirage58.html?__utma=207178772.1041416446.1448742392.1463652490.1465984451.6&__utmb=207178772.1.10.1465984451&__utmc=207178772&__utmx=-&__utmz=207178772.1465984451.6.2.utmcsr=gss-bridges.vmware.com|utmccn=%28referral%29|utmcmd=referral|utmcct=/ViewRelease.aspx&__utmv=-&__utmk=144806071",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=MIRAGE-580&productId=407&rPId=11654",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1089,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1473264000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2246,
                        "version": "5.9",
                        "guided": false,
                        "gaDate": 1490198400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/mirage/59/releasenotes-mirage59.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_mirage/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2446,
                        "version": "5.9.1",
                        "guided": false,
                        "gaDate": 1506528000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 88,
                "name": "VMware vRealize Log Insight",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4221,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.1.1/rn/vRealize-Log-Insight-811.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 499,
                        "version": "1.0.4",
                        "guided": true,
                        "gaDate": 1373299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 528,
                        "version": "1.5.0",
                        "guided": true,
                        "gaDate": 1389024000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 625,
                        "version": "2.0.0",
                        "guided": false,
                        "gaDate": 1402329600000,
                        "rnLink": "http://wwwcontentdev.vmware.com:10036/support/log-insight/doc/log-insight-20-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_log_insight/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 778,
                        "version": "2.0.5",
                        "guided": false,
                        "gaDate": 1412784000000,
                        "rnLink": "https://www.vmware.com/support/log-insight/doc/log-insight-205-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=STRATA205&productId=412&rPId=6888",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 813,
                        "version": "2.5.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/log-insight/doc/log-insight-25-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-250&productId=471&rPId=7140",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 814,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1373299200000,
                        "rnLink": "https://www.vmware.com/support/log-insight/doc/log-insight-10-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_log_insight/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 911,
                        "version": "3.0.0",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1000,
                        "version": "3.3.0",
                        "guided": false,
                        "gaDate": 1456761600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/33/log-insight-33-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/3_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1023,
                        "version": "3.3.1",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/33/log-insight-331-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1049,
                        "version": "3.6.0",
                        "guided": false,
                        "gaDate": 1470844800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/36/log-insight-36-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-360&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1064,
                        "version": "3.3.2",
                        "guided": false,
                        "gaDate": 1465401600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/33/log-insight-332-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/3_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1070,
                        "version": "2.0.6",
                        "guided": false,
                        "gaDate": 1456848000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2053,
                        "version": "4.0.0",
                        "guided": false,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/40/log-insight-40-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-400&productId=616&rPId=13351",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2161,
                        "version": "4.3.0",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/43/log-insight-43-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/get-download?downloadGroup=VRLI-430&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2277,
                        "version": "4.5.0",
                        "guided": false,
                        "gaDate": 1497283200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "dLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2425,
                        "version": "4.6.0",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2555,
                        "version": "4.5.1",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2805,
                        "version": "4.7",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2841,
                        "version": "4.6.1",
                        "guided": false,
                        "gaDate": 1528128000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3087,
                        "version": "4.6.2",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.6/rn/vRealize-Log-Insight-462-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3096,
                        "version": "4.7.1",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.7.1/rn/VMware-vRealize-Log-Insight-471-Release-Notes-.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3213,
                        "version": "4.8",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.8/rn/vRealize-Log-Insight-48-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VRLI-480&productId=796&rPId=31973",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3528,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.0/rn/vRealize-Log-Insight-80.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info?slug=infrastructure_operations_management/vmware_vrealize_log_insight/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3852,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.1/com.vmware.log-insight.getting-started.doc/GUID-5F0ED330-158D-4F00-B579-F30136FDB42F.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 89,
                "name": "VMware vSphere AppHA",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 506,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1379779200000,
                        "rnLink": "https://www.vmware.com/support/appha/doc/releasenotes-appha10.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=APPHA-100&productId=353&rPId=4287",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 598,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1396886400000,
                        "rnLink": "https://www.vmware.com/support/appha/doc/releasenotes-appha11.html",
                        "dLink": "http://www.vmware.com/go/download-vsphere",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 763,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091087",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=APPHA-111&productId=353&rPId=6657",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 90,
                "name": "vCloud Hybrid Service (vCHS)",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 564,
                        "version": null,
                        "guided": true,
                        "gaDate": 1378137600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 91,
                "name": "VMware vCloud Air Plugin",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 641,
                        "version": "vCHS Plug-in 1.0.1",
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 703,
                        "version": "vCHS Plug-In 1.5",
                        "guided": true,
                        "gaDate": 1406131200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 704,
                        "version": "vCloud Air Plugin 1.0.2",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 783,
                        "version": "vCHS Plug-In 1.5.1",
                        "guided": true,
                        "gaDate": 1416240000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 93,
                "name": "VMware NSX Data Center for vSphere",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 601,
                        "version": "6.0.1",
                        "guided": false,
                        "gaDate": 1385913600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 613,
                        "version": "6.0.2",
                        "guided": false,
                        "gaDate": 1390406400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 685,
                        "version": "6.0.3",
                        "guided": false,
                        "gaDate": 1396540800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 686,
                        "version": "6.0.4",
                        "guided": false,
                        "gaDate": 1397750400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 698,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1410364800000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_61.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 715,
                        "version": "6.0.5",
                        "guided": false,
                        "gaDate": 1404144000000,
                        "rnLink": "https://www.vmware.com/support/pubs/nsx_pubs.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 749,
                        "version": "6.0.6",
                        "guided": false,
                        "gaDate": 1410364800000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_606.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=417&rPId=6553&downloadGroup=NSX_V606",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 759,
                        "version": "6.0.7",
                        "guided": false,
                        "gaDate": 1412352000000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_607.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=417&rPId=6871&downloadGroup=NSX-V-607",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 760,
                        "version": "6.1.1",
                        "guided": false,
                        "gaDate": 1412352000000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_611.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=417&rPId=6871&downloadGroup=NSX-V-611",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 779,
                        "version": "6.1.2",
                        "guided": false,
                        "gaDate": 1417622400000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_612.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 780,
                        "version": "6.2",
                        "guided": false,
                        "gaDate": 1440000000000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_620.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 811,
                        "version": "6.1.3",
                        "guided": false,
                        "gaDate": 1427040000000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_613.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 880,
                        "version": "6.1.4",
                        "guided": false,
                        "gaDate": 1430928000000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_614.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 893,
                        "version": "6.2.1",
                        "guided": false,
                        "gaDate": 1450281600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/6.2.1/releasenotes_nsx_vsphere_621.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 912,
                        "version": "6.1.5",
                        "guided": false,
                        "gaDate": 1443628800000,
                        "rnLink": "https://www.vmware.com/support/nsx/doc/releasenotes_nsx_vsphere_615.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 959,
                        "version": "6.2.2",
                        "guided": false,
                        "gaDate": 1456848000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/6.2.2/releasenotes_nsx_vsphere_622.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 980,
                        "version": "6.1.6",
                        "guided": false,
                        "gaDate": 1456934400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/6.1/releasenotes_nsx_vsphere_616.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1079,
                        "version": "6.2.3",
                        "guided": false,
                        "gaDate": 1465401600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1080,
                        "version": "6.1.7",
                        "guided": false,
                        "gaDate": 1465401600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1734,
                        "version": "6.3.0",
                        "guided": true,
                        "gaDate": 1485964800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1914,
                        "version": "6.2.4",
                        "guided": false,
                        "gaDate": 1472054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2130,
                        "version": "6.2.5",
                        "guided": false,
                        "gaDate": 1483545600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2243,
                        "version": "6.2.6",
                        "guided": false,
                        "gaDate": 1485964800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2244,
                        "version": "6.2.7",
                        "guided": false,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2295,
                        "version": "6.3.2",
                        "guided": true,
                        "gaDate": 1496851200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/6.3.2/releasenotes_nsx_vsphere_632.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2297,
                        "version": "6.3.3",
                        "guided": true,
                        "gaDate": 1502294400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.3/rn/releasenotes_nsx_vsphere_633.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2323,
                        "version": "6.3.1",
                        "guided": true,
                        "gaDate": 1488211200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2349,
                        "version": "6.4.0",
                        "guided": true,
                        "gaDate": 1516032000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_640.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2436,
                        "version": "6.2.8",
                        "guided": false,
                        "gaDate": 1499270400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.2/rn/releasenotes_nsx_vsphere_628.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2528,
                        "version": "6.2.9",
                        "guided": false,
                        "gaDate": 1508947200000,
                        "rnLink": "http://tpub-stg-ic-1.eng.vmware.com:8080/Release_Notes/en/nsx/629/VMware-NSX-for-vSphere-629-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2532,
                        "version": "6.3.5",
                        "guided": true,
                        "gaDate": 1510761600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.3/rn/releasenotes_nsx_vsphere_635.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2581,
                        "version": "6.3.4",
                        "guided": true,
                        "gaDate": 1507737600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.3/rn/releasenotes_nsx_vsphere_634.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/networking_security/vmware_nsx/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2739,
                        "version": "6.4.2",
                        "guided": true,
                        "gaDate": 1534780800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_642.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx_data_center_for_vsphere/6_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2746,
                        "version": "6.3.6",
                        "guided": true,
                        "gaDate": 1522252800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2778,
                        "version": "6.4.1",
                        "guided": true,
                        "gaDate": 1527091200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_641.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=NSXV_641_OSS&productId=417",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2979,
                        "version": "6.3.7",
                        "guided": true,
                        "gaDate": 1542211200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.3/rn/releasenotes_nsx_vsphere_637.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/details?productId=417&rPId=28721&downloadGroup=NSXV_637",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2990,
                        "version": "6.4.3",
                        "guided": true,
                        "gaDate": 1536595200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_643.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=NSXV_643_OSS&productId=417",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3010,
                        "version": "6.4.4",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-Data-Center-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_644.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=NSXV_644_OSS&productId=417",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3225,
                        "version": "6.4.5",
                        "guided": true,
                        "gaDate": 1555516800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-Data-Center-for-vSphere/6.4/rn/releasenotes_nsx_vsphere_645.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=NSXV_645_OSS&productId=417",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3608,
                        "version": "6.4.6",
                        "guided": true,
                        "gaDate": 1570636800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 95,
                "name": "VMware vCenter Hyperic",
                "childId": 111,
                "parentId": 46,
                "releases": [
                    {
                        "id": 590,
                        "version": "5.8.0",
                        "guided": true,
                        "gaDate": 1386604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 639,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes_hyperic581.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_hyperic/5_8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 656,
                        "version": "5.8.2",
                        "guided": true,
                        "gaDate": 1403539200000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes_hyperic582.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_hyperic/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 735,
                        "version": "5.8.3",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091109",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_hyperic/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 98,
                "name": "VMware Horizon DaaS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 637,
                        "version": "6.0.0",
                        "guided": false,
                        "gaDate": 1395936000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 676,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=HORIZON-DAAS-610-DOC&productId=405&rPId=6527",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 677,
                        "version": "6.0.1",
                        "guided": false,
                        "gaDate": 1402502400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=HORIZON-DAAS-601&productId=405&rPId=5946",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 863,
                        "version": "6.1.3",
                        "guided": false,
                        "gaDate": 1421683200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 896,
                        "version": "6.1.4",
                        "guided": false,
                        "gaDate": 1428508800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 897,
                        "version": "6.1.5",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=HORIZON-DAAS-610-BIN&productId=405&rPId=6527",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 965,
                        "version": "6.1.6",
                        "guided": true,
                        "gaDate": 1467820800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2152,
                        "version": "7.0.0",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/horizon-daas-platform/horizon-daas-platform-70-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_daas/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2710,
                        "version": "8.0.0",
                        "guided": true,
                        "gaDate": 1524067200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3304,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1557936000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3970,
                        "version": "9.0.0",
                        "guided": true,
                        "gaDate": 1588780800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 101,
                "name": "VMware  vCenter Support Assistant",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 660,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1390233600000,
                        "rnLink": "https://www.vmware.com/support/support-assistant/doc/vcenter-support-assistant-55-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCSA550&productId=353",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 661,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 702,
                        "version": "5.5.1",
                        "guided": true,
                        "gaDate": 1401120000000,
                        "rnLink": "https://www.vmware.com/support/support-assistant/doc/vcenter-support-assistant-55-1-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VCSA551&productId=351",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 786,
                        "version": "6.0.0",
                        "guided": true,
                        "gaDate": 1428508800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1094,
                        "version": "6.0.2",
                        "guided": true,
                        "gaDate": 1468857600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2111,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1485964800000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCSA650&productId=614",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 103,
                "name": "VMware Horizon View Clients",
                "childId": 108,
                "parentId": null,
                "releases": [
                    {
                        "id": 674,
                        "version": null,
                        "guided": true,
                        "gaDate": 1384358400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 675,
                        "version": null,
                        "guided": true,
                        "gaDate": 1390406400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 104,
                "name": "VMware Horizon vRO Plugin",
                "childId": 311,
                "parentId": null,
                "releases": [
                    {
                        "id": 709,
                        "version": "Horizon vCO Plugin",
                        "guided": true,
                        "gaDate": 1403107200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 744,
                        "version": "Horizon vCO Plugin 1.1",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 832,
                        "version": "Horizon vRO Plugin 1.2",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1022,
                        "version": "VR-O Plug-in for View 1.3.0",
                        "guided": true,
                        "gaDate": 1464624000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 105,
                "name": "VMware Horizon Workspace Portal",
                "childId": 140,
                "parentId": 79,
                "releases": [
                    {
                        "id": 711,
                        "version": "2.0.0",
                        "guided": false,
                        "gaDate": 1403107200000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/wp_release_notes_20.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=HZNWS200&productId=419&rPId=5757",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 743,
                        "version": "2.1.0",
                        "guided": false,
                        "gaDate": 1412092800000,
                        "rnLink": "http://kb.vmware.com/selfservice/microsites/search.do?language=en_US&cmd=displayKC&externalId=2091067",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=HZNP210&productId=419&rPId=6533",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 840,
                        "version": "2.1.1",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/horizon_workspace/doc/wp_release_notes_211.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_workspace/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 106,
                "name": "vSphere PowerCLI",
                "childId": 299,
                "parentId": null,
                "releases": [
                    {
                        "id": 716,
                        "version": "5.5.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 717,
                        "version": "5.1.0",
                        "guided": true,
                        "gaDate": 1347206400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 718,
                        "version": "5.0.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 719,
                        "version": "4.1.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 740,
                        "version": "5.8.0",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": "https://www.vmware.com/support/developer/PowerCLI/PowerCLI58R1/powercli58r1-releasenotes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=PCLI58R1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 851,
                        "version": "6.0.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 950,
                        "version": "6.3.0",
                        "guided": true,
                        "gaDate": 1458144000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/powercli/63r1/powercli63r1-releasenotes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=PCLI630R1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 107,
                "name": "vSphere PowerCLI for Tenants",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 731,
                        "version": null,
                        "guided": true,
                        "gaDate": 1362931200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 732,
                        "version": null,
                        "guided": true,
                        "gaDate": 1328889600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 741,
                        "version": null,
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 108,
                "name": "VMware Horizon Clients",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4138,
                        "version": "5.4.1",
                        "guided": true,
                        "gaDate": 1584979200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-Client-for-Android/5.4.1/rn/horizon-client-android-541-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_clients/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4150,
                        "version": "5.4.2",
                        "guided": true,
                        "gaDate": 1585843200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4674,
                        "version": "5.4.3",
                        "guided": true,
                        "gaDate": 1591632000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 736,
                        "version": "2.4.0",
                        "guided": true,
                        "gaDate": 1403107200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 737,
                        "version": "3.0.0",
                        "guided": true,
                        "gaDate": 1403107200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 738,
                        "version": "3.1.0",
                        "guided": true,
                        "gaDate": 1410192000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 824,
                        "version": "3.2.0",
                        "guided": true,
                        "gaDate": 1418918400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 842,
                        "version": "3.3.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 875,
                        "version": "3.4.0",
                        "guided": true,
                        "gaDate": 1433347200000,
                        "rnLink": "https://www.vmware.com/support/viewclients/doc/viewclients_pubs.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon_clients/3_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 889,
                        "version": "3.5.0",
                        "guided": true,
                        "gaDate": 1441209600000,
                        "rnLink": "https://www.vmware.com/support/viewclients/doc/viewclients_pubs.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_horizon_clients/3_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1019,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1458144000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1037,
                        "version": "4.1.0",
                        "guided": true,
                        "gaDate": 1466006400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_clients/4_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1041,
                        "version": "4.0.1",
                        "guided": true,
                        "gaDate": 1459353600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1086,
                        "version": "4.2.0",
                        "guided": true,
                        "gaDate": 1473868800000,
                        "rnLink": "https://www.vmware.com/support/viewclients/doc/viewclients_pubs.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1672,
                        "version": "3.5.1",
                        "guided": true,
                        "gaDate": 1443628800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1676,
                        "version": "3.5.2",
                        "guided": true,
                        "gaDate": 1445875200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2114,
                        "version": "4.2.1",
                        "guided": true,
                        "gaDate": 1475510400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2144,
                        "version": "4.3.0",
                        "guided": true,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2222,
                        "version": "4.4.0",
                        "guided": true,
                        "gaDate": 1489593600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2393,
                        "version": "4.5.0",
                        "guided": true,
                        "gaDate": 1496851200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2482,
                        "version": "4.6.0",
                        "guided": true,
                        "gaDate": 1505923200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2570,
                        "version": "4.7.0",
                        "guided": true,
                        "gaDate": 1514995200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2578,
                        "version": "4.6.1",
                        "guided": true,
                        "gaDate": 1506960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2749,
                        "version": "4.8.0",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2904,
                        "version": "4.9.0",
                        "guided": true,
                        "gaDate": 1536163200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2920,
                        "version": "4.8.1",
                        "guided": true,
                        "gaDate": 1533571200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2982,
                        "version": "4.10.0",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3249,
                        "version": "5.0.0",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3465,
                        "version": "5.1.0",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3605,
                        "version": "5.2.0",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3664,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": 1563379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3741,
                        "version": "5.3.0",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3843,
                        "version": "5.3.1",
                        "guided": true,
                        "gaDate": 1579017600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3861,
                        "version": "5.2.1",
                        "guided": true,
                        "gaDate": 1572451200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3925,
                        "version": "5.4.0",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 110,
                "name": "VMware vRealize Code Stream",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 784,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/codestream/doc/vrealize-code-stream-10-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRCS-100&productId=472&rPId=7137",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 837,
                        "version": "1.1.0",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/codestream/doc/vrealize-code-stream-10-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_code_stream/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 909,
                        "version": "1.2.0",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "https://www.vmware.com/support/codestream/doc/vrealize-code-stream-12-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_code_stream/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 949,
                        "version": "2.0.0",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrcs/vrealize-code-stream-20-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_code_stream/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1062,
                        "version": "2.1.0",
                        "guided": false,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrcs/vrealize-code-stream-21-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_code_stream/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2087,
                        "version": "2.2.0",
                        "guided": false,
                        "gaDate": 1483200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2221,
                        "version": "2.3",
                        "guided": false,
                        "gaDate": 1494864000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrcs/vrealize-code-stream-23-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_code_stream/2_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2541,
                        "version": "2.4",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2729,
                        "version": "2.3.1",
                        "guided": false,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2787,
                        "version": "2.4.1",
                        "guided": false,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3441,
                        "version": "2.4.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 111,
                "name": "VMware vRealize Hyperic",
                "childId": null,
                "parentId": 95,
                "releases": [
                    {
                        "id": 789,
                        "version": "5.8.4",
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vfabric-hyperic/doc/releasenotes_hyperic584.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_hyperic/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 904,
                        "version": "5.8.5",
                        "guided": true,
                        "gaDate": 1449504000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1072,
                        "version": "5.8.6",
                        "guided": true,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/hyperic/586/releasenotes_hyperic586.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_hyperic/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 112,
                "name": "VMware vRealize Business Standard",
                "childId": 243,
                "parentId": null,
                "releases": [
                    {
                        "id": 791,
                        "version": "6.0.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vrb-std/doc/vrealize-business-standard-60-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRB-STD-600&productId=470&rPId=7138",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 831,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vrb-std/doc/vrealize-business-standard-61-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRB-STD-610&productId=470&rPId=7479",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 871,
                        "version": "6.1.1",
                        "guided": false,
                        "gaDate": 1433779200000,
                        "rnLink": "https://www.vmware.com/support/vrb-std/doc/vrealize-business-standard-611-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=470&rPId=8212&downloadGroup=VRB-STD-611",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 892,
                        "version": "6.2.3",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRealizeBusinessStandard/623/vrealize-business-standard-623-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=470&rPId=8854&downloadGroup=VRB-STD-623",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 932,
                        "version": "7.0.0",
                        "guided": false,
                        "gaDate": 1450281600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRealizeBusinessStandard/70/vrealize-business-standard-70-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 113,
                "name": "VMware vRealize Business Adv/Ent",
                "childId": 244,
                "parentId": null,
                "releases": [
                    {
                        "id": 467,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1386604800000,
                        "rnLink": "https://www.vmware.com/support/itbms/doc/itbm-standard-edition-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/it_business_management/vmware_it_business_management_suite/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 607,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1390838400000,
                        "rnLink": "https://www.vmware.com/support/itbmae/doc/itbm-advanced-and-enterprise-edition-801-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/it_business_management/vmware_it_business_management_suite/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 714,
                        "version": "8.0.2",
                        "guided": true,
                        "gaDate": 1405958400000,
                        "rnLink": "https://www.vmware.com/support/itbmae/doc/itbm-advanced-and-enterprise-edition-802-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/it_business_management/vmware_it_business_management_suite/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 790,
                        "version": "8.2.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vrb-ae/doc/vrealizebusiness-advanced-and-enterprise-82-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRB-ENT-820&productId=470&rPId=7139",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 830,
                        "version": "8.2.1",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vrb-ae/doc/vrealizebusiness-advanced-and-enterprise-821-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 870,
                        "version": "8.2.2",
                        "guided": false,
                        "gaDate": 1433779200000,
                        "rnLink": "https://www.vmware.com/support/vrb-ae/doc/vrealizebusiness-advanced-and-enterprise-822-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 917,
                        "version": "8.2.3",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRealizeBusinessAE/823/vrealizebusiness-advanced-and-enterprise-823-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=470&rPId=8855&downloadGroup=VRB-ADV-823",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 973,
                        "version": "8.2.4",
                        "guided": false,
                        "gaDate": 1450281600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRealizeBusinessAE/824/vrealizebusiness-advanced-and-enterprise-824-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 114,
                "name": "VMware vRealize Automation",
                "childId": null,
                "parentId": 74,
                "releases": [
                    {
                        "id": 799,
                        "version": "6.2",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vrealize-automation-62-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 816,
                        "version": "7.0.0",
                        "guided": false,
                        "gaDate": 1450281600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-automation-70-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 827,
                        "version": "6.2.1",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vrealize-automation-621-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 861,
                        "version": "6.2.2",
                        "guided": false,
                        "gaDate": 1433779200000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vrealize-automation-622-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 884,
                        "version": "6.2.3",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "https://www.vmware.com/support/vcac/doc/vrealize-automation-623-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 940,
                        "version": "7.0.1",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-automation-701-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 961,
                        "version": "6.2.4",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-automation-624-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_automation/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1044,
                        "version": "7.1.0",
                        "guided": true,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-automation-71-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1053,
                        "version": "6.2.5",
                        "guided": false,
                        "gaDate": 1479312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2072,
                        "version": "7.2.0",
                        "guided": true,
                        "gaDate": 1479744000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2198,
                        "version": "7.3.0",
                        "guided": true,
                        "gaDate": 1494864000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2433,
                        "version": "7.4.0",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2705,
                        "version": "7.3.1",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2814,
                        "version": "7.5.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3291,
                        "version": "7.6.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3331,
                        "version": "8.0.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3917,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3968,
                        "version": "8.1.0",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 115,
                "name": "VMware vRealize Automation Application Services",
                "childId": null,
                "parentId": 65,
                "releases": [
                    {
                        "id": 800,
                        "version": "6.2",
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 975,
                        "version": "6.2.4",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 116,
                "name": "VMware vRealize Operations Manager",
                "childId": null,
                "parentId": 35,
                "releases": [
                    {
                        "id": 801,
                        "version": "6.0.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vrops/doc/vrops-60-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 817,
                        "version": "6.0.1",
                        "guided": false,
                        "gaDate": 1424880000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 818,
                        "version": "6.1",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/61/vrops-61-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROPS-610&productId=538&rPId=8817#errorCheckDiv",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 873,
                        "version": "6.0.2",
                        "guided": false,
                        "gaDate": 1433779200000,
                        "rnLink": "https://www.vmware.com/support/vrops/doc/vrops-602-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 898,
                        "version": "6.0.3",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 943,
                        "version": "6.2",
                        "guided": false,
                        "gaDate": 1453910400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/62/vrops-62-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VROPS-620",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1017,
                        "version": "6.2.1",
                        "guided": false,
                        "gaDate": 1461600000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/62/vrops-621-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VROPS-621",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1018,
                        "version": "6.3",
                        "guided": false,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/63/vrops-63-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/6_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2071,
                        "version": "6.4",
                        "guided": false,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/64/vrops-64-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VROPS-640&productId=612&rPId=13291",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2193,
                        "version": "6.5",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/65/vrops-65-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VROPS-650",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2200,
                        "version": "6.6",
                        "guided": false,
                        "gaDate": 1497283200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vrops/66/vrops-66-release-notes.html?__utma=207178772.1971027777.1487808956.1497607926.1498241877.22&__utmb=207178772.2.10.1498241877&__utmc=207178772&__utmx=-&__utmz=207178772.1498241877.22.19.utmcsr=my.vmware.com|utmccn=(referral)|utmcmd=referral|utmcct=/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/6_6&__utmv=-&__utmk=111326983",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VROPS-660&productId=656&rPId=16752",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2461,
                        "version": "6.3.1",
                        "guided": false,
                        "gaDate": 1496246400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2480,
                        "version": "6.7",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2497,
                        "version": "6.6.1",
                        "guided": true,
                        "gaDate": 1502121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2803,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Operations-Manager/7.0/rn/vRealize-Operations-Manager-70.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3026,
                        "version": "7.5",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3477,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Operations-Manager/8.0/rn/vRealize-Operations-Manager-80.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3834,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Operations-Manager/8.1/rn/vRealize-Operations-Manager-81.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3906,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 117,
                "name": "VMware vRealize Orchestrator",
                "childId": null,
                "parentId": 13,
                "releases": [
                    {
                        "id": 808,
                        "version": "6.0.1",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "http://vmware.com/support/orchestrator/doc/vrealize-orchestrator-601-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROVA_601&productId=491&rPId=7503",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 864,
                        "version": "6.0.0",
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 881,
                        "version": "6.0.2",
                        "guided": true,
                        "gaDate": 1430755200000,
                        "rnLink": "https://www.vmware.com/support/orchestrator/doc/vcenter-orchestrator-602-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VROVA602",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 900,
                        "version": "6.0.3",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "https://www.vmware.com/support/orchestrator/doc/vrealize-orchestrator-603-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VROVA603",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 934,
                        "version": "7.0.0",
                        "guided": false,
                        "gaDate": 1450281600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/orchestrator/vrealize-orchestrator-70-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROVA_700&productId=561&rPId=9867",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 996,
                        "version": "6.0.4",
                        "guided": true,
                        "gaDate": 1457539200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/orchestrator/vrealize-orchestrator-604-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROVA_604&productId=491&rPId=10254",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 997,
                        "version": "7.0.1",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/orchestrator/vrealize-orchestrator-701-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROVA_701&productId=491&rPId=10254",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1028,
                        "version": "7.1.0",
                        "guided": false,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/orchestrator/vrealize-orchestrator-71-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VROVA_710&productId=601&rPId=12504",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1909,
                        "version": "6.0.5",
                        "guided": true,
                        "gaDate": 1479312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2074,
                        "version": "7.2.0",
                        "guided": false,
                        "gaDate": 1479744000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2199,
                        "version": "7.3.0",
                        "guided": true,
                        "gaDate": 1494864000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2434,
                        "version": "7.4.0",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2715,
                        "version": "7.3.1",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2813,
                        "version": "7.5.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3290,
                        "version": "7.6.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3518,
                        "version": "8.0.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3918,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3969,
                        "version": "8.1.0",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 118,
                "name": "VMware vCenter Host Gateway",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 809,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vsphere6/doc/vsphere-esxi-vcenter-server-60-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VC600&productId=491&rPId=7501#",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 121,
                "name": "VMware vRealize Configuration Manager",
                "childId": null,
                "parentId": 25,
                "releases": [
                    {
                        "id": 815,
                        "version": "5.7.3",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vrealize-configuration-manager-573-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VCM_573&productId=445&rPId=7194",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 860,
                        "version": "5.8",
                        "guided": true,
                        "gaDate": 1433779200000,
                        "rnLink": "https://www.vmware.com/support/vcm/doc/vrealize-configuration-manager-58-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=datacenter_downloads/vmware_vcenter_configuration_manager/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 899,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1442332800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcm/581/vrealize-configuration-manager-581-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 971,
                        "version": "5.8.2",
                        "guided": true,
                        "gaDate": 1457539200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcm/582/vrealize-configuration-manager-582-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations_insight/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2088,
                        "version": "5.8.3",
                        "guided": true,
                        "gaDate": 1480521600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2496,
                        "version": "5.8.4",
                        "guided": true,
                        "gaDate": 1516204800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2932,
                        "version": "5.8.5",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Configuration-Manager/5.8.5/rn/VMware-vRealize-Configuration-Manager-585-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_configuration_manager/5_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 123,
                "name": "VMware vRealize Operations for Horizon",
                "childId": null,
                "parentId": 58,
                "releases": [
                    {
                        "id": 821,
                        "version": "6.0.0",
                        "guided": false,
                        "gaDate": 1418054400000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vrealize-horizon-60-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VROPS-HZN-600&productId=475&rPId=7313",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 848,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1416499200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 931,
                        "version": "6.2.0",
                        "guided": false,
                        "gaDate": 1449504000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4h/62/vrealize-horizon-62-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_vrealize_operations_for_horizon_and_published_applications/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1004,
                        "version": "6.2.1",
                        "guided": false,
                        "gaDate": 1458144000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4h/621/vrealize-horizon-621-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_vrealize_operations_for_horizon_and_published_applications/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1047,
                        "version": "6.3.0",
                        "guided": false,
                        "gaDate": 1466006400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4h/63/vrealize-horizon-63-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=V4H-630-GA&productId=475&rPId=11735",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2085,
                        "version": "6.4.0",
                        "guided": false,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2471,
                        "version": "6.5.0",
                        "guided": false,
                        "gaDate": 1505923200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2596,
                        "version": "6.5.1",
                        "guided": false,
                        "gaDate": 1514995200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2903,
                        "version": "6.6.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Operations-for-Horizon/6.6/rn/vrealize-horizon-66-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_vrealize_operations_for_horizon_and_published_applications/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3696,
                        "version": "6.7.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3904,
                        "version": "6.7.1",
                        "guided": true,
                        "gaDate": 1581955200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3905,
                        "version": "6.6.1",
                        "guided": true,
                        "gaDate": 1581955200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 124,
                "name": "VMware vRealize Infrastructure Navigator",
                "childId": null,
                "parentId": 53,
                "releases": [
                    {
                        "id": 829,
                        "version": "5.8.4",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vin/doc/releasenotes-vin584.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vcenter_infrastructure_navigator/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 946,
                        "version": "5.8.5",
                        "guided": false,
                        "gaDate": 1454947200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1073,
                        "version": "5.8.6",
                        "guided": false,
                        "gaDate": 1464796800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vin/586/releasenotes-vin586.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_operations/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1789,
                        "version": "5.8.7",
                        "guided": false,
                        "gaDate": 1473091200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 640,
                "name": "VMware Smart Assurance",
                "childId": null,
                "parentId": 517,
                "releases": [
                    {
                        "id": 3758,
                        "version": "10.1",
                        "guided": true,
                        "gaDate": 1567440000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Smart-Assurance/10.1/rn/smart-assurance-101-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_smart_assurance/10_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 131,
                "name": "VMware App Volumes",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4635,
                        "version": "2.18.4",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 858,
                        "version": "2.7.0",
                        "guided": false,
                        "gaDate": 1430150400000,
                        "rnLink": "https://www.vmware.com/support/appvolumes/doc/app-volumes-27-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=AV-250-GA&productId=479&rPId=7314",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 859,
                        "version": "2.6.0",
                        "guided": false,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/appvolumes/doc/app-volumes-26-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=AV-250-GA&productId=479&rPId=7314",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 876,
                        "version": "2.9",
                        "guided": false,
                        "gaDate": 1434297600000,
                        "rnLink": "https://www.vmware.com/support/appvolumes/doc/app-volumes-29-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 890,
                        "version": "2.10",
                        "guided": false,
                        "gaDate": 1448294400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/appvolumes/2.10/app-volumes-210-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=AV-210&productId=534&rPId=9639",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1008,
                        "version": "3.0",
                        "guided": false,
                        "gaDate": 1458576000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/appvolumes/3/app-volumes-300-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/3_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1067,
                        "version": "2.11.0",
                        "guided": false,
                        "gaDate": 1466006400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/appvolumes/2.11/app-volumes-211-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1782,
                        "version": "2.12.0",
                        "guided": false,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2231,
                        "version": "2.12.1",
                        "guided": false,
                        "gaDate": 1491840000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/appvolumes/2.12/app-volumes-2121-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2381,
                        "version": "2.13.0",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": "https://docs.vmware.com/en/App-Volumes/2.13/rn/app-volumes-213-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2602,
                        "version": "2.13.1",
                        "guided": true,
                        "gaDate": 1509552000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.13.1/rn/app-volumes-2131-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2701,
                        "version": "2.13.2",
                        "guided": true,
                        "gaDate": 1516636800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.13.2/rn/app-volumes-2132-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2752,
                        "version": "2.14.0",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.14/rn/VMware-App-Volumes-214-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2784,
                        "version": "2.13.3",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.13.3/rn/VMware-App-Volumes-2133-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2924,
                        "version": "2.14.2",
                        "guided": true,
                        "gaDate": 1536163200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.14.2/rn/VMware-App-Volumes-2142-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3001,
                        "version": "2.15.0",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.15/rn/VMware-App-Volumes-215-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_app_volumes/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3224,
                        "version": "2.16.0",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3283,
                        "version": "2.14.3",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.14.3/rn/VMware-App-Volumes-2143.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=534&rPId=29613&downloadGroup=AV-2143",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3444,
                        "version": "2.17.0",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3467,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1578931200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/4.0/rn/VMware-App-Volumes-4-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_app_volumes/4_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3646,
                        "version": "2.18.0",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3686,
                        "version": "2.14.8",
                        "guided": true,
                        "gaDate": 1572278400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3752,
                        "version": "2.17.1",
                        "guided": true,
                        "gaDate": 1569254400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-App-Volumes/2.17.1/rn/VMware-App-Volumes-2171-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=534&rPId=36599&downloadGroup=AV-2171",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3992,
                        "version": "4.0.1",
                        "guided": true,
                        "gaDate": 1583942400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4007,
                        "version": "2.18.2",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4031,
                        "version": "2.18.1",
                        "guided": true,
                        "gaDate": 1581955200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 644,
                "name": "VMware Tanzu Kubernetes Grid Integrated Edition",
                "childId": null,
                "parentId": 549,
                "releases": [
                    {
                        "id": 4122,
                        "version": "1.8.0",
                        "guided": true,
                        "gaDate": 1593446400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 133,
                "name": "VMware User Environment Manager",
                "childId": 512,
                "parentId": null,
                "releases": [
                    {
                        "id": 869,
                        "version": "8.6.0",
                        "guided": false,
                        "gaDate": 1429545600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 920,
                        "version": "8.7.0",
                        "guided": false,
                        "gaDate": 1441209600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/uem/87/uem-870-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_user_environment_manager/8_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1007,
                        "version": "9.0",
                        "guided": false,
                        "gaDate": 1458144000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/uem/9.0/uem-90-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1730,
                        "version": "9.1.0",
                        "guided": false,
                        "gaDate": 1473868800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/uem/9.1/uem-91-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2242,
                        "version": "9.2.0",
                        "guided": false,
                        "gaDate": 1494864000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/uem/9.2/uem-92-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2484,
                        "version": "9.2.1",
                        "guided": false,
                        "gaDate": 1505750400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.2.1/rn/VMware-User-Environment-Manager-Release-Notes-921.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2583,
                        "version": "9.3.0",
                        "guided": true,
                        "gaDate": 1514995200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.3.0/rn/VMware-User-Environment-Manager-Release-Notes-930.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2724,
                        "version": "9.4.0",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.4.0/rn/VMware-User-Environment-Manager-Release-Notes-940.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2872,
                        "version": "9.5.0",
                        "guided": true,
                        "gaDate": 1536163200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.5.0/rn/VMware-User-Environment-Manager-Release-Notes-950.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2984,
                        "version": "9.6.0",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.6.0/rn/VMware-User-Environment-Manager-Release-Notes-960.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3053,
                        "version": "9.4.1",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3248,
                        "version": "9.7.0",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3424,
                        "version": "9.5.1",
                        "guided": true,
                        "gaDate": 1552320000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.2.2/rn/VMware-User-Environment-Manager-Release-Notes-922.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=UEM-922&productId=861&rPId=31121",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3425,
                        "version": "9.3.1",
                        "guided": true,
                        "gaDate": 1552320000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3426,
                        "version": "9.2.2",
                        "guided": true,
                        "gaDate": 1552320000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3464,
                        "version": "9.8.0",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 134,
                "name": "vRealize Production Test",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 874,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1429804800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 882,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1432137600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 939,
                        "version": "1.4.0",
                        "guided": true,
                        "gaDate": 1450281600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1005,
                        "version": "1.5.0",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2033,
                        "version": "1.6.0",
                        "guided": true,
                        "gaDate": 1471881600000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=468&downloadGroup=VRPT_160",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2145,
                        "version": "1.7.0",
                        "guided": true,
                        "gaDate": 1483545600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2430,
                        "version": "1.7.1",
                        "guided": true,
                        "gaDate": 1497283200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/get-download?downloadGroup=VRPT_171",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 647,
                "name": "VMware Site Recovery Manager",
                "childId": null,
                "parentId": 505,
                "releases": [
                    {
                        "id": 80,
                        "version": "4.0",
                        "guided": false,
                        "gaDate": 1254672000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 81,
                        "version": "4.1",
                        "guided": false,
                        "gaDate": 1278950400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 164,
                        "version": "5.0",
                        "guided": false,
                        "gaDate": 1315929600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 182,
                        "version": "4.1.1",
                        "guided": false,
                        "gaDate": 1297267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 184,
                        "version": "4.0.3",
                        "guided": false,
                        "gaDate": 1304524800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 250,
                        "version": "4.1.2",
                        "guided": false,
                        "gaDate": 1319644800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 260,
                        "version": "5.0.1",
                        "guided": false,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 263,
                        "version": "5.1.0",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-1-0.html",
                        "dLink": "http://downloads.vmware.com/d/info/infrastructure_operations_management/vmware_vcenter_site_recovery_manager/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 356,
                        "version": "5.0.2",
                        "guided": false,
                        "gaDate": 1355932800000,
                        "rnLink": "https://www.vmware.com/support/srm/srm-releasenotes-5-0-2.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=SRM502&productId=238&rPId=3341",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 423,
                        "version": "5.5.0",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "http://vmware.com/support/srm/srm-releasenotes-5-5-0.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=infrastructure_operations_management/vmware_vcenter_site_recovery_manager/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 437,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-1-1html",
                        "dLink": "http://downloads.vmware.com/d/info/infrastructure_operations_management/vmware_vcenter_site_recovery_manager/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 479,
                        "version": "5.0.3",
                        "guided": false,
                        "gaDate": 1381939200000,
                        "rnLink": "https://www.vmware.com/support/srm/srm-releasenotes-5-0-3.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=SRM503",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 501,
                        "version": "5.8.0",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-8-0.html",
                        "dLink": "https://www.vmware.com/group/vmware/info?slug=infrastructure_operations_management/vmware_vcenter_site_recovery_manager/5_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 599,
                        "version": "5.1.2",
                        "guided": false,
                        "gaDate": 1389801600000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-1-2.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=SRM512",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 621,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-5-1.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=infrastructure_operations_management/vmware_vcenter_site_recovery_manager/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 688,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/srm/srm-releasenotes-6-0-0.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=SRM60&productId=489&rPId=7560",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 755,
                        "version": "5.1.3",
                        "guided": false,
                        "gaDate": 1417622400000,
                        "rnLink": "http://www.vmware.com/support/srm/srm-releasenotes-5-1-3.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=SRM513",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 825,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/srm/61/srm-releasenotes-6-1-0.html?__utma=207178772.1981082503.1391456634.1435477565.1441901949.6&__utmb=207178772.3.10.1441901949&__utmc=207178772&__utmx=-&__utmz=207178772.1441901949.6.2.utmcsr=vmware.com|utmccn=(referral)|utmcmd=referral|utmcct=/products/vsphere/&__utmv=-&__utmk=45192359",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_site_recovery_manager/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 828,
                        "version": "5.8.1",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/srm/58/srm-releasenotes-5-8-1.html",
                        "dLink": "https://my.vmware.com/web/vmware/get-download?downloadGroup=SRM581",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1057,
                        "version": "6.1.1",
                        "guided": true,
                        "gaDate": 1464192000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/srm/61/srm-releasenotes-6-1-1.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=SRM611&productId=526&rPId=11051",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1656,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/srm/65/srm-releasenotes-6-5.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=SRM65&productId=617&rPId=13478",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2179,
                        "version": "6.1.2",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsphere-replication/612/vsphere-replication-612-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VR612&productId=569&rPId=14659https://my.vmware.com/web/vmware/details?downloadGroup=VR612&productId=569&rPId=14659",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2372,
                        "version": "6.5.1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": "https://docs.vmware.com/en/Site-Recovery-Manager/6.5/rn/srm-releasenotes-6-5-1.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_site_recovery_manager/6_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2598,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1510675200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2721,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2928,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/infrastructure_operations_management/vmware_site_recovery_manager/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2961,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": "https://docs.vmware.com/en/Site-Recovery-Manager/8.1/rn/srm-releasenotes-8-1-1.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_site_recovery_manager/8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3332,
                        "version": "8.1.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/Site-Recovery-Manager/8.1/rn/srm-releasenotes-8-1-2.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_site_recovery_manager/8_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3732,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 135,
                "name": "VMware vRealize Operations for Published Applications",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 878,
                        "version": "6.1.0",
                        "guided": false,
                        "gaDate": 1433952000000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vrealize-horizon-61-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/horizon_application_management_bundle/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 924,
                        "version": "6.2.0",
                        "guided": false,
                        "gaDate": 1449504000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4pa/62/vrealize-operations-for-published-applications62-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_vrealize_operations_for_horizon_and_published_applications/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1003,
                        "version": "6.2.1",
                        "guided": false,
                        "gaDate": 1458144000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4pa/621/vrealize-operations-for-published-applications621-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_vrealize_operations_for_horizon_and_published_applications/6_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1046,
                        "version": "6.3.0",
                        "guided": false,
                        "gaDate": 1466006400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/v4pa/63/vrealize-operations-for-published-applications63-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=V4PA-630-GA&productId=475&rPId=11735",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1785,
                        "version": "6.1.1",
                        "guided": false,
                        "gaDate": 1473091200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2086,
                        "version": "6.4.0",
                        "guided": false,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2472,
                        "version": "6.5.0",
                        "guided": false,
                        "gaDate": 1505923200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2597,
                        "version": "6.5.1",
                        "guided": true,
                        "gaDate": 1514995200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 648,
                "name": "VMware Cloud on AWS",
                "childId": null,
                "parentId": 279,
                "releases": [
                    {
                        "id": 4099,
                        "version": "1.10 V2",
                        "guided": true,
                        "gaDate": 1587312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4297,
                        "version": "1.11",
                        "guided": true,
                        "gaDate": 1591200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4623,
                        "version": "1.10 V3",
                        "guided": true,
                        "gaDate": 1589385600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3564,
                        "version": "1.8",
                        "guided": true,
                        "gaDate": 1564675200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3745,
                        "version": "1.9",
                        "guided": true,
                        "gaDate": 1579104000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4027,
                        "version": "1.10",
                        "guided": true,
                        "gaDate": 1583769600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 136,
                "name": "VMware Virtual SAN Health Check Plug-in",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 879,
                        "version": "VSAN Health Check Plug-in 6.0.0",
                        "guided": true,
                        "gaDate": 1430755200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 908,
                        "version": "VSAN Health Check Plug-in 6.0.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 139,
                "name": "VMware Tools ",
                "childId": null,
                "parentId": 39,
                "releases": [
                    {
                        "id": 918,
                        "version": "10.0.0",
                        "guided": true,
                        "gaDate": 1441209600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 925,
                        "version": "10.0.5",
                        "guided": true,
                        "gaDate": 1448294400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vmwaretools/1005/vmware-tools-1005-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=datacenter_cloud_infrastructure/vmware_vsphere/6_0#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 962,
                        "version": "10.1.0",
                        "guided": true,
                        "gaDate": 1477324800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 982,
                        "version": "10.0.6",
                        "guided": true,
                        "gaDate": 1456934400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1060,
                        "version": "10.0.8",
                        "guided": true,
                        "gaDate": 1461168000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1082,
                        "version": "10.0.9",
                        "guided": true,
                        "gaDate": 1465401600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1705,
                        "version": "10.0.12",
                        "guided": true,
                        "gaDate": 1477324800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2308,
                        "version": "10.1.5",
                        "guided": true,
                        "gaDate": 1487779200000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vmwaretools/1010/vmware-tools-1015-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VMTOOLS1015&productId=491",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2319,
                        "version": "10.2.0",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.2/rn/vmware-tools-1020-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VMTOOLS1020&productId=614",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2321,
                        "version": "10.1.10",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.1/rn/vmware-tools-10110-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2514,
                        "version": "10.1.15",
                        "guided": true,
                        "gaDate": 1506355200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2698,
                        "version": "10.2.5",
                        "guided": true,
                        "gaDate": 1522252800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.2/rn/vmware-tools-1025-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VMTOOLS1025&productId=614",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2800,
                        "version": "10.3.0",
                        "guided": true,
                        "gaDate": 1531324800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.3/rn/vmware-tools-1030-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMTOOLS1030&productId=614",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2963,
                        "version": "10.3.5",
                        "guided": true,
                        "gaDate": 1541001600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.3/rn/vmware-tools-1035-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_0#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3206,
                        "version": "10.3.10",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.3/rn/vmware-tools-10310-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_0#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3343,
                        "version": "11.0.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/11.0.0/rn/VMware-Tools-1100-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_0#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3668,
                        "version": "10.3.20",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.3.20/rn/VMware-Tools-10320-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMTOOLS10320&productId=742",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3808,
                        "version": "11.0.5",
                        "guided": true,
                        "gaDate": 1578931200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/11.0/rn/VMware-Tools-1105-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/6_0#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3987,
                        "version": "11.1.0",
                        "guided": true,
                        "gaDate": 1588780800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/11.1/rn/VMware-Tools-1110-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMTOOLS1110&productId=974",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4074,
                        "version": "10.3.22",
                        "guided": true,
                        "gaDate": 1588780800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Tools/10.3/rn/VMware-Tools-10322-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMTOOLS10322&productId=974",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 140,
                "name": "VMware Identity Manager",
                "childId": 513,
                "parentId": 105,
                "releases": [
                    {
                        "id": 921,
                        "version": "2.4",
                        "guided": false,
                        "gaDate": 1441641600000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vidm_onPremise/24/vidm_release_notes_24.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_identity_manager/2_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 964,
                        "version": "2.4.1",
                        "guided": false,
                        "gaDate": 1447689600000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vidm_onPremise/24/vidm_release_notes_241.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIDM_ONPREM_2.4.1&productId=488&rPId=9602",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1009,
                        "version": "2.6",
                        "guided": false,
                        "gaDate": 1458144000000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vidm_onPremise/26/identitymanager_release_notes_26.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_identity_manager/2_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1091,
                        "version": "2.7.0",
                        "guided": true,
                        "gaDate": 1467129600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1706,
                        "version": "2.7",
                        "guided": false,
                        "gaDate": 1468252800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1777,
                        "version": "2.8",
                        "guided": false,
                        "gaDate": 1479312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2091,
                        "version": "2.7.1",
                        "guided": false,
                        "gaDate": 1473868800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vidm_onPremise/27/identitymanager_release_notes_271.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIDM_ONPREM_271&productId=591&rPId=12826",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2203,
                        "version": "2.9",
                        "guided": true,
                        "gaDate": 1493136000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2311,
                        "version": "2.8.1",
                        "guided": false,
                        "gaDate": 1487606400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2359,
                        "version": "2.9.1",
                        "guided": true,
                        "gaDate": 1495641600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2416,
                        "version": "2.8.2",
                        "guided": false,
                        "gaDate": 1494864000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2545,
                        "version": "3.0",
                        "guided": false,
                        "gaDate": 1505923200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-AirWatch/9.2/rn/VMware-Identity-Manager-Cloud-Release-Notes.html",
                        "dLink": "https://resources.air-watch.com/?page=1&requiredfields=contentType:Software&sort=relevance&version=9.2&partialfields=languageId:en&searchText=identity",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2557,
                        "version": "2.9.2",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Identity-Manager/2.9.2/rn/identitymanager_release_notes_292.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VIDM_ONPREM_292",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2592,
                        "version": "3.1",
                        "guided": false,
                        "gaDate": 1512576000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-AirWatch/9.2/rn/VMware-Identity-Manager-31-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_identity_manager/3_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2691,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Identity-Manager/3.2/rn/VMware-Identity-Manager-32-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_identity_manager/3_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2793,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1537200000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Identity-Manager/3.3/rn/VMware-Identity-Manager-33-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_identity_manager/3_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2836,
                        "version": "2.8.3",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3288,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1555344000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Identity-Manager/19.03/rn/VMware-Identity-Manager-1903-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_identity_manager/19_03",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3700,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1570032000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Identity-Manager/3.3/rn/VMware-Identity-Manager-331-Release-Note.html",
                        "dLink": "https://docs.vmware.com/en/VMware-Identity-Manager/3.3/3-3-1-upgrade-guide/GUID-DE88FAE6-AD91-48C8-8012-2C1D02742A4B.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4060,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-Access/3.3/rn/VMware-Identity-Manager-332-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 652,
                "name": "VMware Cloud on Dell EMC",
                "childId": null,
                "parentId": 544,
                "releases": [
                    {
                        "id": 3949,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1576512000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 142,
                "name": "VMware vRealize Log Insight Agent",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 954,
                        "version": null,
                        "guided": true,
                        "gaDate": 1402329600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 955,
                        "version": null,
                        "guided": true,
                        "gaDate": 1418054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 956,
                        "version": null,
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1001,
                        "version": null,
                        "guided": true,
                        "gaDate": 1456761600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1024,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457884800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1050,
                        "version": "vRealize Log Insight 3.6.0",
                        "guided": false,
                        "gaDate": 1470844800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/36/log-insight-36-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-360&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1065,
                        "version": null,
                        "guided": true,
                        "gaDate": 1465401600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1071,
                        "version": null,
                        "guided": true,
                        "gaDate": 1458489600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2054,
                        "version": "vRealize Log Insight 4.0.0",
                        "guided": false,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/40/log-insight-40-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-400&productId=616&rPId=13351",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2162,
                        "version": "vRealize Log Insight 4.3.0",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/43/log-insight-43-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/get-download?downloadGroup=VRLI-430&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2300,
                        "version": "vRealize Log Insight 4.5.0",
                        "guided": false,
                        "gaDate": 1497283200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "dLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2490,
                        "version": "vRealize Log Insight 4.6.0",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2558,
                        "version": "vRealize Log Insight 4.5.1",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2807,
                        "version": "vRealize Log Insight 4.7",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2842,
                        "version": "vRealize Log Insight 4.6.1",
                        "guided": false,
                        "gaDate": 1528128000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3088,
                        "version": "vRealize Log Insight 4.6.2",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.6/rn/vRealize-Log-Insight-462-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3097,
                        "version": "vRealize Log Insight 4.7.1",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.7.1/rn/VMware-vRealize-Log-Insight-471-Release-Notes-.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3214,
                        "version": "vRealize Log Insight 4.8",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.8/rn/vRealize-Log-Insight-48-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VRLI-480&productId=796&rPId=31973",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3529,
                        "version": "vRealize Log Insight 8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.0/rn/vRealize-Log-Insight-80.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info?slug=infrastructure_operations_management/vmware_vrealize_log_insight/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3873,
                        "version": "vRealize Log Insight 8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.1/com.vmware.log-insight.getting-started.doc/GUID-5F0ED330-158D-4F00-B579-F30136FDB42F.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 143,
                "name": "VMware vRealize Log Insight Importer",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1002,
                        "version": null,
                        "guided": true,
                        "gaDate": 1456761600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1025,
                        "version": null,
                        "guided": true,
                        "gaDate": 1457884800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1051,
                        "version": "vRealize Log Insight 3.6.0",
                        "guided": false,
                        "gaDate": 1470844800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/36/log-insight-36-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-360&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1066,
                        "version": null,
                        "guided": true,
                        "gaDate": 1465401600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2055,
                        "version": "vRealize Log Insight 4.0.0",
                        "guided": false,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/40/log-insight-40-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRLI-400&productId=616&rPId=13351",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2163,
                        "version": "vRealize Log Insight 4.3.0",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/43/log-insight-43-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/get-download?downloadGroup=VRLI-430&productId=598&rPId=12336",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2301,
                        "version": "vRealize Log Insight 4.5.0",
                        "guided": false,
                        "gaDate": 1497283200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "dLink": "http://pubs.vmware.com/Release_Notes/en/LogInsight/45/VMwarevRealizeLogInsight45ReleaseNotes.html",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2491,
                        "version": "vRealize Log Insight 4.6.0",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2808,
                        "version": "vRealize Log Insight 4.7",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2843,
                        "version": "vRealize Log Insight 4.6.1",
                        "guided": false,
                        "gaDate": 1528128000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3098,
                        "version": "vRealize Log Insight 4.7.1",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.7.1/rn/VMware-vRealize-Log-Insight-471-Release-Notes-.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3215,
                        "version": "vRealize Log Insight 4.8",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/4.8/rn/vRealize-Log-Insight-48-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VRLI-480&productId=796&rPId=31973",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3530,
                        "version": "vRealize Log Insight 8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.0/rn/vRealize-Log-Insight-80.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info?slug=infrastructure_operations_management/vmware_vrealize_log_insight/8_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3874,
                        "version": "vRealize Log Insight 8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Log-Insight/8.1/com.vmware.log-insight.getting-started.doc/GUID-5F0ED330-158D-4F00-B579-F30136FDB42F.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 144,
                "name": "VMware vCloud Air Hybrid Cloud Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 960,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1441641600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 970,
                        "version": "1.0.0 U1",
                        "guided": true,
                        "gaDate": 1448467200000,
                        "rnLink": "https://www.vmware.com/support/vca/doc/vca-hcm-v1-0u1-rel-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=HCM100U1&productId=343",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1088,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1473609600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2207,
                        "version": "2.0 U1",
                        "guided": true,
                        "gaDate": 1481731200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2299,
                        "version": "2.0 U2",
                        "guided": true,
                        "gaDate": 1487174400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2388,
                        "version": "2.0 U3",
                        "guided": true,
                        "gaDate": 1494432000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 145,
                "name": "vRealize Code Stream Management Pack for IT DevOps",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 983,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VRCS-MP-VRA-200&productId=571",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1042,
                        "version": "2.0.1",
                        "guided": true,
                        "gaDate": 1460563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1061,
                        "version": "2.1.0",
                        "guided": true,
                        "gaDate": 1466006400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1788,
                        "version": "2.1.1",
                        "guided": true,
                        "gaDate": 1472054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2109,
                        "version": "2.2",
                        "guided": true,
                        "gaDate": 1483372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2302,
                        "version": "2.2.1",
                        "guided": true,
                        "gaDate": 1490803200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VRCS-MP-VRA-221&productId=571",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 657,
                "name": "VMware Cloud Director App Launchpad",
                "childId": null,
                "parentId": 522,
                "releases": [
                    {
                        "id": 3821,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1586361600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 658,
                "name": "VMware Cloud Director Object Storage Extension",
                "childId": null,
                "parentId": 475,
                "releases": [
                    {
                        "id": 3755,
                        "version": "1.5",
                        "guided": true,
                        "gaDate": 1586966400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 147,
                "name": "VMware Remote Console ",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 969,
                        "version": "8.0.0",
                        "guided": true,
                        "gaDate": 1449504000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1074,
                        "version": "8.1.0",
                        "guided": true,
                        "gaDate": 1467648000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1744,
                        "version": "9.0.0",
                        "guided": true,
                        "gaDate": 1472054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2509,
                        "version": "10.0",
                        "guided": true,
                        "gaDate": 1496246400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vmrc/100/vmware-remote-console-100-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMRC10&productId=561",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3400,
                        "version": "11.0",
                        "guided": true,
                        "gaDate": 1575475200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Remote-Console/11.0/rn/VMware-Remote-Console-110-Release-Notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VMRC1100&productId=742",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4002,
                        "version": "11.1.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Remote-Console/11.1/rn/VMware-Remote-Console-1110-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VMRC1110&productId=974",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 148,
                "name": "VMware Host Client",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 979,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1735,
                        "version": "1.2.0",
                        "guided": true,
                        "gaDate": 1469462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 660,
                "name": "VMware HCX",
                "childId": null,
                "parentId": 372,
                "releases": [
                    {
                        "id": 3678,
                        "version": "3.5.2",
                        "guided": true,
                        "gaDate": 1565193600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4066,
                        "version": "3.5.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 149,
                "name": "vSphere Integrated Containers",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 986,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2342,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1492444800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2406,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1495036800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsphere-integrated-containers/11/vsphere-integrated-containers-111-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VIC110&productId=648&rPId=15925",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2417,
                        "version": "1.2",
                        "guided": false,
                        "gaDate": 1505145600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2576,
                        "version": "1.3.0",
                        "guided": false,
                        "gaDate": 1515427200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2612,
                        "version": "1.2.1",
                        "guided": true,
                        "gaDate": 1506960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2760,
                        "version": "1.3.1",
                        "guided": true,
                        "gaDate": 1518019200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2776,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1526313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3043,
                        "version": "1.5",
                        "guided": true,
                        "gaDate": 1547654400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3079,
                        "version": "1.4.4",
                        "guided": true,
                        "gaDate": 1541088000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 661,
                "name": "VMware Cloud Director",
                "childId": null,
                "parentId": 224,
                "releases": [
                    {
                        "id": 4058,
                        "version": "10.1",
                        "guided": true,
                        "gaDate": 1586361600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Cloud-Director/10.1/rn/VMware-Cloud-Director-101-Release-Notes.html",
                        "dLink": "https://www.vmware.com/go/download/vcloud-director",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": true,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 662,
                "name": "vCloud Usage Meter",
                "childId": null,
                "parentId": 92,
                "releases": [
                    {
                        "id": 569,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1381334400000,
                        "rnLink": "https://communities.vmware.com/community/vmtn/vcd/vcloud_usage_meter",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=UMSV31",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 650,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1396800000000,
                        "rnLink": "https://communities.vmware.com/docs/DOC-25792",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=UMSV33",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 713,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1403712000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 758,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1412179200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 894,
                        "version": "3.3.3",
                        "guided": true,
                        "gaDate": 1429113600000,
                        "rnLink": "https://communities.vmware.com/docs/DOC-27109",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=UMSV333",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 929,
                        "version": "3.4",
                        "guided": true,
                        "gaDate": 1438272000000,
                        "rnLink": "https://communities.vmware.com/docs/DOC-28279",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=UMSV34",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1679,
                        "version": "3.5.0",
                        "guided": false,
                        "gaDate": 1473868800000,
                        "rnLink": "https://communities.vmware.com/docs/DOC-28279",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=UMSV34",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2084,
                        "version": "3.6.0",
                        "guided": true,
                        "gaDate": 1499875200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2666,
                        "version": "3.6.1",
                        "guided": true,
                        "gaDate": 1513526400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2672,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1552924800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3532,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3907,
                        "version": "4.2",
                        "guided": true,
                        "gaDate": 1589990400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 150,
                "name": "VMware vRealize CloudClient",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 988,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 989,
                        "version": "3.4.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 990,
                        "version": "3.4.1",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 991,
                        "version": "4.0.0",
                        "guided": true,
                        "gaDate": null,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 992,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1461772800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2029,
                        "version": "3.5.0",
                        "guided": true,
                        "gaDate": 1475510400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2061,
                        "version": "4.2.0",
                        "guided": true,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-cloudclient-42-release-notes.html",
                        "dLink": "https://developercenter.vmware.com/tool/cloudclient/4.2.0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2108,
                        "version": "4.3.0",
                        "guided": true,
                        "gaDate": 1479916800000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-cloudclient-43-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=CLOUDCLIENT_430&productId=568",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2375,
                        "version": "4.4.0",
                        "guided": true,
                        "gaDate": 1494864000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vra/vrealize-cloudclient-44-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=CLOUDCLIENT_440&productId=650",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2777,
                        "version": "4.5.0",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2995,
                        "version": "4.6.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3454,
                        "version": "4.7.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 663,
                "name": "VMware Cloud Provider Stack",
                "childId": null,
                "parentId": 493,
                "releases": [
                    {
                        "id": 3593,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1564329600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3763,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1569427200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 152,
                "name": "VMware Access Point",
                "childId": 326,
                "parentId": null,
                "releases": [
                    {
                        "id": 2095,
                        "version": "2.7.2",
                        "guided": true,
                        "gaDate": 1473868800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2150,
                        "version": "2.5.1",
                        "guided": true,
                        "gaDate": 1454342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2164,
                        "version": "2.8",
                        "guided": true,
                        "gaDate": 1480348800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 664,
                "name": "VMware NSX® Migration for VMware Cloud Director™",
                "childId": null,
                "parentId": 552,
                "releases": [
                    {
                        "id": 4029,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1586966400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_cloud_director/10_1#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 153,
                "name": "VMware Horizon Air Hybrid-Mode",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1020,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1462982400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/horizon-air-hybrid-mode/1/horizon-air-hybrid-mode-10-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_air_hybrid_mode/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1778,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1473868800000,
                        "rnLink": "https://www.vmware.com/support/pubs/horizon-air-hybrid-mode-pubs.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_air_hybrid_mode/1_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 665,
                "name": "vRealize Orchestrator Plug-in for vSphere Replication",
                "childId": null,
                "parentId": 507,
                "releases": [
                    {
                        "id": 3473,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3474,
                        "version": "8.1.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/vSphere-Replication/8.1/rn/vr-plugin-812-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3515,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3785,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 666,
                "name": "vRealize Operations Management Pack for Site Recovery Manager",
                "childId": null,
                "parentId": 518,
                "releases": [
                    {
                        "id": 2105,
                        "version": "6.5.0",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/srm/65/srm-vropsmp-releasenotes-6-5.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=SRM65-PLUGIN&productId=617",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2636,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1510675200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2809,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3068,
                        "version": "6.5.1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3069,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3386,
                        "version": "8.1.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/Site-Recovery-Manager/8.1/rn/srm-vropsmp-releasenotes-8-1-2.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3419,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3727,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 667,
                "name": "Site Recovery Manager Configuration Import/Export Tool",
                "childId": null,
                "parentId": 574,
                "releases": [
                    {
                        "id": 4101,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 155,
                "name": "VMware vRealize Hardening Tool",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1043,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1459785600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 156,
                "name": "vCloud Availability for vCloud Director",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1087,
                        "version": "1.0.0",
                        "guided": false,
                        "gaDate": 1469635200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2191,
                        "version": "1.0.1",
                        "guided": false,
                        "gaDate": 1485360000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2309,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 669,
                "name": "vRealize Orchestrator Plug-In for Site Recovery Manager",
                "childId": null,
                "parentId": 523,
                "releases": [
                    {
                        "id": 3475,
                        "version": "8.1.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/Site-Recovery-Manager/8.1/rn/srm-plugin-812-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3478,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3511,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3514,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3784,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 670,
                "name": "VMware vSAN™",
                "childId": null,
                "parentId": 508,
                "releases": [
                    {
                        "id": 643,
                        "version": "5.5",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 696,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "http://www.vmware.com/support/vsphere6/doc/vmware-virtual-san-60-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_virtual_san/6_0#product_downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 926,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsan/61/vmware-virtual-san-61-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_virtual_san/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1010,
                        "version": "6.2",
                        "guided": true,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsan/62/vmware-virtual-san-62-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_virtual_san/6_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1710,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsan/61/vmware-virtual-san-61-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_virtual_san/6_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2209,
                        "version": "6.6",
                        "guided": true,
                        "gaDate": 1492444800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2508,
                        "version": "6.6.1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2586,
                        "version": "6.7",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2751,
                        "version": "6.6.1 U2",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2818,
                        "version": "6.7 U1",
                        "guided": true,
                        "gaDate": 1539619200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3286,
                        "version": "6.7 U2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3287,
                        "version": "6.7 U3",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3349,
                        "version": "6.6.1 U3",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3926,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 671,
                "name": "VMware vSphere Replication",
                "childId": null,
                "parentId": 509,
                "releases": [
                    {
                        "id": 464,
                        "version": "5.1",
                        "guided": false,
                        "gaDate": 1347206400000,
                        "rnLink": "http://www.vmware.com/support/vsphere5/doc/vsphere-replication-51-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 470,
                        "version": "5.1.1",
                        "guided": false,
                        "gaDate": 1366819200000,
                        "rnLink": "http://www.vmware.com/support/vsphere5/doc/vsphere-replication-511-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/datacenter_cloud_infrastructure/vmware_vsphere/5_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 560,
                        "version": "5.5.0",
                        "guided": false,
                        "gaDate": 1379779200000,
                        "rnLink": "http://vmware.com/support/vsphere5/doc/vsphere-replication-55-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR550_GA&productId=353&rPId=4284",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 615,
                        "version": "5.1.2",
                        "guided": false,
                        "gaDate": 1389801600000,
                        "rnLink": "http://www.vmware.com/support/vsphere5/doc/vsphere-replication-512-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VR512",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 619,
                        "version": "5.5.1",
                        "guided": false,
                        "gaDate": 1394467200000,
                        "rnLink": "https://www.vmware.com/support/vsphere5/doc/vsphere-replication-551-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=datacenter_cloud_infrastructure/vmware_vsphere/5_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 658,
                        "version": "5.8",
                        "guided": false,
                        "gaDate": 1410192000000,
                        "rnLink": "http://www.vmware.com/support/vsphere-replication/doc/vsphere-replication-58-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR580&productId=353&rPId=5721",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 659,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "http://www.vmware.com/support/vsphere-replication/doc/vsphere-replication-60-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR600&productId=491&rPId=7502",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 756,
                        "version": "5.1.3",
                        "guided": false,
                        "gaDate": 1417622400000,
                        "rnLink": "http://www.vmware.com/support/vsphere5/doc/vsphere-replication-513-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VR513",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 838,
                        "version": "5.8.1",
                        "guided": false,
                        "gaDate": 1442332800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vsphere-replication/581/vsphere-replication-581-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/get-download?downloadGroup=VR581",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 919,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vsphere-replication/61/vsphere-replication-61-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VR610",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1058,
                        "version": "6.1.1",
                        "guided": true,
                        "gaDate": 1464192000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsphere-replication/611/vsphere-replication-611-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VR611&productId=526&rPId=11051",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1199,
                        "version": "5.8.0 U1",
                        "guided": false,
                        "gaDate": 1412092800000,
                        "rnLink": "http://kb.vmware.com/kb/2091019",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=353&rPId=5721&downloadGroup=VR5801",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1200,
                        "version": "5.6.0 U2",
                        "guided": false,
                        "gaDate": 1412179200000,
                        "rnLink": "http://kb.vmware.com/kb/2091031",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=343&rPId=6701&downloadGroup=VR5602",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1201,
                        "version": "5.6",
                        "guided": false,
                        "gaDate": 1397404800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": false,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1646,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1479139200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vsphere-replication/65/vsphere-replication-65-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR65&productId=614&rPId=13345",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2180,
                        "version": "6.1.2",
                        "guided": true,
                        "gaDate": 1487865600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2371,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2373,
                        "version": "6.5.1",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": "https://docs.vmware.com/en/vSphere-Replication/6.5/rn/vsphere-replication-651-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VR651&productId=614&rPId=17342",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2601,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1510675200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2929,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR820&productId=796&rPId=33343#product_downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2962,
                        "version": "8.1.1",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": "https://docs.vmware.com/en/vSphere-Replication/8.1/rn/vsphere-replication-811-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VR811&productId=742&rPId=27702",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3333,
                        "version": "8.1.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/vSphere-Replication/8.1/rn/vsphere-replication-812-release-notes.html#whatsnew",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VR812&productId=742&rPId=32080",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3674,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 672,
                "name": "vRealize Operations Management Pack for vSphere Replication",
                "childId": null,
                "parentId": 524,
                "releases": [
                    {
                        "id": 3476,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1557331200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3726,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 673,
                "name": "vSphere Replication Configuration Import/Export Tool",
                "childId": null,
                "parentId": 573,
                "releases": [
                    {
                        "id": 4102,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 162,
                "name": "vSphere Virtual Disk Development Kit",
                "childId": 582,
                "parentId": null,
                "releases": [
                    {
                        "id": 4177,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1624,
                        "version": "6.0.1",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": "https://www.vmware.com/support/developer/vddk/vddk-601-releasenotes.html",
                        "dLink": "https://developercenter.vmware.com/web/sdk/60/vddk",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2831,
                        "version": "6.5.3",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 674,
                "name": "vRealize Operations Management Pack for HCX",
                "childId": null,
                "parentId": 579,
                "releases": [
                    {
                        "id": 3338,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1548259200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3629,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1565539200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 682,
                "name": "VMware Carbon Black Cloud Windows Sensor",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4730,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1580918400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 174,
                "name": "VMware NSX for Multi-Hypervisor",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 773,
                        "version": "4.2.1",
                        "guided": false,
                        "gaDate": 1412352000000,
                        "rnLink": "https://my.vmware.com/web/vmware/details?downloadGroup=NSX-MH-421-DOC&productId=418&rPId=6873",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=418&rPId=6873&downloadGroup=NSX-MH-421",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 774,
                        "version": "4.1.4",
                        "guided": false,
                        "gaDate": 1412352000000,
                        "rnLink": "https://my.vmware.com/web/vmware/details?productId=418&rPId=6873&downloadGroup=NSX-MH-414-DOC",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=418&rPId=6873&downloadGroup=NSX-MH-414",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 845,
                        "version": "4.2.4",
                        "guided": false,
                        "gaDate": 1429718400000,
                        "rnLink": "http://userdoc.nicira.eng.vmware.com/pdfs/NSX/4.2.4/NSX_Release-Notes-4.2.4.pdf",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/4_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 846,
                        "version": "4.2.3",
                        "guided": false,
                        "gaDate": 1422460800000,
                        "rnLink": "https://my.vmware.com/group/vmware/details?productId=418&rPId=5800&downloadGroup=NSX-MH-423-DOC",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/4_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 847,
                        "version": "4.2.2",
                        "guided": false,
                        "gaDate": 1416844800000,
                        "rnLink": "https://my.vmware.com/group/vmware/details?productId=418&rPId=5800&downloadGroup=NSX-MH-422-DOC",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/4_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1021,
                        "version": "4.2.5",
                        "guided": false,
                        "gaDate": 1456934400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 175,
                "name": "VMware NSX-T Data Center",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4198,
                        "version": "3.0.1",
                        "guided": true,
                        "gaDate": 1592841600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1689,
                        "version": "1.1",
                        "guided": false,
                        "gaDate": 1485964800000,
                        "rnLink": "https://my.vmware.com/web/vmware/details?productId=418&rPId=5800&downloadGroup=NSX-MH-420-DOC",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/4_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2176,
                        "version": "2.0",
                        "guided": false,
                        "gaDate": 1504713600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T/2.0/rn/VMware-NSX-T-20-Release-Notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/networking_security/vmware_nsx/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2526,
                        "version": "2.1.0",
                        "guided": false,
                        "gaDate": 1513785600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T/2.1/rn/VMware-NSX-T-21-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2595,
                        "version": "2.2.0",
                        "guided": false,
                        "gaDate": 1528128000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2714,
                        "version": "2.3.0",
                        "guided": true,
                        "gaDate": 1537200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2852,
                        "version": "2.4.0",
                        "guided": true,
                        "gaDate": 1551283200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3216,
                        "version": "2.3.1",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T-Data-Center/2.3.1/rn/VMware-NSX-T-Data-Center-231-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/networking_security/vmware_nsx_t_data_center/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3394,
                        "version": "2.5.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3413,
                        "version": "3.0.0",
                        "guided": true,
                        "gaDate": 1586188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3451,
                        "version": "2.4.1",
                        "guided": true,
                        "gaDate": 1558368000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T-Data-Center/2.4.1/rn/VMware-NSX-T-Data-Center-241-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/networking_security/vmware_nsx_t_data_center/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3645,
                        "version": "2.4.2",
                        "guided": true,
                        "gaDate": 1565193600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T-Data-Center/2.4.2/rn/VMware-NSX-T-Data-Center-242-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/networking_security/vmware_nsx_t_data_center/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3777,
                        "version": "2.4.3",
                        "guided": true,
                        "gaDate": 1573056000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-NSX-T-Data-Center/2.4/rn/VMware-NSX-T-Data-Center-243-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/networking_security/vmware_nsx_t_data_center/2_x",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3787,
                        "version": "2.5.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 178,
                "name": "VMware Studio",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1221,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1251302400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1222,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1220544000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1408,
                        "version": "2.6",
                        "guided": true,
                        "gaDate": 1331136000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1409,
                        "version": "2.5",
                        "guided": true,
                        "gaDate": 1302710400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1410,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1278950400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 181,
                "name": "VMware vFabric Application Director",
                "childId": 65,
                "parentId": null,
                "releases": [
                    {
                        "id": 361,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1331740800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 362,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1354118400000,
                        "rnLink": "https://www.vmware.com/support/appdirector/doc/releasenotes_appdirector50.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_application_director/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 421,
                        "version": "5.0.1",
                        "guided": true,
                        "gaDate": 1357747200000,
                        "rnLink": "https://www.vmware.com/support/appdirector/doc/releasenotes_appdirector501.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_application_director/5_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 486,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1369843200000,
                        "rnLink": "https://www.vmware.com/support/appdirector/doc/releasenotes_appdirector52.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/application_management/vmware_vfabric_application_director/5_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 195,
                "name": "VMware AirWatch Console for WS1 Platform",
                "childId": 382,
                "parentId": null,
                "releases": [
                    {
                        "id": 1648,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1446998400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1673,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1456243200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1714,
                        "version": "8.4",
                        "guided": true,
                        "gaDate": 1466006400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2090,
                        "version": "9.0",
                        "guided": true,
                        "gaDate": 1478188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2238,
                        "version": "9.0.2",
                        "guided": true,
                        "gaDate": 1485446400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2265,
                        "version": "8.4.9",
                        "guided": true,
                        "gaDate": 1485446400000,
                        "rnLink": "https://support.air-watch.com/articles/115002078447",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2286,
                        "version": "9.0.3",
                        "guided": true,
                        "gaDate": 1487088000000,
                        "rnLink": "https://support.air-watch.com/articles/115002903507",
                        "dLink": "https://resources.air-watch.com/?page=1&requiredfields=categoryGroup:AirWatch%20Software.categoryName:Console&sort=meta:updatedOn:D&partialfields=languageId:en",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2361,
                        "version": "9.1.0",
                        "guided": false,
                        "gaDate": 1493136000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2399,
                        "version": "9.1.1",
                        "guided": true,
                        "gaDate": 1496073600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2400,
                        "version": "9.0.4",
                        "guided": true,
                        "gaDate": 1495555200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2404,
                        "version": "9.1.2",
                        "guided": true,
                        "gaDate": 1498492800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2457,
                        "version": "9.0.5",
                        "guided": true,
                        "gaDate": 1498492800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2494,
                        "version": "9.1.3",
                        "guided": true,
                        "gaDate": 1500998400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2513,
                        "version": "9.1.4",
                        "guided": true,
                        "gaDate": 1502985600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2547,
                        "version": "9.0.6",
                        "guided": true,
                        "gaDate": 1507219200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2551,
                        "version": "9.2.0",
                        "guided": false,
                        "gaDate": 1505923200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2566,
                        "version": "9.2.1",
                        "guided": false,
                        "gaDate": 1509465600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2571,
                        "version": "9.1.5",
                        "guided": true,
                        "gaDate": 1510761600000,
                        "rnLink": "https://resources.air-watch.com/view/sp2yj4gs9dfw4d8pgphk",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2611,
                        "version": "9.2.2",
                        "guided": false,
                        "gaDate": 1512403200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2671,
                        "version": "9.2.3",
                        "guided": false,
                        "gaDate": 1516809600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2774,
                        "version": "9.4.0",
                        "guided": true,
                        "gaDate": 1525190400000,
                        "rnLink": "https://docs-staging.vmware.com/en/VMware-Workspace-ONE-UEM/9.4/rn/workspace-one-uem-94-release-notes.html",
                        "dLink": "https://my.air-watch.com/products/AirWatch-Console/Windows/v9.4.0.0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2775,
                        "version": "9.3.0",
                        "guided": false,
                        "gaDate": 1520956800000,
                        "rnLink": "https://resources.air-watch.com/view/cc4g8rvqrvmxqcwjxtp2/en",
                        "dLink": "https://resources.air-watch.com/save/65jynn8qy5hy5q7sm9y6/en",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2905,
                        "version": "9.5.0",
                        "guided": true,
                        "gaDate": 1530460800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/9.5/rn/Workspace-ONE-UEM-v95-Release-Notes.html",
                        "dLink": "https://resources.workspaceone.com/view/tf7g3y3lkgly4l5yqv9p",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2908,
                        "version": "9.6.0",
                        "guided": true,
                        "gaDate": 1534089600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/9.6/rn/VMware-Workspace-ONE-UEM-Release-Notes-96.html",
                        "dLink": "https://resources.workspaceone.com/view/x3r3m9555gzgk8plcvs3/",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2967,
                        "version": "9.7.0",
                        "guided": true,
                        "gaDate": 1538496000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/9.7/rn/VMware-Workspace-ONE-UEM-Release-Notes-97.html",
                        "dLink": "https://resources.workspaceone.com/view/jsdjqxll3k9wf5x7ppjw/",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 216,
                "name": "VMware Workstation",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2348,
                        "version": "Workstation 14.0",
                        "guided": false,
                        "gaDate": 1506355200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/14.0.0/rn/workstation-14-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/14_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2590,
                        "version": "Workstation 14.1.0",
                        "guided": false,
                        "gaDate": 1513785600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Player/14.0.0/rn/player-141-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/14_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2753,
                        "version": "Workstation 14.1.2",
                        "guided": false,
                        "gaDate": 1526832000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/14/rn/workstation-1412-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info?slug=desktop_end_user_computing/vmware_workstation_pro/14_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 224,
                "name": "VMware vCloud Director For Service Providers",
                "childId": 661,
                "parentId": 29,
                "releases": [
                    {
                        "id": 727,
                        "version": "5.6.3",
                        "guided": false,
                        "gaDate": 1412611200000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_56.html",
                        "dLink": "http://www.vmware.com/products/vcloud-director/vcloud-director-service-providers",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 822,
                        "version": "5.6.4",
                        "guided": false,
                        "gaDate": 1423670400000,
                        "rnLink": "https://www.vmware.com/support/vcd/doc/rel_notes_vcloud_director_564.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VSPP_VCD564",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 906,
                        "version": "8.0",
                        "guided": false,
                        "gaDate": 1441814400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcd/80/rel_notes_vcloud_director_80.html",
                        "dLink": "https://my.vmware.com/group/vmware/get-download?downloadGroup=VSPP_VCD800",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 933,
                        "version": "5.6.5",
                        "guided": false,
                        "gaDate": 1447516800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 977,
                        "version": "8.10",
                        "guided": false,
                        "gaDate": 1464192000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcd/8-10/rel_notes_vcloud_director_8-10.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1013,
                        "version": "8.0.1",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vcd/801/rel_notes_vcloud_director_801.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1736,
                        "version": "8.0.2",
                        "guided": false,
                        "gaDate": 1475510400000,
                        "rnLink": "http://tpub-stg-ic-1.eng.vmware.com:8080/Release_Notes/en/vcd/802/rel_notes_vcloud_director_802.html#networkresolved",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2062,
                        "version": "8.20",
                        "guided": false,
                        "gaDate": 1487606400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2099,
                        "version": "8.10.1",
                        "guided": false,
                        "gaDate": 1480953600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 231,
                "name": "Photon Controller",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2201,
                        "version": "1.1.1",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2256,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1492444800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 233,
                "name": "AirWatch Inbox for iOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2378,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1496246400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2485,
                        "version": "3.4",
                        "guided": true,
                        "gaDate": 1503244800000,
                        "rnLink": "https://my.air-watch.com/products",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2567,
                        "version": "3.4.2",
                        "guided": true,
                        "gaDate": 1506268800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2575,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1509465600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2627,
                        "version": "3.5.1",
                        "guided": true,
                        "gaDate": 1512316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 236,
                "name": "HTML Console SDK",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1685,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1460390400000,
                        "rnLink": "https://www.vmware.com/support/developer/html-console/html-console-sdk-200-release-notes.pdf",
                        "dLink": "https://my.vmware.com/en/web/vmware/details?downloadGroup=HTMLCONSOLESDK200&productId=491",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2100,
                        "version": "2.1.0",
                        "guided": true,
                        "gaDate": 1477324800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 237,
                "name": "Photon OS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1662,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1465488000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2506,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1509379200000,
                        "rnLink": null,
                        "dLink": "https://github.com/vmware/photon/wiki/Downloading-Photon-OS",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3024,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1549468800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 238,
                "name": "Lightwave",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2227,
                        "version": "1.0.3",
                        "guided": true,
                        "gaDate": 1483459200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2228,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1493049600000,
                        "rnLink": null,
                        "dLink": "https://github.com/vmware/lightwave",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 243,
                "name": "vRealize Business for Cloud  ",
                "childId": null,
                "parentId": 112,
                "releases": [
                    {
                        "id": 1012,
                        "version": "7.0.1",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRBforCloud/701/vRBforCloud-701-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1722,
                        "version": "7.1.0",
                        "guided": false,
                        "gaDate": 1471881600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRBforCloud/71/vRBforCloud-71-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VRBC-710&productId=590&rPId=12534",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2077,
                        "version": "7.2.0",
                        "guided": false,
                        "gaDate": 1479139200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2204,
                        "version": "7.3.0",
                        "guided": false,
                        "gaDate": 1497283200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRBforCloud/73/vRBforCloud-73-release-notes.html?__utma=207178772.1990572040.1497367320.1497369242.1497372937.3&__utmb=207178772.1.10.1497372937&__utmc=207178772&__utmx=-&__utmz=207178772.1497367320.1.1.utmcsr=%28direct%29|utmccn=%28direct%29|utmcmd=%28none%29&__utmv=-&__utmk=45194407",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business_for_cloud/7_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2258,
                        "version": "7.2.1",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRBforCloud/72/vRBforCloud-721-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/7_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2488,
                        "version": "7.4.0",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Business-for-Cloud/7.4/rn/vRBforCloud-74-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2498,
                        "version": "7.3.1",
                        "guided": false,
                        "gaDate": 1503504000000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Business/7.3.1/rn/vRBforCloud-731-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business_for_cloud/7_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2812,
                        "version": "7.5.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3296,
                        "version": "7.6.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 244,
                "name": "vRealize Business Enterprise ",
                "childId": null,
                "parentId": 113,
                "releases": [
                    {
                        "id": 1011,
                        "version": "8.2.5",
                        "guided": false,
                        "gaDate": 1457971200000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/vRealizeBusinessAE/825/vrealizebusiness-enterprise-825-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1083,
                        "version": "8.3",
                        "guided": false,
                        "gaDate": 1466006400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2075,
                        "version": "8.4",
                        "guided": false,
                        "gaDate": 1472486400000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_business/8_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 246,
                "name": "AirWatch Inbox for Android",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2452,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1500220800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2594,
                        "version": "3.4",
                        "guided": true,
                        "gaDate": 1505664000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 247,
                "name": "VMware Browser for Android",
                "childId": 422,
                "parentId": null,
                "releases": [
                    {
                        "id": 2327,
                        "version": "6.2",
                        "guided": true,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2401,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": "https://support.air-watch.com/articles/115010860348",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2525,
                        "version": "6.9",
                        "guided": true,
                        "gaDate": 1507737600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2608,
                        "version": "6.11",
                        "guided": true,
                        "gaDate": 1513008000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2761,
                        "version": "6.12",
                        "guided": true,
                        "gaDate": 1522252800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2822,
                        "version": "6.13",
                        "guided": true,
                        "gaDate": 1526486400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2864,
                        "version": "6.14",
                        "guided": true,
                        "gaDate": 1529856000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2911,
                        "version": "6.15",
                        "guided": true,
                        "gaDate": 1531152000000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/BROW/Browser+-+6.15+-+Android",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2926,
                        "version": "6.16",
                        "guided": true,
                        "gaDate": 1535990400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 248,
                "name": "VMware AirWatch Agent for Android",
                "childId": 380,
                "parentId": null,
                "releases": [
                    {
                        "id": 1698,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1467734400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1792,
                        "version": "6.2",
                        "guided": true,
                        "gaDate": 1471363200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2079,
                        "version": "6.3",
                        "guided": true,
                        "gaDate": 1476028800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2098,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1484236800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2232,
                        "version": "7.1",
                        "guided": true,
                        "gaDate": 1490803200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2307,
                        "version": "7.2",
                        "guided": true,
                        "gaDate": 1499184000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2379,
                        "version": "7.3",
                        "guided": true,
                        "gaDate": 1504540800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2476,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2568,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1513526400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2569,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1519574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2700,
                        "version": "8.0.2",
                        "guided": true,
                        "gaDate": 1515686400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2717,
                        "version": "8.2",
                        "guided": true,
                        "gaDate": 1528646400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2824,
                        "version": "8.3",
                        "guided": true,
                        "gaDate": 1533484800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2951,
                        "version": "8.3.1",
                        "guided": true,
                        "gaDate": 1535472000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 249,
                "name": "VMware AirWatch Agent for iOS",
                "childId": 381,
                "parentId": null,
                "releases": [
                    {
                        "id": 2234,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1496246400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2489,
                        "version": "5.5.1",
                        "guided": true,
                        "gaDate": 1498665600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2510,
                        "version": "5.6",
                        "guided": true,
                        "gaDate": 1511712000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2651,
                        "version": "5.5.4",
                        "guided": true,
                        "gaDate": 1509379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2664,
                        "version": "5.7",
                        "guided": true,
                        "gaDate": 1519228800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2825,
                        "version": "5.8",
                        "guided": true,
                        "gaDate": 1534348800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2966,
                        "version": "5.8.1",
                        "guided": true,
                        "gaDate": 1535558400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 251,
                "name": "VMware Remote Management for Android",
                "childId": 428,
                "parentId": null,
                "releases": [
                    {
                        "id": 2584,
                        "version": "4.2",
                        "guided": true,
                        "gaDate": 1510761600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2850,
                        "version": "4.4",
                        "guided": true,
                        "gaDate": 1531756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 252,
                "name": "VMware Browser for iOS",
                "childId": 423,
                "parentId": null,
                "releases": [
                    {
                        "id": 2402,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1507132800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2607,
                        "version": "6.6",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2653,
                        "version": "6.5.2",
                        "guided": true,
                        "gaDate": 1509552000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2703,
                        "version": "6.11",
                        "guided": true,
                        "gaDate": 1518451200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2795,
                        "version": "6.12",
                        "guided": true,
                        "gaDate": 1522684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2823,
                        "version": "6.13",
                        "guided": true,
                        "gaDate": 1526313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2865,
                        "version": "6.14",
                        "guided": true,
                        "gaDate": 1529251200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2912,
                        "version": "6.15",
                        "guided": true,
                        "gaDate": 1532275200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2927,
                        "version": "6.16",
                        "guided": true,
                        "gaDate": 1534348800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 253,
                "name": "VMware Content Locker for iOS",
                "childId": 386,
                "parentId": null,
                "releases": [
                    {
                        "id": 2515,
                        "version": "4.9",
                        "guided": true,
                        "gaDate": 1506355200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2626,
                        "version": "4.12",
                        "guided": true,
                        "gaDate": 1516204800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2639,
                        "version": "4.9.3",
                        "guided": true,
                        "gaDate": 1512316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2644,
                        "version": "4.9.3",
                        "guided": true,
                        "gaDate": 1511971200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2645,
                        "version": "4.9.2",
                        "guided": true,
                        "gaDate": 1509379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2650,
                        "version": "4.9.2",
                        "guided": true,
                        "gaDate": 1509379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2757,
                        "version": "4.13",
                        "guided": true,
                        "gaDate": 1522771200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2876,
                        "version": "4.13.3",
                        "guided": true,
                        "gaDate": 1528819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 254,
                "name": "VMware AirWatch Agent for macOS",
                "childId": 383,
                "parentId": null,
                "releases": [
                    {
                        "id": 2325,
                        "version": "2.4.2",
                        "guided": true,
                        "gaDate": 1494259200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2539,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1519574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2693,
                        "version": "2.4.4",
                        "guided": true,
                        "gaDate": 1515427200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2781,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1523980800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 255,
                "name": "VMware Content Locker for Android",
                "childId": 385,
                "parentId": null,
                "releases": [
                    {
                        "id": 2272,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1489593600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2474,
                        "version": "3.7",
                        "guided": true,
                        "gaDate": 1502035200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2552,
                        "version": "3.9",
                        "guided": true,
                        "gaDate": 1507132800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2625,
                        "version": "3.12",
                        "guided": true,
                        "gaDate": 1516032000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2756,
                        "version": "3.13",
                        "guided": true,
                        "gaDate": 1522080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2875,
                        "version": "3.13.3",
                        "guided": true,
                        "gaDate": 1528819200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2916,
                        "version": "3.14",
                        "guided": true,
                        "gaDate": 1531843200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 257,
                "name": "VMware AirWatch Windows Mobile Agent ",
                "childId": 389,
                "parentId": null,
                "releases": [
                    {
                        "id": 2060,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1478188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2171,
                        "version": "6.2",
                        "guided": true,
                        "gaDate": 1493222400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2260,
                        "version": "6.0.43",
                        "guided": true,
                        "gaDate": 1485360000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2530,
                        "version": "6.4",
                        "guided": true,
                        "gaDate": 1510502400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2531,
                        "version": "6.3",
                        "guided": true,
                        "gaDate": 1510502400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2758,
                        "version": "6.5",
                        "guided": true,
                        "gaDate": 1523289600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2780,
                        "version": "6.4.12",
                        "guided": true,
                        "gaDate": 1519574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 258,
                "name": "VMware AirWatch Launcher for Android",
                "childId": 388,
                "parentId": null,
                "releases": [
                    {
                        "id": 2329,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1498665600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2468,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1504108800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2523,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1513267200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2618,
                        "version": "3.2.2",
                        "guided": true,
                        "gaDate": 1510070400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2702,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1515945600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2718,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1523289600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2772,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1518537600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 260,
                "name": "VMware Horizon 7",
                "childId": null,
                "parentId": 23,
                "releases": [
                    {
                        "id": 945,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1458576000000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-7-view/horizon-70-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?downloadGroup=VIEW-700-STD&productId=577&rPId=10437",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1075,
                        "version": "7.0.1",
                        "guided": true,
                        "gaDate": 1466006400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/horizon-7-view/horizon-701-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2022,
                        "version": "7.0.2",
                        "guided": true,
                        "gaDate": 1473868800000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/horizon-7-view/horizon-702-view-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2147,
                        "version": "7.0.3",
                        "guided": true,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2181,
                        "version": "7.1.0",
                        "guided": true,
                        "gaDate": 1489593600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2369,
                        "version": "7.2.0",
                        "guided": true,
                        "gaDate": 1496851200000,
                        "rnLink": null,
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2483,
                        "version": "7.3.0",
                        "guided": true,
                        "gaDate": 1505923200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.3/rn/horizon-73-view-release-notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2572,
                        "version": "7.4.0",
                        "guided": true,
                        "gaDate": 1514995200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.4/rn/horizon-74-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2577,
                        "version": "7.3.1",
                        "guided": true,
                        "gaDate": 1506960000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/index.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2629,
                        "version": "7.3.2",
                        "guided": true,
                        "gaDate": 1511107200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.3/rn/horizon-73-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2704,
                        "version": "7.5.0",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.5/rn/horizon-75-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2827,
                        "version": "7.4.1",
                        "guided": true,
                        "gaDate": 1523894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2877,
                        "version": "7.6.0",
                        "guided": true,
                        "gaDate": 1536163200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.6/rn/horizon-76-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2917,
                        "version": "7.5.1",
                        "guided": true,
                        "gaDate": 1531929600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.5.1/rn/horizon-751-view-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3002,
                        "version": "7.7.0",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.7/rn/horizon-77-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3006,
                        "version": "7.3.3",
                        "guided": true,
                        "gaDate": 1538582400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3218,
                        "version": "7.8.0",
                        "guided": true,
                        "gaDate": 1552492800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.8/rn/horizon-78-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3377,
                        "version": "7.5.2",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-User-Environment-Manager/9.4.1/rn/VMware-User-Environment-Manager-Release-Notes-941.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_user_environment_manager/9_4",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3445,
                        "version": "7.9.0",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.9/rn/horizon-79-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_9",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3613,
                        "version": "7.10.0",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": "https://docs-staging.vmware.com/en/VMware-Horizon-7/7.10/rn/horizon-710-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_10",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3746,
                        "version": "7.5.3",
                        "guided": true,
                        "gaDate": 1572278400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.5.3/rn/horizon-753-view-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIEW-753-ENT&productId=757&rPId=39133",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3782,
                        "version": "7.11.0",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.11/rn/horizon-711-view-release-notes.htm",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_11",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3938,
                        "version": "7.12.0",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.12/rn/horizon-712-view-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon/7_12",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3940,
                        "version": "7.5.4",
                        "guided": true,
                        "gaDate": 1576771200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3941,
                        "version": "7.10.1",
                        "guided": true,
                        "gaDate": 1576771200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4059,
                        "version": "7.10.2",
                        "guided": true,
                        "gaDate": 1589990400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-7/7.10.2/rn/horizon-7102-view-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=VIEW-7102-ENT&productId=924&rPId=46511",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 261,
                "name": "VMware Boxer for iOS",
                "childId": 415,
                "parentId": null,
                "releases": [
                    {
                        "id": 2409,
                        "version": "4.5",
                        "guided": true,
                        "gaDate": 1498665600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2493,
                        "version": "4.7",
                        "guided": true,
                        "gaDate": 1502640000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2521,
                        "version": "4.8",
                        "guided": true,
                        "gaDate": 1508774400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2538,
                        "version": "4.7.1",
                        "guided": true,
                        "gaDate": 1505318400000,
                        "rnLink": null,
                        "dLink": "https://my.air-watch.com/products/VMware-Boxer",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2609,
                        "version": "4.9",
                        "guided": true,
                        "gaDate": 1513526400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2616,
                        "version": "4.8.2",
                        "guided": true,
                        "gaDate": 1510156800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2623,
                        "version": "4.8.3",
                        "guided": true,
                        "gaDate": 1510675200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2624,
                        "version": "4.8.1",
                        "guided": true,
                        "gaDate": 1509292800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2687,
                        "version": "4.10",
                        "guided": true,
                        "gaDate": 1517328000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2688,
                        "version": "4.9.1",
                        "guided": true,
                        "gaDate": 1514995200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2728,
                        "version": "4.11",
                        "guided": true,
                        "gaDate": 1520352000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2740,
                        "version": "4.10.1",
                        "guided": true,
                        "gaDate": 1517760000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2773,
                        "version": "4.10.2",
                        "guided": true,
                        "gaDate": 1518451200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2783,
                        "version": "4.12",
                        "guided": true,
                        "gaDate": 1523808000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2799,
                        "version": "4.13",
                        "guided": true,
                        "gaDate": 1526486400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2832,
                        "version": "4.11.2",
                        "guided": true,
                        "gaDate": 1522339200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2867,
                        "version": "4.14",
                        "guided": true,
                        "gaDate": 1531756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2913,
                        "version": "4.15",
                        "guided": true,
                        "gaDate": 1535299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 264,
                "name": "VMware Workspace ONE for iOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4103,
                        "version": "3.3.9",
                        "guided": true,
                        "gaDate": 1586188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2477,
                        "version": "3.1.0",
                        "guided": true,
                        "gaDate": 1502640000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2536,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1512921600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2655,
                        "version": "3.1.2",
                        "guided": true,
                        "gaDate": 1509552000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2741,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1527004800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2909,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1534780800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3085,
                        "version": "3.3.4",
                        "guided": true,
                        "gaDate": 1542556800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3380,
                        "version": "3.3.5",
                        "guided": true,
                        "gaDate": 1553443200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3551,
                        "version": "3.3.6",
                        "guided": true,
                        "gaDate": 1554307200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3683,
                        "version": "3.3.7",
                        "guided": true,
                        "gaDate": 1568563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3893,
                        "version": "3.3.8",
                        "guided": true,
                        "gaDate": 1579622400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 265,
                "name": "VMware Workspace ONE for Android",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4104,
                        "version": "3.3.7",
                        "guided": true,
                        "gaDate": 1584979200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4649,
                        "version": "3.3.8",
                        "guided": true,
                        "gaDate": 1591718400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2542,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1512921600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2543,
                        "version": "3.1.0",
                        "guided": true,
                        "gaDate": 1503331200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2694,
                        "version": "3.2.1",
                        "guided": true,
                        "gaDate": 1519747200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2779,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1526918400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2907,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1533744000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3365,
                        "version": "3.3.3",
                        "guided": true,
                        "gaDate": 1548691200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3366,
                        "version": "3.3.3",
                        "guided": true,
                        "gaDate": 1548691200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3379,
                        "version": "3.3.4",
                        "guided": true,
                        "gaDate": 1554652800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3675,
                        "version": "3.3.5",
                        "guided": true,
                        "gaDate": 1566316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3894,
                        "version": "3.3.6",
                        "guided": true,
                        "gaDate": 1578326400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 266,
                "name": "VMware Workspace ONE for Windows 10",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4669,
                        "version": "3.6",
                        "guided": true,
                        "gaDate": 1594224000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2421,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1501084800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2544,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1512576000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2792,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1531670400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2973,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1538928000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3356,
                        "version": "3.4",
                        "guided": true,
                        "gaDate": 1565193600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3578,
                        "version": "3.3.59",
                        "guided": true,
                        "gaDate": 1557244800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3579,
                        "version": "3.3.59",
                        "guided": true,
                        "gaDate": 1557244800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3804,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1575907200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 271,
                "name": "VMware Boxer for Android",
                "childId": 414,
                "parentId": null,
                "releases": [
                    {
                        "id": 2408,
                        "version": "4.5",
                        "guided": true,
                        "gaDate": 1498665600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2492,
                        "version": "4.7",
                        "guided": true,
                        "gaDate": 1502726400000,
                        "rnLink": null,
                        "dLink": "https://play.google.com/store/apps/details?id=com.boxer.email&hl=en",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2522,
                        "version": "4.8",
                        "guided": true,
                        "gaDate": 1506960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2610,
                        "version": "4.9",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2670,
                        "version": "4.8.1",
                        "guided": true,
                        "gaDate": 1507824000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2678,
                        "version": "4.9.1",
                        "guided": true,
                        "gaDate": 1514476800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2685,
                        "version": "4.9.2",
                        "guided": true,
                        "gaDate": 1515686400000,
                        "rnLink": "https://my.air-watch.com/products/VMware-Boxer",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2686,
                        "version": "4.10",
                        "guided": true,
                        "gaDate": 1517760000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2727,
                        "version": "4.11",
                        "guided": true,
                        "gaDate": 1520265600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2782,
                        "version": "4.12",
                        "guided": true,
                        "gaDate": 1522771200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2798,
                        "version": "4.13",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2866,
                        "version": "4.14",
                        "guided": true,
                        "gaDate": 1530028800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2914,
                        "version": "4.15",
                        "guided": true,
                        "gaDate": 1534176000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 272,
                "name": "Windows VMware Content Locker",
                "childId": 387,
                "parentId": null,
                "releases": [
                    {
                        "id": 2394,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1497456000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2859,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1530720000000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/ACL/Content+Locker+-+1.3+-+Windows",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 275,
                "name": "Virtual SAN for Photon Controller",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1769,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1488384000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2352,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1492444800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 281,
                "name": "VMware Tunnel for Windows",
                "childId": 421,
                "parentId": null,
                "releases": [
                    {
                        "id": 2363,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1493308800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2574,
                        "version": "2.0.0",
                        "guided": true,
                        "gaDate": 1512403200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 282,
                "name": "VMware Tunnel for iOS",
                "childId": 419,
                "parentId": null,
                "releases": [
                    {
                        "id": 2419,
                        "version": "1.3.4",
                        "guided": true,
                        "gaDate": 1494345600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2448,
                        "version": "2.0.2",
                        "guided": true,
                        "gaDate": 1496246400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2786,
                        "version": "3.0.0",
                        "guided": true,
                        "gaDate": 1524412800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2844,
                        "version": "3.1.0",
                        "guided": true,
                        "gaDate": 1530460800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 284,
                "name": "VMware AirWatch Windows Inbox",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1917,
                        "version": "1.6.3",
                        "guided": true,
                        "gaDate": 1469030400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 285,
                "name": "vRealize Network Insight",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 1922,
                        "version": "3.0.0",
                        "guided": false,
                        "gaDate": 1469980800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2052,
                        "version": "3.1.0",
                        "guided": false,
                        "gaDate": 1475683200000,
                        "rnLink": "https://pubs.vmware.com/Release_Notes/en/vrni/31/vrealize-network-insight-31-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_network_insight/3_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2146,
                        "version": "3.2.0",
                        "guided": false,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2223,
                        "version": "3.3.0",
                        "guided": false,
                        "gaDate": 1488384000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2330,
                        "version": "3.4.0",
                        "guided": false,
                        "gaDate": 1496246400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2464,
                        "version": "3.5.0",
                        "guided": false,
                        "gaDate": 1504540800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2535,
                        "version": "3.6.0",
                        "guided": false,
                        "gaDate": 1510588800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2631,
                        "version": "3.7.0",
                        "guided": false,
                        "gaDate": 1519056000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2771,
                        "version": "3.8.0",
                        "guided": false,
                        "gaDate": 1529337600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2860,
                        "version": "3.9.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3014,
                        "version": "4.0.0",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3294,
                        "version": "4.1.0",
                        "guided": true,
                        "gaDate": 1555516800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3519,
                        "version": "4.2.0",
                        "guided": true,
                        "gaDate": 1563379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3566,
                        "version": "4.1.1",
                        "guided": true,
                        "gaDate": 1560182400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3625,
                        "version": "5.0.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3837,
                        "version": "5.1.0",
                        "guided": true,
                        "gaDate": 1578931200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3974,
                        "version": "5.2.0",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 289,
                "name": "Vmware AirWatch Container for Android",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2403,
                        "version": "3.6",
                        "guided": true,
                        "gaDate": 1504713600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2512,
                        "version": "3.7",
                        "guided": true,
                        "gaDate": 1508083200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2582,
                        "version": "3.8",
                        "guided": true,
                        "gaDate": 1519747200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2742,
                        "version": "3.9",
                        "guided": true,
                        "gaDate": 1534953600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3044,
                        "version": "3.9.1",
                        "guided": true,
                        "gaDate": 1540828800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 291,
                "name": "VMware AirWatch iOS Container",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2271,
                        "version": "2.5",
                        "guided": true,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2410,
                        "version": "2.5.1",
                        "guided": true,
                        "gaDate": 1497196800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2633,
                        "version": "2.5.4",
                        "guided": true,
                        "gaDate": 1511712000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2652,
                        "version": "2.5.3",
                        "guided": true,
                        "gaDate": 1509379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 292,
                "name": "VMware SDDC Health Monitoring Solution",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2960,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": "https://marketplace.vmware.com/resources/a229f25255ee11e78024005056a107c6/doc/058450879ecd4407a2c127a74c58b550/VMware%20SDDC%20Health%20Management%20Pack%205.0%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vmware-sddc-management-health-solution",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3285,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3597,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/8.0/rn/SDDC-Health-Monitoring-Solution-80-Release-Notes.html",
                        "dLink": "asdfa",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4004,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/8.1/rn/SDDC-Health-Monitoring-Solution-81-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vmware-sddc-management-health-solution#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 297,
                "name": "NSX-vRO Plugin",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2112,
                        "version": "1.0.4",
                        "guided": true,
                        "gaDate": 1472054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2113,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1485964800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2336,
                        "version": "1.2.0",
                        "guided": true,
                        "gaDate": 1502294400000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/suite/releasenotes_nsx_vro_120.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=NSXV_633&productId=614&rPId=17653#drivers_tools",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2396,
                        "version": "1.0.3",
                        "guided": true,
                        "gaDate": 1450281600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2397,
                        "version": "1.0.2",
                        "guided": true,
                        "gaDate": 1440000000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2418,
                        "version": "1.0.5",
                        "guided": true,
                        "gaDate": 1495641600000,
                        "rnLink": "http://pubs.vmware.com/Release_Notes/en/nsx/suite/releasenotes_nsx_vro_105.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?downloadGroup=NSXV_627_TOOLS&productId=417",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 298,
                "name": "VMware Identity Manager Hosted",
                "childId": 515,
                "parentId": null,
                "releases": [
                    {
                        "id": 2121,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1467216000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 299,
                "name": "VMware PowerCLI",
                "childId": null,
                "parentId": 106,
                "releases": [
                    {
                        "id": 1669,
                        "version": "6.5.0",
                        "guided": true,
                        "gaDate": 1479312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2247,
                        "version": "6.5.1",
                        "guided": true,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2507,
                        "version": "6.5.2",
                        "guided": true,
                        "gaDate": 1502121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2550,
                        "version": "6.5.3",
                        "guided": true,
                        "gaDate": 1507564800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2561,
                        "version": "6.5.4",
                        "guided": true,
                        "gaDate": 1510848000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2635,
                        "version": "10.0.0",
                        "guided": true,
                        "gaDate": 1519747200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2797,
                        "version": "10.1.0",
                        "guided": true,
                        "gaDate": 1524672000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2906,
                        "version": "10.1.1",
                        "guided": true,
                        "gaDate": 1529251200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2922,
                        "version": "10.2.0",
                        "guided": true,
                        "gaDate": 1534694400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2930,
                        "version": "11.0.0",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3202,
                        "version": "11.1.0",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3389,
                        "version": "11.2.0",
                        "guided": true,
                        "gaDate": 1551283200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3482,
                        "version": "11.3.0",
                        "guided": true,
                        "gaDate": 1560960000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3626,
                        "version": "11.4.0",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3662,
                        "version": "11.5.0",
                        "guided": true,
                        "gaDate": 1571846400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4093,
                        "version": "12.0.0",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 300,
                "name": "VMWare vRealize Application Services Migration Tool",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2123,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1485792000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2343,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1494864000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 301,
                "name": "VMware Identity Manager Connector",
                "childId": 514,
                "parentId": null,
                "releases": [
                    {
                        "id": 2124,
                        "version": "2016.6.1",
                        "guided": true,
                        "gaDate": 1468252800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2202,
                        "version": "2016.11.1",
                        "guided": true,
                        "gaDate": 1479312000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2383,
                        "version": "2017.5.1",
                        "guided": true,
                        "gaDate": 1495641600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2562,
                        "version": "2017.9.1",
                        "guided": true,
                        "gaDate": 1505923200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2593,
                        "version": "2017.12.1",
                        "guided": true,
                        "gaDate": 1512576000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2759,
                        "version": "2018.1.1",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2794,
                        "version": "2018.8.1",
                        "guided": true,
                        "gaDate": 1537200000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3289,
                        "version": "19.3.0",
                        "guided": true,
                        "gaDate": 1555344000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 302,
                "name": "VMware Airwatch Unified Agent for Windows",
                "childId": 384,
                "parentId": null,
                "releases": [
                    {
                        "id": 2580,
                        "version": "9.2.1",
                        "guided": true,
                        "gaDate": 1509552000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2606,
                        "version": "9.2.2",
                        "guided": true,
                        "gaDate": 1512489600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2684,
                        "version": "9.2.3",
                        "guided": true,
                        "gaDate": 1516896000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2762,
                        "version": "9.3",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2819,
                        "version": "9.4",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2871,
                        "version": "9.5",
                        "guided": true,
                        "gaDate": 1528732800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2974,
                        "version": "9.7",
                        "guided": true,
                        "gaDate": 1536163200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 305,
                "name": "VMware Tunnel for Android",
                "childId": 418,
                "parentId": null,
                "releases": [
                    {
                        "id": 2365,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1495641600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2450,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1501689600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2573,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1511280000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2657,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1512921600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2680,
                        "version": "3.3.2",
                        "guided": true,
                        "gaDate": 1515600000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2837,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1526486400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 306,
                "name": "Windows 10 Agent",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2169,
                        "version": "1.2.5",
                        "guided": true,
                        "gaDate": 1477584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 310,
                "name": "VMware Workspace ONE SDK for Android",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4200,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1586880000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4626,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1589472000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4628,
                        "version": "20.6",
                        "guided": true,
                        "gaDate": 1593014400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2663,
                        "version": "17.9",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2669,
                        "version": "17.11",
                        "guided": true,
                        "gaDate": 1516809600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2695,
                        "version": "17.9.1",
                        "guided": true,
                        "gaDate": 1515600000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2747,
                        "version": "18.2",
                        "guided": true,
                        "gaDate": 1519315200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2750,
                        "version": "17.11.1",
                        "guided": true,
                        "gaDate": 1517932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2789,
                        "version": "18.3",
                        "guided": true,
                        "gaDate": 1525276800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2806,
                        "version": "18.2.2",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2845,
                        "version": "18.5",
                        "guided": true,
                        "gaDate": 1529251200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2915,
                        "version": "18.6",
                        "guided": true,
                        "gaDate": 1532016000000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/APPSDK/SDK+-+Android+-+v18.6",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2964,
                        "version": "18.7",
                        "guided": true,
                        "gaDate": 1534694400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2989,
                        "version": "18.8",
                        "guided": true,
                        "gaDate": 1538064000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3060,
                        "version": "18.10",
                        "guided": true,
                        "gaDate": 1540742400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3090,
                        "version": "18.11",
                        "guided": true,
                        "gaDate": 1544457600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3348,
                        "version": "19.1",
                        "guided": true,
                        "gaDate": 1549468800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3387,
                        "version": "19.2",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3490,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1555344000000,
                        "rnLink": "https://my.workspaceone.com/products/Workspace-ONE-SDK",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3667,
                        "version": "19.6.1",
                        "guided": true,
                        "gaDate": 1563897600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3670,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1565798400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3766,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1568563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3835,
                        "version": "19.9",
                        "guided": true,
                        "gaDate": 1570723200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3847,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1573488000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3964,
                        "version": "19.11.1",
                        "guided": true,
                        "gaDate": 1577894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3990,
                        "version": "19.9.3",
                        "guided": true,
                        "gaDate": 1577030400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4011,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1581955200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4047,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1584028800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 311,
                "name": "vRealize Orchestrator Plug-in for Horizon",
                "childId": null,
                "parentId": 104,
                "releases": [
                    {
                        "id": 2248,
                        "version": "6.1.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": "https://www.vmware.com/support/vcops-view/doc/vrealize-horizon-61-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/details?productId=475&rPId=7642&downloadGroup=V4H-610-GA",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2249,
                        "version": "1.3.0",
                        "guided": true,
                        "gaDate": 1464624000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2250,
                        "version": "1.4.0",
                        "guided": true,
                        "gaDate": 1489593600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3080,
                        "version": "1.5.0",
                        "guided": true,
                        "gaDate": 1542038400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 313,
                "name": "VMware AirWatch SDK for iOS",
                "childId": 390,
                "parentId": null,
                "releases": [
                    {
                        "id": 2637,
                        "version": "5.9.5",
                        "guided": true,
                        "gaDate": 1511798400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2659,
                        "version": "17.6.1",
                        "guided": true,
                        "gaDate": 1513180800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2668,
                        "version": "17.11",
                        "guided": true,
                        "gaDate": 1516204800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2689,
                        "version": "5.9.6",
                        "guided": true,
                        "gaDate": 1522166400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2748,
                        "version": "18.2",
                        "guided": true,
                        "gaDate": 1519747200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2791,
                        "version": "18.4",
                        "guided": true,
                        "gaDate": 1526313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2847,
                        "version": "18.5",
                        "guided": true,
                        "gaDate": 1528732800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2849,
                        "version": "5.9.7",
                        "guided": true,
                        "gaDate": 1534348800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2921,
                        "version": "18.6.1",
                        "guided": true,
                        "gaDate": 1531929600000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/APPSDK/SDK+-+iOS+-+v18.6",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2968,
                        "version": "5.9.8",
                        "guided": true,
                        "gaDate": 1535990400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2987,
                        "version": "18.8",
                        "guided": true,
                        "gaDate": 1539100800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2988,
                        "version": "5.10",
                        "guided": true,
                        "gaDate": 1540915200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 314,
                "name": "VMware Tunnel for macOS",
                "childId": 420,
                "parentId": null,
                "releases": [
                    {
                        "id": 2449,
                        "version": "2.0.2",
                        "guided": true,
                        "gaDate": 1496592000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2785,
                        "version": "3.0.0",
                        "guided": true,
                        "gaDate": 1524412800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 316,
                "name": "VMware vRealize End Point Operations for Microsoft Hyper-V",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2339,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1494864000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3832,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 319,
                "name": "VMware vRealize End Point Operations for Java Application",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2407,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1500566400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3826,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 320,
                "name": "VMware vRealize End Point Operations for SharePoint",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2414,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1500566400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3829,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 321,
                "name": "Horizon Cloud with Hosted Infrastructure",
                "childId": 457,
                "parentId": null,
                "releases": [
                    {
                        "id": 2101,
                        "version": "16.11.0",
                        "guided": true,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2255,
                        "version": "17.1.0",
                        "guided": true,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2481,
                        "version": "17.1.1",
                        "guided": true,
                        "gaDate": 1497456000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2502,
                        "version": "17.2.0",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": "http://www.vmware.com/info?id=1441",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2707,
                        "version": "18.1",
                        "guided": true,
                        "gaDate": 1517328000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2869,
                        "version": "18.3.0",
                        "guided": true,
                        "gaDate": 1531929600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-Cloud-Service-with-Hosted-Infrastructure/18.3/rn/Horizon-Cloud-Hosted-183-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info?slug=desktop_end_user_computing/vmware_workspace_one/1_0#product_downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3004,
                        "version": "18.4.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 326,
                "name": "VMware Unified Access Gateway",
                "childId": null,
                "parentId": 152,
                "releases": [
                    {
                        "id": 4120,
                        "version": "3.9.1",
                        "guided": true,
                        "gaDate": 1584979200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2501,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1505923200000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.1/rn/unified_access_gateway-31-release-notes.html",
                        "dLink": "https://my.vmware.com/group/vmware/info?slug=desktop_end_user_computing/vmware_unified_access_gateway/3_1",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2604,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1513872000000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.2/rn/Unified-Access-Gateway-32-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2722,
                        "version": "3.3",
                        "guided": true,
                        "gaDate": 1526918400000,
                        "rnLink": "http://docs.vmware.com/en/Unified-Access-Gateway/3.3/rn/Unified-Access-Gateway-33-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2723,
                        "version": "3.2.1",
                        "guided": true,
                        "gaDate": 1518451200000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.2.1/rn/Unified-Access-Gateway-321-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_2",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2918,
                        "version": "3.3.1",
                        "guided": true,
                        "gaDate": 1534348800000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.3.1/rn/Unified-Access-Gateway-331-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2999,
                        "version": "3.4",
                        "guided": true,
                        "gaDate": 1543852800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3258,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1552320000000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.5/rn/Unified-Access-Gateway-35-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_5",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3462,
                        "version": "3.6",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.6/rn/Unified-Access-Gateway-36-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_6",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3631,
                        "version": "3.7",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": "https://docs.vmware.com/en/Unified-Access-Gateway/3.7/rn/Unified-Access-Gateway-37-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_unified_access_gateway/3_7",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3801,
                        "version": "3.8",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3943,
                        "version": "3.9",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4023,
                        "version": "3.7.2",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 329,
                "name": "VMware Horizon Air",
                "childId": null,
                "parentId": 154,
                "releases": [
                    {
                        "id": 1038,
                        "version": "15.3.3",
                        "guided": true,
                        "gaDate": 1447084800000,
                        "rnLink": "https://my.vmware.com/group/vmware/details?downloadGroup=HZN-AIR-1533&productId=504&download=true&fileId=69acef91470d4433b373203a3f3f9df7&secureParam=d93ee3f2c7e8cf48183f945f3239192e&uuId=19eef5e7-18fe-43ae-8bb3-38013a96d133&downloadType=",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_horizon_air/15_3",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1039,
                        "version": "15.3.2",
                        "guided": true,
                        "gaDate": 1437494400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1276,
                        "version": "15.3.0",
                        "guided": true,
                        "gaDate": 1426089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 1665,
                        "version": "16.6.0",
                        "guided": true,
                        "gaDate": 1471881600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 330,
                "name": "VMware Airwatch Application Deployment Agent for Windows",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2619,
                        "version": "3.1.0",
                        "guided": true,
                        "gaDate": 1516809600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3280,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1549296000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3479,
                        "version": "3.6",
                        "guided": true,
                        "gaDate": 1556121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3630,
                        "version": "3.8",
                        "guided": true,
                        "gaDate": 1563120000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 332,
                "name": "Horizon Cloud with On-Premises Infrastructure",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2210,
                        "version": "1.2.0",
                        "guided": true,
                        "gaDate": 1481126400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2366,
                        "version": "1.3.0",
                        "guided": true,
                        "gaDate": 1492617600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 335,
                "name": "VMware Remote Management for Windows Mobile/CE",
                "childId": 429,
                "parentId": null,
                "releases": [
                    {
                        "id": 2585,
                        "version": "4.2",
                        "guided": true,
                        "gaDate": 1510761600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2851,
                        "version": "4.4",
                        "guided": true,
                        "gaDate": 1531756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 337,
                "name": "VMware vRealize Suite Lifecycle Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2460,
                        "version": "1.0",
                        "guided": false,
                        "gaDate": 1505750400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Suite/2017/rn/vrealize-lifecycle-manager-1-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info?slug=infrastructure_operations_management/vmware_vrealize_suite/2017",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2556,
                        "version": "1.1",
                        "guided": false,
                        "gaDate": 1513008000000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Suite/2017/rn/vRealize-Suite-Lifecycle-Manager-11-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_suite/2017",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2662,
                        "version": "1.2",
                        "guided": false,
                        "gaDate": 1523462400000,
                        "rnLink": "https://docs.vmware.com/en/vRealize-Suite/2018/rn/vRealize-Suite-Lifecycle-Manager-12-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2810,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1531152000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2933,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3100,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Suite-Lifecycle-Manager/2018/rn/VMware-vRealize-Suite-Lifecycle-Manager-21-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_log_insight/4_8",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3577,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Suite-Lifecycle-Manager/2019/rn/VMware-vRealize-Suite-Lifecycle-Manager-80-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/infrastructure_operations_management/vmware_vrealize_suite/2019",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3872,
                        "version": "8.0.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3996,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-vRealize-Suite-Lifecycle-Manager/8.1/com.vmware.vrsuite.lcm.8.1.doc/GUID-AC013263-7003-402E-9A22-83BFFC4E6CAE.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 338,
                "name": "VMware vRealize Operations Compliance Pack for PCI",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2465,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1499011200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3391,
                        "version": "1.0.2",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 340,
                "name": "vCloud Director Extender",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2495,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2725,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1524067200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 341,
                "name": "VMware Datacenter CLI",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2537,
                        "version": "2.7.0",
                        "guided": true,
                        "gaDate": 1503849600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2983,
                        "version": "2.10.0",
                        "guided": true,
                        "gaDate": 1535904000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 346,
                "name": "VMware Application Proxy",
                "childId": 458,
                "parentId": null,
                "releases": [
                    {
                        "id": 2546,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2972,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 347,
                "name": "VMware Workspace ONE Admin Assistant for macOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2554,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1521043200000,
                        "rnLink": null,
                        "dLink": "https://vmware.sabacloud.com/Saba/Web_spf/NA1PRD0121/common/leclassview/virtc-02017548",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 349,
                "name": "Horizon Cloud Service on Microsoft Azure",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2560,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1507737600000,
                        "rnLink": "http://www.vmware.com/info?id=1433",
                        "dLink": "http://www.vmware.com/info?id=1430",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2706,
                        "version": "1.5",
                        "guided": true,
                        "gaDate": 1517328000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2870,
                        "version": "1.6",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Horizon-Cloud-Service-on-Microsoft-Azure/services/rn/horizon-cloud-on-microsoft-azure-relnotes16.html",
                        "dLink": "https://my.vmware.com/group/vmware/details?productId=716&downloadGroup=HCS-MA-160",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3005,
                        "version": "1.7.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3273,
                        "version": "1.8",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3312,
                        "version": "1.9",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3505,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1562169600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3749,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3937,
                        "version": "2.2",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3972,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 351,
                "name": "VMware Workspace ONE for macOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2599,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1518710400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3082,
                        "version": "1.0.3",
                        "guided": true,
                        "gaDate": 1541520000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 352,
                "name": "VMware PIV-D Manager for iOS",
                "childId": 427,
                "parentId": null,
                "releases": [
                    {
                        "id": 2840,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1527523200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 353,
                "name": "VMware People Search for Android",
                "childId": 424,
                "parentId": null,
                "releases": [
                    {
                        "id": 2614,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1512921600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2712,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1516809600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2744,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1521388800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2821,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1525881600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2857,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1531238400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 354,
                "name": "VMware AirWatch App Wrapping",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4216,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1588089600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2641,
                        "version": "AW-AppWrapping 5.3.1",
                        "guided": true,
                        "gaDate": 1488988800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2642,
                        "version": "AW-AppWrapping 5.4.1",
                        "guided": true,
                        "gaDate": 1505318400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2643,
                        "version": "AW-AppWrapping 5.4",
                        "guided": true,
                        "gaDate": 1500480000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2660,
                        "version": "5.4.3",
                        "guided": true,
                        "gaDate": 1509552000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2667,
                        "version": "5.4.4",
                        "guided": true,
                        "gaDate": 1516204800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2802,
                        "version": "5.4.5",
                        "guided": true,
                        "gaDate": 1526486400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2934,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1534867200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3031,
                        "version": "5.5.1",
                        "guided": true,
                        "gaDate": 1543766400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3611,
                        "version": "5.6",
                        "guided": true,
                        "gaDate": 1562515200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3768,
                        "version": "5.7",
                        "guided": true,
                        "gaDate": 1569945600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3900,
                        "version": "5.7.1",
                        "guided": true,
                        "gaDate": 1575475200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3963,
                        "version": "5.7.3",
                        "guided": true,
                        "gaDate": 1578412800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 357,
                "name": "VMware People Search for iOS",
                "childId": 425,
                "parentId": null,
                "releases": [
                    {
                        "id": 2673,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1512403200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2713,
                        "version": "1.0.2",
                        "guided": true,
                        "gaDate": 1516809600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2743,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1521648000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2820,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1525708800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2858,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1531065600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 359,
                "name": "VMware Xamarin",
                "childId": 391,
                "parentId": null,
                "releases": [
                    {
                        "id": 2971,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1535299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 360,
                "name": "VMware PIV-D Manager for Android",
                "childId": 426,
                "parentId": null,
                "releases": [
                    {
                        "id": 2692,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1519228800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 365,
                "name": "vRealize Orchestrator Plug-in for vCloud Director",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 2764,
                        "version": "9.1",
                        "guided": true,
                        "gaDate": 1520438400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2880,
                        "version": "9.5",
                        "guided": true,
                        "gaDate": 1538582400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3576,
                        "version": "9.7",
                        "guided": true,
                        "gaDate": 1553702400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3774,
                        "version": "10.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 366,
                "name": "Workspace ONE Send for Android",
                "childId": 392,
                "parentId": null,
                "releases": [
                    {
                        "id": 2816,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1526227200000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/ACL/Send+-+1.0+-+Android",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 367,
                "name": "Workspace ONE Send for iOS",
                "childId": 393,
                "parentId": null,
                "releases": [
                    {
                        "id": 2815,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1526227200000,
                        "rnLink": "https://confluence-euc.eng.vmware.com/display/ACL/Send+-+1.0+-+iOS",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 368,
                "name": "VMware vCloud Availability for Cloud-to-Cloud DR",
                "childId": 476,
                "parentId": null,
                "releases": [
                    {
                        "id": 2634,
                        "version": "vCloud Availability for Cloud-to-Cloud DR 1.0",
                        "guided": true,
                        "gaDate": 1526486400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2919,
                        "version": "vCloud Availability for Cloud-to-Cloud DR 1.5",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 369,
                "name": "Workspace ONE AirLift",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4046,
                        "version": "2.2",
                        "guided": true,
                        "gaDate": 1586188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 372,
                "name": "Hybrid Cloud Extension (HCX)",
                "childId": 660,
                "parentId": null,
                "releases": [
                    {
                        "id": 2874,
                        "version": "3.5.1",
                        "guided": true,
                        "gaDate": 1528905600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 377,
                "name": "Horizon Agents Installer",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3028,
                        "version": "18.5",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3032,
                        "version": "18.2.1",
                        "guided": true,
                        "gaDate": 1533225600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3033,
                        "version": "18.2.2",
                        "guided": true,
                        "gaDate": 1537977600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3034,
                        "version": "18.1.0",
                        "guided": true,
                        "gaDate": 1533225600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3038,
                        "version": "18.4",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3311,
                        "version": "19.1",
                        "guided": true,
                        "gaDate": 1551888000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3633,
                        "version": "19.2",
                        "guided": true,
                        "gaDate": 1562169600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3648,
                        "version": "18.2.3",
                        "guided": true,
                        "gaDate": 1563724800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3720,
                        "version": "19.1.1",
                        "guided": true,
                        "gaDate": 1566144000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3750,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3867,
                        "version": "18.2.4",
                        "guided": true,
                        "gaDate": 1572969600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3898,
                        "version": "19.3.1",
                        "guided": true,
                        "gaDate": 1574179200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3936,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3971,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1588780800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 380,
                "name": "VMware Workspace ONE Intelligent Hub for Android",
                "childId": null,
                "parentId": 248,
                "releases": [
                    {
                        "id": 4105,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1588694400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4152,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4235,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4238,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1591718400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3051,
                        "version": "9.0",
                        "guided": true,
                        "gaDate": 1540915200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3078,
                        "version": "9.0.1",
                        "guided": true,
                        "gaDate": 1544630400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3284,
                        "version": "9.0.1",
                        "guided": true,
                        "gaDate": 1545062400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3340,
                        "version": "19.2",
                        "guided": true,
                        "gaDate": 1551369600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3378,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1554220800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3438,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3521,
                        "version": "19.5",
                        "guided": true,
                        "gaDate": 1559491200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3561,
                        "version": "19.6",
                        "guided": true,
                        "gaDate": 1563120000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3610,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1565539200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3672,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1567958400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3740,
                        "version": "19.9",
                        "guided": true,
                        "gaDate": 1569945600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3790,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1572969600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3853,
                        "version": "19.11",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3960,
                        "version": "19.11.1",
                        "guided": true,
                        "gaDate": 1577894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3984,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1580659200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4001,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1582732800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 381,
                "name": "VMware Workspace ONE Intelligent Hub for iOS",
                "childId": null,
                "parentId": 249,
                "releases": [
                    {
                        "id": 4106,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4107,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1588521600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4239,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1592755200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4642,
                        "version": "20.4.1",
                        "guided": true,
                        "gaDate": 1589904000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3063,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1540915200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3217,
                        "version": "6.1",
                        "guided": true,
                        "gaDate": 1544976000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3339,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1553443200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3481,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1556640000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3556,
                        "version": "19.6",
                        "guided": true,
                        "gaDate": 1561564800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3637,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1564502400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3673,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1566921600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3739,
                        "version": "19.9",
                        "guided": true,
                        "gaDate": 1570032000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3791,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1573056000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3811,
                        "version": "19.9.1",
                        "guided": true,
                        "gaDate": 1570550400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3854,
                        "version": "19.11",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3998,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1582732800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4000,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 382,
                "name": "VMware Workspace ONE UEM Console",
                "childId": null,
                "parentId": 195,
                "releases": [
                    {
                        "id": 4100,
                        "version": "2004",
                        "guided": true,
                        "gaDate": 1585843200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/2004/rn/VMware-Workspace-ONE-UEM-Release-Notes-2004.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4176,
                        "version": "2005",
                        "guided": true,
                        "gaDate": 1591804800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4664,
                        "version": "2006",
                        "guided": true,
                        "gaDate": 1591804800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3070,
                        "version": "1810",
                        "guided": true,
                        "gaDate": 1541088000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1810/rn/VMware-Workspace-ONE-UEM-Release-Notes-1810.html",
                        "dLink": "https://resources.workspaceone.com/view/3z9w7b5kpxz33f3h483s/",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3071,
                        "version": "1811",
                        "guided": true,
                        "gaDate": 1544976000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1811/rn/-VMware-Workspace-ONE-UEM-Release-Notes-1811.html",
                        "dLink": "https://resources.workspaceone.com/view/9dxrr35zyr28t4yvsndr/",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3223,
                        "version": "1902",
                        "guided": true,
                        "gaDate": 1552406400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1902/rn/VMware-Workspace-ONE-UEM-Release-Notes-1902.html",
                        "dLink": "https://resources.workspaceone.com/view/q3qmcb2cj54qkfn82jwy/en",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3372,
                        "version": "1903",
                        "guided": true,
                        "gaDate": 1555603200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1903/rn/VMware-Workspace-ONE-UEM-Release-Notes-1903.html",
                        "dLink": "https://resources.workspaceone.com/view/qcmh9q9cvzftjv4yhbvs/",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3463,
                        "version": "1904",
                        "guided": true,
                        "gaDate": 1556035200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1904/rn/VMware-Workspace-ONE-UEM-Release-Notes-1904.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3516,
                        "version": "1905",
                        "guided": true,
                        "gaDate": 1559145600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1905/rn/VMware-Workspace-ONE-UEM-Release-Notes-1905.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3584,
                        "version": "1907",
                        "guided": true,
                        "gaDate": 1564675200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1907/rn/VMware-Workspace-ONE-UEM-Release-Notes-1907.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3699,
                        "version": "1908",
                        "guided": true,
                        "gaDate": 1566230400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1908/rn/VMware-Workspace-ONE-UEM-Release-Notes-1908.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3731,
                        "version": "1909",
                        "guided": true,
                        "gaDate": 1571587200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1909/rn/VMware-Workspace-ONE-UEM-Release-Notes-1909.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3836,
                        "version": "1910",
                        "guided": true,
                        "gaDate": 1572364800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3855,
                        "version": "1912",
                        "guided": true,
                        "gaDate": 1575907200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workspace-ONE-UEM/1912/rn/VMware-Workspace-ONE-UEM-Release-Notes-1912.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3939,
                        "version": "2001",
                        "guided": true,
                        "gaDate": 1582646400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4065,
                        "version": "2003",
                        "guided": true,
                        "gaDate": 1583424000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 383,
                "name": "VMware Workspace ONE Intelligent Hub for macOS",
                "childId": null,
                "parentId": 254,
                "releases": [
                    {
                        "id": 4097,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1583683200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4108,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1586102400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4179,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1589472000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4260,
                        "version": "20.6",
                        "guided": true,
                        "gaDate": 1592409600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3073,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1539619200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3281,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1556121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3562,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1563206400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3680,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1566316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3725,
                        "version": "19.9",
                        "guided": true,
                        "gaDate": 1569772800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3802,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1572451200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3891,
                        "version": "19.12",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3997,
                        "version": "19.12.1",
                        "guided": true,
                        "gaDate": 1579536000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4030,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 384,
                "name": "VMware Workspace ONE Intelligent Hub for Windows 10",
                "childId": null,
                "parentId": 302,
                "releases": [
                    {
                        "id": 4098,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1583683200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4109,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1586102400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4196,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1589472000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4261,
                        "version": "20.6",
                        "guided": true,
                        "gaDate": 1592409600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3072,
                        "version": "18.10",
                        "guided": true,
                        "gaDate": 1539619200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3083,
                        "version": "18.11",
                        "guided": true,
                        "gaDate": 1543852800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3279,
                        "version": "19.2",
                        "guided": true,
                        "gaDate": 1549900800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3410,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1553616000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3480,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1556121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3537,
                        "version": "19.5",
                        "guided": true,
                        "gaDate": 1559232000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3538,
                        "version": "19.12",
                        "guided": true,
                        "gaDate": 1575907200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3590,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1563465600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3679,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1566316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3748,
                        "version": "19.9",
                        "guided": true,
                        "gaDate": 1569772800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3803,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1572451200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3999,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 385,
                "name": "VMware Workspace ONE Content for Android",
                "childId": null,
                "parentId": 255,
                "releases": [
                    {
                        "id": 4151,
                        "version": "3.22",
                        "guided": true,
                        "gaDate": 1588003200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3107,
                        "version": "3.16",
                        "guided": true,
                        "gaDate": 1543766400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3110,
                        "version": "3.15",
                        "guided": true,
                        "gaDate": 1538582400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3446,
                        "version": "3.17",
                        "guided": true,
                        "gaDate": 1555516800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3573,
                        "version": "3.18",
                        "guided": true,
                        "gaDate": 1561996800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3639,
                        "version": "3.19",
                        "guided": true,
                        "gaDate": 1564502400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3733,
                        "version": "3.20",
                        "guided": true,
                        "gaDate": 1569513600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3920,
                        "version": "3.21",
                        "guided": true,
                        "gaDate": 1577894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 386,
                "name": "VMware Workspace ONE Content for iOS",
                "childId": null,
                "parentId": 253,
                "releases": [
                    {
                        "id": 4683,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1593360000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3105,
                        "version": "4.14",
                        "guided": true,
                        "gaDate": 1536076800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3109,
                        "version": "4.15",
                        "guided": true,
                        "gaDate": 1537977600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3114,
                        "version": "4.16",
                        "guided": true,
                        "gaDate": 1543161600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3341,
                        "version": "4.17",
                        "guided": true,
                        "gaDate": 1553443200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3574,
                        "version": "4.18",
                        "guided": true,
                        "gaDate": 1562515200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3676,
                        "version": "4.19",
                        "guided": true,
                        "gaDate": 1567440000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3895,
                        "version": "4.19.3",
                        "guided": true,
                        "gaDate": 1571932800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3919,
                        "version": "4.20",
                        "guided": true,
                        "gaDate": 1577635200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4024,
                        "version": "4.21",
                        "guided": true,
                        "gaDate": 1581523200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4077,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1590422400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 388,
                "name": "VMware Workspace ONE Launcher for Android",
                "childId": null,
                "parentId": 258,
                "releases": [
                    {
                        "id": 3104,
                        "version": "4.2",
                        "guided": true,
                        "gaDate": 1545062400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3368,
                        "version": "4.3",
                        "guided": true,
                        "gaDate": 1554048000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3483,
                        "version": "4.4",
                        "guided": true,
                        "gaDate": 1560096000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3589,
                        "version": "4.4.1",
                        "guided": true,
                        "gaDate": 1563292800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3863,
                        "version": "4.5",
                        "guided": true,
                        "gaDate": 1572796800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3973,
                        "version": "4.6",
                        "guided": true,
                        "gaDate": 1584374400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 390,
                "name": "VMware Workspace ONE SDK for iOS",
                "childId": null,
                "parentId": 313,
                "releases": [
                    {
                        "id": 4191,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1586448000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4211,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1586966400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4214,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1587052800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4644,
                        "version": "20.5",
                        "guided": true,
                        "gaDate": 1590940800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4672,
                        "version": "20.6",
                        "guided": true,
                        "gaDate": 1593360000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3113,
                        "version": "18.10",
                        "guided": true,
                        "gaDate": 1541001600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3350,
                        "version": "19.2.1",
                        "guided": true,
                        "gaDate": 1552838400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3367,
                        "version": "18.11.1",
                        "guided": true,
                        "gaDate": 1546876800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3484,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1554739200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3491,
                        "version": "5.10.3",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3520,
                        "version": "19.4",
                        "guided": true,
                        "gaDate": 1555603200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3533,
                        "version": "19.4.1",
                        "guided": true,
                        "gaDate": 1556035200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3534,
                        "version": "19.4.2",
                        "guided": true,
                        "gaDate": 1556467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3563,
                        "version": "19.5",
                        "guided": true,
                        "gaDate": 1558972800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3620,
                        "version": "19.6",
                        "guided": true,
                        "gaDate": 1562688000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3654,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1563379200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3671,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1567612800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3705,
                        "version": "19.7.1",
                        "guided": true,
                        "gaDate": 1565712000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3736,
                        "version": "5.9.9 U5",
                        "guided": true,
                        "gaDate": 1567526400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3798,
                        "version": "19.8.1",
                        "guided": true,
                        "gaDate": 1569168000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3845,
                        "version": "19.10",
                        "guided": true,
                        "gaDate": 1572278400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3871,
                        "version": "19.11",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3903,
                        "version": "19.12",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4014,
                        "version": "20.1",
                        "guided": true,
                        "gaDate": 1580745600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4048,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1583164800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 392,
                "name": "VMware Workspace ONE Send for Android",
                "childId": null,
                "parentId": 366,
                "releases": [
                    {
                        "id": 4178,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1588003200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3108,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1543852800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 393,
                "name": "VMware Workspace ONE Send for iOS",
                "childId": null,
                "parentId": 367,
                "releases": [
                    {
                        "id": 4121,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1586102400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 414,
                "name": "VMware Workspace ONE Boxer for Android",
                "childId": null,
                "parentId": 271,
                "releases": [
                    {
                        "id": 4112,
                        "version": "5.16",
                        "guided": true,
                        "gaDate": 1585584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4172,
                        "version": "5.17",
                        "guided": true,
                        "gaDate": 1588521600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4277,
                        "version": "5.18",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3167,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1547049600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3169,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1539100800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3175,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1540828800000,
                        "rnLink": "https://my.workspaceone.com/products/VMware-Workspace-ONE-Boxer",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3293,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1548604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3309,
                        "version": "5.2.1",
                        "guided": true,
                        "gaDate": 1547395200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3335,
                        "version": "5.4",
                        "guided": true,
                        "gaDate": 1551628800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3411,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1554134400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3459,
                        "version": "5.6",
                        "guided": true,
                        "gaDate": 1556467200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3545,
                        "version": "5.7",
                        "guided": true,
                        "gaDate": 1560096000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3585,
                        "version": "5.8",
                        "guided": true,
                        "gaDate": 1561910400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3644,
                        "version": "5.9",
                        "guided": true,
                        "gaDate": 1563984000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3695,
                        "version": "5.10",
                        "guided": true,
                        "gaDate": 1568217600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3764,
                        "version": "5.11",
                        "guided": true,
                        "gaDate": 1571587200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3850,
                        "version": "5.12",
                        "guided": true,
                        "gaDate": 1573488000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3889,
                        "version": "5.13",
                        "guided": true,
                        "gaDate": 1576598400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3890,
                        "version": "5.14",
                        "guided": true,
                        "gaDate": 1579104000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4017,
                        "version": "5.14.1",
                        "guided": true,
                        "gaDate": 1580313600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4064,
                        "version": "5.15",
                        "guided": true,
                        "gaDate": 1583856000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 415,
                "name": "VMware Workspace ONE Boxer for iOS",
                "childId": null,
                "parentId": 261,
                "releases": [
                    {
                        "id": 4113,
                        "version": "5.16",
                        "guided": true,
                        "gaDate": 1586188800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4173,
                        "version": "5.17",
                        "guided": true,
                        "gaDate": 1587916800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4272,
                        "version": "5.17.1",
                        "guided": true,
                        "gaDate": 1588176000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4278,
                        "version": "5.18",
                        "guided": true,
                        "gaDate": 1589990400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4648,
                        "version": "5.18.1",
                        "guided": true,
                        "gaDate": 1590508800000,
                        "rnLink": "https://docs-staging.vmware.com/en/VMware-Workspace-ONE-Boxer/services/rn/VMware-Workspace-ONE-Boxer-for-Android.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3158,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1543334400000,
                        "rnLink": "https://my.workspaceone.com/products/VMware-Boxer",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3159,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1544976000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3164,
                        "version": "5.0.1",
                        "guided": true,
                        "gaDate": 1542124800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3166,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1541001600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3308,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1548604800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3334,
                        "version": "5.4",
                        "guided": true,
                        "gaDate": 1551024000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3403,
                        "version": "5.4.1",
                        "guided": true,
                        "gaDate": 1552838400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3412,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1553702400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3458,
                        "version": "5.6",
                        "guided": true,
                        "gaDate": 1555948800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3544,
                        "version": "5.7",
                        "guided": true,
                        "gaDate": 1558368000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3595,
                        "version": "5.8",
                        "guided": true,
                        "gaDate": 1562256000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3643,
                        "version": "5.9",
                        "guided": true,
                        "gaDate": 1564329600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3660,
                        "version": "5.10",
                        "guided": true,
                        "gaDate": 1567008000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3747,
                        "version": "5.10.1",
                        "guided": true,
                        "gaDate": 1568563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3765,
                        "version": "5.11",
                        "guided": true,
                        "gaDate": 1570032000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3773,
                        "version": "5.10.2",
                        "guided": true,
                        "gaDate": 1568736000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3822,
                        "version": "5.11.2",
                        "guided": true,
                        "gaDate": 1571155200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3858,
                        "version": "5.12",
                        "guided": true,
                        "gaDate": 1572364800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3887,
                        "version": "5.13",
                        "guided": true,
                        "gaDate": 1576512000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3888,
                        "version": "5.14",
                        "guided": true,
                        "gaDate": 1580659200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4043,
                        "version": "5.14.1",
                        "guided": true,
                        "gaDate": 1581523200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4049,
                        "version": "5.15",
                        "guided": true,
                        "gaDate": 1583337600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 416,
                "name": "VMware Workspace ONE Notebook for Android",
                "childId": null,
                "parentId": 378,
                "releases": [
                    {
                        "id": 4636,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1589731200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 417,
                "name": "VMware Workspace ONE Notebook for iOS",
                "childId": null,
                "parentId": 379,
                "releases": [
                    {
                        "id": 4168,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1589731200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3174,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1551628800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 418,
                "name": "VMware Workspace ONE Tunnel for Android",
                "childId": null,
                "parentId": 305,
                "releases": [
                    {
                        "id": 4234,
                        "version": "5.6",
                        "guided": true,
                        "gaDate": 1589299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3295,
                        "version": "4.2.1",
                        "guided": true,
                        "gaDate": 1547481600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3448,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1555862400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3557,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1562774400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3722,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1567612800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3805,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1572364800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3899,
                        "version": "5.4",
                        "guided": true,
                        "gaDate": 1576080000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3955,
                        "version": "5.4.1",
                        "guided": true,
                        "gaDate": 1576771200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4036,
                        "version": "5.4.2",
                        "guided": true,
                        "gaDate": 1581350400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4069,
                        "version": "5.5",
                        "guided": true,
                        "gaDate": 1584028800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 419,
                "name": "VMware Workspace ONE Tunnel for iOS",
                "childId": null,
                "parentId": 282,
                "releases": [
                    {
                        "id": 3161,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1547049600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3450,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1555862400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3729,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1568563200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 420,
                "name": "VMware Workspace ONE Tunnel for macOS",
                "childId": null,
                "parentId": 314,
                "releases": [
                    {
                        "id": 4139,
                        "version": "4.2",
                        "guided": true,
                        "gaDate": 1586707200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3165,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1548777600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3452,
                        "version": "4.0",
                        "guided": true,
                        "gaDate": 1557158400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3730,
                        "version": "4.1",
                        "guided": true,
                        "gaDate": 1570032000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 421,
                "name": "VMware Workspace ONE Tunnel for Windows",
                "childId": null,
                "parentId": 281,
                "releases": [
                    {
                        "id": 3361,
                        "version": "2.0.1",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 422,
                "name": "VMware Workspace ONE Web for Android",
                "childId": null,
                "parentId": 247,
                "releases": [
                    {
                        "id": 4279,
                        "version": "7.13",
                        "guided": true,
                        "gaDate": 1589299200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4641,
                        "version": "7.14",
                        "guided": true,
                        "gaDate": 1591545600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3163,
                        "version": "7.1",
                        "guided": true,
                        "gaDate": 1542729600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3168,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1539100800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3170,
                        "version": "7.2",
                        "guided": true,
                        "gaDate": 1545148800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3310,
                        "version": "7.3",
                        "guided": true,
                        "gaDate": 1549987200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3401,
                        "version": "7.4",
                        "guided": true,
                        "gaDate": 1553616000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3460,
                        "version": "7.5",
                        "guided": true,
                        "gaDate": 1556726400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3552,
                        "version": "7.6",
                        "guided": true,
                        "gaDate": 1560700800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3596,
                        "version": "7.7",
                        "guided": true,
                        "gaDate": 1564588800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3728,
                        "version": "7.8",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3841,
                        "version": "7.9",
                        "guided": true,
                        "gaDate": 1572796800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3842,
                        "version": "7.10",
                        "guided": true,
                        "gaDate": 1574611200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3930,
                        "version": "7.10.8",
                        "guided": true,
                        "gaDate": 1577894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4016,
                        "version": "7.11",
                        "guided": true,
                        "gaDate": 1581264000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4081,
                        "version": "7.12",
                        "guided": true,
                        "gaDate": 1584979200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 423,
                "name": "VMware Workspace ONE Web for iOS",
                "childId": null,
                "parentId": 252,
                "releases": [
                    {
                        "id": 4228,
                        "version": "7.14",
                        "guided": true,
                        "gaDate": 1588521600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4653,
                        "version": "7.15",
                        "guided": true,
                        "gaDate": 1591718400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3160,
                        "version": "7.0",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3162,
                        "version": "7.1",
                        "guided": true,
                        "gaDate": 1541952000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3171,
                        "version": "7.2",
                        "guided": true,
                        "gaDate": 1544544000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3282,
                        "version": "7.2.1",
                        "guided": true,
                        "gaDate": 1545148800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3336,
                        "version": "7.3",
                        "guided": true,
                        "gaDate": 1549209600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3376,
                        "version": "7.3.1",
                        "guided": true,
                        "gaDate": 1550592000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3402,
                        "version": "7.4",
                        "guided": true,
                        "gaDate": 1554048000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3461,
                        "version": "7.5",
                        "guided": true,
                        "gaDate": 1555862400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3553,
                        "version": "7.6",
                        "guided": true,
                        "gaDate": 1559750400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3586,
                        "version": "7.7",
                        "guided": true,
                        "gaDate": 1561564800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3659,
                        "version": "7.8",
                        "guided": true,
                        "gaDate": 1565193600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3706,
                        "version": "7.9",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3820,
                        "version": "7.10",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3848,
                        "version": "7.11",
                        "guided": true,
                        "gaDate": 1574006400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3985,
                        "version": "7.11.1",
                        "guided": true,
                        "gaDate": 1578585600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4018,
                        "version": "7.12",
                        "guided": true,
                        "gaDate": 1580918400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4080,
                        "version": "7.13",
                        "guided": true,
                        "gaDate": 1583856000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 424,
                "name": "VMware Workspace ONE People for Android",
                "childId": null,
                "parentId": 353,
                "releases": [
                    {
                        "id": 4140,
                        "version": "1.3.3",
                        "guided": true,
                        "gaDate": 1585584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3201,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3965,
                        "version": "1.3.2",
                        "guided": true,
                        "gaDate": 1577894400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 425,
                "name": "VMware Workspace ONE People for iOS",
                "childId": null,
                "parentId": 357,
                "releases": [
                    {
                        "id": 4141,
                        "version": "1.3.3",
                        "guided": true,
                        "gaDate": 1585756800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3200,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 426,
                "name": "VMware Workspace ONE PIV-D Manager for Android",
                "childId": null,
                "parentId": 360,
                "releases": [
                    {
                        "id": 4158,
                        "version": "20.4",
                        "guided": true,
                        "gaDate": 1588176000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3199,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1541347200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3211,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1557417600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 427,
                "name": "VMware Workspace ONE PIV-D Manager for iOS",
                "childId": null,
                "parentId": 352,
                "releases": [
                    {
                        "id": 4677,
                        "version": "20.6",
                        "guided": true,
                        "gaDate": 1592841600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3197,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1541433600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3212,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1556121600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3688,
                        "version": "1.5",
                        "guided": true,
                        "gaDate": 1572969600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3737,
                        "version": "1.4.2",
                        "guided": true,
                        "gaDate": 1567526400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3869,
                        "version": "1.5.1",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4092,
                        "version": "1.6",
                        "guided": true,
                        "gaDate": 1585584000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 428,
                "name": "VMware Advanced Remote Management for Android",
                "childId": 503,
                "parentId": 251,
                "releases": [
                    {
                        "id": 3198,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1544371200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3420,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1555603200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 429,
                "name": "VMware Advanced Remote Management for Windows Mobile/CE",
                "childId": 504,
                "parentId": 335,
                "releases": [
                    {
                        "id": 3196,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1544371200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3421,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1555603200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 431,
                "name": "vRealize Operations Management Pack for NSX - vSphere",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3237,
                        "version": "3.5.2",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3666,
                        "version": "3.6",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.6/rn/NSX-for-vSphere-36-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4083,
                        "version": "3.6.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.6.1/rn/NSX-for-vSphere-361-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-nsx-for-vsphere#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 432,
                "name": "vRealize Operations Management Pack for VMware Integrated OpenStack",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4137,
                        "version": "5.1.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/5.1.1/rn/VMware-Integrated-OpenStack-511-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-openstack#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4639,
                        "version": "6.0",
                        "guided": true,
                        "gaDate": 1593014400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3255,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1530547200000,
                        "rnLink": "https://marketplace-download.vmware.com/resources/a15677ab55ee11e78024005056a107c6/doc/b26e09e5718c447a9e6ec6ace3117e0d/vRealize%20Operations%20Management%20Pack%20for%20OpenStack%205.0-ReleaseNotes.pdf",
                        "dLink": "https://marketplace-download.vmware.com/resources/a15677ab55ee11e78024005056a107c6/doc/bc9db7faf00e4eb3b270dce76eaeaec2/open_source_license_vRealize_Management_Pack_for_OpenStack_5.0_GA.txt",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3684,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/5.1/rn/Management-Pack-for-VMware-Integrated-OpenStack-51-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 434,
                "name": "vRealize Operations Tenant App for vCloud Director",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3266,
                        "version": "vROps TenantApp for VCD 1.2",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3267,
                        "version": "vROps TenantApp for VCD 1.1",
                        "guided": true,
                        "gaDate": 1508342400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3268,
                        "version": "vROps TenantApp for VCD 2.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": "https://marketplace.vmware.com/resources/a107886c55ee11e78024005056a107c6/doc/bc25de1537db46fcb09f8785a70a5dad/vRealize%20Operations%20Manager%20Tenant%20App%20for%20vCloud%20Director%202.0%20Release%20Notes.pdf",
                        "dLink": "https://download3.vmware.com/software/mp-2018/vROps-Tenant-App-for-vCD-2.0.0.23799-10119879.oss.tgz",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3270,
                        "version": "vROps TenantApp for VCD 2.1",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": null,
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vcloud-director#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3449,
                        "version": "vROps TenantApp for VCD 2.2",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3603,
                        "version": "vROps TenantApp for VCD 2.3",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/2.3/rn/Tenant-App-23-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4005,
                        "version": "vROps TenantApp for VCD 2.4",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/2.4/rn/Tenant-App-24-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vcloud-director#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 435,
                "name": "VMware vRealize Operations Management Pack for VMware vCloud Director",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3264,
                        "version": "4.5",
                        "guided": true,
                        "gaDate": 1498579200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3269,
                        "version": "5.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": "https://marketplace.vmware.com/resources/a107886c55ee11e78024005056a107c6/doc/ff867324d4404feb97d8e1654d131a9b/vRealize%20Operations%20Management%20Pack%20for%20vCloud%20Director%205.0%20-%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vcloud-director",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3393,
                        "version": "5.1",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3601,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/5.2/rn/vCloud-Director-52-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4006,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/5.3/rn/vCloud-Director-53-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vcloud-director#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 437,
                "name": "VMware Workspace ONE Intelligent Hub for Linux",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3381,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1553616000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 438,
                "name": "VMware vRealize Operations Management Pack for VMware Skyline",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3274,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1556208000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3548,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1558281600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.0.1/rn/VMware-Skyline-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vmware-vrealize-operations-management-pack-for-vmware-skyline",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3689,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.1/rn/VMware-Skyline-11-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 439,
                "name": "VMware vRealize Operations Federation Management Pack",
                "childId": 689,
                "parentId": null,
                "releases": [
                    {
                        "id": 3275,
                        "version": "1.0.1",
                        "guided": true,
                        "gaDate": 1530547200000,
                        "rnLink": "https://marketplace-download.vmware.com/resources/74ecfbbab2dc4421b9254e8100202385/doc/e5f2e487e2f246d49b8f6b862a555ce2/VMware%20vRealize%C2%AE%20Operations%20Federation%20Management%20Pack%201.0.1%20-%20Release%20Notes.pdf",
                        "dLink": "https://marketplace-download.vmware.com/resources/74ecfbbab2dc4421b9254e8100202385/doc/b8067cb21b804f5e98a1d90206d9d7a6/vRealize_Operations_Federation_Management_pack_1.0.6828309.zip",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3276,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1507651200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3277,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1545235200000,
                        "rnLink": null,
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrops-federation-management-pack",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3690,
                        "version": "1.1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.1.1/rn/Federation-Management-Pack-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 441,
                "name": "VMware vRealize Operations Service Discovery Management Pack",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3297,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1527609600000,
                        "rnLink": "https://marketplace-download.vmware.com/resources/9e418d7555ee11e78024005056a107c6/doc/ec17c645987e4254a2e184a8a045bdc1/vRealize%20Operations%20Service%20Discovery%20Management%20Pack%202.1%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-service-discovery-management-pack?ref=search",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 442,
                "name": "VMware vRealize Operations Management Pack for Cloud Provider Pod",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3352,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3600,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.0/rn/Cloud-Provider-Pod-30-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4085,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.1/rn/Cloud-Provider-Pod-31-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-manager-management-pack-for-vmware-cloud-provider-pod#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 443,
                "name": "VMware vRealize Operations Management Pack for vRealize Orchestrator",
                "childId": null,
                "parentId": 440,
                "releases": [
                    {
                        "id": 4157,
                        "version": "3.1.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.1.1/rn/vRealize-Orchestrator-311-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vrealize-orchestrator#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3305,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1523462400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3306,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": "https://marketplace-download.vmware.com/resources/b86b5a4e94eb4278a133e9dca4411116/doc/c7a552c9ff5e45829105a07233e330ca/Management%20Pack%20for%20vRealize%20Orchestrator%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/management-pack-for-vrealize-orchestrator",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3307,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3599,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.1/rn/vRealize-Orchestrator-31-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 444,
                "name": "VMware vRealize End Point Operations for Active Directory",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3315,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1441814400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3814,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 445,
                "name": "vRealize Automation Plugin for Storage Automation",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3317,
                        "version": "2.5",
                        "guided": true,
                        "gaDate": 1548345600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 446,
                "name": "VMware vRealize End Point Operations for Microsoft SQL Server",
                "childId": null,
                "parentId": 317,
                "releases": [
                    {
                        "id": 3337,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1478707200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3816,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 450,
                "name": "VMware vRealize Operations Management Pack for VMware NSX-T",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3355,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3550,
                        "version": "2.1",
                        "guided": true,
                        "gaDate": 1563897600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/2.1/rn/NSX-T-21-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vmware-vrealize-operations-management-pack-for-nsx-t",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3735,
                        "version": "2.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/2.2/rn/NSX-T-22-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3928,
                        "version": "2.1.1",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3929,
                        "version": "2.3",
                        "guided": true,
                        "gaDate": 1576684800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 451,
                "name": "VMware vRealize Operations Management Pack for VMware Identity Manager",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3354,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3665,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.1/rn/VMware-Identity-Manager-11-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 452,
                "name": "VMware vRealize Operations Management Pack for Container Monitoring",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4118,
                        "version": "1.4.3",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.4.3/rn/Container-Monitoring-143-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-container-monitoring#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3359,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1539187200000,
                        "rnLink": "https://marketplace.vmware.com/resources/5e2577e0424642e395074ec18ceb9b45/doc/cee7fb454d8e4575958ecda0d1b86f1c/vRealize_Operations_Management_Pack_for_Container_Monitoring_Release_Notes_1.2.pdf",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-container-monitoring",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3360,
                        "version": "1.2.1",
                        "guided": true,
                        "gaDate": 1550419200000,
                        "rnLink": null,
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-container-monitoring",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3466,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3549,
                        "version": "1.4.1",
                        "guided": true,
                        "gaDate": 1560787200000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.4.1/rn/Container-Monitoring-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-management-pack-for-container-monitoring",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3691,
                        "version": "1.4.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.4.2/rn/Container-Monitoring-142-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 453,
                "name": "VMware vRealize Operations Management Pack for AWS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3370,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1537372800000,
                        "rnLink": "https://marketplace.vmware.com/resources/a52413f055ee11e78024005056a107c6/doc/c2ff6edefe0e4ef4b3f6a3ef0cd224e3/AWS%203.0%20Release%20Notes.pdf",
                        "dLink": "https://marketplace.vmware.com/resources/a52413f055ee11e78024005056a107c6/doc/1888692dbf1d48b994c004d834abdd58/open_source_license_vROps_MP_for_AWS_3.0_GA.txt",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3371,
                        "version": "3.1",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3770,
                        "version": "3.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 454,
                "name": "VMware Fusion",
                "childId": null,
                "parentId": 87,
                "releases": [
                    {
                        "id": 2368,
                        "version": "10.0",
                        "guided": false,
                        "gaDate": 1506355200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/10.0/rn/fusion-100-release-notes.html",
                        "dLink": "https://my.vmware.com/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/10_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2589,
                        "version": "10.1.0",
                        "guided": false,
                        "gaDate": 1513785600000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/10.0/rn/fusion-1010-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/10_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2738,
                        "version": "11.0.0",
                        "guided": true,
                        "gaDate": 1537718400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/11/rn/fusion-11-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/11_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 2754,
                        "version": "10.1.2",
                        "guided": false,
                        "gaDate": 1526832000000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/10/rn/fusion-1012-release-notes.html",
                        "dLink": "https://my.vmware.com/en/group/vmware/info?slug=desktop_end_user_computing/vmware_fusion/10_0#product_downloads",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": false,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3047,
                        "version": "11.0.1",
                        "guided": true,
                        "gaDate": 1541692800000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/11/rn/fusion-1101-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/11_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3210,
                        "version": "11.1.0",
                        "guided": true,
                        "gaDate": 1557763200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/11.1.0/rn/VMware-Fusion-1110-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/11_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3405,
                        "version": "11.5.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/11.5.0/rn/VMware-Fusion-1150-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/11_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3877,
                        "version": "11.5.5",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Fusion/11.5.0/rn/VMware-Fusion-1155-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_fusion/11_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 455,
                "name": "VMware Workstation",
                "childId": null,
                "parentId": 216,
                "releases": [
                    {
                        "id": 2745,
                        "version": "Workstation 15.0.0",
                        "guided": true,
                        "gaDate": 1537718400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/15/rn/workstation-15-release-notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/15_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": true,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3384,
                        "version": "Workstation 15.1.0",
                        "guided": true,
                        "gaDate": 1557763200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/15/rn/VMware-Workstation-151-Pro-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/15_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3418,
                        "version": "Workstation 15.5.0",
                        "guided": true,
                        "gaDate": 1568822400000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/15.5/rn/VMware-Workstation-155-Pro-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/15_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3878,
                        "version": "Workstation 15.5.5",
                        "guided": true,
                        "gaDate": 1590595200000,
                        "rnLink": "https://docs.vmware.com/en/VMware-Workstation-Pro/15.5/rn/VMware-Workstation-1555-Pro-Release-Notes.html",
                        "dLink": "https://my.vmware.com/en/web/vmware/info/slug/desktop_end_user_computing/vmware_workstation_pro/15_0",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 456,
                "name": "VMware vRealize Operations Management Pack for Storage Devices",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3433,
                        "version": "7.5",
                        "guided": true,
                        "gaDate": 1556208000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3598,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/8.0/rn/Storage-Devices-80-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 457,
                "name": "Horizon Cloud Service on IBM Cloud",
                "childId": null,
                "parentId": 321,
                "releases": [
                    {
                        "id": 4159,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1588780800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3435,
                        "version": "19.1",
                        "guided": true,
                        "gaDate": 1553097600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3751,
                        "version": "19.3",
                        "guided": true,
                        "gaDate": 1568649600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 458,
                "name": "vRealize Application Remote Collector",
                "childId": null,
                "parentId": 346,
                "releases": [
                    {
                        "id": 3437,
                        "version": "7.5",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3642,
                        "version": "8.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4095,
                        "version": "8.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 461,
                "name": "VMware vRealize End Point Operations for Oracle WebLogic Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3440,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1491148800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3823,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 462,
                "name": "VMware vRealize End Point Operations for PostgreSQL",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3439,
                        "version": "1.1.0",
                        "guided": true,
                        "gaDate": 1478707200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3817,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 464,
                "name": "VMware Workspace ONE Content SDK for Android",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3635,
                        "version": "19.7",
                        "guided": true,
                        "gaDate": 1563465600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3649,
                        "version": "19.6",
                        "guided": true,
                        "gaDate": 1561824000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3658,
                        "version": "19.8",
                        "guided": true,
                        "gaDate": 1566835200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3856,
                        "version": "19.11",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4087,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1583769600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 465,
                "name": "VMware Workspace ONE Content SDK for iOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3588,
                        "version": "19.5",
                        "guided": true,
                        "gaDate": 1558022400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3857,
                        "version": "19.11",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3916,
                        "version": "19.12",
                        "guided": true,
                        "gaDate": 1575993600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4045,
                        "version": "20.2",
                        "guided": true,
                        "gaDate": 1581436800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4088,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1583942400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 469,
                "name": "VMware vRealize End Point Operations for JBoss Enterprise Application Platform",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3499,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1481731200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3827,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 470,
                "name": "VMware vRealize End Point Operations for Microsoft Exchange Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3500,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1481644800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3831,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 471,
                "name": "VMware vRealize End Point Operations for Microsoft Internet Information Services",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3501,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1478707200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3830,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 472,
                "name": "VMware vRealize End Point Operations for Pivotal tc Server",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3502,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1478620800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3828,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 473,
                "name": "VMware vRealize End Point Operations for Rabbit MQ",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3498,
                        "version": "1.0.0",
                        "guided": true,
                        "gaDate": 1486051200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3824,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 474,
                "name": "VMware vRealize End Point Operations for vCenter",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3581,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1472054400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3813,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 476,
                "name": "VMware vCloud Availability",
                "childId": 570,
                "parentId": 368,
                "releases": [
                    {
                        "id": 3513,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1554912000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3622,
                        "version": "3.5",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 478,
                "name": "VMware vRealize End Point Operations for Apache Tomcat",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3815,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3825,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 482,
                "name": "VMware Skyline Advisor ",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3522,
                        "version": "2.1.0",
                        "guided": true,
                        "gaDate": 1553702400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 0
            },
            {
                "id": 483,
                "name": "VMware vRealize Operations Management Pack for VMware Smart Assurance",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3526,
                        "version": "10.0",
                        "guided": true,
                        "gaDate": 1556553600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3734,
                        "version": "10.1",
                        "guided": true,
                        "gaDate": 1567440000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 484,
                "name": "VMware Workspace ONE Assist for Windows 10",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3632,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1564848000000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3719,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1573574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3978,
                        "version": "5.3.1",
                        "guided": true,
                        "gaDate": 1579017600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3981,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585497600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 488,
                "name": "VMware Pulse IoT Center",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3565,
                        "version": "2.0",
                        "guided": true,
                        "gaDate": 1567699200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3756,
                        "version": "2.0.1",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 492,
                "name": "RDS on VMware",
                "childId": 675,
                "parentId": 497,
                "releases": [
                    {
                        "id": 3592,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1571155200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 495,
                "name": "vRealize Operations Management Pack for SNMP",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3602,
                        "version": "3.0",
                        "guided": true,
                        "gaDate": 1587398400000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/3.0/rn/SNMP-30-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vrealize-operations-manager-snmp-adapter",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 498,
                "name": "VMware vRealize Operations Management Pack for CloudHealth by VMware",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3771,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.0/rn/CloudHealth-10-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4042,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1586793600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.1/rn/CloudHealth-11-Release-Notes.html",
                        "dLink": "https://marketplace.vmware.com/vsx/solutions/vmware-vrealize-operations-management-pack-for-cloudhealth-by-vmware#resources",
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 499,
                "name": "VMware vRealize Operations Management Pack for VMware Cloud on AWS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 3624,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1571241600000,
                        "rnLink": "https://docs.vmware.com/en/Management-Packs-for-vRealize-Operations-Manager/1.0/rn/VMware-Cloud-on-AWS-10-Release-Notes.html",
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 500,
                "name": "VMware Workspace ONE Tunnel for Windows 10",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4246,
                        "version": "1.4",
                        "guided": true,
                        "gaDate": 1590422400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3640,
                        "version": "1.0",
                        "guided": true,
                        "gaDate": 1566403200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3809,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1572796800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3944,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1578931200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 4086,
                        "version": "1.3",
                        "guided": true,
                        "gaDate": 1583942400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 501,
                "name": "VMware Workspace ONE Smartfolio for iOS",
                "childId": null,
                "parentId": null,
                "releases": [
                    {
                        "id": 4119,
                        "version": "1.2",
                        "guided": true,
                        "gaDate": 1586275200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3866,
                        "version": "1.1",
                        "guided": true,
                        "gaDate": 1575907200000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 503,
                "name": "VMware Workspace ONE Assist for Android",
                "childId": null,
                "parentId": 428,
                "releases": [
                    {
                        "id": 3634,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1566316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3717,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1573660800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3979,
                        "version": "20.3",
                        "guided": true,
                        "gaDate": 1585497600000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            },
            {
                "id": 504,
                "name": "VMware Workspace ONE Assist for Windows Mobile/CE",
                "childId": null,
                "parentId": 429,
                "releases": [
                    {
                        "id": 3636,
                        "version": "5.2",
                        "guided": true,
                        "gaDate": 1566316800000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    },
                    {
                        "id": 3718,
                        "version": "5.3",
                        "guided": true,
                        "gaDate": 1573574400000,
                        "rnLink": null,
                        "dLink": null,
                        "components": [],
                        "collectionComponents": [],
                        "dummy": false,
                        "techGuided": true,
                        "genGuided": true,
                        "rowReleases": null,
                        "matrix": null
                    }
                ],
                "versioned": 1
            }
        ]
}