export const environment = {
  production: false,
  // simServiceUrl: 'https://interop.esp-staging.vmware-aws.com/external',
  // simServiceUrl: 'https://apigw.vmware.com/v1/m4/api/sim-stg',
  agentUrl:"/apidataStg",
  // simServiceUrl: 'https://mp-svc.vmware.com/stg-sim/external',
  // simServiceUrl: 'https://mp-svc.vmware.com/stg-sim',
  // simServiceUrl: 'https://apigw-test.vmware.com/uat2/v1/m4/api/sim-stg',
  // simServiceUrl: 'https://apigw.vmware.com/v1/m4/api/SIM-Internal',
  // simServiceUrl: 'https://apigw-sbx.vmware.com/internal/dev12/v1/m4/api/sim-staging',
  simServiceUrl: 'https://apigw.vmware.com/v1/m4/api/sim-stg',
  feedbackUrl: 'https://feedback.esp-staging.vmware-aws.com/assets/ipf/esp.js',
  // feedbackUrl: 'https://lumos.esp-staging.vmware-aws.com/assets/lumos.js',
  feedbackFormName: 'esp-stg-225-jhvcf',
  authUrl: 'https://console-stg.cloud.vmware.com/csp/gateway',
  orgId:"d68f8adc-7e3b-48af-9c54-eb8780ad40da",
  codeChallenge:"yfnZzZb0fuhyWsVVb9xXsLyV7LDA3A0j-MMNyPGIyhI",
  codeVerifier:"YnFU3stkVtkaJ6gkT-bfMc5PUl1WzRrIv9FKBnwrXChh05-FaV1qZAJgtJkAOKFF6L4m5JvwerJc9oUR1LmR1KRi0a_1_-HD1jbR2gSEylnD6B3O9rRQ0-ij1Yzry7nd",
  logoutUrl: 'https://auth.esp-staging.vmware-aws.com/api/auth/v2/logout',
  authClientId: 'esp-stg-225-jhvcf',
  expireTime: 30 * 60 * 60, //30 mins 
};
