<clr-modal [(clrModalOpen)]="bundleOpen">
    <h3 class="modal-title">Download VCF Bundle</h3>
    <div class="modal-body">
        <p>VMware Cloud Foundation bundle is a JSON file that includes upgrade path of below 4
            solutions and their interoperability with each other.</p>
            <ul>
                <li>vSphere Hypervisor (ESXi)</li>
                <li>vCenter Server</li>
                <li>NSX-T Data Center</li>
                <li>SDDC Manager</li>
            </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="bundleOpen = !bundleOpen">CANCEL</button>
        <button type="button" class="btn btn-primary" (click)="download()">DOWNLOAD</button>
    </div>
</clr-modal>
