<!-- <div class="gradient-hr"></div> -->
<div class="ph-footer">
    <!-- <p>Contact us</p> -->
    <div class="fb-links">
      <div class="logo-container" style="display: inline-block">
        <a href="..." class="nav-link">
          <img style="background-size: contain;
              height: 39px;margin-top: 7px;" alt="VMware Logo" aria-label="VMware Logo" src="../../../images/vm-logo.svg" />
          <!-- src="../../../images/vm-logo-footer.png" -->
        </a><br />
      </div>
      <div class="footer-right-link">
        <span style="color: #fff;font-size: 12px;">Copyright © 2005-{{nowtime}} Broadcom. All Rights Reserved. The term "Broadcom" refers to Broadcom Inc. and/or its subsidiaries.</span>
        <div class="footer-social-links">
          <div class="footer-social-links-left">
            <!-- <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html" title="Your California Privacy Rights" target="_self">Your California Privacy Rights</a> -->
            <a href="https://www.vmware.com/help/legal.html" title="Terms of Use" target="_self">Terms of Use</a>
            <a href="https://www.vmware.com/help/accessibility.html" title="Accessibility" target="_self"
              name="&amp;lpos=nav_footer : 8&amp;le=event52,event75">Accessibility</a>
            <a href="https://www.vmware.com/help/trademarks.html" title="Trademarks" target="_self">Trademarks</a>
            <a href="https://www.vmware.com/help/privacy.html" title="Privacy" target="_self">Privacy</a>
            <a href="https://www.vmware.com/site_index.html" title="Site Map" target="_self">Site Map</a>
            <a href="https://www.vmware.com/topics/glossary" title="Glossary" target="_blank">Glossary</a>
            <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html" title="Your California Privacy Rights" target="_self">Your California Privacy Rights</a>
            <a href="https://www.vmware.com/help.html" title="Help" target="_self">Help</a>
            <!-- <span class="feedback right"> -->
              <a class="feedback-trigger"  href="javascript:void(0);">Feedback</a>
            <!-- </span> -->
            <!-- <span id="NewSiteFeedback" class="SiteFeedback"
          ><a
            class="footer-feeback"
            href="javascript:void(0);"
            title="Feedback"
            target="feedback-iframe"
            >Feedback</a
          ></span
        > -->
          </div>
          <span class="social-footer">
            <a href="https://twitter.com/VMware" target="_blank">
              <fa-icon [icon]="fontAwesomeIcon.faTwitter"></fa-icon>
            </a>
            <a href="https://www.facebook.com/vmware" target="_blank">
              <fa-icon [icon]="fontAwesomeIcon.faFacebookF"></fa-icon>
            </a>
            <a href="https://www.linkedin.com/company/vmware" target="_blank">
              <fa-icon [icon]="fontAwesomeIcon.faLinkedinIn"></fa-icon>
            </a>
            <a href="https://www.youtube.com/user/vmwaretv" target="_blank">
              <fa-icon [icon]="fontAwesomeIcon.faYoutube"></fa-icon>
            </a>
          </span>
        </div>
      </div>


    </div>

  </div>
