import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentsService } from '../../services/environment.service';
import { AuthService } from '../../services/auth.service';
import { JsonPipe, Location } from '@angular/common';
import { mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-login',
  template: '',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  REDIRECT_URI = '/login/authenticate';
  url: string
  constructor(private route: ActivatedRoute, private router: Router, private _env: EnvironmentsService,
    private _auth: AuthService, private _loc: Location) { }

  ngOnInit() {
    this.route.queryParams.pipe(
      mergeMap((params: any) => {
        const code = params['code'];
        const state = this.getState(params);
        if (!state) {
          return throwError("Wrong state...");
        }
        this.url = state.url;
        return this._auth.authenticase(code);
      })
    ).subscribe(
      (response: any) => {
        this._auth.saveCurrentSession(response);
        this.router.navigateByUrl(this.url);
      },
      (error) => {
        console.log(error);
        this._auth.redirectToLogin('/')
      }
    )
  }

  private getState(params) {
    const state = params['state'];
    if (state) {
      try {
        const decoded = JSON.parse(atob(state));
        if (decoded.client_id === this._env.config.authClientId) {
          return decoded;
        }
      } catch (e) { }
    }
  }

}
