import { Component, OnInit } from '@angular/core';
import { DcrService } from "../../services/dcr.service";

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {

  link: string
  opened: boolean = false;
  bundleOpen: boolean = false;
  constructor(private dcrService: DcrService) { }

  ngOnInit(): void {
  }
  openModal(type, link?) {
    switch (type) {
      case 'link':
        this.link = link;
        this.opened = true;
        break;
      case 'download':
        this.bundleOpen = true;
        break
    }


  }
  goLink() {
    let a = document.createElement('a');
    a.setAttribute('target', '_blank')
    a.setAttribute('href', this.link)
    a.click()
    this.opened = false
  }

  download() {
    this.dcrService.getbundle().subscribe(r => {
      this.downloadFile(r)
    })
    this.bundleOpen = false
  }

  downloadFile(data) {
    let str = JSON.stringify(data)
    let url = URL.createObjectURL(new Blob([str]))
    let a = document.createElement('a');
    a.setAttribute('href', url)
    a.setAttribute('download', 'vcf-bundle' + '.json')
    a.click()
    URL.revokeObjectURL(url);
  }
}
