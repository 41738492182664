import { ModuleWithProviders } from '@angular/compiler/src/core';
import { Routes, RouterModule } from '@angular/router';

import { TabPageComponent } from './components/tab-page/tab-page.component';
import { InteroperableComponent } from './components/interoperable/interoperable.component';
import { databaseTabComponent } from './components/database-tab/database-tab.component';
import { UpgradeComponent } from './components/upgrade/upgrade.component';
import { SolutionComponent } from './components/solution/solution.component';
import { LoginComponent } from "./components/login/login.component";
import { MyFavoriteComponent } from './components/my-favorite/my-favorite.component';
import { MultiCloudComponent } from './components/multi-cloud/multi-cloud.component';

export const ROUTES: Routes = [
  { path: '', redirectTo: '/Interoperability', pathMatch: 'full' },
  { path: 'login/authenticate', component: LoginComponent },
  {
    path: 'Interoperability', component: TabPageComponent, children: [
      { path: '', component: InteroperableComponent }]
  },
  {
    path: 'Database', component: TabPageComponent, children: [
      { path: '', component: databaseTabComponent }]
  },
  {
    path: 'Solution', component: TabPageComponent, children: [
      { path: '', component: SolutionComponent }]
  },
  {
    path: 'Upgrade', component: TabPageComponent, children: [
      { path: '', component: UpgradeComponent }]
  },
  {
    path: 'MultiCloud', component: TabPageComponent, children: [
      { path: '', component: MultiCloudComponent }]
  },
  {
    path: 'Myfavorite', component: MyFavoriteComponent
  },
  { path: '**', redirectTo: '/Interoperability', pathMatch: 'full' },
];

export const ROUTING: ModuleWithProviders = RouterModule.forRoot(ROUTES, { useHash: false });
