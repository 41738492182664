<div class="contain-inter">
    <div class="selproduct-area" [style.width]="selproductWid">
        <div>
            <span (click)="widchange()" class="collspan" [hidden]="left"><i nz-icon nzType="double-left"
                    nzTheme="outline"></i></span>
            <span class="collspan" [hidden]="!left"><i nz-icon nzType="double-right" nzTheme="outline"
                    (click)="widchange()"></i></span>
            <div class="selproduct-area-label" [hidden]="!left">
                Interoperability Comparison
            </div>
        </div>
        <div class="selproduct-cond" [hidden]="left">
            <h2>Compare</h2>
            <!-- <h3>Select a Solution</h3> -->
            <h3 style="color: rgba(86, 86, 86, 1); margin: 1rem 0;border-bottom: 1px solid rgba(0, 0, 0, 0.2);padding-bottom: 0.5rem;">Select VMware Products</h3>
            <span style="color: black;">Solution</span>
            <!-- <p>To show all versions leave versions blank.</p> -->
            <div style="background-color:rgba(29, 66, 138, 0.05);padding-bottom: 5px;">
                <div style="padding:10px;">
                <span>Select a Solution</span>
            <nz-select nzShowSearch [(ngModel)]="SelListModal.selectedPro" (ngModelChange)="ProductChange($event)"
                nzPlaceHolder="Select a Solution">
                <ng-container *ngFor="let p of SelListModal.ProductData">
                    <nz-option *ngIf="!LoadSel" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
                </ng-container>
                <nz-option *ngIf="LoadSel" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                </nz-option>
            </nz-select>
            <div *ngIf="SelListModal.rebrandNameList.length">
                <!-- <span>View </span> -->
                <a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
                    "Also known as"
                    <i nz-icon nzType="question-circle" nzTheme="outline"></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item><strong>This solution is also known as:</strong></li>
                        <li nz-menu-item *ngFor="let name of SelListModal.rebrandNameList,let i = index" style="cursor:not-allowed">{{name}}</li>
                    </ul>
                </nz-dropdown-menu>
            </div>
            <!-- <div *ngIf="SelListModal.rebrandNameList">
                <ng-container *ngFor="let name of SelListModal.rebrandNameList,let i = index">
                    <span *ngIf="i==0">Rebranded Name: </span>
                    <nz-tag nzColor="default">{{name}}</nz-tag>
                </ng-container>
            </div> -->
            <span>Select a Version</span>
            <nz-select nzAllowClear nzShowSearch nzMode="multiple" nzPlaceHolder="All versions"
                [(ngModel)]="SelListModal.selectedReleases" (ngModelChange)="ReleaseChange($event)">
                <nz-option *ngFor="let option of SelListModal.VersionData" [nzLabel]="option.version"
                    [nzValue]="option.id">
                </nz-option>
            </nz-select>
        </div>
            </div>
            <!-- <button class="addSolution" id="step1" nzType="primary" nz-button> <i nz-icon
                nzType="plus-circle" nzTheme="outline"></i>Add
            Another Solution</button> -->
            <h2 style="margin-top: 2rem;">With</h2>
            <h3 style="margin: 1rem 0;border-bottom: 1px solid rgba(0, 0, 0, 0.2);padding-bottom: 0.5rem;">Select Database</h3>
            <span style="color: black;">Add Database (optional)</span>
            <!-- <h3 style="margin-top: 1rem;">Add Database (optional)</h3> -->
            <!-- <p>Add databases to see if they are compatible with the selected solution.</p> -->
            <!-- <span>Solution</span> -->
            <div style="background-color:rgba(29, 66, 138, 0.05);">
                <div style="padding:10px;">
                    <!-- <span>Select a Category</span>     -->
            <nz-select nzAllowClear nzShowSearch [nzMode]="selectType" [(ngModel)]="SelDatabaseListModal.selectedDatabases"
                nzPlaceHolder="All databases" (ngModelChange)="databaseChange($event)">
                <nz-option [nzValue]="'0'" [nzLabel]="'All databases'"></nz-option>
                <nz-option-group [nzLabel]="p.name" *ngFor="let p of SelDatabaseListModal.databaseData">
                    <nz-option *ngFor="let o of p.release" [nzValue]="o.id" [nzLabel]="o.value"></nz-option>
                </nz-option-group>
            </nz-select>  
            <!-- <span>Select a Solution</span>    
            <nz-select nzAllowClear nzShowSearch nzMode="multiple" nzPlaceHolder="All versions"
                [(ngModel)]="SelListModal.selectedReleases" (ngModelChange)="ReleaseChange($event)">
                <nz-option *ngFor="let option of SelListModal.VersionData" [nzLabel]="option.version"
                    [nzValue]="option.id">
                </nz-option>
            </nz-select>
            </div>
            </div>
            <button class="addSolution" id="step1" nzType="primary" nz-button> <i nz-icon
                nzType="plus-circle" nzTheme="outline"></i>Add
            Another Solution</button>
            </nz-select> -->
            </div>
            </div>
            <!-- <button class="addSolution" id="step1" nzType="primary" nz-button> <i nz-icon
                nzType="plus-circle" nzTheme="outline"></i>Add
            Another Solution</button> -->
            <div class="fix-but-reset">
                <div class="fix-but">
                    <button class="checkInter" id="checkInter_1" nzType="primary" (click)="CheckInter()" nz-button
                    [disabled]="LoadSel||disabledCheckInterop" nzBlock>
                    CHECK INTEROPERABILITY</button>
                </div>
                <!-- <button class="checkInter" nzType="primary" (click)="reset()" nz-button
                    [disabled]="LoadSel||disabledCheckInterop" nzBlock>
                    RESET</button> -->
                <span (click)="reset(!disabledCheckInterop||!tableEmpty)" [ngStyle]="{'cursor':!disabledCheckInterop||!tableEmpty?'pointer':'not-allowed'}" style="color: #1d428a;font-weight: 600">CLEAR</span>
            </div>
        </div>
    </div>
    <div class="tableShow-area" [style.width]="tableShowWid" [style.overflow]="tableShowOverflow"
        (scroll)="onScroll($event)">
        <div *ngIf="hideTip">
            <nz-alert nzBanner nzMessage='Unselect "Hide Legacy Releases" to check more interoperability.' nzCloseable></nz-alert>
        </div>
        <div class="tableShow-header">
            <div class="check-1" style="display: none;margin-top: 10px;">
                <h3>Hide Interoperability</h3>
                <div class="checkArea">
                    <ng-container *ngFor="let c of checkOptionsOne,let i = index">
                        <label title="{{tooltipTwo[3]}}" (ngModelChange)="log(checkOptionsOne)" nz-checkbox
                            [(ngModel)]="c.checked">{{c.label}}</label>
                    </ng-container>
                </div>
            </div>
            <div class="check-2" style="flex-grow: 2;margin-top: 10px;">
                <h3>Hide Legacy Releases
                    <!-- <i style="cursor: pointer;" nz-icon nzTooltipTitle="Unselect 'Hide Legacy Releases' to check more interoperability." nzType="exclamation-circle" nzTheme="outline" nz-tooltip></i> -->
                    <i style="cursor: pointer;" nz-icon title="Unselect 'Hide Legacy Releases' to check more interoperability." nzType="exclamation-circle" nzTheme="outline" nz-tooltip></i>
                </h3>
                <div class="checkArea">
                    <ng-container *ngFor="let c of checkOptionsTwo,let i = index">
                        <label title="{{tooltipTwo[i+4]}}" (ngModelChange)="log(checkOptionsOne)" nz-checkbox
                            [(ngModel)]="c.checked">{{c.label}}</label>
                    </ng-container>
                </div>
            </div>
            <div class="divBut" style="text-align: right;margin-top:-10px">
                <nz-button-group nzSize="small" class="tableBut">
                    <button nzType="text" title="{{tooltipOne[0]}}" nz-button [disabled]="tableEmpty"
                            (click)="printLink()">PRINT</button><span> | </span>
                    <button nzType="text" title="{{tooltipOne[1]}}" nz-button [disabled]="tableEmpty"
                            (click)="CSVlink()">CSV</button><span> | </span>
                    <button nzType="text" title="{{tooltipOne[2]}}" nz-button [disabled]="tableEmpty"
                            (click)="copyClick()">COPY</button>
                </nz-button-group>
            </div>
        </div>
        <div class="tableShow-table">
            <div class="butArea">
                <!-- <div class="divBut">
                    <nz-button-group nzSize="small" class="tableBut">
                        <button nzType="primary" title="{{tooltipOne[0]}}" nz-button [disabled]="tableEmpty"
                            (click)="printLink()">Print</button>
                        <button nzType="primary" title="{{tooltipOne[1]}}" nz-button [disabled]="tableEmpty"
                            (click)="CSVlink()">CSV</button>
                        <button nzType="primary" title="{{tooltipOne[2]}}" nz-button [disabled]="tableEmpty"
                            (click)="copyClick()">Copy</button>
                    </nz-button-group>
                </div> -->

                <!-- <div [style.height]="'30px'" class="tableTop">
                    <div class="thLeft" style="width:100%">
                        <a class="columns" style="pointer-events: auto;"
                            [ngStyle]="{'cursor':tableEmpty?'not-allowed':''}" nz-dropdown [nzDisabled]="tableEmpty"
                            nzTrigger="click" [nzDropdownMenu]="menu">
                            <span>Hide Columns</span>
                        </a>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <div class="dropDownCls">
                                <div class="Down-header">Show Columns</div>
                                <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="unCheck($event)">
                                    <div nz-row>
                                        <div *ngFor="let i of ShowColumnData" nz-col nzSpan="24"><label nz-checkbox
                                                [nzValue]="i" [nzDisabled]="i.disabled"
                                                [ngModel]="i.checked">{{i.name}}</label></div>
                                    </div>
                                </nz-checkbox-wrapper>
                            </div>
                        </nz-dropdown-menu>
                    </div>
                </div> -->
                <nz-table [nzFrontPagination]="false" id="databaseTable" #groupingTable [nzData]="productListData"
                    nzBordered nzSize="small" nzHideOnSinglePage="true" [nzNoResult]="emptyTemplate">
                    <ng-template #emptyTemplate>
                        <tr>
                            <nz-empty *ngIf="NoResultId=='No Data'" nzNotFoundImage="simple"></nz-empty>
                            <nz-empty *ngIf="NoResultId=='No Result'" nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                [nzNotFoundContent]="contentTpl">
                                <ng-template #contentTpl>
                                    <span> {{NoResultId}} </span>
                                </ng-template>
                            </nz-empty>
                        </tr>
                    </ng-template>
                    <thead>
                        <tr>
                            <th nzLeft rowspan="2" nzWidth="1%" nzLeft class="lenged" style="background-color: white;">
                                <div style="width: 330px;display: flex;">
                                    <ul>
                                        <li title="{{tooltipTwo[0]}}" class="comptibility">Compatible</li>
                                        <li title="{{tooltipTwo[2]}}" class="Incompatible">Incompatible
                                        </li>
                                        <li title="{{tooltipTwo[3]}}" class="notSupport">Not Supported
                                        </li>
                                    </ul>
                                    <ul>
                                        <!-- <li title="{{tooltipTwo[1]}}" class="comptibility_NT">Compatible:
                                            Not
                                            Tested
                                        </li> -->
                                        <li title="{{tooltipTwo[5]}}" class="EOTS">Past End of Technical
                                            Guidance</li>
                                        <li title="{{tooltipTwo[4]}}" class="ENGS">Past End of General
                                            Support</li>
                                    </ul>
                                </div>
                            </th>
                            <ng-container *ngFor="let c of columnData">
                                <th nzLeft class="firstTh" style="text-align: left;top: 0px;z-index: 20;background-color: white;"
                                    [colspan]="c.length">
                                    {{c.name}}
                                </th>
                            </ng-container>
                        </tr>
                        <tr>
                            <ng-container *ngFor="let releases of columnData">
                                <ng-container *ngFor="let release of releases.releases">
                                    <th nzLeft nz-tooltip [nzTooltipTitle]="titleTemplate"
                                        style="text-align: center;top: 39px;z-index: 20;font-size: 12px;background: white;">
                                        {{release.version=="Nonversioned"?"":release.version}}
                                        <span [ngClass]="{'techGuided' : !release.techGuided }"></span>
                                        <span [ngClass]="{'genGuided': !release.genGuided }"></span>
                                    </th>
                                    <ng-template #titleTemplate>
                                        <span>{{release.version}}</span><br>
                                        <span>GA : {{formatTime(release.gaDate)}}</span><br>
                                        <span *ngIf="release.rnLink"><a href="{{release.rnLink}}"
                                                target="_blank">Release
                                                Notes</a><br></span>
                                        <span *ngIf="release.dLink"><a href="{{release.dLink}}" target="_blank">Download
                                                Page</a><br></span>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let database of groupingTable.data">
                            <td nzLeft style="color: rgba(0,0,0,.85);">{{database.name}}</td>
                            <ng-container *ngFor="let releases of database.prodReleaseMap,let i = index">
                                <ng-container *ngFor="let release of releases.releases,let j = index">
                                    <td *ngIf="!release.footnotes" (mouseover)="tdMouseOver(i,j)"
                                        (mouseout)="tdMouseOut()"
                                        [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                        [ngClass]="displayStatus(release.status,release.footnotes,release)">
                                    </td>
                                    <td *ngIf="release.footnotes" nz-tooltip [nzTooltipTitle]="tdInfo"
                                        (mouseover)="tdMouseOver(i,j)" (mouseout)="tdMouseOut()"
                                        [ngStyle]="{'background-color':tdSelected==i+''+j?'#fafafa':''}"
                                        [ngClass]="displayStatus(release.status,release.footnotes,release)">
                                    </td>
                                    <ng-template #tdInfo>
                                        <span [innerHTML]="release.footnotes"></span><br>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
            <div id="databasePrint" [style.display]="copyType">
                <table>
                    <tr>
                        <th></th>
                        <ng-container *ngFor="let c of columnData">
                            <ng-container *ngFor="let r of c.releases">
                                <th style="text-align: center;">{{c.name}} {{r.version}} <span
                                        *ngIf="!r.techGuided||!r.genGuided">*</span></th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tbody>
                        <tr *ngFor="let database of groupingTable.data">
                            <td>{{database.name}}</td>
                            <ng-container *ngFor="let releases of database.prodReleaseMap">
                                <ng-container *ngFor="let release of releases.releases">
                                    <td>
                                        <span>{{tableTdWords[release.status]}}</span>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </tbody>
                    <tr></tr>
                </table>
                <span>* This is a legacy release. For more information, please refer to https://lifecycle.vmware.com.</span>
            </div>
        </div>
    </div>
    
   
</div>
<ngx-loading [show]="isVisible" [config]="{ backdropBorderRadius: '3px'}" [template]="customLoadingTemplate">
</ngx-loading>