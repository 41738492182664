
export class SelectBus {
    id: number;
    name: string;
    products: string
}

export class selectedPro {

}

export class ProductInter {
    ProductData: any[];
    VersionData: any[];
    selectedPro: any;
    selectedReleases: any[];
    versionHide: boolean;
    rebrandNameList: any[]
}

export class CategoryInter {
    CategoryData: any[];
    ProductData: any[];
    selectCatagory: any;
    selectProduct: any;
    versionHide: boolean;
}

export class position {
    x:number;
    y:number;

    constructor(xInput:number,yInput:number) {
        this.x = xInput;
        this.y = yInput;
    }
}