import { Component, OnInit } from '@angular/core';
import { DcrService } from '../../services/dcr.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { RESOURCE_TYPE, EVENT_TYPE } from '../../model/common.model';
import { mergeMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-favorite',
  templateUrl: './my-favorite.component.html',
  styleUrls: ['./my-favorite.component.scss']
})
export class MyFavoriteComponent implements OnInit {

  projectList: any[] = [];
  productlist: any[] = [];
  categorylist: any[] = [];
  activeType: string = RESOURCE_TYPE.INVENTORY;
  emptyList: boolean = false;
  loading: boolean = true;
  showDeleteModal: boolean = false;
  deleteId: number;
  constructor(private _dcr: DcrService,private _data: DataService,
    private router: Router,private route: ActivatedRoute,private common: CommonService,) { }

  ngOnInit(): void {
    this.getFavoriteData(RESOURCE_TYPE.INVENTORY);
    if(this._data.getProductSelData().length == 0) this.getProduct();
    if(this._data.getVersionData().length == 0) this.getCategory();
  }

  onClick(type, item) {
    switch (type) {
      case EVENT_TYPE.CHECK:
        this.formUrl(item);
        break;
      case EVENT_TYPE.DELETE:
        this.showDeleteModal = true;
        this.deleteId = item.id;
        break;
      case EVENT_TYPE.SHARE:
        console.log("share")
        break;
    }
  }

  deleteFavorite() {
    this.showDeleteModal = false;
    this._data.clearDataOfType(this.activeType);
    this._dcr.deleteFavoriteList(this.deleteId).subscribe(d => this.getFavoriteData(this.activeType));
  }

  getFavoriteData(type: string) {
    this.activeType = type;
    this.loading = true;
    this._dcr.getProduct(type).pipe(
      mergeMap((params: any) => {
        this.productlist = params;
        return this._dcr.getFavoriteList(type)
      })
    ).subscribe((d: any) => {
      this.emptyList = false;
      this.loading = false;
      this.projectList = d.sort((a,b)=> b.lastUpdateDate - a.lastUpdateDate).map(r => {
        if (type == RESOURCE_TYPE.UPGRADE) {
          return {
            ...r,
            modelList: [
              { name: "product", expand: false, productList: this.getProductList(r.aReleases) }
            ]
          }
        }
        return {
          ...r,
          modelList: [
            { name: "Compare", expand: false, productList: this.getProductList(r.aReleases) },
            { name: "With", expand: false, productList: this.getProductList(r.bReleases,type) }]
        }
      })
    }, error => {
      this.productlist = [];
      this.emptyList = true;
    }
    )
  }

  getProductList(abRelease: string,type?:string) {
    let releaseOri = JSON.parse(abRelease), pro, ver;
    if(type == RESOURCE_TYPE.THIRDPARTY){
      this.categorylist = this._data.getVersionData()
      return releaseOri.map((r) => {
        pro = this.getProductName(this.categorylist, r.product[0],type);
        ver = this.getReleaseName(pro,pro?.products, r.release,type);
        return {
          product: pro?.name,
          release: ver
        }
      })
    }
    if(type == RESOURCE_TYPE.MULTICLOUD){
      this.productlist = this._data.getProductSelData();
      return releaseOri.map((r) => {
        pro = this.getProductName(this.productlist, r.product[0],type);
        ver = this.getReleaseName(pro,pro?.releases, r.release,type);
        return {
          product: pro?.name,
          release: ver
        }
      })
    }
    return releaseOri.map((r) => {
      pro = this.getProductName(this.productlist, r.product[0]);
      ver = this.getReleaseName(pro,pro?.releases, r.release);
      return {
        product: pro?.name,
        release: ver
      }
    })
  }

  getProductName(productList: any[], key,type?:string) {
    let version = [];
    if (!key){
      return type == RESOURCE_TYPE.THIRDPARTY ? { name: "All Categories", releases: [] } : { name: "All Solutions", releases: [] }
    } else if(productList == this._data.getVersionData()){
      return productList.filter(x => x.id == key)[0]
    }else{
      version = this.common.getVersion(key,productList);
      version = version.sort((a, b) => { return this.common.sortVersion(a, b) });
      version = this.common.reDuplicates(version);
      let prod = productList.filter(x => x.id == key)[0];
      prod.releases =version; 
      return prod;
    }
  }

  getReleaseName(pro:any,relList: any[], key: any[],type?:string): string {
    if (!key.length) {
      let releases = pro?.releases
      let versionname =  releases?.length == 1 ?  pro?.releases[0]?.version : ''
      return type == RESOURCE_TYPE.THIRDPARTY ? "All Solutions" : 
      (versionname.indexOf("Nonversioned")!=-1 || versionname == "0.0.0") ? '' : "All Versions";
    }
    let relMap = {};
    relList.forEach((r) => {
      relMap[r.id] = (r?.name?r?.name:"") + " " + r.version
    })
    return key.reduce((pre, cur, i) => pre + (i == 0 ? "" : ' , ') + relMap[cur], "")
  }

  formUrl(item){
    switch(item.section){
      case RESOURCE_TYPE.INVENTORY:
        let p = this.formatInventoryParam(item.aReleases,item.bReleases);
        this.router.navigate(
          ['/Interoperability'],
          {
            relativeTo: this.route,
            queryParams: {col: p.col, row: p.row },//{ col: colVal, row: rowVal }
            queryParamsHandling: 'merge',
          });
        break;
      case RESOURCE_TYPE.MULTICLOUD:
        let pr = this.formatInventoryParam(item.aReleases,item.bReleases);
        this.router.navigate(
          ['/MultiCloud'],
          {
            relativeTo: this.route,
            queryParams: {col: pr.col, row: pr.row },
            queryParamsHandling: 'merge',
          });
        break;
      case RESOURCE_TYPE.THIRDPARTY:
        let param = this.formatSolutionParam(item.aReleases,item.bReleases);
        this.router.navigate(
          ['/Solution'],
          {
            relativeTo: this.route,
            queryParams: { key: JSON.stringify(param) },
            queryParamsHandling: 'merge',
          });
        break;
      case RESOURCE_TYPE.UPGRADE:
        let value = JSON.parse(item.aReleases)[0].product.toString();
        this.router.navigate(
          ['/Upgrade'],
          {
            relativeTo: this.route,
            queryParams: { productId: value },
            queryParamsHandling: 'merge',
          });
        break;
    }

  }
  formatUrlParam(d: any) {
    let n: string[] = [];
    d.forEach((r) => { n.push(`${r.product},${r.releases.join(",")}`) });
    return n.join("&")
  }
  formatInventoryParam(c,r){
    let colVal=[];
    let rowVal=[];
    let col = JSON.parse(c);
    let row = JSON.parse(r);
    col.forEach(p => {
      p.release.length==0 ? colVal.push({"product": p.product[0], "releases": []}) : colVal.push({"product": p.product[0], "releases": p.release})
    });
    row.forEach(v => {
      if(v.product.length==0 && v.release.length==0){
        rowVal.push({"product": 0, "releases": []})
      }else{
        v.release.length==0 ? rowVal.push({"product": v.product[0], "releases": []}) : rowVal.push({"product": v.product[0], "releases": v.release})
      }
      
     
    });
    let colParam = this.formatUrlParam(colVal);
    let rowParam = this.formatUrlParam(rowVal);
    return {"col":colParam,"row":rowParam};
  }
  formatSolutionParam(c,r){
    let colVal=[];
    let rowVal=[];
    let col = JSON.parse(c);
    let row = JSON.parse(r);
    col.forEach(p => {
      p.release.length==0 ? colVal.push({"productId":p.product[0],"releases":[]}) : colVal.push({"productId":p.product[0],"releases":p.release})
    });
    row.forEach(v => {
      v.product.length==0 && v.release.length==0 ? rowVal.push({"categoryId": 0,"extProdIds":[]}) : rowVal.push({"categoryId":v.product[0],"extProdIds":v.release})
    });
    return {"rows":colVal,"columns":rowVal};
  }

  getCategory(){
    this._dcr.getCategory().subscribe(data => {
      this._data.setVersionData(data)
    })
  }

  getProduct(){
    this._dcr.getProduct("product").subscribe(data => {
      this._data.setProductSelData(data);
    })
  }
}
