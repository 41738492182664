import { Injectable } from '@angular/core';
import { EnvironmentsService } from "./environment.service";


declare var common: any;
declare var commonlumos: any;
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  url: string;
  formName: string;


  constructor(private environmentsService: EnvironmentsService) {
    this.url = `${this.environmentsService.config.feedbackUrl}`;
    this.formName = `${this.environmentsService.config.feedbackFormName}`;
  }

  showFeedback(): void {
    if(this.url.includes("lumos")){  
      commonlumos.feedback(this.url).init(this.formName, 'VMware SIM');   
    }else{
      common.feedback(this.url).init(this.formName, 'VMware SIM');  
    }
  }

  identifyUser(email: String): void {
    
    if(this.url.includes("lumos")){  
      commonlumos.lumos.identify(email); 
    }else{
      common.esp.identify(email);
    }
  }
}
