<header class="header-7">
  <div class="branding">
    <a href="./" class="nav-link">
      <img alt="VMware Logo" aria-label="VMware Logo" src="../../../images/vm-logo.svg" />
      <span>Product Interoperability Matrix</span>
    </a>
  </div>
  <div class="header-actions">
    <a href="https://blogs.vmware.com/" target="_blank" class="nav-link">Communities</a>
    <a href="https://store-eu.vmware.com/" target="_blank" class="nav-link">Store</a>
    <a id="loginLink" *ngIf="!isLogin;else user" (click)="logIn()" target="_blank" class="nav-link">Login</a>
    <ng-template #user>
      <clr-dropdown id="userInfo">
        <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
          {{name}}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a [routerLink]="['/Myfavorite']"  clrDropdownItem>My Favorite</a>
          <a (click)="logout()" clrDropdownItem>Log out</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </ng-template>
  </div>
</header>